import React, { FC } from "react";
import { TypographyProps } from "@mui/material/Typography/Typography";
import { BoxProps } from "@mui/material/Box/Box";
import { Box, Typography } from "@mui/material";


interface Props {
  label: string,
  value: string | number | React.ReactNode,
  ValueProps?: TypographyProps
  fullWidth?: boolean
  Props?: BoxProps
}

const CardField: FC<Props> = ({ label, value, ValueProps, fullWidth, Props }: Props) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        mb: 2,
        ...(Props?.sx && { ...Props?.sx })
      }}
    >
      <Typography
        variant={"caption"}
        color={"text.secondary"}
        sx={{
          whiteSpace: 'nowrap',
          alignSelf: 'start',
        }}
      >
        {label}:&nbsp;
      </Typography>
      {
        fullWidth && (
          <Box
            flexGrow={1}
            borderBottom={"1px dashed"}
            borderColor={"divider"}

            height={".8rem"}
            mx={1}
          />
        )
      }

      <Typography
        component={'span'}
        {...ValueProps}
        sx={{
          fontWeight: 600,
          lineHeight: "1.3",
          textAlign: "right",
          ...(ValueProps?.sx && { ...ValueProps?.sx })
        }}
        variant={"body2"}
      >
        {value}
      </Typography>
    </Box>
  )
    ;
};

export default CardField;