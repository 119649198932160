import React from "react";
import {
  Box,
  Dialog as MuiDialog,
  dialogClasses,
  DialogContent,
  DialogProps,
  DialogTitle as MuiDialogTitle,
  IconButton as MuiIconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { lighten, styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Breakpoint } from "@mui/system";
import { BootstrapTooltip } from "../BootstrapTooltip";


interface IDialogProps extends DialogProps {
  minHeight?: number,
}

const Dialog = styled((rest: IDialogProps) => {
  const {minHeight, ...props} = rest
  return <MuiDialog {...props} />
})(({minHeight, theme}) => ({
  [`& .${dialogClasses.paper}`]: {
    width: '100%',
    minHeight: minHeight ? minHeight : 'calc(100% - 34px * 2)',
    backgroundColor: lighten(theme.palette.background.default, .2),
  },
}))

const DialogTitle = styled(MuiDialogTitle)(({theme}) => ({
  padding: theme.spacing(0, 3),
  position: "sticky",
  top: 0,
  display: 'flex',
  margin: 0,
  height: 48,
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1
}))

const Title = styled(Typography)(({theme}) => ({
  width: '100%',
  textAlign: 'left'
})) as typeof Typography

const IconButton = styled(MuiIconButton)(({theme}) => ({
  marginLeft: 'auto',
  color: theme.palette.text.secondary,
  padding: theme.spacing(.3),
  fontSize: '20px'
}))


interface Props {
  title: string
  onClose: () => void
  children: any
  maxWidth?: Breakpoint | false
  ActionsComponent?: any
  ActionsProps?: any
  minHeight?: number
  additionalButtonComponents?: React.ReactNode[]
}

const PageDialog = (props: Props) => {
  const {children, onClose, title = '', maxWidth = 'md', ActionsComponent, ActionsProps, minHeight, additionalButtonComponents} = props
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {

    const {current: descriptionElement} = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }

  }, [descriptionElementRef]);

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        maxWidth={maxWidth}
        scroll={'paper'}
        minHeight={minHeight}
      >
        <DialogTitle>
          <Title variant={'h5'} align={"center"} component={'div'}> {title} </Title>

          <Stack direction={'row'} spacing={2} alignItems={'center'}>

            {additionalButtonComponents && additionalButtonComponents?.map(component =>(component))}

            <Box>
              <BootstrapTooltip title={'Закрыть'}>
                <IconButton onClick={onClose}> <CloseIcon fontSize={'inherit'}/> </IconButton>
              </BootstrapTooltip>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            position: 'relative',
            zIndex:0,
            display: 'flex',
            flexDirection: 'column',
          }}
          dividers
        >
          <Stack
            flex={1}
            ref={descriptionElementRef}
            tabIndex={-1}
            height={'100%'}
          >
            {children}
          </Stack>
        </DialogContent>
        {ActionsComponent && <ActionsComponent {...ActionsProps} />}
      </Dialog>
    </>
  );
};

export default PageDialog
