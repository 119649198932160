import React from 'react';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


interface StyledTabProps {
    label: string;
}


export const AntTabs = withStyles((theme: Theme) => ({
    root: {
        borderBottom: '1px solid #e8e8e8',
        '& .MuiTab-root': {
            margin: 0,
            fontWeight: '500',
            '&.Mui-selected': {
                background: 'white',
            }
        }
    },
    indicator: {
        backgroundColor: theme.palette.primary.main,
        transform: 'scaleX(.7)',
        bottom: -1
    },
}))(Tabs);

export const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightBold,
            letterSpacing: '-0.24px',
            marginRight: theme.spacing(4),
            opacity: .7,
            transition: 'all .3s',
            fontFamily: [
                '-apple-system',
                'BlinkMacSystemFont',
                '"Segoe UI"',
                'Roboto',
                '"Helvetica Neue"',
                'Arial',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
            '&:hover': {
                color: '#353d43',
                opacity: 1,
            },
            '&$selected': {
                color: '#353d43',
                opacity: 1,
            },
            '&:focus': {
                color: '#353d43',
            },
        },
        selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

