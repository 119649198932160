import React from 'react';
import {observer} from "mobx-react-lite";
import {
	Button, DialogContentText,
} from "@mui/material";
import {
	EquipmentRelocationRequestEntity,
	RelocateEquipmentInput,
} from "../../../../graphql/graphQlApiHooks";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";
import {useStores} from "../../../../store";

interface Props {
	data?: EquipmentRelocationRequestEntity | null
	open: boolean
	onClose: () => void
}

const RelocateCompletion = observer((props: Props) => {
	const {data, open, onClose} = props;
	const {equipmentRelocationRequestStore} = useStores();

	const handleConfirm = () => {
		const input: RelocateEquipmentInput = {
			relocateRequestId: data?.id as string,
			targetSportObjectId: data?.targetSportObject?.id as string,
		}

		equipmentRelocationRequestStore.relocate(input).then(() => {
			handleClose()
		}).catch((error: any) => {
			console.error(error.message)
		})

	};

	function handleClose() {
		onClose()
	}

	return (

			<Dialog
					open={open}
					onClose={handleClose}
					title={'Подтверждение получения оборудования'}
					titles={[
						<EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt} />,
						<StepperRelocation targetSportObject={data?.targetSportObject}
						                   sourceSportObject={data?.sourceSportObject}/>
					]}
					actions={<>
						<Button
								variant={'contained'}
								onClick={handleConfirm}
								color={'primary'}
						>Подтвердить получение</Button>
					</>}
			>
				<DialogContentText variant={'h4'}>
					Вы подтверждаете, что оборудование было получено?
				</DialogContentText>

			</Dialog>
	);
})

export default RelocateCompletion;
