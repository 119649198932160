import React from 'react';
import {IconButton} from '@mui/material';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NavItem from "../views/infrastructure/Layouts/Dashboard/NavBar/NavItem";


function LofOut({...rest}) {

    function logOutHandler() {
        // AuthInfo.clearUser()
    }

    return <>
        {rest.iconButton ?
            (<IconButton
                aria-label="delete"
                onClick={logOutHandler}
                size="large"
            >
                <ExitToAppIcon/>
            </IconButton>) :
            (
                <NavItem
                    className={"NavItem"}
                    title={'Выход'}
                    icon={<ExitToAppIcon/>}
                    clickHandler={logOutHandler}
                />

            )
        }

    </>;
}

LofOut.protoTypes = {}

export default LofOut;
