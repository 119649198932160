import React, {useState} from 'react';
import {Tooltip, Typography} from "@mui/material";
import {getNodeText} from "helpers/getNodeText";
import { BootstrapTooltip } from "./BootstrapTooltip";

interface Props {
  text: string | JSX.Element
  props?: {
    [key: string]: any;
  }
}

const Tooltiper = (rest: Props) => {
  const {props} = rest;
  const [tooltipDisabled, setTooltipDisabled] = useState(true);
  const [tooltipOpen, setTooltipOpen,] = useState(false);
  const text = (typeof rest.text !== 'string') ? String(getNodeText(rest.text)) : rest.text



  return (
    <BootstrapTooltip
      open={!tooltipDisabled && tooltipOpen}
      onClose={() => !tooltipDisabled && setTooltipOpen(false)}
      onOpen={() => !tooltipDisabled && setTooltipOpen(true)}
      title={(text) ? `${text?.charAt(0).toUpperCase()}${text?.slice(1)}` : ''}
    >
      <Typography
        variant={'inherit'}
        color={'inherit'}
        fontSize={'inherit'}
        {...props}
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          overflow: 'hidden',
        }}
        onMouseEnter={(e: any) => setTooltipDisabled(!(e.target?.offsetWidth < e.target?.scrollWidth))}
        onMouseLeave={(e: any) => setTooltipDisabled(!(e.target?.offsetWidth < e.target?.scrollWidth))}
      >
        {rest.text}
      </Typography>
    </BootstrapTooltip>
  );
}

export default Tooltiper;
