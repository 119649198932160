import React, { useState } from "react";
import { Box, Button, Divider } from "@mui/material";
import ruLocale from "date-fns/locale/ru";

import { DateRange, DateRangePicker } from "../mui-daterange-picker";

const FilterDateRange = (props: {
  value: DateRange | null,
  filterResult: (result: DateRange | null) => any,
  onClose?: (result:DateRange | null) => void
}) => {
  const {filterResult, onClose} = props;

  const [dateRange, setDateRange] = useState<DateRange | null>(props?.value || null);

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          minWidth: '500px'
        }}
      >
        <DateRangePicker
          open={true}
          toggle={() => {
          }}
          onChange={(range) => {
            setDateRange(range)
          }}
          locale={ruLocale}
          initialDateRange={props.value}
        />
        <Divider/>
        <Box p={1} justifyContent={'end'} display={'flex'}>

          <Box display={'flex'}>
            <Button
              onClick={() => {
                filterResult(dateRange)
                onClose && onClose(dateRange);
              }}
              variant={'contained'}
              size={'small'}

            >
              Применить
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default FilterDateRange;
