import React, { useEffect } from "react";
import { Box, Button, Collapse, Stack } from "@mui/material";

interface Props {
  isSelectable: boolean;
  selectedPhotos: string[];
  onSelectAll: () => void;
  onUnSelectAll: () => void;
  onDeleteSelected: () => void;
}

export const SportEquipmentPhotosToolbar = (props: Props) => {
  const {
    isSelectable,
    selectedPhotos,
    onSelectAll,
    onUnSelectAll,
    onDeleteSelected
  } = props;



  return (
    <Collapse
      in={isSelectable}
      sx={{
        width: "100%",
        margin: "0 !important",
        pt: isSelectable ? 1 : 0
      }}
    >

      <Stack direction={"row"} spacing={2}>
        <Button
          size={"small"}
          onClick={onSelectAll}
        >
          Выбрать все
        </Button>
        <Button
          size={"small"}
          onClick={onUnSelectAll}
          disabled={selectedPhotos.length === 0}
        >
          Снять выделения
        </Button>
        <Box flexGrow={1} />
        <Button
          size={"small"}
          color={"secondary"}
          onClick={onDeleteSelected}
          disabled={selectedPhotos.length === 0}
        >
          Удалить выбранное
        </Button>
      </Stack>
    </Collapse>
  );
};
