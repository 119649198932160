import React, {useEffect} from 'react';
import {Box, IconButton, ListItemSecondaryAction, ListSubheader, Menu, MenuItem, Skeleton} from '@mui/material';
import List from "@mui/material/List";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddIcon from '@mui/icons-material/Add';
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import AddSportZone from "../../Dialogs/AddSportZone";
import DeleteSportZone from "../../Dialogs/DeleteSportZone";
import EditSportZone from "../../Dialogs/EditSportZone";
import {useStores} from '../../../../../../store';
import {Observer} from 'mobx-react-lite';
import {useStyles} from "./styles";


function SportAreasList({...rest}) {
	const classes = useStyles();
	const {settingsStore} = useStores();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [anchorElMoreAction, setAnchorElMoreAction] = React.useState<null | HTMLElement>(null);
	const [anchorElEditDialog, setAnchorElEditDialog] = React.useState<null | HTMLElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [sportZoneDeleteDialog, setSportZoneDeleteDialog] = React.useState<boolean>(false);

	const [openAddSportZone, setOpenAddSportZone] = React.useState<boolean>(false);

	const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number,) => {
		setSelectedIndex(index)
		if (settingsStore.sportZones) {
			settingsStore.setSportZone(settingsStore.sportZones[index].id)
		}
	};

	useEffect(() => {
		if (settingsStore?.sportZone && settingsStore?.sportZones) {
			setSelectedIndex(settingsStore.sportZones.findIndex((z: any) => z.id === settingsStore.sportZone.id))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settingsStore.sportZone])

	const handleClickAddSportZonePanel = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpenAddSportZone(true)
	};

	const handleCloseAddSportZonePanel = () => {
		setAnchorEl(null);
		setOpenAddSportZone(false)
	};

	const handleCloseEditSportZonePanel = () => {
		setAnchorElEditDialog(null);
	};

	const handleClickMoreAreas = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorElMoreAction(event.currentTarget);
	};

	const handleCloseMoreAreas = () => {
		setAnchorElMoreAction(null);
	};

	const deleteSportZoneHandle = (event: React.MouseEvent<HTMLElement>, anchor: HTMLElement | null) => {
		const sportZoneId = anchor?.getAttribute('aria-label')
		settingsStore.setSportZone(sportZoneId)
		setSportZoneDeleteDialog(true)
	};

	function editSportZoneHandle(event: any, anchor: HTMLElement | null) {
		const sportZoneId = anchor?.getAttribute('aria-label')
		settingsStore.setSportZone(sportZoneId)
		setAnchorElEditDialog(event.currentTarget);
	}

	return <Observer>{() =>
			<>

				<Box className={classes.sportAreasList}>
					<List
							component="nav"
							aria-labelledby="nested-list-subheader"
							className={classes.list}

							subheader={
								<ListSubheader
										component="div"
										id="nested-list-subheader"
								>
									Объекты спорта
									<IconButton
											aria-controls="customized-menu"
											aria-haspopup="true"
											className={classes.subHeaderDetails}
											size="small"
											onClick={handleClickAddSportZonePanel}
									>
										<AddIcon fontSize="inherit"/>
									</IconButton>
									<AddSportZone
											anchorEl={anchorEl}
											open={openAddSportZone}
											onClose={handleCloseAddSportZonePanel}
									/>

								</ListSubheader>}
					>


						<List
								className={classes.list}
								dense
								component="div"
								role="list"
								disablePadding={true}
						>
							{
								(settingsStore.sportZones) ?
										(settingsStore.sportZones
														.map((value: any, index: number) => (
																<ListItem
																		key={value.id}
																		role="listitem"
																		button={true}
																		className={classes.listItem}
																		selected={selectedIndex === index}
																		onClick={(event) => handleListItemClick(event, index)}
																>
																	<ListItemText primary={value.name}/>
																	<ListItemSecondaryAction
																			classes={{root: classes.listItemSecondaryAction}}
																	>
																		<IconButton
																				aria-controls="sportZone-more-action"
																				aria-haspopup="true"
																				size="small"
																				aria-label={value.id}
																				onClick={handleClickMoreAreas}
																		>
																			<MoreHorizIcon fontSize="inherit"/>

																		</IconButton>

																	</ListItemSecondaryAction>
																</ListItem>
														))
										) : <Skeleton/>
							}
							<Menu
									id="sportZone-more-action"
									anchorEl={anchorElMoreAction}
									keepMounted
									open={Boolean(anchorElMoreAction)}
									onClose={handleCloseMoreAreas}
									className={classes.sportZoneMoreAction}

									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'right',
									}}

									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
							>
								<MenuItem
										onClick={(event: any) => editSportZoneHandle(event, anchorElMoreAction)}
								>Изменить</MenuItem>
								<MenuItem
										onClick={(event: any) => deleteSportZoneHandle(event, anchorElMoreAction)}
								>Удалить</MenuItem>

							</Menu>


							<EditSportZone
									anchorEl={anchorElEditDialog}
									onClose={handleCloseEditSportZonePanel}
									currentSportZone={settingsStore.sportZone}
							/>
							<DeleteSportZone
									open={sportZoneDeleteDialog}
									onClose={(state) => {
										handleCloseMoreAreas()
										setSelectedIndex(0)
										setSportZoneDeleteDialog(state);
									}}
									currentSportZone={settingsStore.sportZone}
							/>


						</List>
					</List>
				</Box>

			</>
	}</Observer>
}

SportAreasList.protoTypes = {}

export default SportAreasList;
