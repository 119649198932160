import React from "react";
import { Avatar, Badge, badgeClasses, Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Tooltiper from "components/Tooltiper";
import EquipmentAvatarMouseOver from "./EquipmentAvatarMouseOver";
import { darken, lighten, styled, useTheme } from "@mui/material/styles";
import { Maybe } from "graphql/jsutils/Maybe";
// @ts-ignore
import { ReactComponent as LogoComponent } from "assets/mossport_logo.svg";
import Iconify from "./Iconify";
import Settings from "../store/settings";
import { BootstrapTooltip } from "./BootstrapTooltip";


const InUseBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "inUse"
})<{ inUse: boolean }>(({ theme, inUse }) => {
  const color = inUse ? theme.palette.primary.main : theme.palette.grey["400"];
  return {
    [`& .${badgeClasses.badge}`]: {
      backgroundColor: color,
      color: color,
      transform: "none",
      position: "relative",
      right: "0",
      bottom: "0"
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0
      }
    }
  };

});
const TechnicalConditionBadge = styled(Badge, {
  shouldForwardProp: (prop) => prop !== "technicalCondition"
})<{ technicalCondition: boolean }>(({ theme, technicalCondition }) => {
  const color = !technicalCondition ? theme.palette.error.main : theme.palette.grey["400"];
  return {
    [`& .${badgeClasses.badge}`]: {
      backgroundColor: color,
      color: color,
      transform: "none",
      position: "relative",
      right: "0",
      bottom: "0"
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0
      }
    }
  };

});


interface Props {
  name: Maybe<string>;
  inUse: boolean;
  technicalCondition: boolean;
  isEditable?: boolean;
  inventoryNumber: string;
  avatarUrlOrigin?: false | Maybe<string>;
  avatarUrl?: false | Maybe<string>;
  failureReason?: Maybe<string>;
  avatarOnly?: Maybe<boolean>;
}

function EquipmentRowName(props: Props) {
  const theme = useTheme();
  const {
    name,
    inUse,
    technicalCondition,
    inventoryNumber,
    avatarUrlOrigin,
    avatarUrl,
    failureReason,
    isEditable,
    avatarOnly
  } = props;

  return (
    <>
      <Stack direction={"row"} spacing={1} alignItems={"center"} minWidth={0} width={"100%"}>
        {!avatarOnly && <BootstrapTooltip
          title={
            `${inUse ? "Оборудование используется" : "Оборудование не используется"} ${!isEditable && ", редактирование запрещено"}`
          }
        >

          {
            isEditable ?
              <InUseBadge
                overlap='circular'
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant='dot'
                inUse={inUse}
              />
              :
              <Box
                sx={{
                  position: "relative",
                  width: 8,
                  "& .iconify": {
                    position: "absolute",
                    height: 16,
                    width: 20,
                    left: -6,
                    top: -7
                  }
                }}
              >
                <Iconify
                  icon={"material-symbols:lock"}
                  color={inUse ? "primary.main" : "grey.400"}
                />
              </Box>

          }

        </BootstrapTooltip>}

        <EquipmentAvatarMouseOver
          urlAvatar={(avatarUrl) ? ((avatarUrl.indexOf("http") !== -1) ? avatarUrl : `${Settings.server}${avatarUrl}`) : undefined}
        >
          <Box position={"relative"}>
            <Avatar
              sx={{
                width: 28,
                height: 28,
                mr: .5,
                bgcolor: darken(theme.palette.grey["200"], .2),
                ...(!inUse && { opacity: .7 })
              }}
              src={(avatarUrl) ? ((avatarUrl.indexOf("http") !== -1) ? avatarUrl : `${Settings.server}${avatarUrl}`) : undefined}
            >
              <LogoComponent fill={theme.palette.background.default} />
            </Avatar>
            {!technicalCondition &&
              <Box
                className={"iconify"}
                sx={{
                  position: "absolute",
                  fontSize: 28,
                  top: 0,
                  left: 0,
                  display: "flex"
                }}
              >
                <Iconify icon={"mdi:progress-wrench"} color={"error.main"} fontSize={"inherit"} />
              </Box>

            }
          </Box>
        </EquipmentAvatarMouseOver>
        {!avatarOnly && <Stack
          direction={"column"}
          spacing={-.5}
          minWidth={0}
          className={"equipment__info"}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={0}
          >
            {(failureReason && failureReason !== "") &&
              <Box ml={"auto"}>
                <BootstrapTooltip title={failureReason as string}>
                  <IconButton
                    size={"small"}
                    color={"error"}
                    component={"div"}
                    sx={{ ":hover": { cursor: "help" } }}
                    disableRipple
                  >
                    <Iconify
                      icon={"bxs:help-circle"}
                      fontSize={"inherit"}
                    />
                  </IconButton>
                </BootstrapTooltip>
              </Box>
            }
            <Tooltiper
              text={name || "Неизвестное оборудование"}
              props={{
                variant: "subtitle1",
                style: {
                  fontWeight: "bold",
                  ...(!inUse && technicalCondition && { color: theme.palette.grey["500"] }),
                  ...(!technicalCondition && !inUse && { color: lighten(theme.palette.error.main, .5) }),
                  ...(!technicalCondition && inUse && { color: theme.palette.error.main })
                }
              }}
            />
          </Stack>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"left"}
            sx={{
              ".MuiTypography-root": {
                width: "auto",
                mr: .5
              }
            }}
          >
            <Typography variant={"body2"}>Инв. №</Typography>
            <Typography
              variant={"subtitle2"}
              sx={{
                color: inUse ? "primary.main" : "grey.500"
              }}
            >{inventoryNumber}</Typography>
          </Box>
        </Stack>}
      </Stack>
    </>
  );
}

export default EquipmentRowName;
