import React, { FC, FormEvent, useEffect, useMemo, useState } from "react";
import { useStyles } from "../../../../../components/Dialogs/Dialog";
import { useTheme } from "@mui/material/styles";
import { DepartmentEnum } from "../../../../../store/department.enum";
import {
  ExternalOrderNotExistingRecordEntity,
  ExternalOrderRecordEntity,
  ReturnEquipmentRecordInput,
  SportEquipmentEntity,
  useAcceptExternalOrderMutation,
  useExportExternalOrderLazyQuery,
  useGetOrderByIdQuery,
  useRemoveExternalOrderNotExistingEquipmentMutation,
  useReturnEquipmentsMutation
} from "../../../../../graphql/graphQlApiHooks";
import { ExternalOrderStatusEnum } from "../../../ReturnedEquipments/external-order-status.enum";
import { TechnicalConditionEnum } from "../../../ReturnedEquipments/technical-condition.enum";
import Settings from "../../../../../store/settings";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import Iconify from "../../../../../components/Iconify";
import CloseIcon from "@mui/icons-material/Close";
import { useStylesUpload } from "../../../EquipmentRelocate/Dialogs/EquipmentDetails";
import CartItem from "./CartItem";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { darken } from "@material-ui/core";
import { ReactComponent as LogoComponent } from "assets/mossport_logo.svg";
import Tooltiper from "../../../../../components/Tooltiper";
import ReplaceNotExistingExternalOrderEquipmentDialog from "./ReplaceNotExistingExternalOrderEquipmentDialog";
import DeleteDialog from "../../../../../components/Dialogs/DeleteDialog";
import PhotoPreview from "../../../Equipments/components/Dialogs/SportEquipment/PhotoPreview";

interface Props {
}

const RentDialog: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const classesUpload = useStylesUpload();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId } = useParams();

  const isExternalUser = user?.departments.some((department: DepartmentEnum) => {
    return department === DepartmentEnum.Storekeeper ||
      department === DepartmentEnum.EquipmentAcceptanceAndDiagnostics ||
      department === DepartmentEnum.SportObject;
  });

  const [orderRecords, setOrderRecords] = useState<ReturnEquipmentRecordInput[]>([]);


  const handleCloseModal = () => {
    setOrderRecords([]);
    onClose();
  };

  const onClose = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      const path = location.pathname.split("/").slice(0, -1).join("/");
      navigate(path);
    }
  };


  const [acceptExternalOrderMutation] = useAcceptExternalOrderMutation();
  const [returnEquipmentsMutation] = useReturnEquipmentsMutation();
  const [removeNotExistingCartRecord] = useRemoveExternalOrderNotExistingEquipmentMutation();


  const [exportExternalOrder] = useExportExternalOrderLazyQuery();


  const handleExportSportEquipmentsInExcel = () => {
    exportExternalOrder({
      variables: {
        externalOrderId: Number(orderId)
      }
    }).then((res) => {
      const exportExternalOrder = res?.data?.exportExternalOrder;
      const url = exportExternalOrder?.url;
      url && window.location.replace(`${Settings.server}${url}`);
    });
  };


  const { data, loading, error } = useGetOrderByIdQuery({
    variables: {
      orderId: Number(orderId)
    },
    skip: !orderId
  });


  useEffect(() => {
    if (data?.getOrderById?.records && orderRecords.length === 0) {
      setOrderRecords([...data?.getOrderById.records].map((record: ExternalOrderRecordEntity) => ({
        orderRecordId: record.id,
        technicalCondition: record?.technicalCondition || TechnicalConditionEnum.Working,
        comment: record?.comment || ""
      })));
    }
  }, [data?.getOrderById]);


  const items = useMemo(() => {
    if (!data?.getOrderById?.records) return;

    const cartItems = data?.getOrderById?.records.map(item => ({
      ...item.equipment, orderRecordId: item.id
    }));

    const groupBy = (arr: any[], criteria: any) => {
      return arr.reduce((obj, item) => {
        const key = typeof criteria === "function" ? criteria(item) : item[criteria];

        if (!obj.hasOwnProperty(key)) {
          obj[key] = [];
        }

        obj[key].push(item);
        return obj;

      }, {});
    };

    const groupedBySportObject: SportEquipmentEntity[][] = Object.values(
      groupBy(cartItems, (item: SportEquipmentEntity) =>
        item.sportObject?.id
      )
    );

    return groupedBySportObject.map((groupedBySportObject: SportEquipmentEntity[]) => (
      Object.values(
        groupBy(groupedBySportObject, (item: SportEquipmentEntity) =>
          item.sportSubZone?.id
        )
      )
    ));

  }, [data?.getOrderById]);

  const [isSendToTransportService, setIsSendToTransportService] = useState(false);

  const [replaceExternalOrderEquipment, setReplaceExternalOrderEquipment] = useState({
    isOpen: false,
    notExistingEquipment: undefined
  });
  const [confirmRemoveNotExistingEquipment, setConfirmRemoveNotExistingEquipment] = useState<{
    isOpen: boolean,
    notExistingEquipment?: ExternalOrderNotExistingRecordEntity
  }>({
    isOpen: false,
    notExistingEquipment: undefined
  });

  const [notExistingEquipmentRecordPreview, setNotExistingEquipmentRecordPreview] = useState<{
    isOpen: boolean,
    notExistingEquipment?: ExternalOrderNotExistingRecordEntity
  }>({
    isOpen: false,
    notExistingEquipment: undefined
  });

  function removeNotExistingEquipmentHandler(notExistingEquipment: ExternalOrderNotExistingRecordEntity) {
    removeNotExistingCartRecord({
      variables: {
        notExistingRecordId: notExistingEquipment.id
      }
    });
  }

  function handleOpenModalNotExistingEquipmentRecord(notExistingEquipment: ExternalOrderNotExistingRecordEntity) {
    setNotExistingEquipmentRecordPreview({
      isOpen: true,
      notExistingEquipment: notExistingEquipment as ExternalOrderNotExistingRecordEntity
    });
  }

  return (
    <Dialog
      open={true}
      onClose={handleCloseModal}
      keepMounted
      maxWidth={"md"}
      classes={{ paper: classesUpload.root }}
    >

      <div className={classes.root}>
        <form
          noValidate
          autoComplete={"off"}
          onSubmit={(event: FormEvent<HTMLElement>) => event.preventDefault()}
          id='addDataForm'
          className={classes.root}
        >

          <Stack
            direction={"row"}
            id='scroll-dialog-title'
            classes={classes.dialogTitle}
            justifyContent={"space-between"}
            alignItems={"center"}
            py={1}
            px={2}
          >
            <Typography
              variant={"h5"}
              component={"div"}
            > Заявка на аренду оборудования</Typography>


            <Stack
              spacing={2}
              direction={"row"}
            >

              <Box>
                <Button
                  startIcon={<Iconify icon={"mdi:microsoft-excel"} />}
                  onClick={() => {
                    handleExportSportEquipmentsInExcel();
                  }}
                  size={"small"}
                >
                  Скачать Excel файл
                </Button>
              </Box>

              <Box>
                <IconButton
                  onClick={handleCloseModal}
                  size='small'
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Stack>
          </Stack>


          <DialogContent
            className={classesUpload.dialogContent}
            dividers={false}
          >


            <Box width={"100%"}>

              <div className={classesUpload.main}>
                <Box
                  id='scroll-dialog-description'
                  tabIndex={-1}
                  p={2}
                >
                  <Stack
                    direction={"column"}
                    spacing={2}
                  >
                    <Box
                      mx={2}
                      mb={1}
                      p={3}
                      sx={{
                        bgcolor: "background.default",
                        borderRadius: 1
                      }}
                    >
                      <Stack
                        direction={"row"}
                        spacing={4}
                        justifyContent={"space-between"}
                      >
                        <Box>
                          <Typography variant={"caption"}>
                            Период аренды
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{ fontWeight: "bold" }}
                          >
                            {dayjs(Number(data?.getOrderById?.startEventDate)).format("DD.MM.YYYY HH:mm")} - {dayjs(Number(data?.getOrderById?.returnDate)).format("DD.MM.YYYY HH:mm")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant={"caption"}>
                            Адрес доставки
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{ fontWeight: "bold" }}
                          >
                            {data?.getOrderById?.targetPointAddress}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant={"caption"}>
                            Получатель
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{ fontWeight: "bold" }}
                          >
                            {data?.getOrderById?.receivingName}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{ fontWeight: "bold" }}
                          >
                            {data?.getOrderById?.receivingPhone}
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{ fontWeight: "bold" }}
                          >
                            {data?.getOrderById?.receivingPosition}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant={"caption"}>
                            Примечание к заявке
                          </Typography>
                          <Typography
                            variant={"body1"}
                            sx={{ fontWeight: "bold" }}
                          >
                            {data?.getOrderById?.comments === "" ? "Без примечаний" : data?.getOrderById?.comments}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>

                    {/*Вывести заявки оборудования которого не существует*/}

                    {
                      data?.getOrderById?.notExistingEquipmentRecords?.length > 0 && <Box
                        mx={2}
                        mb={1}
                        p={3}
                      >
                        <Typography
                          variant={"h6"}
                          sx={{ fontWeight: "bold" }}
                        >
                          Заявки на несуществующее оборудование
                        </Typography>
                        <Stack
                          direction={"column"}
                          spacing={2}
                          justifyContent={"space-between"}
                        >
                          {
                            data?.getOrderById?.notExistingEquipmentRecords?.map((item, index) => {
                              const avatarUrl = item?.images?.length > 0 ? item?.images[0]?.avatarUrl || item?.images[0]?.url : undefined;
                              return (
                                <Card
                                  variant={"outlined"}
                                  sx={{ p: 2 }}
                                >
                                  <CardHeader

                                    sx={{
                                      p: 0,
                                      maxWidth: "100%",
                                      "& .MuiCardHeader-content": {
                                        overflow: "hidden"
                                      },
                                      "& .MuiTypography-root": {
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden"
                                      }

                                    }}
                                    title={
                                      <>
                                        <Stack
                                          direction={"row"}
                                          spacing={2}
                                          width={"100%"}
                                          onClick={() => {
                                            handleOpenModalNotExistingEquipmentRecord(item as ExternalOrderNotExistingRecordEntity);
                                          }}
                                        >
                                          <Box position={"relative"}>
                                            <Avatar
                                              sx={{
                                                width: 28,
                                                height: 28,
                                                mr: .5,
                                                bgcolor: darken(theme.palette.grey["200"], .2)
                                              }}
                                              src={(avatarUrl) ? ((avatarUrl.indexOf("http") !== -1) ? avatarUrl : `${Settings.server}${avatarUrl}`) : undefined}
                                            >
                                              <LogoComponent fill={theme.palette.background.default} />
                                            </Avatar>
                                          </Box>

                                          <Stack
                                            direction={"column"}
                                            spacing={-.5}
                                            minWidth={0}
                                            className={"equipment__info"}
                                            width={"100%"}
                                          >
                                            <Tooltiper
                                              text={item?.name || "Неизвестное оборудование"}
                                              props={{
                                                variant: "subtitle1"
                                              }}
                                            />
                                            <Box
                                              display={"flex"}
                                              flexDirection={"row"}
                                              alignItems={"left"}
                                              sx={{
                                                ".MuiTypography-root": {
                                                  width: "auto",
                                                  mr: .5
                                                }
                                              }}
                                            >
                                              <Tooltiper
                                                text={item?.description || "Без описания"}
                                                props={{
                                                  variant: "subtitle2"
                                                }}
                                              />
                                            </Box>
                                          </Stack>

                                        </Stack>
                                        <Dialog
                                          open={notExistingEquipmentRecordPreview.isOpen}
                                          onClose={() => {
                                            setNotExistingEquipmentRecordPreview({
                                              isOpen: false,
                                              notExistingEquipment: undefined
                                            });
                                          }}
                                          maxWidth={"md"}

                                          PaperProps={{
                                            style: {
                                              width: "100%",
                                              background: "transparent",
                                              boxShadow: "none",
                                              position: 'relative'

                                            }
                                          }}
                                        >
                                          {
                                            notExistingEquipmentRecordPreview?.notExistingEquipment && (
                                              <Paper
                                                sx={{
                                                  mx: 4,
                                                  overflow: 'hidden'
                                                }}
                                              >
                                                <Grid container>

                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={5}
                                                    style={{
                                                      position: "relative"
                                                    }}
                                                  >

                                                    <PhotoPreview
                                                      name={notExistingEquipmentRecordPreview?.notExistingEquipment?.name ? notExistingEquipmentRecordPreview.notExistingEquipment.name : "Фото оборудования"}
                                                      photos={notExistingEquipmentRecordPreview?.notExistingEquipment?.images}
                                                      mainPhoto={notExistingEquipmentRecordPreview?.notExistingEquipment?.images?.length && notExistingEquipmentRecordPreview?.notExistingEquipment?.images?.[0]}
                                                    />
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={12}
                                                    md={7}
                                                  >
                                                    <Box
                                                      sx={{
                                                        height: 550,
                                                        overflow: "hidden"
                                                      }}
                                                    >
                                                      <Stack
                                                        direction={"column"}
                                                        height={"100%"}
                                                      >
                                                        <>
                                                          <Box
                                                            position={"sticky"}
                                                            top={0}
                                                            p={3}
                                                            sx={{
                                                              bgcolor: "background.paper"
                                                            }}
                                                          >
                                                            <Typography
                                                              variant={"h2"}
                                                              color={"text.main"}
                                                            >
                                                              {notExistingEquipmentRecordPreview.notExistingEquipment.name}

                                                            </Typography>
                                                            <Box>

                                                              <Typography
                                                                variant={"caption"}
                                                                sx={{ color: "text.secondary", fontWeight: "bold" }}
                                                              >
                                                                Описание:
                                                              </Typography>
                                                              <Typography
                                                                variant={"caption"}
                                                                component={"span"}
                                                                sx={{
                                                                  color: "text.primary",
                                                                  display: "-webkit-box",
                                                                  WebkitLineClamp: "auto",
                                                                  WebkitBoxOrient: "vertical",
                                                                  overflow: "hidden",
                                                                  textOverflow: "ellipsis"
                                                                }}
                                                              >
                                                                {notExistingEquipmentRecordPreview.notExistingEquipment.description}
                                                              </Typography>
                                                            </Box>
                                                          </Box>
                                                        </>
                                                      </Stack>

                                                    </Box>
                                                  </Grid>

                                                </Grid>
                                              </Paper>

                                            )
                                          }

                                        </Dialog>
                                      </>

                                    }
                                    action={
                                      <Stack
                                        direction={"row"}
                                        spacing={2}
                                        width={"100%"}
                                      >
                                        <Button
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setReplaceExternalOrderEquipment({
                                              isOpen: true,
                                              notExistingEquipment: item
                                            });
                                          }}
                                        >
                                          Предложить замену
                                        </Button>
                                        <Button
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            setConfirmRemoveNotExistingEquipment({
                                              isOpen: true,
                                              notExistingEquipment: item as ExternalOrderNotExistingRecordEntity
                                            });
                                          }}
                                        >
                                          Удалить оборудование из заявки
                                        </Button>

                                      </Stack>
                                    }
                                  >
                                  </CardHeader>

                                </Card>
                              );
                            })
                          }

                        </Stack>

                        <ReplaceNotExistingExternalOrderEquipmentDialog
                          open={replaceExternalOrderEquipment.isOpen}
                          onClose={() => setReplaceExternalOrderEquipment({
                            isOpen: false,
                            notExistingEquipment: undefined
                          })}
                          externalOrderId={data?.getOrderById?.id}
                          notExistingEquipmentId={replaceExternalOrderEquipment.notExistingEquipment?.id}

                        />

                        {confirmRemoveNotExistingEquipment?.notExistingEquipment && <DeleteDialog
                          open={confirmRemoveNotExistingEquipment.isOpen}
                          close={() => setConfirmRemoveNotExistingEquipment({
                            isOpen: false,
                            notExistingEquipment: undefined
                          })}
                          confirm={() => removeNotExistingEquipmentHandler(confirmRemoveNotExistingEquipment.notExistingEquipment)}
                          title={`Удаление оборудования из заявки`}
                          message={`Вы уверены что хотите удалить ${confirmRemoveNotExistingEquipment.notExistingEquipment.name} из заявки? Это действие не обратимо!`}
                          id={confirmRemoveNotExistingEquipment.notExistingEquipment.id}
                        />}
                      </Box>

                    }


                    {
                      items && items?.map((items, index) =>
                        <CartItem
                          key={index}
                          items={items as SportEquipmentEntity[][]}
                          externalOrderId={data?.getOrderById?.id}
                          rowRecordId={data?.getOrderById?.records[index]?.id}
                          isExternalUser={isExternalUser}
                          orderRecords={orderRecords}
                          onChangeOrderRecords={(orderRecords) => setOrderRecords(orderRecords)}
                          rowStatus={data?.getOrderById.status}
                          technicalCondition={data?.getOrderById?.records[index]?.technicalCondition}
                        />)
                    }


                  </Stack>

                </Box>
              </div>
            </Box>

          </DialogContent>
          <DialogActions className={classes.dialogFooter}>

            {data?.getOrderById?.status === ExternalOrderStatusEnum.Created &&
              (
                data?.getOrderById?.status === ExternalOrderStatusEnum.Created && <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSendToTransportService}
                        onChange={(event) => setIsSendToTransportService(event.target.checked)}
                        name='checkedB'
                        color='primary'
                      />

                    }
                    label='Отправить в транспортную службу'
                  />
                  <Button
                    color={"primary"}
                    className={classes.submitButton}
                    variant='contained'
                    autoFocus
                    onClick={() => {
                      acceptExternalOrderMutation({
                        variables: {
                          input: {
                            externalOrderId: Number(data?.getOrderById.id),
                            sendToTransportService: isSendToTransportService
                          }
                        }
                      }).then(() => {
                        onClose();
                      });
                    }}
                    size={"small"}
                  >
                    Подтвердить заявку
                  </Button>
                </Stack>
              )
            }

            {data?.getOrderById?.status !== ExternalOrderStatusEnum.Created &&
              data?.getOrderById?.status !== ExternalOrderStatusEnum.ReturnedComplete &&
              <Button
                color={"primary"}
                className={classes.submitButton}
                variant='contained'
                onClick={() => {
                  returnEquipmentsMutation({
                    variables: {
                      input: {
                        orderId: Number(data?.getOrderById.id),
                        orderRecords: orderRecords
                      }
                    }
                  }).then(() => {
                    onClose();
                  });
                }}
                size={"small"}
              >
                Подтвердить приём
              </Button>
            }
          </DialogActions>
        </form>
      </div>
      <Outlet />
    </Dialog>
  );
};

export default RentDialog;