import React, { useRef } from "react";
import { Box, Tooltip } from "@mui/material";
import { LoadingButton } from "./LoadingButton.styled";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { BootstrapTooltip } from "../../../../../../../../components/BootstrapTooltip";

interface Props {
  loading: boolean;

  actions: {
    importSportEquipmentListFromExcel: (event: any) => void
  };
}

export const ImportButton = (props: Props) => {
  const { loading, actions: { importSportEquipmentListFromExcel } } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    if (!inputRef || !inputRef?.current) return;

    inputRef.current.value = "";
  };

  return (
    <>
      <LoadingButton
        loading={loading}
        onClick={clearInput}
      >
        <BootstrapTooltip title={"Загрузить оборудование из Excel файла"}>
          <Box component={"label"} sx={{
            cursor: "pointer",
            position: "absolute",
            width: "100%",
            height: "100%",
            p: .4,
            color: "text.primary",
            fontSize: "20px"
          }} htmlFor="import-from-excel">
            {!loading &&
              <UploadFileIcon fontSize={"inherit"} />
            }
          </Box>
        </BootstrapTooltip>
        <input
          type="file"
          hidden
          id={"import-from-excel"}
          name={"import-from-excel"}
          accept={
            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          }
          onChange={importSportEquipmentListFromExcel}
          ref={inputRef}
        />
      </LoadingButton>
    </>
  );
};