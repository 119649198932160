// ----------------------------------------------------------------------

import {Theme} from "@mui/material";


export default function MuiTab(theme:Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(3),
          textTransform: 'none',
          opacity: .6,
          transition: 'opacity .3s',
          '&:hover':{
            opacity: .8
          },
          '&.Mui-selected':{
            opacity: 1
          },
          '&:not(:first-of-type)':{
            marginLeft: theme.spacing(2)
          }
        }
      }
    },
  };
}

