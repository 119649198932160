import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";
import {useStores} from "../../../../../store";


const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

function DeleteDictionaries({...rest}) {
	const classes = useStyles();
	const {dictionary, open, onClose} = rest
	const {dictionariesStore} = useStores();

	const [methodName, setMethodName] = useState('')
	const [dialogTitle, setDialogTitle] = useState('')

	React.useEffect(() => {
		setMethodName(`delete${dictionary?.__typename.replace(/Entity/g,'')}`);
		setDialogTitle((dictionary?.__typename.includes('Specification')) ? 'Удаление характеристики оборудования' : 'Удаление типа оборудования');
	}, [dictionary])

	const handleClose = () => onClose(false);
	const handleDelete = () => dictionariesStore?.[methodName](dictionary?.id).finally(handleClose);

	return <>
		<Dialog
				onClose={handleClose}
				aria-labelledby="delete-dictionary-dialog-title"
				open={open}
		>
			<DialogTitle id="delete-dictionary-dialog-title">
				<Box
						display={'flex'}
						alignItems={'flex-end'}
				>
					<DeleteOutlineIcon
							color={'secondary'}
							style={{marginRight: '.3em'}}
					/>
					<span>{dialogTitle}</span>
				</Box>
				<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={handleClose}
						size="large"
				>
					<CloseIcon/>
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography gutterBottom variant={'h4'}>
					Вы действительно хотите удалить {dictionary?.name}?
				</Typography>
				<Typography >Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку ниже.</Typography>
			</DialogContent>
			<DialogActions>
				<Button
						onClick={handleClose}
						color="primary"
				>
					Отмена
				</Button>
				<Button
						autoFocus
						onClick={handleDelete}
						color="secondary"
						variant={'contained'}
				>
					Удалить
				</Button>
			</DialogActions>
		</Dialog>
	</>;
}

DeleteDictionaries.protoTypes = {}

export default DeleteDictionaries;
