import React, {useEffect, useMemo} from 'react';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {observer} from "mobx-react-lite";
import {
    Box, Button, Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup, FormHelperText,
    FormLabel,
    InputLabel,
    OutlinedInput,
    Typography
} from "@mui/material";
import Page from "components/Page";
import {useStores} from "../../../store";


const useStyles = makeStyles((theme: Theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        position: 'relative',
    },
    pageContent: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        }
    },
    pageTitle: {
        height: 90,
        padding: theme.spacing(3, 4),
        display: 'flex',
        alignItems: 'center',
        "& .MuiTypography-root": {
            fontSize: '21px',
            whiteSpace: 'nowrap',
            color: theme.palette.text.primary,
        },
    },
    mainSection: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    mainSectionContainer: {
        width: 'auto',
        height: '100%',
        position: 'relative',
        padding: theme.spacing(0, 3),
    }

}));

function FieldSpace() {
    return (
        <Box
            sx={{
                height: 20,
            }}
        />
    );
}

const UserSettings = observer(() => {
    const classes = useStyles();

    const {userStore} = useStores()

    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [phoneError, setPhoneError] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);

    const [state, setState] = React.useState({
        equipmentRelocationRequestNotifications: false,
        equipmentRequestNotifications: false,
        equipmentNotWorkingNotifications: false,
    });

    useEffect(() => {
        const userSettings = userStore?.currentUser?.user?.userSettings;
        if (userSettings){
            if (userSettings?.email) setEmail(userSettings?.email)
            if (userSettings?.phone) setPhone(userSettings?.phone)

            setState({
                equipmentRelocationRequestNotifications: JSON.parse(userSettings.equipmentRelocationRequestNotifications),
                equipmentRequestNotifications: JSON.parse(userSettings.equipmentRequestNotifications),
                equipmentNotWorkingNotifications: JSON.parse(userSettings.equipmentNotWorkingNotifications),
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userStore?.currentUser?.user?.userSettings])

    const emailHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        setEmail(email);
        if (email === '') {
            setEmailError(false);
            return
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (re.test(email)) {
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    };

    const phoneHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const phone = event.target.value;
        setPhone(phone);

    };


    const notificationHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };


    const submitHandler = () => {
        let input = {
            id: userStore.currentUser.user.userSettings.id,
            email: email,
            phone: phone,
            equipmentRequestNotifications: state.equipmentRequestNotifications,
            equipmentRelocationRequestNotifications: state.equipmentRelocationRequestNotifications,
            equipmentNotWorkingNotifications: state.equipmentNotWorkingNotifications,
        }
        userStore.updateUserSettings(input)
    }


    useMemo(() => {
        const userSettings = userStore?.currentUser?.user?.userSettings
        if (userSettings) {
            if (email !== userSettings.email
                || phone !== userSettings.phone
                || state.equipmentRequestNotifications !== JSON.parse(userSettings.equipmentRequestNotifications)
                || state.equipmentRelocationRequestNotifications !== JSON.parse(userSettings.equipmentRelocationRequestNotifications)
                || state.equipmentNotWorkingNotifications !== JSON.parse(userSettings.equipmentNotWorkingNotifications)
            ) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }

    }, [email, phone, state, userStore?.currentUser?.user?.userSettings])


    return (
        <Page title={'Настройки пользователя'}>
            <>
                <header className={classes.pageTitle}>
                    <Typography
                        variant="h1"
                    >
                        Настройки пользователя
                        <Box
                            sx={{
                                display: 'inline',
                                mx: 1.5,
                                color: '#dbdbde',
                                fontWeight: 'normal',
                            }}
                        >/</Box>
                        {userStore.currentUser?.user?.login}
                    </Typography>


                </header>

                <Box className={classes.pageContent}>
                    <main className={classes.mainSection}>
                        <div className={classes.mainSectionContainer}>
                            <Box
                                sx={{
                                    backgroundColor: '#fff',
                                    boxShadow: '0 0 0 1px #e8eff3',
                                    borderRadius: '4px',
                                    width: '100%',
                                    height: '100%',
                                    p: 3
                                }}
                            >
                                <Box>
                                    <FormControl
                                        sx={{m: 3}}
                                        component="fieldset"
                                        variant="standard"
                                    >
                                        <FormLabel
                                            component={Typography}
                                            variant={'h3'}
                                        >Основные настройки.</FormLabel>
                                        <FormGroup sx={{my: 2}}>
                                            <FormControl>
                                                <InputLabel
                                                    htmlFor="email"
                                                    color={(!emailError && email !== '') ? "success" : undefined}
                                                    error={emailError}
                                                >Электронная почта</InputLabel>
                                                <OutlinedInput
                                                    name={'email'}
                                                    id={'email'}
                                                    value={email}
                                                    onChange={emailHandleChange}
                                                    label="Электронная почта"
                                                    type="email"
                                                    error={emailError}
                                                    color={(!emailError && email !== '') ? "success" : undefined}
                                                />
                                                <FormHelperText error={emailError}>Укажите корректную электронную почту, куда будут отправляться уведомления</FormHelperText>
                                            </FormControl>
                                            <FieldSpace/>
                                            <FormControl>
                                                <InputLabel
                                                    htmlFor="phone"
                                                    color={(!phoneError && phone !== '') ? "success" : undefined}
                                                    error={emailError}
                                                >Номер телефона</InputLabel>
                                                <OutlinedInput
                                                    name={'phone'}
                                                    id={'phone'}
                                                    value={phone}
                                                    onChange={phoneHandleChange}
                                                    label="Номер телефона"
                                                    type="tel"
                                                    error={phoneError}
                                                    color={(!phoneError && phone !== '') ? "success" : undefined}
                                                />
                                                <FormHelperText error={emailError}>Укажите корректный номер телефона для связи</FormHelperText>
                                            </FormControl>
                                        </FormGroup>
                                    </FormControl>

                                </Box>


                                {!userStore?.currentUser?.user?.isForSportObject &&
                                    <Box>
                                    <Divider/>
                                    <FormControl
                                        sx={{m: 3}}
                                        component="fieldset"
                                        variant="standard"
                                    >
                                        <FormLabel
                                            component={Typography}
                                            variant={'h4'}
                                        >Управление уведомлениями</FormLabel>
                                        <FormGroup sx={{my: 2}}>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.equipmentRequestNotifications}
                                                        onChange={notificationHandleChange}
                                                        name="equipmentRequestNotifications"
                                                    />
                                                }
                                                label="Новая заявка на оборудование"
                                            />
                                            <FormHelperText>Вы будете получать уведомления на почту о поступлении новых заявок на оборудование, в разделе "Заявки на оборудование"</FormHelperText>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.equipmentRelocationRequestNotifications}
                                                        onChange={notificationHandleChange}
                                                        name="equipmentRelocationRequestNotifications"
                                                    />
                                                }
                                                label="Приём оборудования на объекте"
                                            />
                                            <FormHelperText>Вы будете получать уведомление на почту о приёме оборудования на объекте </FormHelperText>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={state.equipmentNotWorkingNotifications}
                                                        onChange={notificationHandleChange}
                                                        name="equipmentNotWorkingNotifications"
                                                    />
                                                }
                                                label="Оборудование вышло из строя"
                                            />
                                            <FormHelperText>Вы будете получать уведомление на почту, когда оборудование становится не исправным </FormHelperText>
                                        </FormGroup>
                                    </FormControl>
                                </Box>
                                }
                                <Box>
                                    <Button
                                        variant="contained"
                                        color={'primary'}
                                        title={'Сохранить'}
                                        disabled={disabled}
                                        onClick={submitHandler}
                                    >Сохранить</Button>
                                </Box>

                            </Box>
                        </div>
                    </main>
                </Box>
            </>
        </Page>
    )
})

export default UserSettings;
