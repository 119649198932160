import Box from "@mui/material/Box";
import { GridSelectedRowCount } from "@mui/x-data-grid/components/GridSelectedRowCount";
import { Button, Paper, Typography } from "@mui/material";
import { GridPagination } from "@mui/x-data-grid";
import * as React from "react";

interface Props {
  selected: any;
  onDeleteHandler: () => void;
}

function CustomFooterStatusComponent(props: Props) {
  const {
    selected,
    onDeleteHandler
  } = props;

  return (
    <Box sx={{ padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <Box display={"flex"}>

        {selected.length > 0 ? <>
          <GridSelectedRowCount selectedRowCount={selected.length} />
          <Button color={"secondary"} variant={"outlined"} size={"small"}
                  sx={{ py: .5, px: 2 }} onClick={onDeleteHandler}>Удалить</Button>
        </> : <>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            "& > .MuiPaper-root": {
              m: 1,
              width: 16,
              height: 16
            }
          }}>
            <Paper elevation={3} sx={{
              bgcolor: "rgba(255,205,184,0.8)"
            }} />
            <Typography variant={"caption"}>- не удалось найти значение в базе</Typography>
            <Paper elevation={3} sx={{
              bgcolor: "rgba(184,235,255,0.8)"
            }} />
            <Typography variant={"caption"}>- не точное совпадение</Typography>
            <Paper elevation={3} sx={{
              bgcolor: "rgba(253,87,127,0.8)"
            }} />
            <Typography variant={"caption"}>- заполнены не все обязательные поля</Typography>
          </Box>

        </>}
      </Box>
      {
        // @ts-ignore
        <GridPagination />
      }
    </Box>
  );
}

export default CustomFooterStatusComponent;
