import React, { FC } from "react";
import Settings from "../../../../../../store/settings";

import { ImageStoreEntity } from "../../../../../../graphql/graphQlApiHooks";
import { Gallery, GalleryProps, Item } from "react-photoswipe-gallery";

import { Box, Card, useMediaQuery } from "@mui/material";

import { DataSourceArray } from "photoswipe";
import Slider from "react-slick";
import { Theme } from "@mui/material/styles";

interface Props {
  photos?: ImageStoreEntity[];
  mainPhoto?: ImageStoreEntity;
  name: string;
}


const uiElements: GalleryProps["uiElements"] = [
  {
    name: "bulletsIndicator",
    order: 9,
    isButton: false,
    appendTo: "wrapper",
    onInit: (el, pswpInstance) => {
      let prevIndex = -1;
      const thumbnails: HTMLElement[] = [];


      el.style.position = "absolute";
      // el.style.bottom = '20px'
      el.style.top = "70px";
      el.style.left = "20px";
      // el.style.right = '0'
      el.style.display = "grid";
      el.style.gridGap = "10px";
      el.style.gridTemplateColumns = "repeat(auto-fit, 100px)";
      el.style.gridTemplateRows = "repeat(auto-fit, 100px)";
      el.style.justifyContent = "center";


      const dataSource = pswpInstance.options.dataSource as DataSourceArray;

      for (let i = 0; i < dataSource.length; i++) {
        const slideData = dataSource[i];

        const thumbnail = document.createElement("div");
        thumbnail.style.transition = "transform 0.15s ease-in";
        thumbnail.style.opacity = "0.6";
        thumbnail.style.cursor = "pointer";
        // thumbnail.onclick = (e: MouseEvent) => {
        //   const target = e.target as HTMLImageElement | HTMLDivElement;
        //   const thumbnailEl =
        //     target.tagName === "IMG"
        //       ? target.parentElement
        //       : (e.target as HTMLImageElement | HTMLDivElement);
        //   pswpInstance.goTo(thumbnails.indexOf(thumbnailEl!));
        // };

        const thumbnailImage = document.createElement("img");
        slideData?.msrc && thumbnailImage.setAttribute("src", slideData.msrc);
        thumbnailImage.style.width = "100%";
        thumbnailImage.style.height = "100%";
        thumbnailImage.style.objectFit = "cover";

        thumbnail.appendChild(thumbnailImage);

        el.appendChild(thumbnail);

        thumbnails.push(thumbnail);
      }

      pswpInstance.on("change", () => {
        if (prevIndex >= 0) {
          const prevThumbnail = thumbnails[prevIndex];
          prevThumbnail.style.opacity = "0.6";
          prevThumbnail.style.cursor = "pointer";
          // prevThumbnail.style.transform = 'scale(1)'
        }

        const currentThumbnail = thumbnails[pswpInstance.currIndex];
        currentThumbnail.style.opacity = "1";
        currentThumbnail.style.cursor = "unset";
        // currentThumbnail.style.transform = 'scale(1.2)'

        prevIndex = pswpInstance.currIndex;
      });
    }
  }
];


const PhotoPreview: FC<Props> = ({ photos, mainPhoto, name }: Props) => {
  const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down("sm"));
  

  return (
    <Card
      elevation={0}
      sx={{
        height: sm ? 250 : 550,
        overflow: "hidden",
        borderRadius: 0,
        bgcolor: "background.default",
        "& .slick-prev": {
          left: 10,
          zIndex: 1
        },
        "& .slick-next": {
          right: 10
        },
        "& .slick-dots": {
          bottom: 10
        }
      }}
    >

      <Gallery
        uiElements={uiElements}
        options={{ bgOpacity: 1 }}
      >
        <Slider
          {...{
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          }}
        >
          {photos && photos?.length > 0 ? (
            photos?.map((photo, index) => (
              <Box
                key={index}
              >
                <Item
                  original={Settings.server + photo.url}
                  thumbnail={Settings.server + (photo?.avatarUrl || photo.url)}
                  width={photo.width as number}
                  height={photo.height as number}
                  alt={name as string}
                >
                  {({ ref, open }) => (
                    <Box
                      position={"relative"}
                      sx={{
                        bgcolor: "background.default",
                        minHeight: 550,
                        height: "100%",
                        width: "100%"
                      }}
                    >
                      <Box
                        sx={{
                          top: "0",
                          left: "0",
                          bottom: "0",
                          position: "absolute",
                          height: "100%",
                          width: "100%",
                          backgroundImage: `url(${Settings.server + photo.url})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center center"
                        }}
                        onDoubleClick={open}
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                      />
                    </Box>

                  )}
                </Item>
              </Box>

            ))
          ) : (
            mainPhoto ? (
              <Item
                original={Settings.server + mainPhoto.url}
                thumbnail={Settings.server + mainPhoto.url}
                width={mainPhoto.width as number}
                height={mainPhoto.height as number}
                alt={name as string}
              >
                {({ ref, open }) => (
                  <Box
                    sx={{
                      minHeight: 550,
                      height: "100%",
                      width: "100%",
                      backgroundImage: `url(${Settings.server as string + mainPhoto?.url as string})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "none",
                      backgroundPosition: "center center"
                    }}
                    ref={ref as React.MutableRefObject<HTMLImageElement>}
                    onClick={open}
                  />
                )}
              </Item>

            ) : (
              <Box
                sx={{
                  minHeight: 550,
                  height: "100%",
                  width: "100%",
                  backgroundImage: `url(static/sport-pattern.png)`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                  backgroundPosition: "center center"
                }}
              />
            )
          )
          }
        </Slider>
      </Gallery>
    </Card>
  );
};

export default PhotoPreview;