import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../store";
import FiltersPanel, {FiltersType} from "components/FiltersPanel";
import FilterAutocomplete from "components/FiltersPanel/FilterComponents/FilterAutocomplete";
import {
	EquipmentTypeDictionaryEntity, SportKindDictionaryEntity,
	SportSubZoneEntity,
	SportZoneEntity
} from "../../../../../graphql/graphQlApiHooks";
import FilterSearch from "components/FiltersPanel/FilterComponents/FilterSearch";
import FilterDateRange from "components/FiltersPanel/FilterComponents/FilterDateRange";
import dayjs from "dayjs";
import {GridToolbarContainer} from "@mui/x-data-grid";
import React from "react";

const TableToolbar = observer(() => {
	const {equipmentRelocationRequestStore, dictionariesStore} = useStores();

	const filters: FiltersType[] = [
		{
			name: 'sourceSportObjectIds',
			title: "Спорт.объект нахождения",
			removable: false,
			filterComponent: (props: any) => <FilterAutocomplete
					values={equipmentRelocationRequestStore.sourceSportObjects}
					selected={equipmentRelocationRequestStore.filters.sourceSportObjectIds}
					filterResult={(result: SportZoneEntity[]) => equipmentRelocationRequestStore.setFilters({sourceSportObjectIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				return (equipmentRelocationRequestStore.filters.sourceSportObjectIds !== null) ? `: ${equipmentRelocationRequestStore.filters.sourceSportObjectIds.length}/${equipmentRelocationRequestStore.sourceSportObjects.length}` : `: 0/${equipmentRelocationRequestStore.sourceSportObjects.length}`
			}
		},
		{
			name: 'targetSportObjectIds',
			title: "Спорт.объект назначения",
			removable: false,
			filterComponent: (props: any) => <FilterAutocomplete
					values={equipmentRelocationRequestStore.targetSportObjects}
					selected={equipmentRelocationRequestStore.filters.targetSportObjectIds}
					filterResult={(result: SportSubZoneEntity[]) => equipmentRelocationRequestStore.setFilters({targetSportObjectIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				return (equipmentRelocationRequestStore.filters.targetSportObjectIds !== null) ? `: ${equipmentRelocationRequestStore.filters.targetSportObjectIds.length}/${equipmentRelocationRequestStore.targetSportObjects.length}` : `: 0/${equipmentRelocationRequestStore.targetSportObjects.length}`
			}
		},
		{
			name: 'equipmentTypeIds',
			title: 'Тип оборудования',
			filterComponent: (props: any) => <FilterAutocomplete
					values={dictionariesStore.equipmentTypeDictionary as EquipmentTypeDictionaryEntity[]}
					selected={equipmentRelocationRequestStore.filters.equipmentTypeIds}
					filterResult={(result: EquipmentTypeDictionaryEntity[]) => equipmentRelocationRequestStore.setFilters({equipmentTypeIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const filter = equipmentRelocationRequestStore.filters.equipmentTypeIds;
				return (filter !== null) ? `: ${filter.length}/${dictionariesStore.equipmentTypeDictionary.length}` : `: 0/${dictionariesStore.equipmentTypeDictionary.length}`
			},
			clearFilter: () => {
				equipmentRelocationRequestStore.setFilters({equipmentTypeIds: null})
			}
		},
		{
			name: 'sportKindIds',
			title: 'Вид спорта',
			filterComponent: (props: any) => <FilterAutocomplete
					values={dictionariesStore.sportKindDictionary as SportKindDictionaryEntity[]}
					selected={equipmentRelocationRequestStore.filters.sportKindIds}
					filterResult={(result: SportKindDictionaryEntity[]) => equipmentRelocationRequestStore.setFilters({sportKindIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const filter = equipmentRelocationRequestStore.filters.sportKindIds;
				return (filter !== null) ? `: ${filter.length}/${dictionariesStore.sportKindDictionary.length}` : `: 0/${dictionariesStore.sportKindDictionary.length}`
			},
			clearFilter: () => {
				equipmentRelocationRequestStore.setFilters({sportKindIds: null})
			}
		},
		{
			name: 'statuses',
			title: 'Статус заявки',
			filterComponent: (props: any) => <FilterAutocomplete
					values={equipmentRelocationRequestStore.statuses}
					selected={equipmentRelocationRequestStore.filters.statuses}
					filterResult={(result: any[]) => equipmentRelocationRequestStore.setFilters({statuses: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const filter = equipmentRelocationRequestStore.filters.statuses;
				return (filter !== null) ? `: ${filter.length}/${equipmentRelocationRequestStore.statuses.length}` : `: 0/${equipmentRelocationRequestStore.statuses.length}`
			},
			clearFilter: () => {
				equipmentRelocationRequestStore.setFilters({statuses: null})
			}
		},
		{
			name: 'name',
			title: 'Название оборудования',
			filterComponent: (props: any) => <FilterSearch
					value={equipmentRelocationRequestStore.filters.name}
					filterResult={(result: string) => equipmentRelocationRequestStore.setFilters({name: result})}
					{...props}
			/>,
			chipDescription: () => {
				return (equipmentRelocationRequestStore.filters.name !== null && equipmentRelocationRequestStore.filters.name !== '') ? ` содержит ${equipmentRelocationRequestStore.filters.name}` : ''
			},
			clearFilter: () => {
				equipmentRelocationRequestStore.setFilters({name: null})
			}
		},
		{
			name: 'inventoryNumber',
			title: 'Инвентарный номер',
			filterComponent: (props: any) => <FilterSearch
					value={equipmentRelocationRequestStore.filters.inventoryNumber}
					filterResult={(result: string) => equipmentRelocationRequestStore.setFilters({inventoryNumber: result})}
					{...props}
			/>,
			chipDescription: () => {
				return (equipmentRelocationRequestStore.filters.inventoryNumber !== null && equipmentRelocationRequestStore.filters.inventoryNumber !== '') ? ` содержит ${equipmentRelocationRequestStore.filters.inventoryNumber}` : ''
			},
			clearFilter: () => {
				equipmentRelocationRequestStore.setFilters({inventoryNumber: null})
			}
		},
		{
			name: 'applicationDate',
			title: 'Дата ввода',
			filterComponent: (props: any) => <FilterDateRange
					value={equipmentRelocationRequestStore.filters.applicationDate}
					filterResult={(result) => equipmentRelocationRequestStore.setFilters({applicationDate: result})}
					{...props}
			/>,
			chipDescription: () => {
				const applicationDate = equipmentRelocationRequestStore.filters.applicationDate;
				if (applicationDate?.[0] !== null) {

					if (!dayjs(applicationDate?.[0]).isSame(applicationDate?.[1]) && applicationDate?.[1]) {
						return ` С ${dayjs(applicationDate?.[0]).format('ll')} по ${dayjs(applicationDate?.[1]).format('ll')}`
					} else {
						return ` ${dayjs(applicationDate?.[0]).format('ll')}`
					}
				} else {
					return ''
				}
			},
			clearFilter: () => {
				equipmentRelocationRequestStore.setFilters({applicationDate: [null, null]})
			}
		}
	];

	function getFiltersSelected(filters: any) {
		let result = [];
		for (let [key, value] of Object.entries(equipmentRelocationRequestStore.filters)) {
			const filter = filters.find((item: any) => item.name === key);
			const isNullsArray = Boolean(Array.isArray(value) && value.filter((item: any) => item === null).length === 2)
			const isEmpty = Boolean(value == null || isNullsArray)
			if (!isEmpty || filter?.removable === false)
				result.push(filter)
		}
		return result;
	}


	return (
			<GridToolbarContainer>
				<FiltersPanel store={equipmentRelocationRequestStore} filters={filters}
				              selected={getFiltersSelected(filters)}/>
			</GridToolbarContainer>
	)
});

export default TableToolbar;