import React, { FC, forwardRef, ReactNode, Ref, useEffect, useRef, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  ListItemIcon, ListItemText,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import PhotoPreview from "../PhotoPreview";
import Iconify from "../../../../../../../components/Iconify";
import { capitalizedString } from "../../../../../../../helpers/capitalizedString";
import { TechnicalCondition } from "../../../../../../../graphql/technical-condition.enum";
import { alpha, lighten, Theme } from "@mui/material/styles";
import { Chip } from "../index";
import { SportEquipmentEntity } from "../../../../../../../graphql/graphQlApiHooks";
import { useTheme } from "@mui/styles";
import CardField from "./CardField";
import Settings from "../../../../../../../store/settings";
import { useNavigate } from "react-router-dom";

interface Props {
  sportEquipment: SportEquipmentEntity;
  onClose: () => void;
}

const SportEquipmentCard: FC<Props> = ({ sportEquipment, onClose }: Props) => {
  const ref = useRef(null);
  const [isLocationCollapse, setIsLocationCollapse] = useState(false);

  useEffect(() => {

    const handleScroll = () => {
      setIsLocationCollapse(ref.current.scrollTop > 0);
    };

    ref?.current?.addEventListener("scroll", handleScroll);

    return () => {
      ref?.current?.removeEventListener("scroll", handleScroll);
    };

  }, [ref]);

  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={4}
      sx={{ height: "100%" }}
    >
      <Grid
        item
        xs={12}
        sm={5}
        style={{ position: "relative" }}
      >
        <PhotoPreview
          name={sportEquipment?.name ? sportEquipment.name : "Фото оборудования"}
          photos={sportEquipment?.photos}
          mainPhoto={sportEquipment?.mainPhoto}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        sx={{ height: "calc(100% + 20px)" }}
      >
        <SportEquipmentCardContent ref={ref}>
          <SportEquipmentCardHeader
            sportEquipment={sportEquipment}
            isCollapse={isLocationCollapse}
          />
          <SportEquipmentCardMain sportEquipment={sportEquipment} />

          <SportEquipmentCardFooter
            handleEdit={() => {
              navigate(`/equipments/edit/${sportEquipment?.id}`);
            }}
            handleClose={() => {
              onClose()
            }}
          />

        </SportEquipmentCardContent>

      </Grid>
    </Grid>
  );
};

export default SportEquipmentCard;


const SportEquipmentCardContent = forwardRef(({ children }: { children: ReactNode }, ref: Ref<HTMLDivElement>) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Card
      ref={ref}
      elevation={0}
      sx={{
        ...(sm && {
          px: 4
        }),
        pr: 4,
        height: "100%",
        overflow: "auto",
        position: "relative"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%"
        }}
      >
        {children}
      </Box>
    </Card>
  );
});
const SportEquipmentCardHeader = ({
                                    sportEquipment,
                                    isCollapse
                                  }: { sportEquipment: SportEquipmentEntity, isCollapse: boolean }) => {
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const theme = useTheme();
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{
          bgcolor: "background.paper",
          ...(!sm && {
            pt: 4
          })
        }}
      >
        <Iconify
          icon={"mdi:map-marker"}
          color={"text.secondary"}
          fontSize={".8rem"}
        />
        <Typography
          variant={"caption"}
          color={"text.secondary"}
          component={"div"}
          sx={{
            display: "flex",
            justifyContent: "end"
          }}
        >
          {capitalizedString(sportEquipment?.sportObject?.name) || "Объект не указан"}
        </Typography>
      </Stack>

      <Typography
        variant={"body1"}
        color={"text.primary"}
        component={"div"}
        sx={{
          fontWeight: 600
        }}
      >
        {capitalizedString(sportEquipment?.sportSubZone?.name) || "Подзона не указана"}
      </Typography>
      <Typography
        variant={"caption"}
        color={"text.secondary"}
        gutterBottom={true}
        sx={{
          display: "flex",
          justifyContent: "start"
        }}
      >
        {capitalizedString(sportEquipment?.sportZone?.name)}
      </Typography>


      <Box
        width={"100%"}
        mt={2}
        sx={{
          bgcolor: "background.paper"
        }}
      >
        <Chip
          state={sportEquipment?.technicalCondition === TechnicalCondition.WORKING}
          type={"technicalCondition"}
          size={"small"}
          icon={<Iconify
            icon={"bi:circle-fill"}
            color={"inherit"}
            fontSize={"inherit"}
          />}
          label={sportEquipment?.technicalCondition === TechnicalCondition.WORKING ? "Оборудование исправно" : "Оборудование не исправно!"}
        />

        <Chip
          sx={{
            ml: 2
          }}
          state={sportEquipment?.inUse}
          type={"inUse"}
          size={"small"}
          icon={<Iconify
            icon={"bi:circle-fill"}
            color={"inherit"}
            fontSize={"inherit"}
          />}
          label={sportEquipment?.inUse ? "Используется на объекте" : "Не используется на объекте"}
        />
      </Box>

      <Typography
        variant={"h2"}
        color={"text.main"}
        position={"sticky"}
        top={0}
        sx={{
          bgcolor: alpha(theme.palette.background.paper, 0.5),
          zIndex: 1,
          py: 2,
          backdropFilter: "blur(10px)",
          width: "102.5%"
        }}
      >
        {sportEquipment?.name}
        {sportEquipment?.technicalCondition === TechnicalCondition.NOT_WORKING && <Iconify
          sx={{
            ml: 2
          }}
          icon={"mdi:progress-wrench"}
          color={"error.main"}
        />}
      </Typography>

      <CardField
        label={"Бух. название"}
        value={sportEquipment?.accountingName ? sportEquipment?.accountingName : sportEquipment?.name}
        Props={{
          sx: {
            m: 0
          }
        }}

        ValueProps={{
          variant: "caption",
          sx: {
            fontWeight: "normal"
          }
        }}
      />

      <CardField
        label={"Инв. номер"}
        value={sportEquipment?.inventoryNumber}

        ValueProps={{
          sx: {
            color: "primary.main"
          }
        }}
      />

      {sportEquipment?.failureReason &&
        <Alert
          severity={"error"}
          style={{
            marginBottom: theme.spacing(2)
          }}
        >
          <Typography
            variant={"caption"}
            color={"text.secondary"}
            component={"div"}
          >
            {sportEquipment?.failureReason}
          </Typography>
        </Alert>}

    </>
  );
};
const SportEquipmentCardMain = ({ sportEquipment }: { sportEquipment: SportEquipmentEntity }) => {
  const theme = useTheme();
  const description = sportEquipment?.description || sportEquipment?.specifications?.[0]?.valueString || "Без описания";



  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          // overflow: "hidden"
        }}
      >
        <Box
          sx={{
            // overflow: "auto",
            height: "100%"
          }}
        >


          <CardField
            label={"Доступность для аренды"}
            value={sportEquipment?.availableForRent ? "доступно" : "не доступно"}
            fullWidth
          />


          <CardField
            label={"Дата следующего тех. обслуживания"}
            value={sportEquipment?.equipmentServicePeriods?.length ? sportEquipment?.equipmentServicePeriods[0]?.nextDate : "не указана"}
            fullWidth
          />


          <Box
            sx={{
              p: 2,
              bgcolor: lighten(theme.palette.primary.main, 0.95),
              borderRadius: 2
            }}
            // onClick={() => setDescriptionChecked(prev => !prev)}
          >

            <Typography
              variant={"caption"}
              sx={{ fontWeight: "bold" }}
            >
              Описание оборудования
            </Typography>
            {/*<Collapse in={descriptionChecked} collapsedSize={40}>*/}
            <Typography
              variant={"body1"}
              component={"span"}
              sx={{
                color: "text.primary",
                display: "-webkit-box",
                // WebkitLineClamp: !descriptionChecked ? 2 : 'auto',
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {description}
            </Typography>
            {/*</Collapse>*/}
          </Box>

          <Box
            sx={{
              mt: 2,
              p: 2,
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider"
            }}
          >
            <Typography
              variant={"caption"}
              sx={{ fontWeight: "bold" }}
            >
              Характеристики оборудования
            </Typography>

            <CardField
              label={"Категория спорта"}
              value={sportEquipment?.sportCategoryObject?.name}
              fullWidth
            />

            <CardField
              label={"Вид спорта"}
              value={sportEquipment?.sportKindObject?.name}
              fullWidth
            />

            <CardField
              label={"Тип оборудования"}
              value={sportEquipment?.equipmentTypeObject?.name}
              fullWidth
            />
          </Box>

          <Box
            sx={{
              my: 2,
              p: 2,
              borderRadius: 2,
              border: "1px solid",
              borderColor: "divider"
            }}
          >
            <Typography
              variant={"caption"}
              sx={{ fontWeight: "bold" }}
              gutterBottom={true}
            >
              Документы
            </Typography>

            <CardField
              label={"Акт ввода в эксплуатацию"}
              value={
                <MenuItem
                  component={"a"}
                  title={sportEquipment?.guarantee?.[0]?.description}
                  href={Settings.server + sportEquipment?.guarantee?.[0]?.url}
                  disabled={!sportEquipment?.guarantee?.[0]}
                  target={"_blank"}
                  disableGutters={true}
                  sx={{
                    p:0,
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={"material-symbols:sim-card-download-outline"} />
                  </ListItemIcon>
                  <ListItemText>Скачать</ListItemText>
                </MenuItem>
              }
              fullWidth
            />

            <CardField
              label={"Паспорт"}
              value={
                <MenuItem
                  component={"a"}
                  title={sportEquipment?.passport?.[0]?.description}
                  href={Settings.server + sportEquipment?.passport?.[0]?.url}
                  disabled={!sportEquipment?.passport?.[0]}
                  target={"_blank"}
                  disableGutters={true}
                  sx={{
                    p:0,
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={"material-symbols:sim-card-download-outline"} />
                  </ListItemIcon>
                  <ListItemText>Скачать</ListItemText>
                </MenuItem>
              }
              fullWidth
            />

            <CardField
              label={"Сертификат"}
              value={
                <MenuItem
                  component={"a"}
                  title={sportEquipment?.certificate?.[0]?.description}
                  href={Settings.server + sportEquipment?.certificate?.[0]?.url}
                  disabled={!sportEquipment?.certificate?.[0]}
                  target={"_blank"}
                  disableGutters={true}
                  sx={{
                    p:0,
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={"material-symbols:sim-card-download-outline"} />
                  </ListItemIcon>
                  <ListItemText>Скачать</ListItemText>
                </MenuItem>
              }
              fullWidth
            />
            <CardField
              label={"Гарантийный талон"}
              value={
                <MenuItem
                  component={"a"}
                  title={sportEquipment?.guarantee?.[0]?.description}
                  href={Settings.server + sportEquipment?.guarantee?.[0]?.url}
                  disabled={!sportEquipment?.guarantee?.[0]}
                  target={"_blank"}
                  disableGutters={true}
                  sx={{
                    p:0,
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={"material-symbols:sim-card-download-outline"} />
                  </ListItemIcon>
                  <ListItemText>Скачать</ListItemText>
                </MenuItem>
              }
              fullWidth
            />
            <CardField
              label={"Руководство по эксплуатации"}
              value={
                <MenuItem
                  component={"a"}
                  title={sportEquipment?.manual?.[0]?.description}
                  href={Settings.server + sportEquipment?.manual?.[0]?.url}
                  disabled={!sportEquipment?.manual?.[0]}
                  target={"_blank"}
                  disableGutters={true}
                  sx={{
                    p:0,
                  }}
                >
                  <ListItemIcon>
                    <Iconify icon={"material-symbols:sim-card-download-outline"} />
                  </ListItemIcon>
                  <ListItemText>Скачать</ListItemText>
                </MenuItem>
              }
              fullWidth
            />


            {sportEquipment?.otherDocuments?.length > 0 &&
              <Divider />
            }

            {sportEquipment?.otherDocuments?.length > 0 &&
              <Box>


                <Typography variant={"caption"} color={"text.secondary"} px={2}>
                  Прочие документы
                </Typography>

                {sportEquipment?.otherDocuments.map((document: any, i: number) => {
                  return (<MenuItem
                    key={i}
                    component={"a"}
                    title={document.description}
                    href={Settings.server + document.url}
                    target={"_blank"}
                    dense
                    // onClick={() => onClose()}
                  >

                    <ListItemIcon>
                      <Iconify icon={"material-symbols:sim-card-download-outline"} />
                    </ListItemIcon>
                    <ListItemText>{document.description}</ListItemText>
                  </MenuItem>);
                })}
              </Box>
            }

          </Box>


        </Box>
      </Box>
    </>
  );
};
const SportEquipmentCardFooter = ({handleEdit, handleClose}: {handleEdit: () => void, handleClose: () => void}) => {
  return (
    <>
      <Box
        display={"flex"}
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          zIndex: 1,
          width: "100%",
          bgcolor: "background.paper",
          py: 1,
        }}
      >
        <Button
          variant='text'
          color='inherit'
          size={"small"}
          onClick={handleEdit}
        >
          Изменить
        </Button>
        <Box flexGrow={1} />
        <Button
          variant='contained'
          color='secondary'
          size={"small"}
          onClick={handleClose}
        >
          Закрыть
        </Button>
      </Box>
    </>
  );
};
