import React, {useState} from 'react';
import {Box, IconButton, Stack, TextField, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Page from "components/Page";
import {useTheme} from "@mui/styles";
import {observer} from "mobx-react-lite";
import RelocateEquipmentsTable from "./Table";
import {useStores} from "../../../store";
import {GridSearchIcon} from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	page: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		position: 'relative',
	},
	pageContent: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		height: '100%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		}
	},
	pageTitle: {
		padding: theme.spacing(3, 4),
		display: 'flex',
		alignItems: 'center',
		height: 90,
		'& .toolbar': {
			marginLeft: '2rem',
			display: 'flex',
			listStyle: 'none',
			width: '100%',
			'&__item:not(:last-child)': {
				marginRight: '1em',
			}
		}
	},
	mainSection: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	mainSectionContainer: {
		width: 'auto',
		height: '100%',
		position: 'relative',
		overflowX: 'auto',
		padding: theme.spacing(0, 3),
	}
}));

const RelocateEquipments = observer(() => {
	const classes = useStyles();
	const theme = useTheme();
	const {equipmentRelocationRequestStore} = useStores()
	const [searchByEquipmentNameValue, setSearchByEquipmentNameValue] =
			useState('');

	return (
			<Page title={'МСО.Объявления'}>

				<header className={classes.pageTitle}>
					<Typography variant="h1">
						МСО.Объявления
						<Box
								component={'small'}
								sx={{
									ml: 1,
									fontSize: '.7em',
									color: theme.palette.text.secondary,
									opacity: .5
								}}
						>{equipmentRelocationRequestStore.count}</Box>
					</Typography>

					<Box flexGrow={1}/>
					<Stack direction={'row'} spacing={3} alignItems={'center'}>
						<TextField
								variant='standard'
								value={searchByEquipmentNameValue}
								onChange={({target: {value}}) =>
										setSearchByEquipmentNameValue(value)
								}
								placeholder='Название оборудования'
								InputProps={{
									startAdornment: <GridSearchIcon fontSize='small'/>,
									endAdornment: (
											<IconButton
													title='Clear'
													aria-label='Clear'
													size='small'
													style={{
														visibility: searchByEquipmentNameValue
																? 'visible'
																: 'hidden',
													}}
													onClick={() => setSearchByEquipmentNameValue('')}
											>
												<ClearIcon fontSize='small'/>
											</IconButton>
									),
								}}
						/>
					</Stack>
				</header>

				<Box className={classes.pageContent}>
					<main className={classes.mainSection}>
						<div className={classes.mainSectionContainer}>
							<RelocateEquipmentsTable
									searchByEquipmentNameValue={searchByEquipmentNameValue}
							/>
						</div>
					</main>
				</Box>


			</Page>
	)
});

export default RelocateEquipments;
