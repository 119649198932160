import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {
	Box,
	Button,
	createFilterOptions,
	TextField,
} from "@mui/material";
import {useStores} from "../../../../store";
import {
	AcceptRequestBySportObjectInput,
	EquipmentRelocationRequestEntity,
	SportObjectEntity,
} from "../../../../graphql/graphQlApiHooks";
import {Autocomplete} from "@mui/lab";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";

interface RequestForAdmissionProps {
	data?: EquipmentRelocationRequestEntity | null
	open: boolean
	onClose: () => void
}


const RequestForAdmission = observer((props: RequestForAdmissionProps) => {
	const {data, open, onClose} = props;
	const {sportObjectsStore, equipmentRelocationRequestStore} = useStores();
	const [sportObject, setSportObject] = useState<SportObjectEntity | null>(null);
	const [comment, setComment] = useState<string>('');
	const [errorsHandler, setErrorsHandler] = useState<boolean>(false);

	const onChangeSportObject = (value: SportObjectEntity) => {
		setSportObject(value)
		setErrorsHandler(false)
	};

	const onChangeComment = (value: string) => {
		setComment(value)
	};

	const handleClose = () => {
		onClose()
		setSportObject(null)
		setComment('')
	};

	const onConfirm = () => {
		if (!sportObject) {
			setErrorsHandler(true)
			return;
		}

		const input:AcceptRequestBySportObjectInput = {
			equipmentRelocationRequestId: data?.id as string,
			targetSportObjectId: sportObject?.id,
			comments: (comment !== '') ? comment : null
		}

		equipmentRelocationRequestStore.acceptRequestBySportObject(input).then(() => {
			handleClose()
		}).catch((error: any) => {
			console.error(error.message)
		})
	}

	const filterOptionsTargetSportObjectSelect = createFilterOptions({
		matchFrom: 'any',
		stringify: (option: SportObjectEntity) => option.name,
		ignoreCase: true
	});



	return (

			<Dialog
					open={open}
					onClose={handleClose}
					title={'Выберите спортобъект назначения'}
					titles={[
						<EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt} />,
						<StepperRelocation targetSportObject={sportObject}
						                   sourceSportObject={data?.sourceSportObject}/>
					]}
					actions={<>
						<Button
								variant={'contained'}
								onClick={onConfirm}
								color={'primary'}
						>Отправить запрос на приём оборудования</Button>
					</>}
			>
				<Box sx={{mt: 1}}>
					<Autocomplete
							id="targetSportObject"
							onChange={(event, value) => {
								onChangeSportObject(value as SportObjectEntity)
							}}
							openOnFocus
							value={sportObject}
							selectOnFocus
							size={"small"}
							options={[...sportObjectsStore.sportObjects].filter((s: SportObjectEntity) => s.id !== data?.sourceSportObject.id)}
							getOptionLabel={(option) => option.name}
							filterOptions={filterOptionsTargetSportObjectSelect}
							renderInput={(params) => (
									<TextField
											{...params}
											label="Спортобъект*"
											error={errorsHandler}
											autoFocus
											fullWidth
											helperText={(errorsHandler) ? "Выберите спортобъект назанчения." : undefined}
									/>
							)}

					/>
				</Box>

				<Box sx={{mt: 2}}>
					<TextField
							margin="dense"
							value={comment}
							onChange={({target: {value}}) => onChangeComment(value) }
							type="text"
							fullWidth
							multiline={true}
							rows={5}
							placeholder={'Укажите комментарий'}
							size={'small'}
					/>
				</Box>

			</Dialog>
	);
})

export default RequestForAdmission;
