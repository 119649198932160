import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import {
  SportCategoryDictionaryEntity,
  SportKindDictionaryEntity,
  useGetAllDictionariesQuery
} from "graphql/graphQlApiHooks";
import { Autocomplete, TextField } from "@mui/material";
import { ImportedSportEquipmentRecord } from "../types";


export const EquipmentTypeEdit = (props: GridRenderCellParams<ImportedSportEquipmentRecord>) => {
  const { id, api, field, row } = props;

  const { data: allDictionaries } = useGetAllDictionariesQuery();
  const sportKinds = allDictionaries?.getAllSportKindDictionary?.sportKinds;
  const sportCategories = allDictionaries?.getAllSportKindDictionary?.sportCategories;
  const options = allDictionaries?.getAllSportKindDictionary?.equipmentTypes;

  const sportKind = sportKinds?.find((kind: SportKindDictionaryEntity) => (kind?.id === row?.sportKind?.id));

  const value = row?.equipmentType;

  const handleChange = (event: any, newValue: any) => {
    const sportKindValue = sportKinds.find((kind: SportKindDictionaryEntity) => (kind?.id === newValue?.sportKind?.id || kind?.id === newValue?.sportKindId));
    const sportCategoryValue = sportCategories.find((category: SportCategoryDictionaryEntity) => sportKindValue?.sportCategoryId === category?.id);

    api.setEditCellValue({ id, field, value: newValue }, event);

    if (newValue) {
      api.setEditCellValue({
        id,
        field: "sportCategory",
        value: sportCategoryValue
      }, event);
      api.setEditCellValue({
        id,
        field: "sportKind",
        value: sportKindValue
      }, event);
    }


    api.commitRowChange(row.id);
  };

  return (
    <Autocomplete
      value={value || null}
      id="equipmentTypes"
      options={options || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
      onChange={handleChange}
      sx={{ width: "100%", height: "100%", ".MuiTextField-root": { height: "100%" } }}
      groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}
      openOnFocus
      renderInput={(params) => <TextField
        error={!value}
        {...params}
        variant="standard"
        size={"small"}
        placeholder="Выберите тип оборудования"
        InputLabelProps={{
          shrink: true
        }}
      />}
    />
  );
};
