import React, {useState} from 'react';
import {OutlinedInputProps, TextField} from '@mui/material';
import {CreateSportObjectInput, SportObjectEntity} from "../../../../../graphql/graphQlApiHooks";
import {useStores} from "../../../../../store";

function AddSportObject({...rest}) {
    const {settingsStore} = useStores();

    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [activeDirectoryGroup, setActiveDirectoryGroup] = useState('');
    const [nameExistsError, setNameExistsError] = useState<string | null>(null);

    const onInputName = ({target: {value}}: any) => {
        nameExistsError && setNameExistsError(null)
        setName(value)
    };
    const onInputAddress = ({target: {value}}: any) => setAddress(value);
    const onInputActiveDirectoryGroup = ({target: {value}}: any) => setActiveDirectoryGroup(value);

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if (name !== '') {
            const nameIsExists = settingsStore.sportObjects.find((obj:SportObjectEntity)=>obj.name === name)

            if (!!nameIsExists) {
                setNameExistsError('Название занято')
                return
            }

            settingsStore.sportObjectCreate({name, address, activeDirectoryGroup} as CreateSportObjectInput).then(() => {
                rest.onAdded()
                setName('')
                setAddress('')
                setActiveDirectoryGroup('')
            })
        }
    };


    return <>
        <form
            id="add_object"
            onSubmit={(e) => handleSubmit(e)}
        >

            <div className="input-wrapper">
                <TextField
                    name='name'
                    id="new_name"
                    label="Название"
                    placeholder="Добавьте название"
                    variant="filled"
                    size={'small'}
                    autoComplete={'off'}
                    value={name}
                    onChange={onInputName}
                    fullWidth={true}
                    multiline={true}
                    maxRows={3}
                    InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
                    helperText={nameExistsError}
                    error={!!nameExistsError}

                />
                <TextField
                    name='address'
                    id="new_address"
                    label="Адрес"
                    placeholder="Добавьте адрес"
                    variant="filled"
                    size={'small'}
                    autoComplete={'off'}
                    value={address}
                    onChange={onInputAddress}
                    fullWidth={true}
                    multiline={true}
                    maxRows={2}
                    InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
                />
                <TextField
                    name='activeDirectoryGroup'
                    id="activeDirectoryGroup"
                    label="Группа в Active Directory"
                    placeholder="Укажите группу в Active Directory"
                    variant="filled"
                    size={'small'}
                    autoComplete={'off'}
                    value={activeDirectoryGroup}
                    onChange={onInputActiveDirectoryGroup}
                    fullWidth={true}
                    InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
                />

            </div>
        </form>
    </>;
}

AddSportObject.protoTypes = {}

export default AddSportObject;
