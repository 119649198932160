import React from 'react';
import {observer} from "mobx-react-lite";
import {
	Box,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogTitle, Grow,
	IconButton,
	Typography,
} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/styles";
import {darken, lighten, Theme} from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';
export const useStyles = makeStyles((theme: Theme) => ({
	formControl: {
		marginBottom: '1em',
		'&:last-child':{
			marginBottom: 0
		},
		'& .MuiFormLabel-root': {
			transform: 'none',
			position: 'relative',
			fontSize: '13px',
			lineHeight: '18px',
			paddingTop: 2,
			paddingBottom: 6,
			// color: '#818c99',
			fontWeight: 500,
			'&.Mui-focused': {
				color: theme.palette.text.primary,
			}
		},
		'& legend': {
			width: '0px !important'
		}
	},
	root: {
		background: 'transparent',
		padding: 0,
		margin: 0,
		overflow: 'initial',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		[theme.breakpoints.down('md')]: {
			height: 'auto',
			minHeight: '100%'
		},
		'& .MuiDialogContent-root': {
			padding: theme.spacing(3),
			boxShadow: 'none',
		},
		'& .MuiOutlinedInput-root': {
			// fontSize: '14.3333px',
			// backgroundColor: lighten(theme.palette.background.paper, 0.3),
			'&.Mui-focused': {
				backgroundColor: theme.palette.background.paper,
				// boxShadow: '0 2px 6px 0 rgb(136 148 171 / 20%), 0 24px 20px -24px rgb(71 82 107 / 10%)'

			}
		},
	},
	inputDescription: {
		minHeight: theme.spacing(10),
		alignItems: 'baseline',
		fontSize: '13px'
	},
	input: {},
	titleInput: {},
	addressInput: {},
	inputRoot: {},
	inputStyled: {
		flex: 1,

	},
	iconButton: {
		padding: 0,
		width: 55,
		height: 55,
		borderRadius: '50%',
		position: 'relative',
		overflow: 'hidden',
		'& .MuiIconButton-label': {
			position: 'relative',
			height: '100%'
		},
		'& .MuiAvatar-root': {
			width: '100%',
			height: '100%',
			borderRadius: 0,
			fontSize: '28px',
			backgroundColor: theme.palette.background.default,
			transition: 'all .3s',
			color: '#818c99',
			'&:hover': {
				backgroundColor: darken(theme.palette.background.default, 0.05),
			}
		}
	},
	divider: {
		height: 28,
		margin: 4,
	},
	dialogTitle: {
		padding: theme.spacing(0, 3),
		position: "relative",
		display: 'flex',
		margin: 0,
		height: 48,
		alignItems: 'center',
		boxShadow: `0 -1px 0 0 ${theme.palette.divider} inset`,
		backgroundColor: lighten(theme.palette.background.default, .4),
		'& > .MuiTypography-root': {
			width: '100%',
		}
	},
	titles: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	title: {
		fontSize: '16px',
		fontWeight: 500,
		letterSpacing: '.005em',
		padding: 0,
		margin: 0,
	},
	subTitle: {
		paddingTop: 2,
		fontSize: '13px',
		fontWeight: 400,
		letterSpacing: '.005em',
		color: '#818c99',
	},
	dialogFooter: {
		backgroundColor: lighten(theme.palette.background.default, .4),
		padding: theme.spacing(2, 3),
		boxShadow: `0 1px 0 0 ${theme.palette.divider} inset`,
	},
	submitButton: {
		// backgroundColor: theme.palette.success.main,
		boxShadow: 'none',
		'&:hover': {
			// backgroundColor: theme.palette.success.dark,
		}
	},
	closeButton: {
		marginLeft: 'auto',
		color: theme.palette.text.secondary,
		padding: theme.spacing(.3),
		fontSize: '12px'

	}

}));



const Transition = React.forwardRef(function Transition(
		props: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>,
) {
	return <Grow
			style={{transformOrigin: 'center top  -1000px'}}
			in={true}
			ref={ref} {...props} />;
});

interface DialogProps {
	open: boolean;
	onClose: () => void;
	title: string;
	titles?: React.ReactNode[];
	children?: React.ReactNode;
	actions?: React.ReactNode;

	props?: {
		[key: string]: any;
	}
}

const Dialog = observer((rest: DialogProps) => {
	const classes = useStyles();
	const theme = useTheme();
	const {open, onClose, title, titles, children, actions, props } = rest;

	function handleClose() {
		onClose()
	}

	return (
			<MuiDialog
					open={open}
					TransitionComponent={Transition}
					onClose={handleClose}
					aria-describedby="label"
					fullWidth={true}
					maxWidth={'sm'}
					scroll={'body'}
					sx={{
						'& .MuiDialog-paper':{
							// maxHeight: '100%',
							height: 'auto',
						}
					}}
					{...props}
			>

				<DialogTitle classes={{root: classes.dialogTitle}}>
					<Typography
							variant={'h5'}
							component={'div'}
					> {title} </Typography>
					<IconButton
							className={classes.closeButton}
							onClick={handleClose}
							size="large"
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<Box className={classes.titles}>
					{titles && titles.map((title:any, index: number) =>(
							<Box
									key={index}
									display={'flex'}
									alignItems={'center'}
									width={'100%'}
									sx={{
										padding: theme.spacing(0, 2),
										boxShadow: `0 -1px 0 0 ${theme.palette.divider} inset`,
									}}
							>
								{title}
							</Box>
					))}
				</Box>
				<DialogContent>
					{children}
				</DialogContent>
				<DialogActions className={classes.dialogFooter}>
					{actions}
				</DialogActions>
			</MuiDialog>
	);
})

export default Dialog;
