import { makeVar } from "@apollo/client";
import { UserEntity } from "../../../graphql/graphQlApiHooks";

export const UserInitial: UserEntity = {
  createdAt: null,
  departments: null,
  enabled: null,
  fio: null,
  id: null,
  isExternal: null,
  isForSportObject: null,
  isLoggedIn: null,
  lastLogin: null,
  lastSeenAt: null,
  login: null,
  password: null,
  permissions: null,
  sportObjects: null,
  userSettings: null,
  userType: null,
};

export const userVars = makeVar<UserEntity>(
  UserInitial
);

