import React, {PropsWithChildren, useEffect} from 'react';
import Page from "components/Page";
import {Navigate, NavLink, useNavigate, useOutlet, useParams} from 'react-router-dom'
import {AntTab, AntTabs} from "components/AntTabs";
import {observer} from "mobx-react-lite";
import useStyles from './styles'
import {useStores} from "../../../store";
import { Tab, Tabs } from "@mui/material";

const a11yProps = (index: number) => ({
    id: `Tab-for-navigating-through-settings-sections-${index}`,
    'aria-controls': `tabpanel-for-navigating-through-settings-sections-${index}`,
});


export const SettingsContext = React.createContext({tabValue: 0});

const tabs = [
    {
        name: 'structure',
        value: 0
    },
    {
        name: 'dictionaries',
        value: 1
    },
    // {
    //     name: 'responsibleUsers',
    //     value: 2
    // },
]


const Settings = observer(() => {
    const classes = useStyles();
    const [tabValue, setTabValue] = React.useState(0);
    const {userStore} = useStores();
    const outlet = useOutlet();
    const navigate = useNavigate();
    const params = useParams()

    useEffect(() => {
        setTabValue(tabs.find(tab => params['*']?.includes(tab.name))?.value || 0)
    }, [params]);


    useEffect(()=>{
        if(window.location.href.endsWith('settings/') || window.location.href.endsWith('settings')) {
            navigate('structure', {replace: true});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[window.location])

    const handleChangeTab = (event: React.SyntheticEvent, newTabValue: number) => {
        setTabValue(newTabValue);
    };

    // Проверка на администратора
    const CheckAuth = observer((props: PropsWithChildren<{ children: any }>) => {
        if (userStore.currentUser?.user.isForSportObject) {
            return <Navigate to={'/'}/>;
        }
        return props.children
    });

    return (
        <CheckAuth>
            <Page title={'Настройки'}>
                <SettingsContext.Provider value={{ tabValue: tabValue }}>
                    <header className={classes.pageHeader}>
                        <Tabs
                            value={tabValue}
                            onChange={handleChangeTab}
                            aria-label="tabpanel-for-navigating-through-settings-sections"
                        >
                            <Tab disableRipple label="Управление структурой" {...a11yProps(0)} component={NavLink} to={'structure'} />
                            <Tab disableRipple label="Справочники" {...a11yProps(1)} component={NavLink} to={'dictionaries'} />
                            {/*<Tab disableRipple label="Назначение ответсвенных" {...a11yProps(2)} component={NavLink} to={'responsibleUsers'} />*/}
                        </Tabs>
                    </header>
                    {outlet}
                </SettingsContext.Provider>
            </Page>
        </CheckAuth>
    )
});


export default Settings;
