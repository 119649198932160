import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Popover, TextField} from '@mui/material';
import {darken, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import {useStores} from "../../../../../store";


const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    sportZoneCreatePanel: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        width: 220,
        '& .MuiFilledInput-root': {
            background: darken(theme.palette.background.default, 0.05),
            '&:before': {
                borderColor: theme.palette.divider
            },
            '&:hover, &.Mui-focused': {
                background: darken(theme.palette.background.default, 0.01)
            }
        }
    },
    sportZoneCreatePanelInput: {},
    sportZoneCreatePanelBottomPanel: {
        display: 'none',
        '&.showPanel': {
            display: 'flex',
            justifyContent: 'space-between'
        }
    }
}));

function EditSportZone({...rest}) {
    const classes = useStyles();
    const inputName = useRef<HTMLInputElement | null>(null)
    const [showPanel, setShowPanel] = useState<boolean>(false)
    const {settingsStore} = useStores();

    const [inputNameValue, setInputNameValue] = useState('')
    const [inputAddressValue, setInputAddressValue] = useState('')


    const editSportZone = () => {
        if (inputNameValue !== '' && rest.currentSportZone.id) {

            const input = {
                sportObjectId: settingsStore?.sportObject?.id,
                id: rest.currentSportZone.id,
                name: inputNameValue,
                address: inputAddressValue
            }
            settingsStore.sportZoneUpdate(input).finally(rest.onClose)
        }
    };


    useMemo(() => {
        if (rest.currentSportZone) {
            setShowPanel((
                inputNameValue !== rest.currentSportZone.name ||
                inputAddressValue !== rest.currentSportZone.address
            ))
        }
    }, [rest.currentSportZone, inputNameValue, inputAddressValue])

    useEffect(() => {
        inputName.current?.focus()
    }, [rest.anchorEl, inputName])

    useEffect(() => {
        if (rest.currentSportZone) {
            setInputNameValue(rest.currentSportZone.name)
            setInputAddressValue(rest.currentSportZone.address)
        }
    }, [rest.currentSportZone])

    function onClose() {
        if (rest.currentSportZone) {
            setInputNameValue(rest.currentSportZone.name)
            setInputAddressValue(rest.currentSportZone.address)
            rest.onClose()
        }
    }

    return (
        <>
            {rest.currentSportZone &&
            <Popover
                id="sportZone-create-panel"
                anchorEl={rest.anchorEl}
                keepMounted
                open={Boolean(rest.anchorEl)}
                onClose={rest.onClose}
                className={classes.sportZoneCreatePanel}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <form
                    autoComplete="off"
                    className={classes.sportZoneCreatePanel}
                >
                    <TextField
                        onChange={(e) => setInputNameValue(e.target.value)}
                        value={inputNameValue}
                        inputRef={inputName}
                        id="name"
                        label="Название"
                        variant="filled"
                        size={'small'}
                        autoComplete='off'
                        tabIndex={0}
                        autoFocus
                    />
                    <TextField
                        onChange={(e) => setInputAddressValue(e.target.value)}
                        value={inputAddressValue}
                        id="address"
                        label="Адрес"
                        variant="filled"
                        size={'small'}
                        autoComplete='off'
                        tabIndex={1}
                    />
                </form>
                <div
                    className={clsx(classes.sportZoneCreatePanelBottomPanel, showPanel ? 'showPanel' : null)}
                >
                    <Button onClick={onClose}>Отмена</Button>
                    <Button
                        onClick={editSportZone}
                        color="primary"
                    >Сохранить</Button>
                </div>

            </Popover>
            }
        </>
    )
}

EditSportZone.protoTypes = {}

export default EditSportZone;
