import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const TooltipIcon = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: "inline-block",
  position: "absolute",
  right: 8,
  top: 8,
  ["&.more-button"]: {
    opacity: 0,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyItems: "center"
  }
})) as typeof Box;
