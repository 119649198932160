import {PaletteMode, PaletteOptions} from "@mui/material";
import {alpha} from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#f2f2f2',
  200: '#f7f7f7',
  300: '#c9c7c9',
  400: '#bebdbe',
  500: '#a4a3a4',
  600: '#7e7d7e',
  700: '#676567',
  800: '#494749',
  900: '#2f2e2f',
  500_8: alpha('#a4a3a4', 0.08),
  500_12: alpha('#a4a3a4', 0.12),
  500_16: alpha('#a4a3a4', 0.16),
  500_24: alpha('#a4a3a4', 0.24),
  500_32: alpha('#a4a3a4', 0.32),
  500_48: alpha('#a4a3a4', 0.48),
  500_56: alpha('#a4a3a4', 0.56),
  500_80: alpha('#a4a3a4', 0.8),
};

const PRIMARY = {
  lighter: '#757bff',
  light: '#5e67ff',
  main: '#4851f9',
  dark: '#343de7',
  darker: '#232bb9',
  contrastText: '#fff',
};

const SECONDARY = {
  lighter: '#6b676b',
  light: '#3f3d3f',
  main: '#292829',
  dark: '#171617',
  darker: '#000000',
  contrastText: '#fff',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
};

const SUCCESS = {
  lighter: '#66e594',
  light: '#1fc95f',
  main: '#189e4a',
  dark: '#127c3a',
  darker: '#0b4b23',
  contrastText: GREY[800],
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#e77879',
  light: '#e03d3e',
  main: '#b53031',
  dark: '#912728',
  darker: '#812324',
  contrastText: '#fff',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const palette = (mode: PaletteMode) => {

  // ** Vars
  const lightColor = '22, 28, 28'
  const darkColor = '243, 243, 255'
  const mainColor = mode === 'light' ? lightColor : darkColor

    return {
      common: {black: '#000', white: '#fff'},
      primary: {...PRIMARY},
      secondary: {...SECONDARY},
      info: {...INFO},
      success: {...SUCCESS},
      warning: {...WARNING},
      error: {...ERROR},
      grey: GREY,
      gradients: GRADIENTS,
      divider: GREY[500_24],
      text: {
        primary: `rgba(${mainColor}, 0.87)`,
        secondary: `rgba(${mainColor}, 0.68)`,
        disabled: `rgba(${mainColor}, 0.38)`,
        contrast: GREY[0]
      },
      background: {
        paper: mode === 'light' ? '#fff' : '#2a2a2e',
        default: mode === 'light' ? GREY[100] : '#212125',
        neutral: mode === 'light' ? GREY[200] : 'black',
        dark: mode === 'light' ? '#24242c' : 'black',

      },
      action: {
        active: `rgba(${mainColor}, 0.54)`,
        hover: `rgba(${mainColor}, 0.04)`,
        selected: `rgba(${mainColor}, 0.08)`,
        disabled: `rgba(${mainColor}, 0.3)`,
        disabledBackground: `rgba(${mainColor}, 0.18)`,
        focus: `rgba(${mainColor}, 0.12)`,
        hoverOpacity: 0.08,
        disabledOpacity: 0.48,
      },
    } as PaletteOptions
  }
;

export default palette;
