import {
	EquipmentServiceJobEntity,
	GetEquipmentServiceJobsDocument,
	UpdateEquipmentServiceJobInput,
	UpdateEquipmentServiceJobDocument,
	DeleteEquipmentServiceJobDocument,
	SportObjectEntity,
	GetArchivedEquipmentServiceJobsBySportObjectsDocument,
	SportZoneEntity, SportSubZoneEntity,
} from '../../graphql/graphQlApiHooks';

import {autorun, makeAutoObservable, runInAction, toJS} from "mobx";
import RootStore from "../.";

import {MobxApolloQuery} from 'mobx-apollo';
import {isEqual, queryToMobxObservable, sortByCreateAt} from '../../helpers';
import Settings from "../settings";
import {client} from "../.";
import dayjs from "dayjs";

export interface IServiceJobsStore {
	allServiceJobs: MobxApolloQuery<unknown>
	error: string
	loading: boolean
	serviceJobs: EquipmentServiceJobEntity[]
	count: number
}

interface SortOrder {
	[key: string]: 'DESC' | 'ASC'
}

const serviceJobsStore = class {
	public rootStore: RootStore;
	public serviceJobsResponse: EquipmentServiceJobEntity[] = [];
	public serviceJobs: EquipmentServiceJobEntity[] = [];
	public archivedServiceJobsResponse: EquipmentServiceJobEntity[] = [];
	public archivedServiceJobs: EquipmentServiceJobEntity[] = [];
	public loading: boolean = true
	public error?: any
	public count: number = 0
	public archivedCount: number = 0
	public filterValues: any[] | undefined;
	page: number = 0;
	pageSize: number = 50;
	sortOrder: SortOrder | null = null;

	filters: any = {
		sportObjectIds: null,
		sportZoneIds: null,
		sportSubZoneIds: null,
		equipmentName: null,
		jobName: null,
		equipmentInventoryNumber: null,
		deadline: [null, null],
		done: [null, null],
	}

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)

		function filterByRowName(values: any, newValues: any, rowName: string) {

			if (!values || values.length === 0) {
				return;
			}

			return values.slice().filter((row: any) => {
				return newValues.some((value: any) => {
					return row[rowName]?.id === value?.id;
				});
			});
		}

		autorun(() => {
			if (this.queryServiceJobs?.current()) {

				let result: any = this.queryServiceJobs?.current()?.getEquipmentServiceJobs;
				let count = result.count;
				result = result.equipmentServiceJobs;

				if (!isEqual(result, toJS(this.serviceJobs))) {
					this.serviceJobs = result
					this.count = count
				}
				this.setLoading(false)


				if (!this.filters.sportZoneIds && this.sportZones.length > 0) {
					this.setFilters({sportZoneIds: this.sportZones.map(val => val.id)})
				}

			}
		})

		autorun(() => {
			if (!this.rootStore.userStore?.currentUser) return

			if (this.queryArchivedServiceJobs?.current()) {

				let sportObjects = this.rootStore?.sportObjectsStore?.sportObjects;
				let sportSubZones = [].concat.apply([], [...sportObjects.map((sportObject: SportObjectEntity) => sportObject.sportSubZones)])

				let result: any = this.queryArchivedServiceJobs?.current()?.getArchivedEquipmentServiceJobsBySportObjects;

				result = result.slice().sort(sortByCreateAt);

				if (!isEqual(result, this.archivedServiceJobsResponse)) {
					this.archivedServiceJobsResponse = result
					this.archivedCount = this.archivedServiceJobsResponse.length
					this.filterValues = undefined
				}
				if (sportSubZones.length > 0) {
					if (!this.filterValues && sportSubZones.length > 0) {
						this.filterValues = sportSubZones
					} else if (sportSubZones.length === 0) {
						this.archivedServiceJobs = []
					}

					if (this.filterValues) {
						const filtered = filterByRowName(this.archivedServiceJobsResponse, this.filterValues, 'sportSubZone')

						if (!isEqual(filtered, toJS(this.archivedServiceJobs))) {
							this.archivedServiceJobs = filtered
						}
					}
				}

			}
		})
	}

	setFilters(filter: any) {
		const checkFilterIsEmptyString = (filter: any) => {
			if (Object.values(filter)[0] === '' || !Object.values(filter)[0]) {
				const filterName = Object.keys(filter)[0];
				filter = (filterName !== 'deadline' && filterName !== 'done') ? {[filterName]: null} : {[filterName]: [null, null]}
			}
			return filter;
		};

		const filters = Object.assign(toJS(this.filters), checkFilterIsEmptyString(filter));

		runInAction(() => {
			this.filters = filters
		})
	}

	get input() {
		let sportObjectIds = this.rootStore?.sportObjectsStore?.currentIds;
		return {
			sportObjectIds: sportObjectIds,
			sportZoneIds: this.filters.sportZoneIds,
			sportSubZoneIds: this.filters.sportSubZoneIds,
			jobName: this.filters.jobName,
			equipmentName: this.filters.equipmentName,
			equipmentInventoryNumber: this.filters.equipmentInventoryNumber,
			deadlineFrom: this.filters?.deadline?.[0] ? dayjs(toJS(this.filters?.deadline?.[0])).format('YYYY-MM-DD') : null,
			deadlineTo: this.filters?.deadline?.[1] ? dayjs(toJS(this.filters?.deadline?.[1])).format('YYYY-MM-DD') : null,
			doneFrom: this.filters?.done?.[0] ? dayjs(toJS(this.filters?.done?.[0])).format('YYYY-MM-DD') : null,
			doneTo: this.filters?.done?.[1] ? dayjs(toJS(this.filters?.done?.[1])).format('YYYY-MM-DD') : null,
			skip: this.page * this.pageSize,
			take: this.pageSize,
			order: this.sortOrder

		}
	}

	get queryServiceJobs(): any {
		let sportObjectIds = this.rootStore?.sportObjectsStore?.currentIds;

		if (sportObjectIds && sportObjectIds.length > 0) {

			return queryToMobxObservable(client.watchQuery({
				query: GetEquipmentServiceJobsDocument,
				variables: { input: this.input },
				pollInterval: Settings.pollIntervals.serviceJobs,
			}));
		} else {
			this.serviceJobs = []
			this.serviceJobsResponse = []
			this.count = 0
			return undefined;
		}

	}

	get queryArchivedServiceJobs(): any {
		let sportObjectIds = this.rootStore?.sportObjectsStore?.currentIds;

		if (sportObjectIds && sportObjectIds.length > 0) {
			return queryToMobxObservable(client.watchQuery({
				query: GetArchivedEquipmentServiceJobsBySportObjectsDocument,
				variables: {sportObjectIds: sportObjectIds},
				pollInterval: Settings.pollIntervals.serviceJobs,
			}));
		} else {
			this.archivedServiceJobs = []
			this.archivedServiceJobsResponse = []
			this.archivedCount = 0
			return undefined;
		}

	}

	get sportZones(): SportZoneEntity[] {
		let sportObjectsIds = this.rootStore?.sportObjectsStore?.currentIds;
		let sportObjects = this.rootStore?.sportObjectsStore.sportObjects.filter((sportObject: SportObjectEntity) => sportObjectsIds.includes(sportObject.id));
		return [].concat.apply([], [...sportObjects.map((sportObject: SportObjectEntity) => sportObject.sportZones)])
	}

	get sportSubZones(): SportSubZoneEntity[] {
		if (this.filters.sportZoneIds) {
			let filterSportZones = this.sportZones.filter((zone: SportZoneEntity) => this.filters.sportZoneIds?.includes(zone.id)) || []
			return [...filterSportZones].map((zone: SportZoneEntity) => zone.sportSubZones).flat()
		}

		return [...this.sportZones].map(sz => sz.sportSubZones).flat()
	}

	get sportZoneIds(): string[] {
		return [...this.sportZones].map(zone => zone.id)
	}

	get sportSubZoneIds(): string[] {
		return this.sportSubZones?.map(value => value.id)
	}

	setSortOrder(sortOrder: SortOrder | null) {
		this.sortOrder = sortOrder
	}

	setLoading(loading: boolean) {
		this.loading = loading
	}

	setPage(page: number) {
		this.page = page
	}

	setPageSize(pageSize: number) {
		this.pageSize = pageSize
	}


	update = (input: UpdateEquipmentServiceJobInput) => client.mutate({
		mutation: UpdateEquipmentServiceJobDocument,
		variables: {input},
		refetchQueries: [{
			query: GetEquipmentServiceJobsDocument,
			variables: { input: this.input },
		}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Обслуживание оборудования обновлено'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

	delete = (id: string) => client.mutate({
		mutation: DeleteEquipmentServiceJobDocument,
		variables: {id},
		refetchQueries: [{
			query: GetEquipmentServiceJobsDocument,
			variables: { input: this.input },
		}]
	})
			.then(() => this.rootStore?.setSnakeMessage('Обслуживание оборудования удалено'))
			.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

}

export default serviceJobsStore;
