import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {Box} from '@mui/system';
import Preloader from "./Preloader";
import {useStores} from "../../../../store";
import {observer} from "mobx-react-lite";
import {DepartmentEnum} from "../../../../store/department.enum";
import { DialogProvider } from "../../../../providers/DialogProvider";


const useStyles = makeStyles((theme) => ({

	main: {
		transition: 'opacity .3s .3',
		animation: 'fade-in 417ms cubic-bezier(0, 0, 0.2, 1) forwards',
		color: '#0d0d0d',
		display: 'flex',
		flexDirection: 'column',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		position: 'fixed',
	},
	mainContainer: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		flex: '1 0 auto',
		width: '100%',
		height: '100%',
		margin: 0,
		padding: 0,
		zIndex: 1,
	},
	navAndMainContent: {
		display: 'flex',
		flexGrow: 1,
		overflow: 'auto',
		position: 'relative',
	},
	mainContent: {
		overflow: 'auto',
		display: 'flex',
		flex: 1,
		position: 'relative',
		// backgroundColor: theme.palette.background.default,
	},

	topBanner: {},
	pageBanner: {},

}));

const DashboardLayout = observer(() => {
	const classes = useStyles();
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const [visible, setVisible] = useState(false);
	const {sportObjectsStore, userStore} = useStores();
	const isExternalUser = userStore?.currentUser?.user?.departments?.includes(DepartmentEnum.External)

	useEffect(() => {
		if (!sportObjectsStore.loading) {
			const timer = setTimeout(() => {
				setVisible(true)
			}, 1000)
			return () => {
				clearTimeout(timer)
			}
		}
	}, [sportObjectsStore.loading]);

	return (
			(!visible) ? <Preloader/> : (

				<DialogProvider>
					<Box className={classes.main} style={{opacity: !visible ? 0 : 1}}>
						<Box className={classes.mainContainer}>
							<Box className={classes.topBanner}/>
							<TopBar
									onMobileNavOpen={() => setMobileNavOpen(true)}
							/>
							<Box className={classes.pageBanner}/>
							<Box className={classes.navAndMainContent}>
								{!isExternalUser && <NavBar
										variant="permanent"
										onMobileClose={() => setMobileNavOpen(false)}
										openMobile={isMobileNavOpen}
								/>}
								<main className={classes.mainContent}>
									<Outlet/>
								</main>
							</Box>
						</Box>

					</Box>
				</DialogProvider>)
	);
});

export default DashboardLayout;
