import RootStore, {client} from "../index";
import {autorun, makeAutoObservable, toJS} from "mobx";
import {isEqual, queryToMobxObservable} from "../../helpers";
import {
	GetSpecificationsDocument, SpecificationEntity,
} from "../../graphql/graphQlApiHooks";
import Settings from "../settings";


const SpecificationsStore = class{
	public rootStore?: RootStore;
	public specifications: SpecificationEntity[] = [];

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this)

		autorun(() => {
			if (this.allSpecifications?.current()) {
				let result: any = this.allSpecifications.current();
				result = result.getSpecifications;

				if (!isEqual(result, this.specifications)) {
					this.specifications = result
				}
			}
		})

	}

	get allSpecifications() {
		if (this.rootStore?.userStore?.currentUser) {
			return queryToMobxObservable(client.watchQuery({
				query: GetSpecificationsDocument,
				pollInterval: Settings.pollIntervals.dictionaries,
			}));
		}
		return undefined;
	}


}

export default SpecificationsStore;