import React, {useEffect} from 'react';
import {
	Badge,
	Box,
	Button, CardHeader,
	Chip,
	DialogActions,
	DialogContent,
	DialogContentText,
	Fab,
	Fade,
	IconButton,
	ListItem,
	Menu,
	MenuItem,
	TextField,
	Tooltip,
	tooltipClasses,
	TooltipProps,
	Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
	DataGrid,
	GridCellParams,
	GridCellValue,
	GridColumns,
	GridRowData, GridSortItem, GridSortModel,
	GridToolbarContainer
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import MuiDataGridStyles from "components/MuiDataGridStyles";
import {
	EquipmentRequestEntity, EquipmentTypeDictionaryEntity, SportKindDictionaryEntity,
	SportObjectEntity, SportSubZoneEntity,
	UpdateEquipmentRequestInput,
} from "../../../../graphql/graphQlApiHooks";
import AddEquipmentRequests from "../Dialogs/Add";
import EditEquipmentRequests from '../Dialogs/Edit';
import DeleteDialog from 'components/Dialogs/DeleteDialog';
import {useStores} from '../../../../store';
import {observer} from 'mobx-react-lite';
import {styled} from "@mui/styles";
import clsx from "clsx";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import FiltersPanel, {FiltersType} from "components/FiltersPanel";
import FilterAutocomplete from "components/FiltersPanel/FilterComponents/FilterAutocomplete";
import FilterSearch from "components/FiltersPanel/FilterComponents/FilterSearch";
import FilterDateRange from "components/FiltersPanel/FilterComponents/FilterDateRange";
import dayjs from "dayjs";
import {HighlightedBox} from "components/HighlightedBox";
import Dialog from "components/Dialogs/Dialog";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Tooltiper from "components/Tooltiper";
import { BootstrapTooltip } from "../../../../components/BootstrapTooltip";

const useStyles = makeStyles((theme: Theme) => ({
	root: {},
	status: {
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1),
		borderRadius: '50px',
		fontWight: 'bold',
		"& .MuiChip-icon": {
			color: 'inherit'
		},
		'&.submitted': {
			// backgroundColor: '#d2eefe',
			// color: '#01b1ff'
			backgroundColor: '#fdf7e7',
			color: '#ce6801'
		},
		'&.success': {
			backgroundColor: '#def1dd',
			color: '#2ca42c'
		},
		'&.supplied': {
			backgroundColor: theme.palette.primary.main,
			color: 'white'
		},
		'&.rejected': {
			backgroundColor: '#ffd7d7',
			color: '#f73333'
		},
	},
	tableButton: {
		'& .MuiButton-label': {
			overflow: 'hidden',
			width: '100%',
			display: 'flex',
			justifyContent: 'end',
		}
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(6),
		right: theme.spacing(6),
	},
	moreMenu: {
		'& span': {
			padding: '8px 12px',
			display: 'block',
			color: theme.palette.text.secondary,
			fontSize: '13px'
		}
	}
}));

const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
		<Tooltip {...props} classes={{popper: className}} children={props.children}/>
))(({theme}) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(1, 2),
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 260,
		maxHeight: 220,
		position: 'relative',
		overflow: 'hidden',
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
	},
}));

const TooltipDescriptionRenderCell = observer((props: { params: GridCellParams; openDescriptionDialog: (params: EquipmentRequestEntity) => void; }) => (
		<HtmlTooltip
				TransitionComponent={Fade}
				TransitionProps={{timeout: 600}}
				disableFocusListener
				title={
						(props.params.row.description)? <React.Fragment>
                        <Typography
                            sx={{whiteSpace: 'nowrap', mb: 1}}
                            variant={'h6'}
                            color="inherit"
                        >Дополнгительная информация</Typography>
                        <Box
                            sx={{
								textOverflow: "ellipsis",
								display: '-webkit-box',
								WebkitLineClamp: 3,
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden'
							}}
                        >
							{props.params.row.description}
                        </Box>

                        <Box display={'flex'}>
                            <Button
		                            size={'small'}
                                onClick={() => props.openDescriptionDialog(props.params.row as EquipmentRequestEntity)}
                                sx={{marginLeft: 'auto', py: .5, px: 1, mt:1}}
                            >Подробнее...</Button>
                        </Box>

                    </React.Fragment> : 'Дополнгительная информация'

				}
		>
			<IconButton
					color="inherit"
					component="span"
					disabled={!props.params.row.description}
					onClick={() => props.openDescriptionDialog(props.params.row as EquipmentRequestEntity)}
			>
				<Badge
						color="primary"
						variant="dot"
						invisible={!props.params.row.description}
				>
					<MessageOutlinedIcon
							sx={{
								opacity: .5,
								transition: 'all .6s',
								fontSize: '18px !important'
							}}
					/>
				</Badge>
			</IconButton>
		</HtmlTooltip>
));

const TooltipRejectionRenderCell = observer((props: { params: GridCellParams; openRejectionDialog: (params: EquipmentRequestEntity) => void; }) => (
		<HtmlTooltip
				TransitionComponent={Fade}
				TransitionProps={{timeout: 600}}
				disableFocusListener
				title={
					(props.params.row.rejectReason) ? <React.Fragment>
						<Typography
								sx={{whiteSpace: 'nowrap'}}
								variant={'h6'}
								color="inherit"
						>Причина отказа по заявке</Typography>
						<Box
								sx={{
									textOverflow: "ellipsis",
									display: '-webkit-box',
									WebkitLineClamp: 3,
									WebkitBoxOrient: 'vertical',
									overflow: 'hidden'
								}}
						>
							{props.params.row.rejectReason}
						</Box>

						<Box display={'flex'}>
							<Button
									onClick={() => props.openRejectionDialog(props.params.row as EquipmentRequestEntity)}
									sx={{marginLeft: 'auto', py: 1, px: 2}}
							>Подробнее...</Button>
						</Box>

					</React.Fragment> : 'Причина отказа по заявке'
				}
		>

			<IconButton
					color="inherit"
					component="span"
					disabled={!props.params.row.rejectReason}
					onClick={() => props.openRejectionDialog(props.params.row as EquipmentRequestEntity)}
			>
				<Badge
						color="primary"
						variant="dot"
						invisible={!props.params.row.rejectReason}
				>
					<MessageOutlinedIcon
							sx={{
								opacity: .5,
								transition: 'all .6s',
								fontSize: '18px !important'
							}}
					/>
				</Badge>
			</IconButton>
		</HtmlTooltip>
));

const MoreMenuRenderCell = observer((props: { row: GridRowData, handleEdit: (row: GridRowData) => void, handleDelete: (id: string) => void, disabled?: boolean }) => {
	const classes = useStyles();
	const {row} = props
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);

	const handleClickMoreAreas = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMoreAreas = () => {
		setAnchorEl(null);
	};
	return (
			<>
				<BootstrapTooltip title={'Дополнительные действия'}>
					<IconButton
							size={'medium'}
							onClick={handleClickMoreAreas}
							id={`more-button-${row.id}`}
							aria-expanded={openMenu ? 'true' : undefined}
							aria-controls="more-menu"
							aria-haspopup="true"
							disabled={props.disabled}
					>
						<MoreHorizIcon fontSize="small" sx={{
							fontSize: '18px !important'
						}}/>
					</IconButton>
				</BootstrapTooltip>

				<Menu
						id={`more-menu-${row.id}`}
						anchorEl={anchorEl}
						keepMounted
						open={openMenu}
						onClose={handleCloseMoreAreas}
						elevation={2}
						className={classes.moreMenu}

						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}

						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
				>
					<MenuItem
							onClick={() => {
								handleCloseMoreAreas()
								props.handleEdit(row)
							}}
					>Изменить</MenuItem>
					<MenuItem
							onClick={() => {
								handleCloseMoreAreas()
								props.handleDelete(row.id as string)
							}}
					>Удалить</MenuItem>

				</Menu>

			</>);
});

const RenderActionsCell = observer(({equipmentRequest, onSuccess, onReject, onSupplied, moreMenuProps}: {
	equipmentRequest: EquipmentRequestEntity;
	onSuccess: () => void;
	onReject: () => void;
	onSupplied: () => void;
	moreMenuProps: {
		row: GridRowData;
		handleEdit: (row: GridRowData) => void;
		handleDelete: (id: string) => void;
	}
}) => {
	const {userStore} = useStores()
	const isAdmin = !userStore?.currentUser?.user?.isForSportObject;

	const {requestStatus} = equipmentRequest;

	const actions = () => {
		switch (requestStatus) {
			case 'Submitted': {
				return (
						<>
							{(isAdmin) ? <>
								<Button
										size={'small'}
										color={'error'}
										onClick={onReject}
								>
									Отклонить
								</Button>
								<Button
										size={'small'}
										variant={'contained'}
										onClick={onSuccess}
								>
									Одобрить
								</Button>
							</> : null}
							<MoreMenuRenderCell {...moreMenuProps} />
						</>
				);
			}
			case 'Success': {
				return (
						<>
							<Button
									size={'small'}
									variant={'contained'}
									onClick={onSupplied}
							>
								Принять оборудование
							</Button>
						</>
				)
			}
			default: {
				return <MoreMenuRenderCell {...moreMenuProps} disabled/>
			}
		}

	};


	return (
			<Box
					alignItems={'center'}
					display={"flex"}
					sx={{
						"& .MuiButton-root": {
							mr: 2,
							py: .2,
							px: 1.5,
						}
					}}
			>
				{actions()}
			</Box>
	)
});

const CustomToolbar = observer(() => {
	const {equipmentRequestStore, dictionariesStore} = useStores();

	const filters: FiltersType[] = [
		{
			name: 'sportZoneIds',
			title: "Объекты спорта",
			removable: false,
			filterComponent: (props: any) => <FilterAutocomplete
					values={equipmentRequestStore.sportZones}
					selected={equipmentRequestStore.filters.sportZoneIds}
					filterResult={(result: SportSubZoneEntity[]) => equipmentRequestStore.setFilters({sportZoneIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const sportZoneIds = equipmentRequestStore.filters.sportZoneIds
				return (sportZoneIds !== null) ? `: ${sportZoneIds.length}/${equipmentRequestStore.sportZones.length}` : ''
			}
		},
		{
			name: 'sportSubZoneIds',
			title: "Спортзоны",
			removable: false,
			filterComponent: (props: any) => <FilterAutocomplete
					values={equipmentRequestStore.sportSubZones}
					selected={equipmentRequestStore.filters.sportSubZoneIds}
					filterResult={(result: SportSubZoneEntity[]) => equipmentRequestStore.setFilters({sportSubZoneIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const sportSubZoneIds = equipmentRequestStore.filters.sportSubZoneIds
				return (sportSubZoneIds !== null) ? `: ${sportSubZoneIds.length}/${equipmentRequestStore.sportSubZones.length}` : `: 0/${equipmentRequestStore.sportSubZones.length}`
			}
		},
		{
			name: 'equipmentTypeIds',
			title: 'Тип оборудования',
			filterComponent: (props: any) => <FilterAutocomplete
					values={dictionariesStore.equipmentTypeDictionary as EquipmentTypeDictionaryEntity[]}
					selected={equipmentRequestStore.filters.equipmentTypeIds}
					filterResult={(result: EquipmentTypeDictionaryEntity[]) => equipmentRequestStore.setFilters({equipmentTypeIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const filter = equipmentRequestStore.filters.equipmentTypeIds;
				return (filter !== null) ? `: ${filter.length}/${dictionariesStore.equipmentTypeDictionary.length}` : `: 0/${dictionariesStore.equipmentTypeDictionary.length}`
			},
			clearFilter: () => {
				equipmentRequestStore.setFilters({equipmentTypeIds: null})
			}
		},
		{
			name: 'sportKindIds',
			title: 'Вид спорта',
			filterComponent: (props: any) => <FilterAutocomplete
					values={dictionariesStore.sportKindDictionary as SportKindDictionaryEntity[]}
					selected={equipmentRequestStore.filters.sportKindIds}
					filterResult={(result: SportKindDictionaryEntity[]) => equipmentRequestStore.setFilters({sportKindIds: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const filter = equipmentRequestStore.filters.sportKindIds;
				return (filter !== null) ? `: ${filter.length}/${dictionariesStore.sportKindDictionary.length}` : `: 0/${dictionariesStore.sportKindDictionary.length}`
			},
			clearFilter: () => {
				equipmentRequestStore.setFilters({sportKindIds: null})
			}
		},
		{
			name: 'statuses',
			title: 'Статус заявки',
			filterComponent: (props: any) => <FilterAutocomplete
					values={equipmentRequestStore.statuses}
					selected={equipmentRequestStore.filters.requestStatus}
					filterResult={(result: any[]) => equipmentRequestStore.setFilters({requestStatus: result.map(val => val.id)})}
					{...props}
			/>,
			chipDescription: () => {
				const filter = equipmentRequestStore.filters.requestStatus;
				return (filter !== null) ? `: ${filter.length}/${equipmentRequestStore.statuses.length}` : `: 0/${equipmentRequestStore.statuses.length}`
			},
			clearFilter: () => {
				equipmentRequestStore.setFilters({requestStatus: null})
			}
		},
		{
			name: 'name',
			title: 'Название оборудования',
			filterComponent: (props: any) => <FilterSearch
					value={equipmentRequestStore.filters.name}
					filterResult={(result: string) => equipmentRequestStore.setFilters({name: result})}
					{...props}
			/>,
			chipDescription: () => {
				return (equipmentRequestStore.filters.name !== null && equipmentRequestStore.filters.name !== '') ? ` содержит ${equipmentRequestStore.filters.name}` : ''
			},
			clearFilter: () => {
				equipmentRequestStore.setFilters({name: null})
			}
		},
		{
			name: 'description',
			title: 'Описание',
			filterComponent: (props: any) => <FilterSearch
					value={equipmentRequestStore.filters.description}
					filterResult={(result: string) => equipmentRequestStore.setFilters({description: result})}
					{...props}
			/>,
			chipDescription: () => {
				const description = equipmentRequestStore.filters.description;
				return (description !== null && description !== '') ? ` содержит ${description}` : ''
			},
			clearFilter: () => {
				equipmentRequestStore.setFilters({description: null})
			}
		},
		// requestStatus
		// rejectReason
		{
			name: 'applicationDate',
			title: 'Дата заявки',
			filterComponent: (props: any) => <FilterDateRange
					value={equipmentRequestStore.filters.applicationDate || [null, null]}
					filterResult={(result) => {
						equipmentRequestStore.setFilters({applicationDate: result})
					}}
					{...props}
			/>,
			chipDescription: () => {
				const applicationDate = equipmentRequestStore.filters.applicationDate;
				if (applicationDate?.[0] !== null) {
					if (!dayjs(applicationDate?.[0]).isSame(applicationDate?.[1]) && applicationDate?.[1]) {
						return ` С ${dayjs(applicationDate?.[0]).format('ll')} по ${dayjs(applicationDate?.[1]).format('ll')}`
					} else {
						return ` ${dayjs(applicationDate?.[0]).format('ll')}`
					}
				} else {
					return ''
				}
			},
			clearFilter: () => {
				equipmentRequestStore.setFilters({applicationDate: [null, null]})
			}
		}
	];

	function getFiltersSelected(filters: any) {
		let result = [];
		for (let [key, value] of Object.entries(equipmentRequestStore.filters)) {
			const filter = filters.find((item: any) => item.name === key);
			const isNullsArray = Boolean(Array.isArray(value) && value.filter((item: any) => item === null).length === 2)
			const isEmpty = Boolean(value == null || isNullsArray)
			if (!isEmpty || filter?.removable === false)
				result.push(filter)
		}
		return result;
	}


	return (
			<GridToolbarContainer>
				<FiltersPanel store={equipmentRequestStore} filters={filters} selected={getFiltersSelected(filters)}/>
				{/*getFiltersSelected(filters)*/}
			</GridToolbarContainer>
	)
});


const EquipmentRequestTable = observer(() => {
	const classes = useStyles();
	const tableClasses = MuiDataGridStyles();

	const [openAddEquipmentRequestItem, setOpenAddEquipmentRequestItem] = React.useState(false);
	const [openEditEquipmentRequestDialog, setOpenEditEquipmentRequestDialog] = React.useState(false);
	const [openDeleteEquipmentRequestDialog, setOpenDeleteEquipmentRequestDialog] = React.useState(false);

	const [editEquipmentRequest, setEditEquipmentRequest] = React.useState<GridRowData | null>(null)
	const [deleteEquipmentRequestId, setDeleteEquipmentRequestId] = React.useState<string | null>(null)

	const [rows, setRows] = React.useState([]);

	const [openDescriptionDialog, setOpenDescriptionDialog] = React.useState(false);
	const [openRejectionDialog, setOpenRejectionDialog] = React.useState(false);
	const [openRejectReasonDialog, setOpenRejectReasonDialog] = React.useState(false);
	const [openSuccessDialog, setOpenSuccessDialog] = React.useState(false);
	const [openSuppliedDialog, setOpenSuppliedDialog] = React.useState(false);
	const [descriptionDialogContent, setDescriptionDialogContent] = React.useState('');
	const [descriptionRejectionContent, setDescriptionRejectionContent] = React.useState('');
	const [equipmentRequestUpdatedItem, setEquipmentRequestUpdatedItem] = React.useState<EquipmentRequestEntity | null>(null);
	const [rejectReasonInputValue, setRejectReasonInputValue] = React.useState('');


	const {equipmentRequestStore, sportObjectsStore} = useStores();

	useEffect(() => {
		setRows([...equipmentRequestStore.equipmentRequest] as never[])
	}, [equipmentRequestStore.equipmentRequest, sportObjectsStore.currentIds]);

	function openEditDialogClick(row: GridRowData) {
		setEditEquipmentRequest(row)
		setOpenEditEquipmentRequestDialog(true)
	}

	function openDeleteDialogClick(id: string) {
		setDeleteEquipmentRequestId(id)
		setOpenDeleteEquipmentRequestDialog(true)
	}

	function editEquipmentRequestCloseHandle(val: boolean) {
		setOpenEditEquipmentRequestDialog(val)
	}

	function deleteEquipmentRequestCloseHandle(val: boolean) {
		setOpenDeleteEquipmentRequestDialog(val)
	}

	function deleteEquipmentRequestConfirmHandle(id: string) {
		equipmentRequestStore.delete(id)
	}

	const getStatusClass = (status: string) => {
		switch (status) {
			case "Submitted":
				return 'submitted'
			case "Success":
				return 'success'
			case "Supplied":
				return 'supplied'
			case "Rejected":
				return 'rejected'
			default:
				return ''
		}
	};
	const getStatusIcon = (status: string) => {
		switch (status) {
			case "Submitted":
				return <AccessTimeOutlinedIcon fontSize={"small"}/>
			case "Success":
				return <DoneIcon fontSize={"small"}/>
			case "Supplied":
				return <DoneAllIcon fontSize={"small"}/>
			case "Rejected":
				return <ThumbDownAltOutlinedIcon fontSize={"small"}/>
			default:
				return <ThumbDownAltOutlinedIcon fontSize={"small"}/>
		}
	};


	function handleOpenRejectionDialog(equipmentRequest: EquipmentRequestEntity) {
		setOpenRejectionDialog(true)
		setDescriptionRejectionContent(equipmentRequest?.rejectReason || '')
	}

	function handleOpenDescriptionDialog(equipmentRequest: EquipmentRequestEntity) {
		setOpenDescriptionDialog(true)
		setDescriptionDialogContent(equipmentRequest?.description || '')
	}

	const columns: GridColumns = [
		{
			field: 'request.name',
			headerName: 'Название необходимого оборудования',
			renderCell: (params) => {
				return (
						<CardHeader
								sx={{
									p: 0,
									maxWidth: '100%',
									'& .MuiCardHeader-content': {
										overflow: 'hidden',
									},
									'& .MuiTypography-root': {
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
										width: '100%',
										overflow: 'hidden',
									},
								}}
								title={
									<Tooltiper
											text={`${params.row.name.charAt(0).toUpperCase()}${params.row.name.slice(1)}`}
											props={{
												variant: 'h6'
											}}/>
								}
						/>
				);
			},
			flex: 2,
			editable: false,
			disableColumnMenu: true,
		},
		{
			field: 'sportObject.name',
			headerName: 'Спортобъект',
			renderCell: (params) => {
				return (
						<Tooltiper
								text={`${params.row.sportObject.name.charAt(0).toUpperCase()}${params.row.sportObject.name.slice(1)}`}/>
				)
			},
			sortComparator: ((v1: GridCellValue, v2: GridCellValue) => {
				const a: SportObjectEntity = v1 as SportObjectEntity, b: SportObjectEntity = v2 as SportObjectEntity;
				if (a.name < b.name) return -1;
				if (a.name > b.name) return 1;
				return 0;
			}),
			flex: 2,
			editable: false,
			disableColumnMenu: true,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'request.count', headerName: 'Количество оборудования',
			flex: .5,
			renderCell: (params) => {
				return (
						<Typography variant={'h6'}>
							{params.row.count}
						</Typography>
				);
			},
			editable: false,
			disableColumnMenu: true,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'request.equipmentType', headerName: 'Тип оборудования',
			renderCell: (params) => {
				const name = (params.row?.equipmentTypeObject?.name) ? params.row.equipmentTypeObject.name : (params.row?.equipmentType) ? params.row.equipmentType : ''

				return (
						<Tooltiper text={`${name?.charAt(0)?.toUpperCase()}${name?.slice(1)}`}/>
				);
			},
			flex: 2,
			editable: false,
			disableColumnMenu: true,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'request.sportKind', headerName: 'Вид спорта',
			renderCell: (params) => {
				const name = (params.row?.sportKindObject?.name) ? params.row.sportKindObject.name : (params.row?.sportKind) ? params.row.sportKind : ''
				return (
						<>
							<Tooltiper
									text={`${name?.charAt(0)?.toUpperCase()}${name?.slice(1)}`}/>
						</>
				);
			},
			flex: 2,
			editable: false,
			disableColumnMenu: true,
			align: 'center',
			headerAlign: 'center',
		},
		{
			field: 'request.requestStatus', headerName: 'Статус заявки',
			renderCell: (params) => {
				return (

						<Chip
								size={'small'}
								className={clsx(classes.status, getStatusClass(params.row.requestStatus))}
								icon={getStatusIcon(params.row.requestStatus)}
								label={equipmentRequestStore.getStatusName(params.row.requestStatus)}
						/>

				);
			},
			flex: 1.2,
			editable: false,
			align: 'center',
			headerAlign: 'center',
			disableColumnMenu: true,
		},
		{
			field: 'request.rejectReason', headerName: 'Причина отказа по заявке',
			renderCell: (params) => {
				return (
						<TooltipRejectionRenderCell
								params={params}
								openRejectionDialog={(params: EquipmentRequestEntity) => handleOpenRejectionDialog(params)}
						/>
				);
			},
			sortable: false,
			flex: 1,
			editable: false,
			align: 'center',
			headerAlign: 'center',
			disableColumnMenu: true,
		},
		{
			field: 'request.description', headerName: 'Дополнительная информация по заявке',
			renderCell: (params) => {
				return (
						<TooltipDescriptionRenderCell
								params={params}
								openDescriptionDialog={(params: EquipmentRequestEntity) => handleOpenDescriptionDialog(params)}
						/>
				);
			},
			sortable: false,
			flex: 1,
			editable: false,
			hideSortIcons: true,
			disableColumnMenu: true,
			align: 'center',
			headerAlign: 'center'
		},
		{
			field: 'actions', headerName: 'Действия',
			renderCell: (params) => {
				return <RenderActionsCell
						equipmentRequest={params.row}
						onSuccess={() => {
							setEquipmentRequestUpdatedItem(params.row)
							setOpenSuccessDialog(true)
						}}
						onReject={() => {
							setEquipmentRequestUpdatedItem(params.row)
							setOpenRejectReasonDialog(true)
						}}
						onSupplied={() => {
							setEquipmentRequestUpdatedItem(params.row)
							setOpenSuppliedDialog(true)
						}}
						moreMenuProps={{
							row: params.row,
							handleEdit: openEditDialogClick,
							handleDelete: openDeleteDialogClick
						}}
				/>
			},
			disableColumnMenu: true,
			flex: 2.4,
			minWidth: 300,
			editable: false,
			sortable: false,
			hideSortIcons: true,
			align: 'right',
			headerAlign: 'center'
		},
	];


	function handleCloseDescriptionDialog() {
		setOpenDescriptionDialog(false)
	}

	function handleCloseRejectionDialog() {
		setOpenRejectionDialog(false)
	}

	function handleCloseRejectReasonDialog() {
		setOpenRejectReasonDialog(false)
		setRejectReasonInputValue('')
	}

	function handleConfirmRejected() {
		let input: UpdateEquipmentRequestInput;

		if (equipmentRequestUpdatedItem) {
			input = {
				id: equipmentRequestUpdatedItem?.id,
				name: equipmentRequestUpdatedItem?.name,
				count: Number(equipmentRequestUpdatedItem?.count),
				equipmentTypeId: equipmentRequestUpdatedItem?.equipmentTypeObject?.id as string,
				requestStatus: 'Rejected',
				rejectReason: rejectReasonInputValue,
				sportObjectId: equipmentRequestUpdatedItem?.sportObject.id,
			}

			equipmentRequestStore.update(input)
					.then(() => {
						setOpenRejectReasonDialog(false)
						setRejectReasonInputValue('')
					})
		}
	}

	function handleCloseSuccessDialog() {
		setOpenSuccessDialog(false)
	}

	const [expanded, setExpanded] = React.useState<string | false>('description');

	const handleChange =
			(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
				setExpanded(isExpanded ? panel : false);
			};

	function handleConfirmSuccess() {
		let input: UpdateEquipmentRequestInput;

		if (equipmentRequestUpdatedItem) {
			input = {
				id: equipmentRequestUpdatedItem?.id,
				name: equipmentRequestUpdatedItem?.name,
				count: Number(equipmentRequestUpdatedItem?.count),
				equipmentTypeId: equipmentRequestUpdatedItem?.equipmentTypeObject?.id as string,
				requestStatus: 'Success',
				sportObjectId: equipmentRequestUpdatedItem?.sportObject.id,
			}

			equipmentRequestStore.update(input)
					.then(handleCloseSuccessDialog)
		}
	}

	function handleConfirmSupplied() {
		let input: UpdateEquipmentRequestInput;

		if (equipmentRequestUpdatedItem) {
			input = {
				id: equipmentRequestUpdatedItem?.id,
				name: equipmentRequestUpdatedItem?.name,
				count: Number(equipmentRequestUpdatedItem?.count),
				equipmentTypeId: equipmentRequestUpdatedItem?.equipmentTypeObject?.id as string,
				requestStatus: 'Supplied',
				sportObjectId: equipmentRequestUpdatedItem?.sportObject.id,
			}

			equipmentRequestStore.update(input)
					.then(handleCloseSuppliedDialog)
		}
	}

	function handleCloseSuppliedDialog() {
		setOpenSuppliedDialog(false)
	}

	const handleSortModelChange = (newModel: GridSortModel) => {
		const sortModel = newModel[0] as GridSortItem
		const sortOrder = sortModel?.field ? {[sortModel?.field]: sortModel?.sort?.toUpperCase()} : null

		equipmentRequestStore.setLoading(true);
		equipmentRequestStore.setSortOrder(sortOrder)
	};
	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setOpen(!open);
	};
	return (
			<>
				<div style={{height: 'calc(100% )', width: '100%'}}>
					<DataGrid
							// components={{Footer: () => <div/>}}
							rows={rows}
							columns={columns}
							className={tableClasses.root}
							rowHeight={60}
							onRowDoubleClick={(params) => openEditDialogClick(params.row)}
							loading={equipmentRequestStore.loading}

							checkboxSelection={false}
							columnBuffer={10}
							columnThreshold={10}
							density={'standard'}
							rowThreshold={3}
							rowBuffer={5}

							pagination
							rowCount={equipmentRequestStore.count}
							paginationMode="server"
							page={equipmentRequestStore.page}
							pageSize={equipmentRequestStore.pageSize}
							onPageChange={(page) => equipmentRequestStore.setPage(page)}
							onPageSizeChange={(pageSize) =>
									equipmentRequestStore.setPageSize(pageSize)
							}
							rowsPerPageOptions={[5, 10, 50, 100]}

							sortingMode="server"
							onSortModelChange={handleSortModelChange}
							components={{
								Toolbar: CustomToolbar,
							}}
					/>
				</div>

				<Fab
						color="primary"
						aria-label="add"
						className={classes.fab}
						onClick={() => setOpenAddEquipmentRequestItem(true)}
				>
					<AddIcon/>
				</Fab>

				<AddEquipmentRequests
						open={openAddEquipmentRequestItem}
						close={setOpenAddEquipmentRequestItem}
				/>


				<DeleteDialog
						title={'Удаление заявки на обслуживание'}
						open={openDeleteEquipmentRequestDialog}
						close={deleteEquipmentRequestCloseHandle}
						confirm={(id: string) => deleteEquipmentRequestConfirmHandle(id)}
						id={deleteEquipmentRequestId}
				/>
				{editEquipmentRequest === null ? null :
						<EditEquipmentRequests
								open={openEditEquipmentRequestDialog}
								close={editEquipmentRequestCloseHandle}
								row={editEquipmentRequest as EquipmentRequestEntity}
						/>}

				<Dialog
						open={openDescriptionDialog}
						onClose={handleCloseDescriptionDialog}
						props={{
							"aria-labelledby": "alert-dialog-title",
							"aria-describedby": "alert-dialog-description"
						}}
						title='Дополнительная информация по заявке'
						actions={
							<Button
									onClick={handleCloseDescriptionDialog}
									autoFocus
							>
								Закрыть
							</Button>
						}
				>
					<DialogContentText id="alert-dialog-description">
						{`${descriptionDialogContent?.charAt(0).toUpperCase()}${descriptionDialogContent?.slice(1)}`}
					</DialogContentText>
				</Dialog>

				<Dialog
						open={openRejectionDialog}
						onClose={handleCloseRejectionDialog}
						title="Причина отказа по заявке"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{descriptionRejectionContent}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
								onClick={handleCloseRejectionDialog}
								autoFocus
						>
							Закрыть
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
						open={openRejectReasonDialog}
						onClose={handleCloseRejectReasonDialog}
						props={{
							"aria-labelledby": "alert-dialog-title",
							"aria-describedby": "alert-dialog-description"
						}}
						title="Отклонение заявки на новое оборудование"
						actions={
							<Button
									variant={'contained'}
									color={"secondary"}
									onClick={handleConfirmRejected}
							>
								Отклонить
							</Button>
						}

						titles={[
							<CardHeader mb={2}
							            title={`${equipmentRequestUpdatedItem?.name.charAt(0).toUpperCase()}${equipmentRequestUpdatedItem?.name.slice(1)}`}
							/>,
							[
								{title: 'Количество:', value: equipmentRequestUpdatedItem?.count},
								{title: 'Спортобъект:', value: equipmentRequestUpdatedItem?.sportObject?.name},
								{title: 'Объект спорта:', value: equipmentRequestUpdatedItem?.sportZone?.name},
								{title: 'Спортзона:', value: equipmentRequestUpdatedItem?.sportSubZone?.name},
							].map((description: any, index: number) => (
									<ListItem key={index} component={'div'}>
										<Typography variant="body2" color="text.primary" sx={{width: '100%'}}>
											{description.title}
										</Typography>
										<Typography variant="h6" color="text.primary" align={'right'}
										            sx={{width: '100%'}}>
											{description.value}
										</Typography>
									</ListItem>
							)),
							<Box flexDirection={'column'} py={2} px={2} width={'100%'}>
								<Typography component={"div"} variant="h6" color="text.secondary"
								            sx={{width: '100%', mb: 1}}>
									Дополнительная информация
								</Typography>
								<HighlightedBox sx={{p: 2, borderRadius: 2}}>
									<Typography component={"div"} variant="body1" color="text.primary"
									            sx={{width: '100%'}}>
										{`${equipmentRequestUpdatedItem?.description?.charAt(0).toUpperCase()}${equipmentRequestUpdatedItem?.description?.slice(1)}` || 'Не указана'}
									</Typography>
								</HighlightedBox>
							</Box>
						].flat()}
				>


					<TextField
							value={rejectReasonInputValue}
							onChange={(event) => setRejectReasonInputValue(event.target.value)}
							placeholder={'Укажите причину отклонения'}
							label={"Причина отклонения"}
							multiline
							fullWidth
							autoFocus
							sx={{
								height: 120,
								'& .MuiInputBase-root': {
									height: '100%',
									'& textarea': {
										alignSelf: 'start'
									}
								}
							}}
					/>

				</Dialog>


				<Dialog
						open={openSuccessDialog}
						onClose={handleCloseSuccessDialog}
						props={{
							"aria-labelledby": "alert-dialog-title",
							"aria-describedby": "alert-dialog-description"
						}}
						title="Одобрение заявки на новое оборудование"
						actions={<>
							<Button
									color={"error"}
									onClick={handleCloseSuccessDialog}
							>
								Отмена
							</Button>
							<Button
									variant={'contained'}
									onClick={handleConfirmSuccess}
									autoFocus
							>
								Одобрить
							</Button>
						</>}

						titles={[
							<CardHeader mb={2}
							            title={`${equipmentRequestUpdatedItem?.name.charAt(0).toUpperCase()}${equipmentRequestUpdatedItem?.name.slice(1)}`}
							/>,
							[
								{title: 'Количество:', value: equipmentRequestUpdatedItem?.count},
								{title: 'Спортобъект:', value: equipmentRequestUpdatedItem?.sportObject?.name},
								{title: 'Объект спорта:', value: equipmentRequestUpdatedItem?.sportZone?.name},
								{title: 'Спортзона:', value: equipmentRequestUpdatedItem?.sportSubZone?.name},
							].map((description: any, index: number) => (
									<ListItem key={index} component={'div'}>
										<Typography variant="body2" color="text.primary" sx={{width: '100%'}}>
											{description.title}
										</Typography>
										<Typography variant="h6" color="text.primary" align={'right'}
										            sx={{width: '100%'}}>
											{description.value}
										</Typography>
									</ListItem>
							))
						].flat()}
				>

					<Typography component={"div"} variant="h6" color="text.secondary" sx={{width: '100%', mb: 1}}>
						Дополнительная информация
					</Typography>
					<HighlightedBox sx={{p: 2, borderRadius: 2}}>
						<Typography component={"div"} variant="body1" color="text.primary" sx={{width: '100%'}}>
							{`${equipmentRequestUpdatedItem?.description?.charAt(0).toUpperCase()}${equipmentRequestUpdatedItem?.description?.slice(1)}` || 'Не указана'}
						</Typography>
					</HighlightedBox>


				</Dialog>

				<Dialog
						open={openSuppliedDialog}
						onClose={handleCloseSuppliedDialog}
						props={{
							"aria-labelledby": "alert-dialog-title",
							"aria-describedby": "alert-dialog-description"
						}}
						title="Подтверждение приёма нового оборудования"
						actions={<>
							<Button
									color={"error"}
									onClick={handleCloseSuppliedDialog}
							>
								Отмена
							</Button>
							<Button
									variant={'contained'}
									onClick={handleConfirmSupplied}
									autoFocus
							>
								Принять новое оборудование
							</Button>
						</>}
						titles={[
							<CardHeader mb={2}
							            title={`${equipmentRequestUpdatedItem?.name.charAt(0).toUpperCase()}${equipmentRequestUpdatedItem?.name.slice(1)}`}
							/>,
							[
								{title: 'Количество:', value: equipmentRequestUpdatedItem?.count},
								{title: 'Спортобъект:', value: equipmentRequestUpdatedItem?.sportObject?.name},
								{title: 'Объект спорта:', value: equipmentRequestUpdatedItem?.sportZone?.name},
								{title: 'Спортзона:', value: equipmentRequestUpdatedItem?.sportSubZone?.name},
							].map((description: any, index: number) => (
									<ListItem key={index} component={'div'}>
										<Typography variant="body2" color="text.primary" sx={{width: '100%'}}>
											{description.title}
										</Typography>
										<Typography variant="h6" color="text.primary" align={'right'}
										            sx={{width: '100%'}}>
											{description.value}
										</Typography>
									</ListItem>
							))
						].flat()}
				>

					<Typography component={"div"} variant="h6" color="text.secondary" sx={{width: '100%', mb: 1}}>
						Дополнительная информация
					</Typography>
					<HighlightedBox sx={{p: 2, borderRadius: 2}}>
						<Typography component={"div"} variant="body1" color="text.primary" sx={{width: '100%'}}>
							{`${equipmentRequestUpdatedItem?.description?.charAt(0).toUpperCase()}${equipmentRequestUpdatedItem?.description?.slice(1)}` || 'Не указана'}
						</Typography>
					</HighlightedBox>

				</Dialog>

			</>
	);
});

export default EquipmentRequestTable;
