import { SportObjectEntity } from "../../../../../../../graphql/graphQlApiHooks";
import { useTheme } from "@mui/styles";
import React, { SyntheticEvent, useEffect } from "react";
import {
  Autocomplete,
  AutocompleteValue,
  Box,
  Button,
  Checkbox, CircularProgress,
  ClickAwayListener,
  TextField,
  Typography
} from "@mui/material";
import { isEqual } from "../../../../../../../helpers";
import { AutocompleteChangeReason, AutocompleteCloseReason } from "@mui/material/Autocomplete";
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";
import { darken } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Transition } from "react-transition-group";
import PopperComponent from "../components/PopperComponent";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration / 2}ms linear, transform ${duration}ms linear, visibility ${duration}ms linear`,
  position: "absolute",
  opacity: 0,
  transform: "translateY(5px)",
  visibility: "hidden",
  boxShadow: "0 20px 40px 0 rgb(0 0 0 / 30%)"
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1, transform: "translateY(0px)", visibility: "visible" },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};


interface SelectedProps {
  sportObjects: SportObjectEntity[],
  selectedSportObjectIds: SportObjectEntity[]


  actions: {
    setSelectedSportObjectIds: (selectedSportObjectIds: string[]) => void
  },
}


// TODO: реализовать автофокус в поле поиска при открытии
function Select(props: SelectedProps) {
  const theme = useTheme();
  const {
    sportObjects,
    selectedSportObjectIds,

    actions: {
      setSelectedSportObjectIds
    }
  } = props;



  const options: any = sportObjects.map((option) => {
    const firstLetter = option?.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option
    };
  });

  const selected: any = options.filter((o: any) => selectedSportObjectIds.includes(o.id));

  const [value, setValue] = React.useState<AutocompleteValue<{}, undefined, undefined, undefined> | any[]>([]);

  useEffect(() => {
    if (Array.isArray(value) && value.length === 0 && !isEqual(value, selected))
      setValue(selected);
  }, [selected, value]);

  const handleChange = (event: React.SyntheticEvent, newValue: AutocompleteValue<{}, undefined, undefined, undefined>, reason: AutocompleteChangeReason) => {
    if (
      event.type === "keydown" &&
      (event as React.KeyboardEvent).key === "Backspace" &&
      reason === "removeOption"
    ) {
      return;
    }
    if (newValue && Array.isArray(newValue) && value && Array.isArray(value)) {
      const id = event.currentTarget.getAttribute("id");
      const isExists = Boolean(value.find(val => val.id === id));

      let result = newValue.filter((val, index, self) =>
          index === self.findIndex((t) => (
            t.id === val.id
          ))
      );

      if (isExists) result = newValue.filter((val) => val.id !== id);

      setSelectedSportObjectIds([...result].map(res => res.id));
      setValue(result);
    }
  };

  function selectAll() {
    setValue(options);
    setSelectedSportObjectIds([...options].map(res => res.id));
  }

  function unSelectAll() {
    setValue([]);
    setSelectedSportObjectIds([]);

  }

  const containerRef = React.useRef(null);

  return <>

    <PopupState variant="popper" popupId="sportObjectsPopup">
      {(popupState) => (
        <ClickAwayListener
          onClickAway={e => {
            bindPopper(popupState).open && bindToggle(popupState).onClick(e as unknown as SyntheticEvent<any>);
          }}>
          <Box width={"100%"} position={"relative"} ref={containerRef}>
            <Button
              disableRipple
              {...bindToggle(popupState)}
              className={bindPopper(popupState).open ? "open" : ""}
              sx={{
                width: "100%",
                bgcolor: "background.default",
                p: 1,
                justifyContent: "space-between",
                color: theme.palette.text.secondary,
                "& .MuiSvgIcon-root": {
                  transform: "rotate(0deg)",
                  transition: "all .3s"
                },
                "&:hover, &.open": {
                  backgroundColor: darken(theme.palette.background.default, .05),
                  color: theme.palette.primary.main
                },
                "&.open": {
                  "& .MuiSvgIcon-root": {
                    transform: "rotate(180deg)"
                  }
                }
              }}
            >
              {value && Array.isArray(value) && (value.length > 0) ? value.slice().slice(0, 2).map((label: any) => (
                <Box
                  key={label.name}
                  sx={{
                    height: 20,
                    padding: ".15em 8px",
                    fontWeight: 600,
                    lineHeight: "15px",
                    borderRadius: "8px",
                    display: "block",
                    flexDirection: "row",
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    mr: 1
                  }}
                  style={{
                    backgroundColor: theme.palette.background.paper,
                    color: "#707579"
                  }}
                >
                  {label.name}
                </Box>
              )) : <Box sx={{ color: "text.disabled", pl: 1 }}>Выберите спортобъект</Box>}

              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                <Box
                  sx={{
                    whiteSpace: "nowrap",
                    mr: 1
                  }}
                >{value && Array.isArray(value) && value.length - 2 > 0 && `+ ${value.length - 2}`}
                </Box>
                <ExpandMoreIcon />
              </Box>

            </Button>
            <Transition in={bindPopper(popupState).open} timeout={duration}>
              {(state) => (
                <Box
                  sx={{
                    border: `1px solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
                    fontSize: 13,
                    color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
                    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
                    borderRadius: "0 0 12px 12px",
                    overflow: "hidden"
                  }}
                  style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                  }}
                >


                  <Box
                    sx={{
                      padding: "8px 10px",
                      display: "flex", justifyContent: "space-between",
                      borderBottom: `1px solid ${
                        theme.palette.mode === "light" ? "#eaecef" : "#30363d"
                      }`
                    }}
                  >
                    <Typography variant={"caption"}>Список спортобъектов</Typography>
                  </Box>


                  <Box>
                    {sportObjects.length > 0 ? (
                      <>
                        <Box
                          sx={{
                            padding: "8px 10px",
                            display: "flex", justifyContent: "space-between",
                            borderBottom: `1px solid ${
                              theme.palette.mode === "light" ? "#eaecef" : "#30363d"
                            }`,
                            "& .MuiButton-root": {
                              py: .5,
                              px: 1
                            }
                          }}
                        >
                          <Button
                            size={"small"}
                            onClick={selectAll}
                          >Выбрать все</Button>
                          <Button
                            size={"small"}
                            onClick={unSelectAll}
                          >Сбросить</Button>
                        </Box>

                        {value && Array.isArray(value) &&
                          <Autocomplete
                            open
                            multiple
                            onClose={(
                              event: React.ChangeEvent<{}>,
                              reason: AutocompleteCloseReason
                            ) => {
                              if (reason === "escape") {
                                bindToggle(popupState).onClick(event as unknown as SyntheticEvent<any>);
                              }
                            }}
                            limitTags={2}
                            value={value}
                            groupBy={(option) => option.firstLetter}
                            onChange={(event, newValue, reason) => handleChange(event, newValue, reason)}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="Спортобъект не найден"
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            renderOption={(props, option, { selected, inputValue }) => {
                              const matches = match(option.name, inputValue);
                              const parts = parse(option.name, matches);
                              return (
                                <li {...props} id={option.id}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={!!value.find(val => val.id === option.id)}
                                  />
                                  <Box
                                    sx={{
                                      flexGrow: 1,
                                      "& span": {
                                        color:
                                          theme.palette.mode === "light" ? "#586069" : "#8b949e"
                                      }
                                    }}
                                  >
                                    {parts.map((part: any, index: number) => (
                                      <span
                                        key={index}
                                        style={{
                                          fontWeight: part.highlight ? 700 : 400
                                        }}
                                      >
                                                  {part.text}
                                                </span>
                                    ))}
                                    <br />
                                    <span>{option.address}</span>
                                  </Box>
                                </li>
                              );
                            }}
                            options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                size={"small"}
                                sx={{
                                  p: 2
                                }}
                                fullWidth
                                ref={params.InputProps.ref}
                                inputProps={params.inputProps}
                                autoFocus
                                placeholder="Название спортобъекта"
                              />
                            )}
                          />
                        }
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          minHeight: "150px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    )}
                  </Box>


                </Box>
              )}
            </Transition>
          </Box>
        </ClickAwayListener>
      )}
    </PopupState>

  </>;
}


export default Select;