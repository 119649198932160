import {observer} from 'mobx-react-lite';
import {
	List,
	Paper,
	ListItem,
	ListItemIcon,
	FormControl,
	InputLabel,
	OutlinedInput,
	FormHelperText,
	Checkbox,
	ListItemText,
	Grid,
	Button,
	Box,
	Autocomplete,
	TextField,
	Popper,
	Divider,
	ListSubheader,
	IconButton,
} from '@mui/material';
import {useEffect, useState, MouseEvent} from 'react';
import Dialog from '../../../../../components/Dialogs/Dialog';
import {useStores} from 'store';
import {
	CreateSportCategoryDictionaryInput,
	CreateSportEquipmentInput,
	SportKindDictionaryEntity
} from 'graphql/graphQlApiHooks';
import AddIcon from '@mui/icons-material/Add';
import AddSportKind from './AddSportKind';

export function not(a: any, b: any) {
	return a.filter((value: any) => b.indexOf(value) === -1);
}

export function intersection({a, b}: { a: any; b: any }) {
	return a.filter((value: any) => b.indexOf(value) !== -1);
}

interface Props {
	onClose: () => void;
	open: boolean;
}

export const RightList = (rest: any) => {

	return (
			<Paper
					elevation={0}
					sx={{
						border: '1px solid',
						borderColor: 'divider',
					}}
			>
				<Autocomplete
						open
						disablePortal
						disableCloseOnSelect
						popupIcon={null}
						multiple
						PopperComponent={props => (
								<Popper
										{...props}
										sx={{
											position: 'relative !important',
											transform: 'none !important',
											boxShadow: 'none !important',
											width: '100% !important',
											minHeight: '40vh',
										}}
								/>
						)}
						PaperComponent={props => <Paper {...props} elevation={0}/>}
						options={rest.items}
						renderInput={params => (
								<Box sx={{m: 1, mb: 2}}>
									<TextField {...params} size={'small'} placeholder='Вид спорта'/>
									<Divider/>
								</Box>
						)}
						getOptionLabel={(option: any) => option.name || ''}
						groupBy={option => option.groupName}
						renderOption={(props, option, {selected}) => {
							const labelId = `transfer-list-item-${option.id}-label`;

							return (
									<ListItem
											key={option.id}
											role='listitem'
											button
											onClick={rest.handleToggle(option)}
											sx={{alignItems: 'start'}}
									>
										<ListItemIcon sx={{minWidth: 'auto', mr: 1}}>
											<Checkbox
													checked={
															[...rest.checked].map(val => val.id).indexOf(option.id) !==
															-1
													}
													tabIndex={-1}
													disableRipple
													inputProps={{
														'aria-labelledby': labelId,
													}}
													size='small'
											/>
										</ListItemIcon>
										<ListItemText
												id={labelId}
												primary={option.name}
												disableTypography
										/>
									</ListItem>
							);
						}}
				/>
			</Paper>
	);
};

const AddSportCategoryDialog = observer((props: Props) => {
	const {onClose, open} = props;
	const {
		dictionariesStore: {sportCategoryDictionary, createSportCategoryDictionary},
	} = useStores();
	const [checked, setChecked] = useState<any[]>([]);
	const [openAddSportKind, setOpenAddSportKind] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [categoryName, setCategoryName] = useState('');

	// const [sportCategoryDictionary, setSportCategoryDictionary] = useState<SportCategoryDictionaryEntity[]>([]);

	const [left, setLeft] = useState<SportKindDictionaryEntity[]>([]);
	const [right, setRight] = useState<SportKindDictionaryEntity[]>([]);

	useEffect(() => {
		if (sportCategoryDictionary?.length) {
			const right = [...sportCategoryDictionary]
					.map(sportCategory =>
							sportCategory?.sportKinds.map(
									(sportKind: SportKindDictionaryEntity) => ({
										...sportKind,
										groupName: sportCategory.name,
									})
							)
					)
					.flat();

			setRight(right);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sportCategoryDictionary]);

	const leftChecked = intersection({a: checked, b: left});
	const rightChecked = intersection({a: checked, b: right});

	const handleToggle = (value: any) => () => {
		const currentIndex = [...checked].map(val => val.id).indexOf(value.id);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		setRight(right.concat(left));
		setLeft([]);
	};

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked));
		setLeft(not(left, leftChecked));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked));
		setRight(not(right, rightChecked));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		setLeft(left.concat(right));
		setRight([]);
	};
	const handleClickAddSportKindPanel = (
			event: MouseEvent<HTMLButtonElement>
	) => {
		setAnchorEl(event.currentTarget);
		setOpenAddSportKind(true);
	};

	const customList = (
			items: SportKindDictionaryEntity[],
			emptyText?: string
	) => (
			<Paper
					elevation={0}
					sx={{
						height: '100%',
						overflow: 'auto',
						border: '1px solid',
						borderColor: 'divider',
						display: 'flex',
					}}
			>
				<List
						dense
						component='div'
						role='list'
						sx={{height: '340px'}}
						subheader={
							<ListSubheader component='div' id='nested-list-subheader'>
								Список видов спорта
								<IconButton
										aria-controls='customized-menu'
										aria-haspopup='true'
										size='small'
										onClick={handleClickAddSportKindPanel}
								>
									<AddIcon fontSize='inherit'/>
								</IconButton>
							</ListSubheader>
						}
				>
					{items.length > 0 ? (
							items.map((value: SportKindDictionaryEntity, index: number) => {
								const labelId = `transfer-list-item-${value.id}-label`;

								return (
										<ListItem
												key={value.id}
												role='listitem'
												button
												onClick={handleToggle(value)}
												sx={{alignItems: 'start'}}
										>
											<ListItemIcon sx={{minWidth: 'auto', mr: 1}}>
												<Checkbox
														checked={
																[...checked].map(val => val.id).indexOf(value.id) !== -1
														}
														tabIndex={-1}
														disableRipple
														inputProps={{
															'aria-labelledby': labelId,
														}}
														size='small'
												/>
											</ListItemIcon>
											<ListItemText
													id={labelId}
													primary={value.name}
													disableTypography
											/>
										</ListItem>
								);
							})
					) : emptyText ? (
							<Box
									p={2}
									px={4}
									display={'flex'}
									justifyContent={'center'}
									justifyItems={'center'}
									alignItems={'center'}
									alignContent={'center'}
									height={'calc(100% - 48px)'}
							>
								<FormHelperText sx={{textAlign: 'center'}}>
									{emptyText}
								</FormHelperText>
							</Box>
					) : null}
				</List>
			</Paper>
	);


	const handleConfirm = () => {
		const input: CreateSportCategoryDictionaryInput = {
			name: categoryName,
			sportKindIds: left.map(item => item.id) || [],
		};

		createSportCategoryDictionary(input);
	}

	return (
			<Dialog
					open={open}
					title={'Создайте категорию спорта'}
					actions={
						<Button
								variant={'contained'}
								onClick={handleConfirm}
								disabled={categoryName === ''}
						>
							Добавить новую категорию
						</Button>
					}
					onClose={onClose}
					props={{
						maxWidth: 'md',
					}}
			>
				<Grid container spacing={2} width='100%'>
					<Grid
							item
							width='50%'
							alignItems='stretch'
							sx={{flexDirection: 'column'}}
							display={'flex'}
					>
						<FormControl fullWidth>
							<InputLabel
									htmlFor='categoryName'
									sx={{
										fontWeight: 400,
										marginBottom: 1,
										position: 'relative',
										transform: 'none',
									}}
							>
								Название категории
							</InputLabel>
							<OutlinedInput
									name={'categoryName'}
									id={'categoryName'}
									value={categoryName}
									onChange={({target: {value}}) => setCategoryName(value)}
									size={'small'}
									placeholder={'Единоборства'}
									fullWidth
							/>
							<FormHelperText>Укажите название категории спорта.</FormHelperText>
						</FormControl>

						<Grid
								container
								alignItems='stretch'
								direction='column'
								display={'flex'}
								height={'100%'}
								mt={2}
						>
							<Grid item sx={{flexDirection: 'column'}} display={'flex'} height={'100%'}>
								{customList(
										left,
										'Выберите виды спорта из списка справа, или добавьте новый вид спорта'
								)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item alignSelf='center'>
						<Grid container direction='column' alignItems='center'>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleAllRight}
									disabled={left.length === 0}
									aria-label='move all right'
							>
								≫
							</Button>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleCheckedRight}
									disabled={leftChecked.length === 0}
									aria-label='move selected right'
							>
								&gt;
							</Button>
							<Button
									sx={{
										my: 0.5,
										padding: '0 4px !important',
										minWidth: '25px',
									}}
									variant='contained'
									size='small'
									onClick={handleCheckedLeft}
									disabled={rightChecked.length === 0}
									aria-label='move selected left'
							>
								&lt;
							</Button>
							{/* <Button
							sx={{
								my: 0.5,
								padding: '0 4px !important',
								minWidth: '25px',
							}}
							variant='contained'
							size='small'
							onClick={handleAllLeft}
							disabled={right.length === 0}
							aria-label='move all left'
						>
							≪
						</Button> */}
						</Grid>
					</Grid>
					<Grid item width='45%'>
						<RightList
								items={right}
								handleToggle={handleToggle}
								checked={checked}
						/>
					</Grid>
				</Grid>
			</Dialog>
	);
});

export default AddSportCategoryDialog;
