import React, { useEffect, useMemo, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInputProps,
  Stack,
  TextField
} from "@mui/material";
import {
  CreateEquipmentRequestInput,
  EquipmentSpecificationDictionaryEntity,
  EquipmentTypeDictionaryEntity,
  SportKindDictionaryEntity,
  SportObjectEntity,
  SportSubZoneEntity,
  SportZoneEntity
} from "graphql/graphQlApiHooks";
import { useTheme } from "@mui/styles";
import { useStores } from "store";
import Dialog, { useStyles } from "components/Dialogs/Dialog";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { observer } from "mobx-react-lite";


const useStylesUpload = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    backdropFilter: "blur(10px)",
    "& .MuiDialogContent-root": {
      padding: 0
    }
  },
  uploadArea: {
    marginTop: "1.25rem",
    border: "none",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    backgroundColor: "transparent",
    padding: "1rem 1rem",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "all .3s",
    justifyContent: "space-between",
    fontSize: 13.3333,
    fontWeight: 400,
    "&:hover, &:focus": {
      cursor: "pointer",
      backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232e44ff' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      "& .uploadIcon": {
        fill: theme.palette.primary.main
      }
    },
    "& .upload-area-icon": {
      display: "block",
      width: "2.25rem",
      height: "2.25rem",
      marginRight: ".5rem",
      "& svg": {
        maxHeight: "100%",
        maxWidth: "100%"
      }
    },
    "& .upload-area-file": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    "& .uploadIcon": {
      transition: "all .3s",
      fill: theme.palette.text.secondary
    },
    "& .upload-area-titles": {
      marginLeft: "1rem"
      // opacity: .8
    },
    "& .upload-area-title": {
      display: "block",
      fontWeight: "700",
      color: theme.palette.text.primary,
      textAlign: "left"
    },
    "& .upload-area-description": {
      display: "block",
      color: theme.palette.text.secondary,
      "& strong": {
        fontWeight: "700",
        color: theme.palette.primary.main
      }
    }

  },
  dialogContent: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%"
  },
  sidebar: {
    width: 280,
    flexGrow: 0,
    flexBasis: 280,
    flexShrink: 0,
    position: "relative",
    borderRight: "1px solid rgba(0, 0, 0, 0.12)"
  },
  sidebarContainer: {
    padding: "1rem 1rem",
    position: "relative",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0
  },
  main: {
    width: "100%",
    height: "100%",
    position: "relative"
  },
  documents: {
    position: "absolute",
    height: "calc(100% - 49px)",
    width: "100%",
    overflowY: "auto",
    padding: "1rem 2rem",
    "& .document:not(:first-child)": {
      marginTop: "1rem"
    }
  },
  avatar: {
    marginRight: ".5em",
    border: `2px solid ${theme.palette.primary.main}`,
    padding: "2px",
    "& img": {
      borderRadius: "100%"
    }
  },
  titleInput: {
    fontSize: "18px",
    fontWeight: 500
  }

}));

const AddEquipmentRequests = observer(({ open, close }: { open: boolean; close: (state: boolean) => void; }) => {
  const classes = useStyles();
  const classesUpload = useStylesUpload();
  const theme = useTheme();

  const { equipmentRequestStore, sportZonesStore, sportObjectsStore, userStore, dictionariesStore } = useStores();
  const [openTooltip, setOpenTooltip] = React.useState(false);


  const [sportObjects, setSportObjects] = useState<SportObjectEntity[]>([]);
  const [sportObject, setSportObject] = useState<SportObjectEntity | null>(null);
  const [sportZones, setSportZones] = useState<SportZoneEntity[]>([]);
  const [sportZone, setSportZone] = useState<SportZoneEntity | null>(null);
  const [sportSubZones, setSportSubZones] = useState<SportSubZoneEntity[]>([]);
  const [sportSubZone, setSportSubZone] = useState<SportSubZoneEntity | null>(null);

  const [sportKinds, setSportKinds] = useState<SportKindDictionaryEntity[]>([]);
  const [sportKind, setSportKind] = useState<SportKindDictionaryEntity | null>(null);
  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentTypeDictionaryEntity[]>([]);
  const [equipmentType, setEquipmentType] = useState<EquipmentTypeDictionaryEntity | null>(null);
  const [equipmentSpecifications, setEquipmentSpecifications] = useState<EquipmentSpecificationDictionaryEntity[]>([]);
  const [equipmentSpecification, setEquipmentSpecification] = useState<EquipmentSpecificationDictionaryEntity | null>(null);

  const [counter, setCounter] = useState(1);

  const [input, setInput] = useState({
    sportObjectId: "",
    sportZoneId: "",
    sportSubZoneId: null,
    name: "",
    count: 1,
    description: "",
    equipmentTypeId: "",
    sportKindId: "",
    // equipmentSpecificationId: '',
    requestStatus: "Submitted"
  } as CreateEquipmentRequestInput);

  const [errors, setErrors] = useState<any>({});

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    (!equipmentType) && setOpenTooltip(true);
  };
  useEffect(() => {
    if (sportObjectsStore.sportObjects) {
      setSportObjects(sportObjectsStore.sportObjects as SportObjectEntity[]);
    }
  }, [sportObjectsStore.sportObjects, open]);

  useEffect(() => {
    if (dictionariesStore.sportKindDictionary) {
      setSportKinds(dictionariesStore.sportKindDictionary as SportKindDictionaryEntity[]);
    }
  }, [dictionariesStore.sportKindDictionary, open]);


  function onChangeSportObjectHandler(value: SportObjectEntity) {
    setSportObject(value);
    if (value && value?.sportZones) {
      setInput({ ...input, sportObjectId: value.id });
      setSportZones(value.sportZones);
    }
  }

  function onChangeSportZoneHandler(sportZone: SportZoneEntity | null) {
    sportZone?.id && setInput({ ...input, sportZoneId: sportZone.id as string });
    setSportZone(sportZone);
    if (sportZone?.sportSubZones) {
      setSportSubZones(sportZone.sportSubZones);
      if (sportZone.sportSubZones.length === 0) {
        setSportSubZone(null);
      }
    } else {
      setSportSubZones([]);
    }
    setSportSubZone(null);
  }

  function onChangeSportSubZoneHandler(sportSubZone: SportSubZoneEntity | null) {
    sportSubZone?.id && setInput({ ...input, sportSubZoneId: sportSubZone.id as string });
    setSportSubZone(sportSubZone);
  }

  useMemo(() => {
    setInput({ ...input, count: counter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);


  function handleClickCountUp() {
    setCounter(counter + 1);
  }

  function handleClickCountDown() {
    setCounter(counter - 1);
  }

  function handleChangeCount(event: React.ChangeEvent<HTMLInputElement>) {
    setCounter(Number(event.target.value));
  }

  const onSubmit = () => {
    equipmentRequestStore.create(input).then(() => {
      handleCloseModal();
    });
  };

  const handleCloseModal = () => {
    setSportObjects([]);
    setSportObject(null);
    setSportZones([]);
    setSportZone(null);
    setSportSubZones([]);
    setSportSubZone(null);
    setSportKind(null);
    setSportKinds([]);
    setEquipmentType(null);
    setEquipmentTypes([]);
    setEquipmentSpecification(null);
    setEquipmentSpecifications([]);
    setCounter(1);
    setInput({
      sportObjectId: "",
      sportZoneId: "",
      sportSubZoneId: null,
      name: "",
      count: 1,
      description: "",
      equipmentTypeId: "",
      sportKindId: "",
      // equipmentSpecificationId: '',
      requestStatus: "Submitted"
    });
    close(false);
  };


  function onChangeSportKindHandler(sportKind: SportKindDictionaryEntity | null) {
    setSportKind(sportKind);
    if (sportKind && sportKind?.equipmentTypes) {
      setInput({ ...input, sportKindId: sportKind.id as string });
      setEquipmentTypes(sportKind.equipmentTypes as EquipmentTypeDictionaryEntity[]);
    } else {
      setInput({ ...input, sportKindId: "" });
      setEquipmentTypes([]);
    }
    setEquipmentType(null);
    setEquipmentSpecification(null);
  }

  function onChangeEquipmentTypeHandler(equipmentType: EquipmentTypeDictionaryEntity | null) {
    setEquipmentType(equipmentType);
    if (equipmentType?.sportKind && equipmentType?.sportKind?.id !== sportKind?.id) {
      setSportKind(equipmentType?.sportKind);
      const sportKind = sportKinds.find((kind: SportKindDictionaryEntity) => kind.id === equipmentType?.sportKind?.id);
      if (sportKind && sportKind?.equipmentTypes) {
        setInput({ ...input, sportKindId: sportKind.id as string });
        setEquipmentTypes(sportKind?.equipmentTypes as EquipmentTypeDictionaryEntity[]);
      }
    }
    if (equipmentType && equipmentType?.equipmentSpecifications) {
      setInput({ ...input, equipmentTypeId: equipmentType.id as string });
      setEquipmentSpecifications(equipmentType.equipmentSpecifications as EquipmentSpecificationDictionaryEntity[]);
    }
    setEquipmentSpecification(null);
  }

  function onChangeEquipmentSpecificationHandler(equipmentSpecification: EquipmentSpecificationDictionaryEntity | null) {
    setEquipmentSpecification(equipmentSpecification);
    if (equipmentSpecification) {
      // setInput({...input, equipmentSpecificationId: equipmentSpecification.id as string})
    }
  }


  return (
    <Dialog
      title={"Заявка на новое оборудование"}
      open={open}
      onClose={handleCloseModal}
      props={{
        "aria-labelledby": "scroll-dialog-title",
        "aria-describedby": "scroll-dialog-description",
        maxWidth: "md"
        // classes: {paper: classesUpload.root,}
      }}
      actions={<>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          form="addEquipmentRequest"
          onClick={onSubmit}
        >
          Создать заявку
        </Button>
      </>}
      titles={[
        <Box py={2} flexDirection={"row"} width={"100%"} display="flex"
             sx={{
               "& .MuiInputBase-input": {
                 fontWeight: "bold"
               }
             }}
        >

          <TextField
            required
            value={input.name}
            onChange={(event) => setInput({ ...input, name: event.target.value })}
            id="equipmentName"
            label="Название необходимого оборудования"
            placeholder="Укажите название"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
          />
          <Box
            sx={{
              width: "35%",
              ml: 1,
              "& .MuiOutlinedInput-root": {
                pl: 1,
                pr: 2.5
              },
              "& .MuiInputBase-input": {
                textAlign: "center"
              }
            }}
          >
            <TextField
              required
              id="equipmentCount"
              value={counter}
              onChange={handleChangeCount}
              type={"number"}
              fullWidth
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickCountUp}
                    edge="end"
                  > <AddIcon fontSize={"small"} /> </IconButton>
                </InputAdornment>,
                startAdornment: <InputAdornment position="start">
                  <IconButton
                    onClick={handleClickCountDown}
                    edge="end"
                  > <RemoveIcon fontSize={"small"} /></IconButton>
                </InputAdornment>

              } as Partial<OutlinedInputProps>}

              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>

        </Box>
      ]}
    >

      <Stack marginBottom={2} direction="row" spacing={2}>
        {((userStore?.currentUser?.user?.sportObjects && userStore?.currentUser?.user?.sportObjects?.length > 0) || !userStore?.currentUser?.user?.isForSportObject) &&
          <FormControl
            size={"small"}
            fullWidth
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <Autocomplete
              value={sportObject}
              id="sportObject"
              options={sportObjects}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: any) => onChangeSportObjectHandler(value)}
              renderInput={(params) => <TextField
                {...params}
                label="Спортобъект"
                variant="outlined"
                placeholder="Выберите спортобъект"
                size={"small"}
                InputLabelProps={{
                  shrink: true
                }}
              />}
            />
          </FormControl>}
        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <Autocomplete
            value={sportZone}
            id="sportZone"
            disabled={sportZones.length === 0}
            options={sportZones}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value: any) => onChangeSportZoneHandler(value)}
            renderInput={(params) => <TextField
              {...params}
              label="Объект спорта"
              variant="outlined"
              size={"small"}
              placeholder="Выберите объект спорта"
              InputLabelProps={{
                shrink: true
              }}
            />}
          />
        </FormControl>


        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <Autocomplete
            value={sportSubZone}
            id="sportZone"
            disabled={sportSubZones.length === 0}
            options={sportSubZones}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value: any) => onChangeSportSubZoneHandler(value)}
            renderInput={(params) => <TextField
              {...params}
              label="Спортзона"
              variant="outlined"
              placeholder="Выберите спортзону"
              size={"small"}
              InputLabelProps={{
                shrink: true
              }}
            />}
          />
        </FormControl>
      </Stack>

      <Stack direction="row" spacing={2}>
        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >

          <Autocomplete
            value={sportKind}
            disabled={dictionariesStore.sportKindDictionary.length === 0}
            id="sportKind"
            options={sportKinds}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value: any) => onChangeSportKindHandler(value)}
            renderInput={(params) => <TextField
              {...params}
              label="Вид спорта *"
              variant="outlined"
              placeholder="Выберите вид спорта"
              size={"small"}
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors?.sportKind)}
              helperText={errors?.sportKind && errors.sportKind.message}
            />}
          />
        </FormControl>

        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <Autocomplete
            value={equipmentType}
            disabled={sportKind?.equipmentTypes?.length === 0}
            id="equipmentType"
            options={equipmentTypes.length > 0 ? equipmentTypes : dictionariesStore?.equipmentTypeDictionary}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}
            onChange={(e, value: EquipmentTypeDictionaryEntity | null) => {
              onChangeEquipmentTypeHandler(value);
            }}
            renderInput={(params) => <TextField
              {...params}
              label="Тип оборудования *"
              variant="outlined"
              placeholder="Выберите тип оборудования"
              size={"small"}
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors?.equipmentType)}
              helperText={errors?.equipmentType && errors.equipmentType.message}
            />}
          />
        </FormControl>

        {/*<Tooltip title={'Сначала выберите тип оборудования'} open={openTooltip}*/}
        {/*         onClose={handleCloseTooltip} onOpen={handleOpenTooltip}>*/}

        {/*	<FormControl*/}
        {/*			size={'small'}*/}
        {/*			fullWidth*/}
        {/*			variant="outlined"*/}
        {/*			classes={{root: classes.formControl}}*/}
        {/*			sx={{*/}
        {/*				pb: 2*/}
        {/*			}}*/}
        {/*	>*/}
        {/*		<Autocomplete*/}
        {/*				value={equipmentSpecification}*/}
        {/*				disabled={equipmentSpecifications.length === 0}*/}
        {/*				id="equipmentSpecifications"*/}
        {/*				options={equipmentSpecifications}*/}
        {/*				getOptionLabel={(option) => option.name}*/}
        {/*				isOptionEqualToValue={(option, value) => option?.id === value?.id}*/}
        {/*				groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}*/}
        {/*				onChange={(e, value: EquipmentSpecificationDictionaryEntity | null) => {*/}
        {/*					onChangeEquipmentSpecificationHandler(value)*/}
        {/*				}}*/}
        {/*				renderInput={(params) => <TextField*/}
        {/*						{...params}*/}
        {/*						label="Характеристики оборудования"*/}
        {/*						variant="outlined"*/}
        {/*						placeholder='Выберите характеристику оборудования'*/}
        {/*						size={'small'}*/}
        {/*						InputLabelProps={{*/}
        {/*							shrink: true,*/}
        {/*						}}*/}
        {/*				/>}*/}
        {/*		/>*/}
        {/*	</FormControl>*/}
        {/*</Tooltip>*/}
      </Stack>

      <Stack direction="row" spacing={2}>
        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <TextField
            value={input.description}
            onChange={(event) => setInput({
              ...input,
              description: event.target.value
            })}
            id="equipmentDescription"
            label="Дополнительная информация по заявке"
            placeholder="Укажите дополнительную информацию к заявке"
            multiline
            rows={7}
            fullWidth
            size={"small"}
            // InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>

      </Stack>


    </Dialog>
  );
});

export default AddEquipmentRequests;
