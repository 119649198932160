import React from "react";
import Tabs from "./Tabs";
import {
  SportEquipmentTab,
  sportEquipmentTabActiveVars,
  useSportEquipmentTabActiveVars
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentTabActive";
import { useReactiveVar } from "@apollo/client";


export default () => {
  const tabActive = useReactiveVar(sportEquipmentTabActiveVars);
  const { setTabActive } = useSportEquipmentTabActiveVars();

  if (!tabActive) return null;

  return (
    <>
      <Tabs
        tabActive={tabActive as SportEquipmentTab}
        actions={{
          setTabActive
        }}
      />
    </>
  );
};