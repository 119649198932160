import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function DeleteDialog({...rest}) {
    const classes = useStyles();
    const {open, close, confirm, title, message, id} = rest

    function handleClose() {
        close(false)
    }

    function handleDelete() {
        confirm(id)
        handleClose()
    }

    return <>
        <Dialog
            onClose={handleClose}
            aria-labelledby="delete-sportSubZone-dialog-title"
            open={open}
        >
            <DialogTitle id="delete-sportSubZone-dialog-title">
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                >
                    <DeleteOutlineIcon
                        color={'secondary'}
                        style={{marginRight: '.3em'}}
                    />
                    <span>{title}</span>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>{message ? message : <>Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку ниже.</>}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                >
                    Отмена
                </Button>
                <Button
                    autoFocus
                    onClick={handleDelete}
                    color="secondary"
                >
                    Удалить
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

DeleteDialog.protoTypes = {}

export default DeleteDialog;
