import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button, Popover, TextField} from '@mui/material';
import {darken, Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import {useStores} from '../../../../../store';
import {SportZoneEntity} from "../../../../../graphql/graphQlApiHooks";


const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    sportZoneCreatePanel: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        width: 240,
        '& .MuiFilledInput-root': {
            background: darken(theme.palette.background.default, 0.05),
            '&:before': {
                borderColor: theme.palette.divider
            },
            '&:hover, &.Mui-focused': {
                background: darken(theme.palette.background.default, 0.01)
            }
        }
    },
    sportZoneCreatePanelInput: {},
    sportZoneCreatePanelBottomPanel: {
        display: 'none',
        '&.showPanel': {
            display: 'flex',
            justifyContent: 'space-between'
        }
    }
}));

function AddSportZone({...rest}) {
    const classes = useStyles();
    const {settingsStore} = useStores();
    const inputName = useRef<HTMLInputElement | null>(null)
    const [showPanel, setShowPanel] = useState<boolean>(false)

    const [inputNameValue, setInputNameValue] = useState('')
    const [inputAddressValue, setInputAddressValue] = useState('')
    const [nameExistsError, setNameExistsError] = useState<string | null>(null);


    // let currentSportObject = useReactiveVar(currentSportObjectVar);

    // const [createSportZoneMutation] = useCreateSportZoneMutation({});

    const addSportZone = (e:any) => {
        e.preventDefault()
        if (inputNameValue !== '' && settingsStore?.sportObject) {

            const nameIsExists = settingsStore.sportZones.find((zone:SportZoneEntity)=>zone.name === inputNameValue)

            if (!!nameIsExists) {
                setNameExistsError('Название занято')
                return
            }

            const input = {
                sportObjectId: settingsStore.sportObject.id,
                name: inputNameValue,
                address: inputAddressValue
            }
            settingsStore.sportZoneCreate(input).finally(onClose)
        } else {
            console.error('Вы не указали название объекта спорта')
        }
    };

    function onClose() {
        setInputNameValue('')
        setInputAddressValue('')
        rest.onClose()
    }

    useMemo(() => {
        setShowPanel(inputNameValue.length > 0)
    }, [inputNameValue])

    useEffect(() => {
        // inputName.current?.focus()
    }, [rest.anchorEl, inputName])

    return (
        <>
            <Popover
                id="sportZone-create-panel"
                anchorEl={rest.anchorEl}
                onClose={onClose}
                // getContentAnchorEl={null}
                open={rest.open}

                className={classes.sportZoneCreatePanel}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}

                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

                <form
                    autoComplete="off"
                    className={classes.sportZoneCreatePanel}
                    onSubmit={(e)=>addSportZone(e)}
                    id={'addSportZone'}
                    name={'addSportZone'}
                >
                    <TextField
                        autoFocus={true}
                        onChange={(e) => {
                            nameExistsError && setNameExistsError(null)
                            setInputNameValue(e.target.value)
                        }}
                        value={inputNameValue}
                        inputRef={inputName}
                        id="name"
                        label="Название"
                        variant="filled"
                        size={'small'}
                        autoComplete={'off'}
                        helperText={nameExistsError}
                        error={!!nameExistsError}
                    />
                    <TextField
                        onChange={(e) => setInputAddressValue(e.target.value)}
                        value={inputAddressValue}
                        id="address"
                        label="Адрес"
                        variant="filled"
                        size={'small'}
                        autoComplete={'off'}
                    />
                </form>
                <div
                    className={clsx(classes.sportZoneCreatePanelBottomPanel, showPanel ? 'showPanel' : null)}
                >

                    <Button onClick={onClose}>Отмена</Button>
                    <Button
                            type={'submit'}
                            form={'addSportZone'}
                        color="primary"
                    >Добавить объект спорта</Button>
                </div>

            </Popover>
        </>
    )
}

AddSportZone.protoTypes = {}

export default AddSportZone;
