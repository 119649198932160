import { makeVar, ReactiveVar } from "@apollo/client";
import { SportObjectsVars, sportObjectsVars } from "../sport-objects/sportObjects";

export enum SportEquipmentTabIdsEnum {
  ALL_EQUIPMENTS = "all_equipments",
  IS_ARCHIVED = "is_archived",
  IS_WROTE_OFF = "is_wrote_off",
}

export interface SportEquipmentTab {
  id: string;
  displayName: string;
};

export const SportEquipmentTabs: { [tab: string]: SportEquipmentTab } = {
  ALL_EQUIPMENTS: {
    id: "all_equipments",
    displayName: "Оборудование на объектах"
  },
  IS_ARCHIVED: {
    id: "is_archived",
    displayName: "Склад"
  },
  IS_WROTE_OFF: {
    id: "is_wrote_off",
    displayName: "Списаное оборудование"
  }
};

export const sportEquipmentTabActiveVars = makeVar<SportEquipmentTab>(
  SportEquipmentTabs.ALL_EQUIPMENTS
);


export const useSportEquipmentTabActiveVars = () => createSportEquipmentTabActiveVars(sportEquipmentTabActiveVars);

const createSportEquipmentTabActiveVars = (sportEquipmentTabActiveVars: ReactiveVar<SportEquipmentTab>) => {
  const setTabActive = (tabActive: SportEquipmentTab) => {
    sportEquipmentTabActiveVars(tabActive);
  };

  return {
    setTabActive
  };
};