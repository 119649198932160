import React from "react";
import {
  Avatar,
  Box, ButtonBase,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Logout, Settings } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStores } from "../../../../../store";
import { BootstrapTooltip } from "../../../../../components/BootstrapTooltip";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    position: "relative"
  },
  userButton: {
    borderRadius: theme.shape.borderRadius
  },
  avatar: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    width: 30,
    height: 30
  },
  userName: {
    marginLeft: "1rem",
    fontSize: 13.3333,
    color: theme.palette.text.primary
  },
  menu: {
    position: "relative",
    marginTop: 2,
    boxShadow: "0 8px 24px rgba(149,157,165,0.2)",
    border: `1px solid ${theme.palette.divider}`,
    "&::after": {
      content: `''`,
      display: "inline-block",
      position: "absolute",
      border: "8px solid transparent",
      borderBottom: `8px solid white`,
      left: "auto",
      right: 9,
      top: -16
    },
    "&::before": {
      content: `''`,
      display: "inline-block",
      position: "absolute",
      border: "9px solid transparent",
      borderBottom: `9px solid ${theme.palette.divider}`,
      left: "auto",
      right: 8,
      top: -18
    }
  },
  companyBox: {
    background: "#e8f2ff",
    padding: "18px 18px",
    borderRadius: 8
  },
  companyAvatar: {
    background: "#e7271b",
    padding: 8
  },
  companyName: {
    marginLeft: "1rem",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    whiteSpace: "nowrap"
  }
}));


interface ICompanyMenu {
  [key: string]: any;
}

const CompanyMenu = observer(({ ...rest }: ICompanyMenu) => {
  const classes = useStyles();

  const { userStore } = useStores();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function logOutHandler() {
    userStore.clearUser();
  }

  return (
    <div className={classes.root}>

      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <BootstrapTooltip title='Настройки пользователя'>

          <ButtonBase
            onClick={handleClick}
            // size="small"
            sx={{ mr: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={open ? "true" : undefined}
          >
            <ListItem
              className={classes.userButton}
            >
              <ListItemIcon style={{ minWidth: "initial" }}>
                <Avatar
                  src='/static/mossport_logo.svg'
                  className={classes.companyAvatar}
                  sx={{ width: 32, height: 32 }}
                />
              </ListItemIcon>
              <ListItemText
                primary={userStore.currentUser?.user.login}
                className={classes.userName}
              />
            </ListItem>
          </ButtonBase>

        </BootstrapTooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              content: "\"\"",
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          component={NavLink}
          to='/settings/user'
        >
          <ListItemIcon>
            <Settings fontSize='small' />
          </ListItemIcon>
          Настройки
        </MenuItem>
        <MenuItem onClick={logOutHandler}>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Выход
        </MenuItem>
      </Menu>


    </div>
  );
});

export default CompanyMenu;