import {
	CreateSportEquipmentDocument,
	CreateSportEquipmentInput,
	DeleteSportEquipmentDocument,
	EquipmentTypeDictionaryEntity,
	ExportSportEquipmentsInExcelDocument,
	FileStoreEntity,
	GetSportEquipmentsDocument,
	ImportSportEquipmentDocument,
	ImportSportEquipmentsInput,
	SetEditableDocument,
	SetEditableInput,
	SportEquipmentEntity,
	SportKindDictionaryEntity,
	SportObjectEntity,
	SportSubZoneEntity,
	SportZoneEntity,
	UpdateSportEquipmentDocument,
	UpdateSportEquipmentInput,
} from '../../graphql/graphQlApiHooks';

import {autorun, configure, makeAutoObservable, runInAction, toJS} from "mobx";
import RootStore from "../.";

import {isEqual, queryToMobxObservable} from '../../helpers';
import Settings from "../settings";
import {client} from "..";
import dayjs from "dayjs";

configure({
	enforceActions: "never",
})


interface Filters {
	sportZoneIds: string[] | null,
	sportSubZoneIds: string[] | null,
	inventoryNumber: string | null,
	name: string | null,
	technicalCondition: boolean | null,
	inUse: boolean | null,
	commissioningDate: [null | Date, null | Date] | null
	createdAtDate: [null | Date, null | Date] | null
	equipmentTypeIds: string[] | null,
	sportKindIds: string[] | null,
	sportCategoryIds: string[] | null,
	archived: boolean | null
}

interface SortOrder {
	[key: string]: 'DESC' | 'ASC'
}

interface GetExportSportEquipmentsInExcelFileResponse {
	data: {
		exportSportEquipmentsInExcel: FileStoreEntity
	}
}

const equipmentsStore = class {
	rootStore?: RootStore;
	error?: any;
	loading: boolean = true;
	equipmentsResponse: SportEquipmentEntity[] = [];
	equipments: SportEquipmentEntity[] = [];
	archived: SportEquipmentEntity[] = [];
	count: number = 0;
	allCount: number = 0;
	archiveCount: number = 0;
	page: number = 0;
	pageSize: number = 50;
	sortOrder: SortOrder = {'equipment.createdAt': 'DESC'};

	filters: Filters = {
		sportZoneIds: null,
		sportSubZoneIds: null,
		inventoryNumber: null,
		name: null,
		technicalCondition: null,
		inUse: null,
		equipmentTypeIds: null,
		sportKindIds: null,
		sportCategoryIds: null,
		commissioningDate: [null, null],
		createdAtDate: [null, null],
		archived: null,
	};

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);

		autorun(() => {
			if (this.query?.current()) {
				let result: any = this.query.current()?.getSportEquipments;
				const count: number = result.count;
				const allCount: number = result.allCount;
				const archiveCount: number = result.inStockCount;
				result = result.sportEquipments;

				if (!isEqual(result, this.equipments)) {
					this.equipments = toJS(result);
					this.count = count;
					this.allCount = allCount;
					this.archiveCount = archiveCount;
				}
				this.setLoading(false);
			} else {
				this.setLoading(false);
			}

			if (!this.filters.sportZoneIds && this.sportZones.length > 0) {
				this.setFilters({
					sportZoneIds: toJS(this.sportZones.map(val => val.id)),
				});
			}
		});
	}

	get sportZones(): SportZoneEntity[] {
		let sportObjectsIds = this.rootStore?.sportObjectsStore?.currentIds;
		let sportObjects = this.rootStore?.sportObjectsStore.sportObjects.filter(
				(sportObject: SportObjectEntity) =>
						sportObjectsIds.includes(sportObject.id)
		);
		return [].concat.apply(
				[],
				[
					...sportObjects.map(
							(sportObject: SportObjectEntity) => sportObject.sportZones
					),
				]
		);
	}

	get sportKinds(): SportKindDictionaryEntity[] {
		const sportCategoryIds = this?.filters?.sportCategoryIds || [];
		return this.rootStore?.dictionariesStore?.sportKindDictionary?.filter(
				(sportKind: SportKindDictionaryEntity) =>
						sportCategoryIds.includes(sportKind?.sportCategory?.id)
		);
	}

	get equipmentTypes(): EquipmentTypeDictionaryEntity[] {
		const sportKindIds = this.filters.sportKindIds || [];
		return this.rootStore?.dictionariesStore?.equipmentTypeDictionary?.filter(
				(equipmentType: EquipmentTypeDictionaryEntity) =>
						sportKindIds.includes(equipmentType.sportKind.id)
		);
	}

	get sportSubZones(): SportSubZoneEntity[] {
		if (this.filters.sportZoneIds) {
			let filterSportZones =
					this.sportZones.filter((zone: SportZoneEntity) =>
							this.filters.sportZoneIds?.includes(zone.id)
					) || [];
			return [...filterSportZones]
					.map((zone: SportZoneEntity) => zone.sportSubZones)
					.flat();
		}

		return [...this.sportZones].map(sz => sz.sportSubZones).flat();
	}

	get sportZoneIds(): string[] {
		return [...this.sportZones].map(zone => zone.id);
	}

	get sportSubZoneIds(): string[] {
		return this.sportSubZones.map(value => value.id);
	}

	get input(): any {
		let sportObjectIds = this.rootStore?.sportObjectsStore?.currentIds;
		return {
			sportObjectIds: sportObjectIds,
			name: this.filters?.name,
			sportCategoryIds: this.filters?.sportCategoryIds,
			equipmentTypeIds: this.filters?.equipmentTypeIds,
			sportKindIds: this.filters?.sportKindIds,
			sportZoneIds: this.filters?.sportZoneIds,
			sportSubZoneIds: this.filters?.sportSubZoneIds,
			inventoryNumber: this.filters?.inventoryNumber,
			inUse: this.filters?.inUse,
			technicalCondition: this.filters?.technicalCondition,
			commissioningDateFrom: this.filters?.commissioningDate?.[0]
					? dayjs(toJS(this.filters?.commissioningDate?.[0])).format('YYYY-MM-DD')
					: null,
			commissioningDateTo: this.filters?.commissioningDate?.[1]
					? dayjs(toJS(this.filters?.commissioningDate?.[1])).format('YYYY-MM-DD')
					: null,
			skip: this.page * this.pageSize,
			take: this.pageSize,
			order: toJS(this.sortOrder),
			archived: this.filters?.archived,
		};
	}

	get query(): any {
		let sportObjectIds = this.rootStore?.sportObjectsStore?.currentIds;

		if (sportObjectIds && sportObjectIds.length > 0 && this.input) {
			return queryToMobxObservable(
					client.watchQuery({
						query: GetSportEquipmentsDocument,
						fetchPolicy: "network-only",
						variables: {
							input: this.input,
						},
						pollInterval: Settings.pollIntervals.sportEquipments,
					})
			);
		} else {
			runInAction(() => {
				this.equipments = [];
				this.count = 0;
			});
			return undefined;
		}
	}

	setFilters(filter: any) {
		// const checkFilterIsEmptyString = (filter: any) => {
		// 	if (Object.values(filter)[0] === '') {
		// 		const filterName = Object.keys(filter)[0];
		// 		filter = (filterName !== 'commissioningDate') ? {[filterName]: null} : {[filterName]: [null, null]}
		// 	}
		// 	return filter;
		// };

		const filters = Object.assign(toJS(this.filters), filter);

		runInAction(() => {
			this.filters = filters;
		});
	}

	setSortOrder(sortOrder: SortOrder) {
		this.sortOrder = sortOrder;
	}

	setLoading(loading: boolean) {
		this.loading = loading;
	}

	setPage(page: number) {
		this.page = page;
	}

	setPageSize(pageSize: number) {
		this.pageSize = pageSize;
	}

	getSportEquipmentById(id: string) {
		return new Promise(function (resolve, reject) {
			client
					.query({
						query: GetSportEquipmentsDocument,
						fetchPolicy: "network-only",
						variables: {
							input: {
								equipmentId: id,
							},
						},
					})
					.then((result: any) => {
						resolve(toJS(result.data.getSportEquipments.sportEquipments?.[0]));
					})
					.catch(error => {
						reject(error);
					});
		});
	}

	update = (input: UpdateSportEquipmentInput) =>
			client
					.mutate({
						mutation: UpdateSportEquipmentDocument,
						variables: {input},
						refetchQueries: [
							{
								query: GetSportEquipmentsDocument,
								fetchPolicy: "network-only",
								variables: {
									input: this.input,
								},
							},
						],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Оборудование успешно обновлено.');
					})
					.catch((error: any) => {
						this.rootStore?.setSnakeMessage(error.message, 'error');
					});

	setEditable = (input: SetEditableInput) =>
			client
					.mutate({
						mutation: SetEditableDocument,
						variables: {input},
						refetchQueries: [
							{
								query: GetSportEquipmentsDocument,
								fetchPolicy: "network-only",
								variables: {
									input: this.input,
								},
							},
						],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage(
								input.isEditable
										? 'Карточка оборудования вновь доступна для редактирования.'
										: 'Карточка оборудования больше не доступна для редактирования.'
						);
					})
					.catch((error: any) => {
						this.rootStore?.setSnakeMessage(error.message, 'error');
					});

	create = (input: CreateSportEquipmentInput) =>
			client
					.mutate({
						mutation: CreateSportEquipmentDocument,
						variables: {input},
						refetchQueries: [
							{
								query: GetSportEquipmentsDocument,
								fetchPolicy: "network-only",
								variables: {
									input: this.input,
								},
							},
						],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Новое оборудование успешно создано.');
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	import = (input: ImportSportEquipmentsInput) =>
			client
					.mutate({
						mutation: ImportSportEquipmentDocument,
						variables: {input},
						refetchQueries: [
							{
								query: GetSportEquipmentsDocument,
								fetchPolicy: "network-only",
								variables: {
									input: this.input,
								},
							},
						],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage(
								'Новое оборудование успешно имортировано.'
						);
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	delete = (id: string) =>
			client
					.mutate({
						mutation: DeleteSportEquipmentDocument,
						variables: {id},
						refetchQueries: [
							{
								query: GetSportEquipmentsDocument,
								fetchPolicy: "network-only",
								variables: {
									input: this.input,
								},
							},
						],
					})
					.then(() => {
						this.rootStore?.setSnakeMessage('Оборудование успешно удалено.');
					})
					.catch((error: any) =>
							this.rootStore?.setSnakeMessage(error.message, 'error')
					);

	getExportSportEquipmentsInExcelFile = () =>
		client
				.query({
					query: ExportSportEquipmentsInExcelDocument,
					variables: {input: this.input},
				})
				.then(({data: {exportSportEquipmentsInExcel}}: GetExportSportEquipmentsInExcelFileResponse) => exportSportEquipmentsInExcel)
				.catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

};

export default equipmentsStore;
