import React, { FC, useEffect, useState } from "react";
import {
  GetOrderByIdDocument,
  ReturnEquipmentRecordInput,
  SportEquipmentEntity,
  useRemoveExternalOrderEquipmentMutation
} from "../../../../../graphql/graphQlApiHooks";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Settings from "../../../../../store/settings";
import {
  Avatar,
  Badge, Box,
  Button,
  Card,
  CardHeader,
  Divider,
  FormControl, InputLabel, MenuItem, Select,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { stringAvatar } from "../../../../../helpers/stringAvatar";
import { MouseOverPopover } from "../../../EquipmentRelocate/Table";
import BlockIcon from "@mui/icons-material/Block";
import Tooltiper from "../../../../../components/Tooltiper";
import { TechnicalConditionEnum } from "../../../ReturnedEquipments/technical-condition.enum";
import DeleteDialog from "../../../../../components/Dialogs/DeleteDialog";
import ReplaceExternalOrderEquipmentDialog from "./ReplaceExternalOrderEquipmentDialog";
import AddCommentDialog from "./AddCommentDialog";
import { BootstrapTooltip } from "../../../../../components/BootstrapTooltip";
import { TechnicalCondition } from "../../../../../graphql/technical-condition.enum";
import EquipmentRowName from "../../../../../components/EquipmentRowName";
import { ExternalOrderStatusEnum } from "../../../ReturnedEquipments/external-order-status.enum";

interface Props {
  items: SportEquipmentEntity[][];
  externalOrderId: string;
  rowRecordId: string;
  isExternalUser: boolean;
  orderRecords: ReturnEquipmentRecordInput[];
  onChangeOrderRecords: (orderRecords: ReturnEquipmentRecordInput[]) => void;
  rowStatus: string;
  technicalCondition?: string;
}

const CartItem: FC<Props> = (props: Props) => {
  const { externalOrderId, isExternalUser, orderRecords, onChangeOrderRecords, rowStatus, technicalCondition } = props;
  const theme = useTheme();
  const { items, rowRecordId } = props;
  const navigate = useNavigate();


  const [confirmRemoveExternalOrderEquipment, setConfirmRemoveExternalOrderEquipment] = useState<{
    isOpen: boolean,
    equipment?: SportEquipmentEntity
  }>({
    isOpen: false,
    equipment: undefined
  });
  const [replaceExternalOrderEquipment, setReplaceExternalOrderEquipment] = useState<{
    isOpen: boolean,
    equipment?: SportEquipmentEntity
  }>({
    isOpen: false,
    equipment: undefined
  });
  const [addCommentEquipment, setAddCommentEquipment] = useState<{
    isOpen: boolean,
    equipment?: SportEquipmentEntity
  }>({
    isOpen: false,
    equipment: undefined
  });


  function getEquipmentPhoto(equipment: SportEquipmentEntity) {
    if (equipment?.mainPhoto?.url) {
      return `${Settings?.server}${equipment?.mainPhoto?.url}`;
    }
    if (equipment?.photos?.[0]?.url) {
      return `${Settings?.server}${equipment?.photos?.[0]?.url}`;
    }
    return "static/sport-pattern.png";
  }

  const [removeExternalOrderEquipmentMutation] = useRemoveExternalOrderEquipmentMutation();

  function removeExternalOrderEquipmentHandler(product: SportEquipmentEntity) {

    removeExternalOrderEquipmentMutation({
      variables: {
        externalOrderRecordId: rowRecordId
      },
      refetchQueries: [
        {
          query: GetOrderByIdDocument,
          variables: {
            orderId: Number(externalOrderId)
          }
        }
      ]
    }).then(result => {
      setConfirmRemoveExternalOrderEquipment(prevState => ({ ...prevState, equipment: undefined }));
    });

  }

  return (
    <>
      <Card
        key={items[0][0].sportObject.id}
        elevation={0}
        variant={"elevation"}
      >
        <Stack
          p={2}
          direction={"row"}
          spacing={1}
          alignItems={"center"}
        >
          <Avatar
            {...stringAvatar(items[0][0].sportObject.name as string)}
            sx={{
              width: 28,
              height: 28
            }}
          />
          <Typography variant={"subtitle1"}>
            {items[0][0].sportObject.name}
          </Typography>
        </Stack>
        <Divider
          sx={{
            mx: 2,
            opacity: .5
          }}
        />

        {items.map((sportSubZones, index) => (
          <React.Fragment key={sportSubZones[0].sportZone!.id! + index}>
            <Stack
              direction={"column"}
              py={1}
              px={2}
            >
              {sportSubZones[0].sportZone?.name &&
                <Typography
                  variant={"caption"}
                  sx={{ fontWeight: "bold" }}
                >
                  {`${sportSubZones[0].sportZone?.name}, ${sportSubZones[0].sportSubZone?.name}`}
                </Typography>
              }
              {sportSubZones[0].sportZone?.address &&
                <Typography
                  variant={"caption"}
                  sx={{ color: "text.secondary", mb: 1 }}
                >
                  {`${sportSubZones[0].sportZone?.address}`}
                </Typography>
              }
              {sportSubZones.map((product, sportSubZoneIndex) => {
                const avatarUrl = (product?.mainPhoto) ? product?.mainPhoto.url : (product.photos?.length > 0) && product.photos?.[0].avatarUrl;
                const avatarUrlOrigin = (product?.mainPhoto) ? product?.mainPhoto.url : (product.photos?.length > 0) && product.photos?.[0].url;
                return <React.Fragment key={product.sportSubZone!.id! + sportSubZoneIndex}>
                  <Card
                    variant={"outlined"}
                    sx={{ p: 2 }}
                  >
                    <CardHeader
                      onClick={() => navigate(`equipment/${product?.id}`)}
                      sx={{
                        p: 0,
                        maxWidth: "100%",
                        "& .MuiCardHeader-content": {
                          overflow: "hidden"
                        },
                        "& .MuiTypography-root": {
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden"
                        },
                        "&:hover": {
                          cursor: "pointer",
                          color: theme.palette.primary.main,
                          "& .MuiTypography-root": {
                            color: theme.palette.primary.main
                          }
                        }
                      }}
                      title={
                        <EquipmentRowName
                          name={product.name}
                          inventoryNumber={product?.inventoryNumber || "б/н"}
                          inUse={product?.inUse}
                          technicalCondition={(product?.technicalCondition === TechnicalCondition.WORKING)}
                          avatarUrl={(product?.mainPhoto) ? product?.mainPhoto.url : (product?.photos && product?.photos?.length > 0) && product?.photos?.[0].avatarUrl}
                          avatarUrlOrigin={(product?.mainPhoto) ? product?.mainPhoto.url : (product?.photos && product?.photos?.length > 0) && product?.photos?.[0].url}
                          failureReason={product?.failureReason}
                          isEditable={product?.isEditable}
                        />
                      }
                      action={<>
                        { rowStatus === "CREATED" &&
                          <Stack
                            direction={"row"}
                            spacing={2}
                            width={"100%"}
                          >
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                setReplaceExternalOrderEquipment({
                                  isOpen: true,
                                  equipment: product
                                });
                              }}
                            >
                              Предложить замену
                            </Button>
                            <Button
                              // onClick={() => removeExternalOrderEquipmentHandler(product)}
                              onClick={(event) => {
                                event.stopPropagation();
                                setConfirmRemoveExternalOrderEquipment({
                                  isOpen: true,
                                  equipment: product
                                });
                              }}
                            >
                              Удалить оборудование из заявки
                            </Button>
                          </Stack>}
                        {isExternalUser && rowStatus !== "CREATED" &&
                          <Stack
                            direction={"row"}
                            spacing={2}
                            width={"100%"}
                          >

                            <FormControl
                              sx={{ m: 1, minWidth: 220 }}
                              size='small'
                            >
                              <InputLabel>Состояние оборудования</InputLabel>
                              <Select
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                readOnly={rowStatus === ExternalOrderStatusEnum.ReturnedComplete}
                                disabled={rowStatus === ExternalOrderStatusEnum.ReturnedComplete}
                                value={orderRecords.find((item) => item.orderRecordId === (product as any).orderRecordId)?.technicalCondition || TechnicalConditionEnum.Working}
                                label='Состояние оборудования'
                                onChange={({ target: { value } }) => {
                                  const updateArr = [...orderRecords];
                                  const currentIndex = updateArr.findIndex((item) => item.orderRecordId === (product as any).orderRecordId);
                                  updateArr[currentIndex].technicalCondition = value;
                                  onChangeOrderRecords(updateArr);
                                }}
                              >
                                <MenuItem
                                  value={TechnicalConditionEnum.Working}
                                >Исправно</MenuItem>
                                <MenuItem value={TechnicalConditionEnum.ForRepair}>Подлежит
                                  ремонту</MenuItem>
                                <MenuItem value={TechnicalConditionEnum.WriteOff}>Не
                                  подлежит ремонту</MenuItem>
                                <MenuItem value={TechnicalConditionEnum.NotReturned}>Оборудование не
                                  вернулось</MenuItem>
                              </Select>
                            </FormControl>
                            <Box
                              alignItems={"center"}
                              display={"flex"}
                            >
                              <Button
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setAddCommentEquipment({
                                    isOpen: true,
                                    equipment: product
                                  });
                                }}
                                size={"small"}
                              >
                                Комментарий
                              </Button>
                            </Box>
                          </Stack>}
                      </>}
                    />
                  </Card>
                  {sportSubZones.length - 1 !== sportSubZoneIndex && <Divider
                    sx={{
                      mx: 2,
                      my: 1,
                      opacity: .5
                    }}
                  />}
                </React.Fragment>;
              })}
            </Stack>


            {sportSubZones.length - 1 !== index && <Divider
              sx={{
                mx: 2,
                my: 1,
                opacity: .5
              }}
            />}
          </React.Fragment>
        ))
        }

      </Card>
      <ReplaceExternalOrderEquipmentDialog
        open={replaceExternalOrderEquipment.isOpen}
        onClose={() => setReplaceExternalOrderEquipment({ isOpen: false, equipment: undefined })}
        originEquipment={replaceExternalOrderEquipment.equipment}
        externalOrderId={externalOrderId}
      />

      <AddCommentDialog
        open={addCommentEquipment.isOpen}
        onClose={() => setAddCommentEquipment({ isOpen: false, equipment: undefined })}
        originEquipment={addCommentEquipment.equipment}
        comment={orderRecords.find((item) => item?.orderRecordId === (addCommentEquipment?.equipment as any)?.orderRecordId)?.comment || ""}
        onChangeComment={(value) => {
          const updateArr = [...orderRecords];
          const currentIndex = updateArr.findIndex((item) => item?.orderRecordId === (addCommentEquipment?.equipment as any)?.orderRecordId);
          updateArr[currentIndex].comment = value;
          onChangeOrderRecords(updateArr);
        }}
      />
      {confirmRemoveExternalOrderEquipment?.equipment && <DeleteDialog
        open={confirmRemoveExternalOrderEquipment.isOpen}
        close={() => setConfirmRemoveExternalOrderEquipment({ isOpen: false, equipment: undefined })}
        confirm={() => removeExternalOrderEquipmentHandler(confirmRemoveExternalOrderEquipment.equipment)}
        title={`Удаление оборудования из заявки`}
        message={`Вы уверены что хотите удалить ${confirmRemoveExternalOrderEquipment.equipment.name} из заявки? Это действие не обратимо!`}
        id={confirmRemoveExternalOrderEquipment.equipment.id}
      />}
      {/*{detailsEquipment?.equipment && <EquipmentDetails*/}
      {/*  open={detailsEquipment.isOpen}*/}
      {/*  onClose={() => setDetailsEquipment({ isOpen: false, equipment: undefined })}*/}
      {/*  equipment={detailsEquipment.equipment}*/}
      {/*/>}*/}
    </>
  );
};

export default CartItem;