import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInputProps,
  Stack,
  TextField
} from "@mui/material";
import {
  EquipmentRequestEntity,
  EquipmentSpecificationDictionaryEntity,
  EquipmentTypeDictionaryEntity,
  SportKindDictionaryEntity,
  SportObjectEntity,
  SportSubZoneEntity,
  SportZoneEntity
} from "graphql/graphQlApiHooks";
import { useStores } from "store";
import Dialog, { useStyles } from "components/Dialogs/Dialog";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";


interface IDialog {
  close: (close: boolean) => void;
  open: boolean;
  row: EquipmentRequestEntity;
}

function EditEquipmentRequests({ open, close, row }: IDialog) {
  const classes = useStyles();
  const { equipmentRequestStore, sportObjectsStore, userStore, dictionariesStore } = useStores();
  const [openTooltip, setOpenTooltip] = React.useState(false);


  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    (!equipmentType) && setOpenTooltip(true);
  };

  const [sportObjects, setSportObjects] = useState<SportObjectEntity[]>([]);
  const [sportObject, setSportObject] = useState<SportObjectEntity | null>(null);

  const [sportZones, setSportZones] = useState<SportZoneEntity[]>([]);
  const [sportZone, setSportZone] = useState<SportZoneEntity | null>(null);

  const [sportSubZones, setSportSubZones] = useState<SportSubZoneEntity[]>([]);
  const [sportSubZone, setSportSubZone] = useState<SportSubZoneEntity | null>(null);


  const [sportKinds, setSportKinds] = useState<SportKindDictionaryEntity[]>([]);
  const [sportKind, setSportKind] = useState<SportKindDictionaryEntity | null>(null);
  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentTypeDictionaryEntity[]>([]);
  const [equipmentType, setEquipmentType] = useState<EquipmentTypeDictionaryEntity | null>(null);
  const [equipmentSpecifications, setEquipmentSpecifications] = useState<EquipmentSpecificationDictionaryEntity[]>([]);
  const [equipmentSpecification, setEquipmentSpecification] = useState<EquipmentSpecificationDictionaryEntity | null>(null);

  const [errors, setErrors] = useState({
    sportObject: {
      state: false,
      message: ""
    },
    sportZone: {
      state: false,
      message: ""
    },
    sportSubZone: {
      state: false,
      message: ""
    },
    name: {
      state: false,
      message: ""
    },
    sportKind: {
      state: false,
      message: ""
    },
    equipmentType: {
      state: false,
      message: ""
    }
  });

  const [counter, setCounter] = useState(Number(row.count));
  const [input, setInput] = useState({
    id: row.id,
    sportObjectId: row.sportObject.id,
    sportZoneId: row.sportZone.id,
    sportSubZoneId: row?.sportSubZone?.id,
    name: row.name,
    count: Number(row.count),
    description: row.description,
    equipmentTypeId: row.equipmentTypeObject?.id || "",
    sportKindId: row.sportKindObject?.id || "",
    requestStatus: row.requestStatus
  });

  useEffect(() => {
    if (sportObjectsStore.sportObjects) {
      setSportObjects(sportObjectsStore.sportObjects as SportObjectEntity[]);
      if (row) {
        setSportObject(row.sportObject);
        const sportObjectResult = [...sportObjectsStore.sportObjects].find((o: SportObjectEntity) => o.id === row.sportObject.id);
        setSportZones(sportObjectResult?.sportZones || []);
        setSportZone(row.sportZone || null);
        setSportSubZones(sportObjectResult?.sportSubZones || []);
        setSportSubZone(row?.sportSubZone || null);

        setSportKind(row?.sportKindObject || null);
        setSportKinds(dictionariesStore.sportKindDictionary as SportKindDictionaryEntity[]);
        setEquipmentType(row?.equipmentTypeObject || null);
        setEquipmentTypes(row?.sportKindObject?.equipmentTypes || []);
        // setEquipmentSpecification(row?.equipmentSpecificationObject || null)
        setEquipmentSpecifications(row?.equipmentTypeObject?.equipmentSpecifications || []);
      }
    }
  }, [sportObjectsStore.sportObjects, row]);


  useMemo(() => {
    if (row) {
      setInput({
        id: row.id,
        sportObjectId: row.sportObject.id,
        sportZoneId: row.sportZone.id,
        sportSubZoneId: row?.sportSubZone?.id,
        name: row.name,
        count: Number(row.count),
        description: row.description,
        equipmentTypeId: row.equipmentTypeObject?.id || "",
        sportKindId: row.sportKindObject?.id || "",
        requestStatus: row.requestStatus
      });
      setCounter(Number(row.count));
    }
  }, [row]);

  function onChangeSportZoneHandler(sportZone: SportZoneEntity | null) {
    sportZone?.id && setInput({ ...input, sportZoneId: sportZone.id as string });
    setSportZone(sportZone);
    if (sportZone?.sportSubZones) {
      setSportSubZones(sportZone.sportSubZones);
    } else {
      setSportSubZones([]);
    }
    setSportSubZone(null);
  }


  function onChangeSportSubZoneHandler(sportSubZone: SportSubZoneEntity | null) {
    sportSubZone?.id && setInput({ ...input, sportSubZoneId: sportSubZone.id as string });
    setSportSubZone(sportSubZone);
  }

  useMemo(() => {
    setInput({ ...input, count: counter });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter]);

  useMemo(() => {
    sportObject && setInput({ ...input, sportObjectId: sportObject.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportObject]);

  useMemo(() => {
    sportZone && setInput({ ...input, sportZoneId: sportZone.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportZone]);

  useMemo(() => {
    sportSubZone && setInput({ ...input, sportSubZoneId: sportSubZone.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sportSubZone]);

  function handleClickCountUp() {
    setCounter(counter + 1);
  }

  function handleClickCountDown() {
    setCounter(counter - 1);
  }

  function handleChangeCount(event: React.ChangeEvent<HTMLInputElement>) {
    setCounter(Number(event.target.value));
  }

  const onSubmit = () => {
    let checkErrors = { ...errors };

    checkErrors.sportObject = (!sportObject) ? { state: true, message: "Вы не выбрали спортобъект" } : {
      state: false,
      message: ""
    };

    checkErrors.sportZone = (!sportZone) ? { state: true, message: "Вы не выбрали объект спорта" } : {
      state: false,
      message: ""
    };

    // checkErrors.sportSubZone = (!sportSubZone) ? {
    // 	state: true,
    // 	message: 'Вы не выбрали спортзону'
    // } : {state: false, message: ''}

    checkErrors.name = (input.name === "") ? {
      state: true,
      message: "Вы не указали название оборудования"
    } : { state: false, message: "" };

    checkErrors.sportKind = (input.sportKindId === "") ? {
      state: true,
      message: "Вы не выбрали вид спорта"
    } : { state: false, message: "" };

    checkErrors.equipmentType = (input.equipmentTypeId === "") ? {
      state: true,
      message: "Вы не выбрали тип оборудования"
    } : { state: false, message: "" };

    setErrors(checkErrors);

    if (sportObject && sportZone && input.name !== "" && input.sportKindId !== "" && input.equipmentTypeId !== "") {
      equipmentRequestStore.update(input).then(() => {
        handleCloseModal();
      });
    }
  };

  const handleCloseModal = () => {
    setErrors({
      sportObject: {
        state: false,
        message: ""
      },
      sportZone: {
        state: false,
        message: ""
      },
      sportSubZone: {
        state: false,
        message: ""
      },
      name: {
        state: false,
        message: ""
      },
      sportKind: {
        state: false,
        message: ""
      },
      equipmentType: {
        state: false,
        message: ""
      }
    });
    close(false);
  };

  function onChangeSportObjectHandler(value: any) {
    setSportObject(value);
    if (value) {
      setInput({ ...input, sportObjectId: value.id });
    }
    setSportZones(value?.sportZones || []);
    onChangeSportZoneHandler(null);
  }

  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  function onChangeSportKindHandler(sportKind: SportKindDictionaryEntity | null) {
    setSportKind(sportKind);
    if (sportKind && sportKind?.equipmentTypes) {
      setInput({ ...input, sportKindId: sportKind.id as string });
      setEquipmentTypes(sportKind.equipmentTypes as EquipmentTypeDictionaryEntity[]);
    } else {
      setInput({ ...input, sportKindId: "" });
      setEquipmentTypes([]);
    }
    setEquipmentType(null);
    setEquipmentSpecification(null);
  }

  function onChangeEquipmentTypeHandler(equipmentType: EquipmentTypeDictionaryEntity | null) {
    setEquipmentType(equipmentType);
    if (equipmentType?.sportKind && equipmentType?.sportKind?.id !== sportKind?.id) {
      setSportKind(equipmentType?.sportKind);
      const sportKind = sportKinds.find((kind: SportKindDictionaryEntity) => kind.id === equipmentType?.sportKind?.id);
      if (sportKind && sportKind?.equipmentTypes) {
        setInput({ ...input, sportKindId: sportKind.id as string });
        setEquipmentTypes(sportKind?.equipmentTypes as EquipmentTypeDictionaryEntity[]);
      }
    }
    if (equipmentType && equipmentType?.equipmentSpecifications) {
      setInput({ ...input, equipmentTypeId: equipmentType.id as string });
      setEquipmentSpecifications(equipmentType.equipmentSpecifications as EquipmentSpecificationDictionaryEntity[]);
    }
    setEquipmentSpecification(null);
  }

  function onChangeEquipmentSpecificationHandler(equipmentSpecification: EquipmentSpecificationDictionaryEntity | null) {
    setEquipmentSpecification(equipmentSpecification);
    if (equipmentSpecification) {
      // setInput({...input, equipmentSpecificationId: equipmentSpecification.id as string})
    }
  }

  return (
    <Dialog
      title={"Редактирование заявки на новое оборудование"}
      open={open}
      onClose={handleCloseModal}
      props={{
        "aria-labelledby": "scroll-dialog-title",
        "aria-describedby": "scroll-dialog-description",
        keepMounted: true,
        maxWidth: "md"
        // classes: {paper: classesUpload.root,}
      }}
      actions={<>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          form="editEquipmentRequest"
          onClick={onSubmit}
        >
          Сохранить изменения
        </Button>
      </>}
      titles={[
        <Box py={2} flexDirection={"row"} width={"100%"} display="flex"
             sx={{
               "& .MuiInputBase-input": {
                 fontWeight: "bold"
               }
             }}
        >
          <TextField
            required
            value={input.name}
            onChange={(event) => setInput({
              ...input,
              name: event.target.value
            })}
            id="equipmentName"
            label="Название необходимого оборудования"
            placeholder="Укажите название"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            error={errors.name.state}
            helperText={errors.name.message}
          />
          <Box
            sx={{
              width: "35%",
              ml: 1,
              "& .MuiOutlinedInput-root": {
                pl: 1,
                pr: 2.5
              },
              "& .MuiInputBase-input": {
                textAlign: "center"
              }
            }}
          >

            <TextField
              required
              id="equipmentCount"
              value={counter}
              onChange={handleChangeCount}
              type={"number"}
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickCountUp}
                    edge="end"
                  > <AddIcon fontSize={"small"} /> </IconButton>
                </InputAdornment>,
                startAdornment: <InputAdornment position="start">
                  <IconButton
                    onClick={handleClickCountDown}
                    edge="end"
                  > <RemoveIcon fontSize={"small"} /></IconButton>
                </InputAdornment>

              } as Partial<OutlinedInputProps>}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Box>
        </Box>
      ]}
    >
      <Stack direction="row" spacing={2}>
        {(!userStore.currentUser?.user.isForSportObject) ?
          <FormControl
            size={"small"}
            fullWidth
            variant="outlined"
            classes={{ root: classes.formControl }}
          >
            <Autocomplete
              value={sportObject}
              id="sportObject"
              options={sportObjects}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: any) => onChangeSportObjectHandler(value)}
              renderInput={(params) => <TextField
                {...params}
                required
                error={errors.sportObject.state}
                helperText={errors.sportObject.message}
                label="Спортобъект"
                variant="outlined"
                placeholder="Выберите спортобъект"
                size={"small"}
                InputLabelProps={{
                  shrink: true
                }}
              />}
            />

          </FormControl> : null
        }


        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <Autocomplete
            value={sportZone}
            id="sportZone"
            disabled={sportZones.length === 0}
            options={sportZones}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value: any) => onChangeSportZoneHandler(value)}
            renderInput={(params) => <TextField
              {...params}
              label="Объект спорта"
              variant="outlined"
              size={"small"}
              placeholder="Выберите объект спорта"
              InputLabelProps={{
                shrink: true
              }}
              error={errors.sportZone.state}
              helperText={errors.sportZone.message}
            />}
          />
        </FormControl>

        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
          sx={{
            mt: 2
          }}
        >
          <Autocomplete
            value={sportSubZone}
            id="sportZone"
            disabled={sportSubZones.length === 0}
            options={sportSubZones}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value: any) => onChangeSportSubZoneHandler(value)}
            renderInput={(params) => <TextField
              {...params}
              label="Спортзона"
              variant="outlined"
              placeholder="Выберите спортзону"
              size={"small"}
              InputLabelProps={{
                shrink: true
              }}
              error={errors.sportSubZone.state}
              helperText={errors.sportSubZone.message}
            />}
          />
        </FormControl>

      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >

          <Autocomplete
            value={sportKind}
            disabled={dictionariesStore.sportKindDictionary.length === 0}
            id="sportKind"
            options={sportKinds}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value: any) => onChangeSportKindHandler(value)}
            renderInput={(params) => <TextField
              {...params}
              label="Вид спорта *"
              variant="outlined"
              placeholder="Выберите вид спорта"
              size={"small"}
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors?.sportKind.state)}
              helperText={errors?.sportKind?.message && errors.sportKind.message}
            />}
          />
        </FormControl>

        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <Autocomplete
            value={equipmentType}
            disabled={sportKind?.equipmentTypes?.length === 0}
            id="equipmentType"
            options={equipmentTypes.length > 0 ? equipmentTypes : dictionariesStore?.equipmentTypeDictionary}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}
            onChange={(e, value: EquipmentTypeDictionaryEntity | null) => {
              onChangeEquipmentTypeHandler(value);
            }}
            renderInput={(params) => <TextField
              {...params}
              label="Тип оборудования *"
              variant="outlined"
              placeholder="Выберите тип оборудования"
              size={"small"}
              InputLabelProps={{
                shrink: true
              }}
              error={Boolean(errors?.equipmentType.state)}
              helperText={errors?.equipmentType?.message && errors.equipmentType.message}
            />}
          />
        </FormControl>

        {/*<Tooltip title={'Сначала выберите тип оборудования'} open={openTooltip}*/}
        {/*         onClose={handleCloseTooltip} onOpen={handleOpenTooltip}>*/}

        {/*	<FormControl*/}
        {/*			size={'small'}*/}
        {/*			fullWidth*/}
        {/*			variant="outlined"*/}
        {/*			classes={{root: classes.formControl}}*/}
        {/*			sx={{*/}
        {/*				pb: 2*/}
        {/*			}}*/}
        {/*	>*/}
        {/*		<Autocomplete*/}
        {/*				value={equipmentSpecification}*/}
        {/*				disabled={equipmentSpecifications.length === 0}*/}
        {/*				id="equipmentSpecifications"*/}
        {/*				options={equipmentSpecifications}*/}
        {/*				getOptionLabel={(option) => option.name}*/}
        {/*				isOptionEqualToValue={(option, value) => option?.id === value?.id}*/}
        {/*				groupBy={(option: any) => (option?.sportKind) ? option?.sportKind.name : undefined}*/}
        {/*				onChange={(e, value: EquipmentSpecificationDictionaryEntity | null) => {*/}
        {/*					onChangeEquipmentSpecificationHandler(value)*/}
        {/*				}}*/}
        {/*				renderInput={(params) => <TextField*/}
        {/*						{...params}*/}
        {/*						label="Характеристики оборудования"*/}
        {/*						variant="outlined"*/}
        {/*						placeholder='Выберите характеристику оборудования'*/}
        {/*						size={'small'}*/}
        {/*						InputLabelProps={{*/}
        {/*							shrink: true,*/}
        {/*						}}*/}
        {/*				/>}*/}
        {/*		/>*/}
        {/*	</FormControl>*/}
        {/*</Tooltip>*/}
      </Stack>
      <Stack direction="row" spacing={2}>
        <FormControl
          size={"small"}
          fullWidth
          variant="outlined"
          classes={{ root: classes.formControl }}
        >
          <TextField
            value={input.description}
            onChange={(event) => setInput({
              ...input,
              description: event.target.value
            })}
            id="equipmentDescription"
            label="Дополнительная информация по заявке"
            placeholder="Укажите дополнительную информацию к заявке"
            multiline
            rows={7}
            fullWidth
            size={"small"}
            // InputProps={{disableUnderline: true} as Partial<OutlinedInputProps>}
            InputLabelProps={{
              shrink: true
            }}
          />
        </FormControl>
      </Stack>
    </Dialog>
  );
}

EditEquipmentRequests.protoTypes = {};

export default EditEquipmentRequests;
