import React, { FC, useEffect, useState } from "react";
import {
  GetSportEquipmentInput,
  SportEquipmentEntity,
  useGetSportEquipmentsLazyQuery, useGetSportEquipmentsQuery
} from "../../../../../graphql/graphQlApiHooks";
import { isEqual } from "../../../../../helpers";
import SODialog from "../../../../../components/Dialogs/Dialog";
import EquipmentInfo from "../../../../../components/Dialogs/Titles/EquipmentInfo";
import { Grid, IconButton, Pagination, Stack, TextField } from "@mui/material";
import FiltersSidebar from "../Edit/FiltersSidebar";
import { GridSearchIcon } from "@mui/x-data-grid";
import ClearIcon from "@mui/icons-material/Clear";
import VirtualizedList from "../Edit/VirtualizedList";

interface Props {
  open: boolean,
  onClose: () => void,
  externalOrderId?: string
  notExistingEquipmentId?: string
}

const ReplaceNotExistingExternalOrderEquipmentDialog: FC<Props> = (props: Props) => {
  const { open, onClose, externalOrderId, notExistingEquipmentId} = props;

  const [filters, setFilters] = useState<GetSportEquipmentInput>({
    take: 50,
    skip: 0,
    sportObjectIds: [],
    technicalCondition: 'WORKING',
    availableForRent: true,
    isWroteOff: false,
  });


  const {} = useGetSportEquipmentsQuery({
    variables: { input: { ...filters } },
    skip: !open,
    onCompleted: (data) => {
      if (data?.getSportEquipments?.sportEquipments) {
        if (!isEqual(data.getSportEquipments.sportEquipments, sportEquipments)) {
          setSportEquipments(data.getSportEquipments.sportEquipments as SportEquipmentEntity[]);
        }
      }

      if (data?.getSportEquipments?.count) {
        setCount(data.getSportEquipments.count);
      } else {
        setCount(0);
      }
    }
  })


  const [sportEquipments, setSportEquipments] = useState<SportEquipmentEntity[]>([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(1);

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  useEffect(() => {

    setFilters(prevState => ({ ...prevState, skip: (page-1) * 50 }));

  }, [page]);

  const [searchByEquipmentName, setSearchByEquipmentName] = useState("");
  const [searchByEquipmentInventoryNumber, setSearchByEquipmentInventoryNumber] = useState("");

  useEffect(() => {
    setFilters(prevState => ({ ...prevState, name: searchByEquipmentName }));
  }, [searchByEquipmentName]);

  useEffect(() => {
    setFilters(prevState => ({ ...prevState, inventoryNumber: searchByEquipmentInventoryNumber }));
  }, [searchByEquipmentInventoryNumber]);


  return (
    <SODialog
      open={open}
      onClose={onClose}
      title={"Выберите оборудование на замену "}
      props={{
        maxWidth: "md",
        sx:{
          "& .MuiPaper-root": {
            maxWidth: "920px",
          }
        }
      }}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >

          <Stack
            direction={"row"}
            spacing={2}
            mb={2}
          >
            <TextField
              fullWidth
              variant='standard'
              value={searchByEquipmentName}
              onChange={({ target: { value } }) =>
                setSearchByEquipmentName(value)
              }
              placeholder='Название оборудования'
              InputProps={{
                startAdornment: <GridSearchIcon fontSize='small' />,
                endAdornment: (
                  <IconButton
                    title='Clear'
                    aria-label='Clear'
                    size='small'
                    style={{
                      visibility: searchByEquipmentName
                        ? "visible"
                        : "hidden"
                    }}
                    onClick={() => setSearchByEquipmentName("")}
                  >
                    <ClearIcon fontSize='small' />
                  </IconButton>
                )
              }}
            />
            <TextField
              variant='standard'
              value={searchByEquipmentInventoryNumber}
              onChange={({ target: { value } }) =>
                setSearchByEquipmentInventoryNumber(value)
              }
              placeholder='Инв. номер'
              InputProps={{
                endAdornment: (
                  <IconButton
                    title='Clear'
                    aria-label='Clear'
                    size='small'
                    style={{
                      visibility: searchByEquipmentInventoryNumber
                        ? "visible"
                        : "hidden"
                    }}
                    onClick={() => setSearchByEquipmentInventoryNumber("")}
                  >
                    <ClearIcon fontSize='small' />
                  </IconButton>
                )
              }}
            />
          </Stack>

          <VirtualizedList
            data={[...sportEquipments] as SportEquipmentEntity[]}
            count={count < 50 ? count : 50}
            externalOrderId={externalOrderId}
            notExistingEquipmentId={notExistingEquipmentId}
            onClose={onClose}
          />

          <Pagination
            count={Math.ceil(count / 50)}
            page={page}
            onChange={handleChangePage}
          />

        </Grid>
      </Grid>


    </SODialog>
  );
};

export default ReplaceNotExistingExternalOrderEquipmentDialog;