import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";
import {
	GetOrderByIdDocument,
	SportEquipmentEntity,
	useReplaceExternalOrderEquipmentMutation, useReplaceExternalOrderNotExistingEquipmentMutation
} from "graphql/graphQlApiHooks";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import Dialog from "components/Dialogs/Dialog";
import { Button } from "@mui/material";

const ReplaceExternalOrderEquipmentDialog = ({
	                                             open,
	                                             onClose,
	                                             onConfirm,
	                                             equipment
                                             }: {
	open: boolean,
	onClose: () => void,
	onConfirm: () => void,
	equipment: SportEquipmentEntity
}) => {


	return (
			<>
				<Dialog
					open={open}
					onClose={onClose}
					title={equipment?.name || "Название оборудования"}
					titles={[
						<EquipmentInfo equipment={equipment}/>,
					]}
					actions={<>
						<Button onClick={onClose}>
							Отмена
						</Button>
					<Button
							autoFocus
							variant={'contained'}
							onClick={onConfirm}
					>
						Заменить
					</Button>
					</>}
				>
					<></>
				</Dialog>
			</>
	)
}


export default function VirtualizedList(rest: {
	data: SportEquipmentEntity[],
	count: number,
	externalOrderId: string,
	oldEquipmentId?: string,
	notExistingEquipmentId?: string,
	onClose: () => void
}) {

	const [replaceExternalOrderEquipmentMutation] = useReplaceExternalOrderEquipmentMutation()
	const [replaceExternalOrderNotExistingEquipment] = useReplaceExternalOrderNotExistingEquipmentMutation()

	const [data, setData] = useState(rest.data);

	const [replaceExternalOrderEquipment, setReplaceExternalOrderEquipment] = useState<{
		isOpen: boolean,
		equipment?: SportEquipmentEntity
	}>({
		isOpen: false,
		equipment: undefined
	});


	function renderRow(props: ListChildComponentProps) {
		const {index, style} = props;

		return (
				<ListItem style={style} key={index} component="div" disablePadding>
					<ListItemButton onClick={() => {
						setReplaceExternalOrderEquipment({
							isOpen: true,
							equipment: rest.data[index]
						})
					}}>

						<EquipmentInfo equipment={rest.data[index]}/>

					</ListItemButton>

				</ListItem>
		);
	}


	return (
			<Box sx={{width: '100%', height: 512, bgcolor: 'background.paper'}}>
				<List
						itemCount={rest.count}
						width={'100%'}
						height={490}
						itemSize={90}
						overscanCount={50}
				>
					{renderRow}
				</List>
				<ReplaceExternalOrderEquipmentDialog
						open={replaceExternalOrderEquipment.isOpen}
						onClose={() => setReplaceExternalOrderEquipment({isOpen: false, equipment: undefined})}
						equipment={replaceExternalOrderEquipment.equipment}
						onConfirm={()=>{
							rest?.oldEquipmentId ? replaceExternalOrderEquipmentMutation({
								variables:{
									input:{
										externalOrderId: Number(rest.externalOrderId),
										newEquipmentId: replaceExternalOrderEquipment.equipment.id,
										oldEquipmentId:rest.oldEquipmentId,

									}
								},
								refetchQueries:[
									{
										query: GetOrderByIdDocument,
										variables:{
											orderId: Number(rest.externalOrderId)
										}
									}
								]
							}).then(() => {
								setReplaceExternalOrderEquipment({isOpen: false, equipment: undefined})
								rest.onClose()
							}) : (
								replaceExternalOrderNotExistingEquipment({
									variables:{
										input:{
											equipmentId: replaceExternalOrderEquipment.equipment.id,
											notExistingEquipmentId: rest?.notExistingEquipmentId,

										}
									},
								})
							)

						}}
				/>
			</Box>
	);
}
