interface IItem {
    href: string;
    icon: string;
    title: string;
}

interface IItems extends Array<IItem> {
}

export const items: IItems = [
    {
        href: '/equipments',
        icon: 'warehouse',
        title: 'Оборудование'
    },
    {
        href: '/reports',
        icon: 'reports',
        title: 'План обслуживания'
    },
    {
        href: '/equipmentRequest',
        icon: 'equipment-request',
        title: 'Заявки на оборудование'
    },
    {
        href: '/relocateEquipments',
        icon: 'equipment-outgoing-request',
        title: 'МСО.Объявления'
    },
    {
        href: '/rent',
        icon: 'equipment-external-request',
        title: 'Аренда оборудования'
    },
    // {
    //     href: '/rent-returned',
    //     icon: 'equipment-external-request',
    //     title: 'Возврат оборудования'
    // },
];
