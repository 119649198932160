import React from 'react';
import {Chip as MuiChip, chipClasses} from "@mui/material";
import Iconify from "./Iconify";
import {lighten, styled, useTheme} from "@mui/material/styles";

export const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'technicalCondition',
})<{ technicalCondition: boolean }>(({ theme, technicalCondition }) => {
  const color = technicalCondition ? theme.palette.success.main : theme.palette.error.main
  return {
    [`&.${chipClasses.root}`]: {
      backgroundColor: lighten(color, .8),
      height: 'auto',
      padding: theme.spacing(.7, 1.5)
    },
    [`& .${chipClasses.label}`]: {
      color: color,
      fontSize: '12px',
      lineHeight: '12px',
      padding: 0
    },
    [`& .${chipClasses.icon}`]: {
      color: color,
      fontSize: '6px',
      marginRight: '4px',
      marginLeft: '0',
    }
  }
});


interface Props {
  technicalCondition: boolean
}

export const EquipmentRowTechnicalCondition = ({technicalCondition}: Props) => {
  const theme = useTheme()

  function getChipTechnicalCondition(technicalCondition: boolean) {

        return <Chip
          technicalCondition={technicalCondition}
          size={'small'}
          icon={<Iconify icon={'bi:circle-fill'} color={'inherit'} fontSize={'inherit'} />}
          label={technicalCondition ? "Исправно" : "Не исправно"}
        />

  }

  return (
    <>
        {getChipTechnicalCondition(technicalCondition)}
    </>
  );
};
