import React from "react";
import PageContent from "components/PageContext";
import Page from "components/Page";
import Header from "./components/Main/Header";
import { Filters } from "./components/Main/Filters";
import Tabs from "./components/Main/Tabs";
import List from "./components/Main/List";
import { Outlet } from "react-router-dom";


function MainPage() {
  const pageTitle = "Оборудование";

  return (
    <Page title={pageTitle}>
      <Header title={pageTitle} />
      <Tabs />
      <Filters />

      <PageContent>
        <List />
        {<Outlet />}
      </PageContent>
    </Page>
  );
}

export default MainPage;