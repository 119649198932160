import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {SportCategoryDictionaryEntity} from "../../../../../graphql/graphQlApiHooks";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import {useStores} from "../../../../../store";
import {observer} from 'mobx-react-lite';


const useStyles = makeStyles((theme: Theme) => ({
	root: {},

	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));

type Props = {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	sportCategory?: SportCategoryDictionaryEntity | null;
};

const DeleteSportCategory = observer((rest: Props) => {
	const classes = useStyles();
	const { open, onClose, onConfirm, sportCategory } = rest;
	const { dictionariesStore } = useStores();

	function handleClose() {
		onClose();
	}

	function handleDelete() {
		if (sportCategory) {
			dictionariesStore
				.deleteSportCategoryDictionary(sportCategory.id)
				.then(onConfirm);
		}
	}

	return (
		<>
			<Dialog
				onClose={handleClose}
				aria-labelledby='delete-sportZone-dialog-title'
				open={open}
			>
				<DialogTitle id='delete-sportZone-dialog-title'>
					<Box display={'flex'} alignItems={'flex-end'}>
						<DeleteOutlineIcon
							color={'secondary'}
							style={{ marginRight: '.3em' }}
						/>
						<span>Удаление категории спорта.</span>
					</Box>
					<IconButton
						aria-label='close'
						className={classes.closeButton}
						onClick={handleClose}
						size='large'
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent>
					<Typography gutterBottom variant={'h4'}>
						Вы действительно хотите удалить {sportCategory?.name}?
					</Typography>
					<Typography>
						Это действие необратимо. Чтобы подтвердить удаление, нажмите кнопку
						ниже.
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleClose} color='primary'>
						Отмена
					</Button>
					<Button
						autoFocus
						onClick={handleDelete}
						color='secondary'
						variant={'contained'}
					>
						Удалить
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
});

export default DeleteSportCategory;
