import { makeVar, ReactiveVar } from "@apollo/client";

export enum RentSportEquipmentTabIdsEnum {
  ORDERS = "orders",
  EQUIPMENTS = "equipments",
}

export interface RentSportEquipmentTab {
  id: string;
  displayName: string;
};

export const RentSportEquipmentTabs: { [tab: string]: RentSportEquipmentTab } = {
  ORDERS: {
    id: "orders",
    displayName: "Заявки"
  },
  EQUIPMENTS: {
    id: "equipments",
    displayName: "Оборудование в аренде"
  },
};

export const rentSportEquipmentTabActiveVars = makeVar<RentSportEquipmentTab>(
  RentSportEquipmentTabs.ORDERS
);


export const useRentSportEquipmentTabActiveVars = () => createRentSportEquipmentTabActiveVars(rentSportEquipmentTabActiveVars);

const createRentSportEquipmentTabActiveVars = (rentSportEquipmentTabActiveVars: ReactiveVar<RentSportEquipmentTab>) => {
  const setTabActive = (tabActive: RentSportEquipmentTab) => {
    rentSportEquipmentTabActiveVars(tabActive);
  };

  return {
    setTabActive
  };
};