import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {CardContent, TextField} from "@mui/material";

const FilterSearch = observer((props: {
    value: string,
    filterResult: (result: string) => void,
    onClose?: () => void
}) => {
    const [value, setValue] = useState('');

    useEffect(() => {

        if (props?.value) {
            setValue(props.value)
        } else {
            setValue('')
        }

    }, [props]);

    function onChange(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const newValue = e.currentTarget.value
        props.filterResult(newValue)
    }
    const clear = () => {
        props.filterResult('');
        props?.onClose && props.onClose();
    };

    return (
        <>
            <CardContent>

                <TextField
                    label="содержит"
                    type={'search'}
                    variant={'standard'}
                    autoFocus={true}
                    value={value}
                    fullWidth={true}
                    placeholder={'Значение'}
                    onChange={(e) => onChange(e)}
                    onKeyPress= {(e) => {
                        if (e.key === 'Enter') {
                            props?.onClose && props.onClose();
                        }
                    }}
                    onKeyDown={(e) => {

                        if (e.key === 'Escape') {
                            clear();
                        }
                    }}
                />
            </CardContent>
        </>
    )
})

export default FilterSearch;
