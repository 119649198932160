import React, { ReactNode } from "react";
import { DialogActions as MuiDialogActions } from "@mui/material";
import { DialogActionsProps } from "@mui/material/DialogActions/DialogActions";
import { styled } from "@mui/material/styles";

const DialogActions = styled(MuiDialogActions)(({theme})=>({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2, 3),
  position: "sticky",
  bottom: 0,
}))

interface Props extends DialogActionsProps{
  children: ReactNode
}

export const PageDialogActions = ({children, ...props}: Props) => {

  return (
      <DialogActions {...props} >
        {children}
      </DialogActions>
  );
};
