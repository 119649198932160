import React from 'react';
import {observer} from "mobx-react-lite";
import {
	Avatar,
	Box,
	Button,
	Step, StepContent, StepLabel, Stepper, Typography,
} from "@mui/material";
import {useTheme} from "@mui/styles";

import {EquipmentRelocationRequestEntity} from "../../../../graphql/graphQlApiHooks";
import dayjs from "dayjs";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import {HighlightedBox} from 'components/HighlightedBox';
import {EquipmentRelocationRequestStatusEnum} from "../../../../store/stores/equipment-relocation-request-status.enum";

interface EquipmentRelocationHistoryDialog {
	data?: EquipmentRelocationRequestEntity | null
	open: boolean
	onClose: () => void
}

function MessageAngle() {
	return <svg width="9" height="20" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<filter x="-50%" y="-14.7%" width="200%" height="141.2%"
			        filterUnits="objectBoundingBox" id="a">
				<feOffset dy="1" in="SourceAlpha"
				          result="shadowOffsetOuter1"></feOffset>
				<feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1"
				                result="shadowBlurOuter1"></feGaussianBlur>
				<feColorMatrix
						values="0 0 0 0 0.0621962482 0 0 0 0 0.138574144 0 0 0 0 0.185037364 0 0 0 0.15 0"
						in="shadowBlurOuter1"></feColorMatrix>
			</filter>
		</defs>
		<g fill="none" fill-rule="evenodd">
			<path d="M3 17h6V0c-.193 2.84-.876 5.767-2.05 8.782-.904 2.325-2.446 4.485-4.625 6.48A1 1 0 003 17z"
			      fill="#000" filter="url(#a)"></path>
			<path d="M3 17h6V0c-.193 2.84-.876 5.767-2.05 8.782-.904 2.325-2.446 4.485-4.625 6.48A1 1 0 003 17z"
			      fill="#FFF" className="corner"></path>
		</g>
	</svg>;
}

const EquipmentRelocationHistory = observer((props: EquipmentRelocationHistoryDialog) => {
	const theme = useTheme();
	const {data, open, onClose} = props;
	const {
		RejectedByCommercialDepartment,
		RejectedByAcceptanceDepartment,
	} = EquipmentRelocationRequestStatusEnum

	const isRejected = data?.status === RejectedByCommercialDepartment ||
			data?.status === RejectedByAcceptanceDepartment

	function handleClose() {
		onClose()
	}
	return (
			<Dialog
					open={open}
					onClose={onClose}
					title={'История перемещений'}
					actions={<>
						<Button
								variant={'contained'}
								onClick={handleClose}
						>Ок</Button>
					</>}
					titles={[
						<EquipmentInfo equipment={data?.equipment}/>,
					]}
			>
				<Stepper orientation="vertical">
					<Step active={Boolean(data?.createdAt)}>
						<StepLabel>
							<Box
									sx={{
										display: 'flex',
										flexDirection: 'column'
									}}
							>
								<Typography
										variant={'body1'}
										component={'span'}
								>Заявка на перемещение создана</Typography>
								<Box>
									<Typography
											variant={'caption'}
											component={'span'}
											sx={{
												mr: 1,
												fontWeight: 'bold'
											}}
									>{data?.sourceSportObject?.name}</Typography>

									<Typography
											variant={'caption'}
											component={'span'}
									>{dayjs(Number(data?.createdAt)).format("DD MMM YYYY, HH:mm")}</Typography>
								</Box>
							</Box>
						</StepLabel>
					</Step>
					<Step active={Boolean(data?.sportObjectResponseUser || data?.targetSportObject)}>
						<StepLabel>
							<Box
									sx={{
										display: 'flex',
										flexDirection: 'column'
									}}
							>
								<Typography
										variant={'body1'}
										component={'span'}
								>Запрос принял спорт. объект</Typography>
								<Typography
										variant={'caption'}
										component={'span'}
										sx={{
											fontWeight: 'bold'
										}}
								>{data?.targetSportObject?.name}</Typography>
							</Box>
						</StepLabel>
						<StepContent>
							<HighlightedBox
									sx={{
										display: 'flex',
										flexDirection: 'column',
										borderRadius: 2,
										p: 2
									}}
							>
								<Box
										sx={{display: 'flex', alignItems: 'center'}}
								>
									<Avatar
											alt={data?.sportObjectResponseUser?.login}
											sx={{
												fontSize: 10,
												width: 28,
												height: 28,
												mr: 1,
												bgcolor: theme.palette.primary.main
											}}
									>{data?.sportObjectResponseUser?.login.slice(0, 2).toUpperCase()}</Avatar>
									<Box sx={{display: 'flex', flexDirection: 'column'}}>
										<Typography
												variant={'body2'}
												component={'span'}
										>
											{data?.sportObjectResponseUser?.login || 'Не известно'}
										</Typography>
										<Typography
												variant={'caption'}
												component={'span'}
										>{dayjs(Number(data?.sportObjectResponseDate)).format("DD MMM YYYY, HH:mm")}</Typography>
									</Box>
								</Box>
								{(data?.acceptBySportObjectComments && data?.acceptBySportObjectComments !== ' ') ?
										<Typography
												variant={'body1'}
												component={'div'}
												sx={{
													mt: 1,
													ml: 4,
													position: 'relative',
												}}
										>
											<Box sx={{
												py: .5,
												px: 1.2,
												backgroundColor: 'background.paper',
												boxShadow: `0 1px 2px ${theme.palette.divider}`,
												borderRadius: 1
											}}>
												{data?.acceptBySportObjectComments}
											</Box>
											<Box sx={{
												overflow: 'hidden',
												position: 'absolute',
												bottom: '-0.0625rem',
												width: '0.5625rem',
												height: '1.125rem',
												left: '-0.562rem',
												fontSize: '1rem !important'
											}}>
												<MessageAngle/>
											</Box>
										</Typography> : null}

							</HighlightedBox>
						</StepContent>
					</Step>

					{(data?.status === RejectedByCommercialDepartment) ?
							<Step active={Boolean(data?.rejectedUser)}>
								<StepLabel>
									<Typography
											variant={'body2'}
											component={'span'}
									>Завяка отклонена коммерческим отделом утверждения</Typography>
								</StepLabel>
								<StepContent>
									<HighlightedBox
											sx={{
												display: 'flex',
												flexDirection: 'column',
												borderRadius: 2,
												p: 2
											}}
									>
										<Box
												sx={{display: 'flex', alignItems: 'center'}}
										>
											<Avatar
													alt={data?.rejectedUser?.login}
													sx={{
														fontSize: 10,
														width: 28,
														height: 28,
														mr: 1,
														bgcolor: theme.palette.primary.main
													}}
											>{data?.rejectedUser?.login.slice(0, 2).toUpperCase()}</Avatar>
											<Box sx={{display: 'flex', flexDirection: 'column'}}>
												<Typography
														variant={'body2'}
														component={'span'}
												>
													{data?.rejectedUser?.login || 'Не известно'}
												</Typography>
												<Typography
														variant={'caption'}
														component={'span'}
												>{dayjs(Number(data?.rejectedDate)).format("DD MMM YYYY, HH:mm")}</Typography>
											</Box>
										</Box>
										{(data?.rejectReason && data?.rejectReason !== ' ') ?
												<Typography
														variant={'body1'}
														component={'div'}
														sx={{
															mt: 1,
															ml: 4,
															position: 'relative',
														}}
												>
													<Box sx={{
														py: .5,
														px: 1.2,
														backgroundColor: 'background.paper',
														boxShadow: `0 1px 2px ${theme.palette.divider}`,
														borderRadius: 1
													}}>
														{data?.rejectReason}
													</Box>
													<Box sx={{
														overflow: 'hidden',
														position: 'absolute',
														bottom: '-0.0625rem',
														width: '0.5625rem',
														height: '1.125rem',
														left: '-0.562rem',
														fontSize: '1rem !important'
													}}>

														<MessageAngle/>
													</Box>
												</Typography> : null}
									</HighlightedBox>
								</StepContent>
							</Step> : null
					}
					{(data?.status !== RejectedByCommercialDepartment) ?
							<Step active={Boolean(data?.acceptedUser)}>
								<StepLabel>
									<Typography
											variant={'body2'}
											component={'span'}
									>Завяка одобрена отделом утверждения</Typography>
								</StepLabel>
								<StepContent>
									<HighlightedBox
											sx={{
												display: 'flex',
												flexDirection: 'column',
												borderRadius: 2,
												p: 2
											}}
									>
										<Box
												sx={{display: 'flex', alignItems: 'center'}}
										>
											<Avatar
													alt={data?.acceptedUser?.login}
													sx={{
														fontSize: 10,
														width: 28,
														height: 28,
														mr: 1,
														bgcolor: theme.palette.primary.main
													}}
											>{data?.acceptedUser?.login.slice(0, 2).toUpperCase()}</Avatar>
											<Box sx={{display: 'flex', flexDirection: 'column'}}>
												<Typography
														variant={'body2'}
														component={'span'}
												>
													{data?.acceptedUser?.login || 'Не известно'}
												</Typography>
												<Typography
														variant={'caption'}
														component={'span'}
												>{dayjs(Number(data?.acceptedDate)).format("DD MMM YYYY, HH:mm")}</Typography>
											</Box>
										</Box>
										{(data?.acceptComments && data?.acceptComments !== ' ') ? <Typography
												variant={'body1'}
												component={'div'}
												sx={{
													mt: 1,
													ml: 4,
													position: 'relative',
												}}
										>
											<Box sx={{
												py: .5,
												px: 1.2,
												backgroundColor: 'background.paper',
												boxShadow: `0 1px 2px ${theme.palette.divider}`,
												borderRadius: 1
											}}>
												{data?.acceptComments}
											</Box>
											<Box sx={{
												overflow: 'hidden',
												position: 'absolute',
												bottom: '-0.0625rem',
												width: '0.5625rem',
												height: '1.125rem',
												left: '-0.562rem',
												fontSize: '1rem !important'
											}}>

												<MessageAngle/>
											</Box>
										</Typography> : null}
									</HighlightedBox>
								</StepContent>
							</Step> : null
					}
					{(data?.status === RejectedByAcceptanceDepartment) ?
							<Step active={Boolean(data?.rejectedUser)}>
								<StepLabel>
									<Typography
											variant={'body2'}
											component={'span'}
									>Заявка отклонена отделом приемки</Typography>
								</StepLabel>
								<StepContent>
									<HighlightedBox
											sx={{
												display: 'flex',
												flexDirection: 'column',
												borderRadius: 2,
												p: 2
											}}
									>
										<Box
												sx={{display: 'flex', alignItems: 'center'}}
										>
											<Avatar
													alt={data?.rejectedUser?.login}
													sx={{
														fontSize: 10,
														width: 28,
														height: 28,
														mr: 1,
														bgcolor: theme.palette.primary.main
													}}
											>{data?.rejectedUser?.login.slice(0, 2).toUpperCase()}</Avatar>
											<Box sx={{display: 'flex', flexDirection: 'column'}}>
												<Typography
														variant={'body2'}
														component={'span'}
												>
													{data?.rejectedUser?.login || 'Не известно'}
												</Typography>
												<Typography
														variant={'caption'}
														component={'span'}
												>{dayjs(Number(data?.rejectedDate)).format("DD MMM YYYY, HH:mm")}</Typography>
											</Box>
										</Box>
										{(data?.rejectReason && data?.rejectReason !== ' ') ?
												<Typography
														variant={'body1'}
														component={'div'}
														sx={{
															mt: 1,
															ml: 4,
															position: 'relative',
														}}
												>
													<Box sx={{
														py: .5,
														px: 1.2,
														backgroundColor: 'background.paper',
														boxShadow: `0 1px 2px ${theme.palette.divider}`,
														borderRadius: 1
													}}>
														{data?.rejectReason}
													</Box>
													<Box sx={{
														overflow: 'hidden',
														position: 'absolute',
														bottom: '-0.0625rem',
														width: '0.5625rem',
														height: '1.125rem',
														left: '-0.562rem',
														fontSize: '1rem !important'
													}}>

														<MessageAngle/>
													</Box>
												</Typography> : null}
									</HighlightedBox>
								</StepContent>
							</Step> : null
					}

					{(!isRejected) ?
							<Step active={Boolean(data?.acceptedByAcceptanceDepartmentUser)}>
								<StepLabel>
									<Typography
											variant={'body2'}
											component={'span'}
									>Заявка утверждена отделом приемки и отправлена в транспортную службу</Typography>
								</StepLabel>
								<StepContent>
									<HighlightedBox
											sx={{
												display: 'flex',
												flexDirection: 'column',
												borderRadius: 2,
												p: 2
											}}
									>
										<Box
												sx={{display: 'flex', alignItems: 'center'}}
										>

											<Avatar
													alt={data?.acceptedByAcceptanceDepartmentUser?.login}
													sx={{
														fontSize: 10,
														width: 28,
														height: 28,
														mr: 1,
														bgcolor: theme.palette.primary.main
													}}
											>{data?.acceptedByAcceptanceDepartmentUser?.login.slice(0, 2).toUpperCase()}</Avatar>
											<Box sx={{display: 'flex', flexDirection: 'column'}}>
												<Typography
														variant={'body2'}
														component={'span'}
												>
													{data?.acceptedByAcceptanceDepartmentUser?.login || 'Не известно'}
												</Typography>
												<Typography
														variant={'caption'}
														component={'span'}
												>{dayjs(Number(data?.acceptedByAcceptanceDepartmentDate)).format("DD MMM YYYY, HH:mm")}</Typography>
											</Box>
										</Box>

										{(data?.acceptByAcceptanceDepartmentComments && data?.acceptByAcceptanceDepartmentComments !== ' ') ?
												<Typography
														variant={'body1'}
														component={'div'}
														sx={{
															mt: 1,
															ml: 4,
															position: 'relative',
														}}
												>
													<Box sx={{
														py: .5,
														px: 1.2,
														backgroundColor: 'background.paper',
														boxShadow: `0 1px 2px ${theme.palette.divider}`,
														borderRadius: 1
													}}>
														{data?.acceptByAcceptanceDepartmentComments}
													</Box>
													<Box sx={{
														overflow: 'hidden',
														position: 'absolute',
														bottom: '-0.0625rem',
														width: '0.5625rem',
														height: '1.125rem',
														left: '-0.562rem',
														fontSize: '1rem !important'
													}}>

														<MessageAngle/>
													</Box>
												</Typography> : null}

									</HighlightedBox>
								</StepContent>
							</Step> : null
					}
					{(!isRejected) ?
							<Step active={Boolean(data?.relocationResponsibleUser)}>
								<StepLabel>
									<Typography
											variant={'body2'}
											component={'span'}
									> Подтверждение отправки оборудования со спортобъекта нахождения</Typography>
								</StepLabel>
								<StepContent>
									<HighlightedBox
											sx={{
												display: 'flex',
												flexDirection: 'column',
												borderRadius: 2,
												p: 2
											}}
									>
										<Box
												sx={{display: 'flex', alignItems: 'center'}}
										>

											<Avatar
													alt={data?.relocationResponsibleUser?.login}
													sx={{
														fontSize: 10,
														width: 28,
														height: 28,
														mr: 1,
														bgcolor: theme.palette.primary.main
													}}
											>{data?.relocationResponsibleUser?.login.slice(0, 2).toUpperCase()}</Avatar>
											<Box sx={{display: 'flex', flexDirection: 'column'}}>
												<Typography
														variant={'body2'}
														component={'span'}
												>
													{data?.relocationResponsibleUser?.login || 'Не известно'}
												</Typography>
												<Typography
														variant={'caption'}
														component={'span'}
												>{dayjs(Number(data?.relocationStartedDate)).format("DD MMM YYYY, HH:mm")}</Typography>
											</Box>
										</Box>

										{(data?.relocationStartedComments && data?.relocationStartedComments !== ' ') ?
												<Typography
														variant={'body1'}
														component={'div'}
														sx={{
															mt: 1,
															ml: 4,
															position: 'relative',
														}}
												>
													<Box sx={{
														py: .5,
														px: 1.2,
														backgroundColor: 'background.paper',
														boxShadow: `0 1px 2px ${theme.palette.divider}`,
														borderRadius: 1
													}}>
														{data?.relocationStartedComments}
													</Box>
													<Box sx={{
														overflow: 'hidden',
														position: 'absolute',
														bottom: '-0.0625rem',
														width: '0.5625rem',
														height: '1.125rem',
														left: '-0.562rem',
														fontSize: '1rem !important'
													}}>

														<MessageAngle/>
													</Box>
												</Typography> : null}

									</HighlightedBox>
								</StepContent>
							</Step> : null
					}
					{(!isRejected) ?
							<Step active={Boolean(data?.receivedEquipmentUser)}>
								<StepLabel>
									<Typography
											variant={'body2'}
											component={'span'}
									> Подтверждение приёма оборудования на спортобъекте назначения</Typography>
								</StepLabel>
								<StepContent>
									<HighlightedBox
											sx={{
												display: 'flex',
												flexDirection: 'column',
												borderRadius: 2,
												p: 2
											}}
									>
										<Box
												sx={{display: 'flex', alignItems: 'center'}}
										>

											<Avatar
													alt={data?.receivedEquipmentUser?.login}
													sx={{
														fontSize: 10,
														width: 28,
														height: 28,
														mr: 1,
														bgcolor: theme.palette.primary.main
													}}
											>{data?.receivedEquipmentUser?.login.slice(0, 2).toUpperCase()}</Avatar>
											<Box sx={{display: 'flex', flexDirection: 'column'}}>
												<Typography
														variant={'body2'}
														component={'span'}
												>
													{data?.receivedEquipmentUser?.login || 'Не известно'}
												</Typography>
												<Typography
														variant={'caption'}
														component={'span'}
												>{dayjs(Number(data?.relocatedDate)).format("DD MMM YYYY, HH:mm")}</Typography>
											</Box>
										</Box>
										{(data?.receivedEquipmentComments && data?.receivedEquipmentComments !== ' ') ?
												<Typography
														variant={'body1'}
														component={'div'}
														sx={{
															mt: 1,
															ml: 4,
															position: 'relative',
														}}
												>
													<Box sx={{
														py: .5,
														px: 1.2,
														backgroundColor: 'background.paper',
														boxShadow: `0 1px 2px ${theme.palette.divider}`,
														borderRadius: 1
													}}>
														{data?.receivedEquipmentComments}
													</Box>
													<Box sx={{
														overflow: 'hidden',
														position: 'absolute',
														bottom: '-0.0625rem',
														width: '0.5625rem',
														height: '1.125rem',
														left: '-0.562rem',
														fontSize: '1rem !important'
													}}>

														<MessageAngle/>
													</Box>
												</Typography> : null}
									</HighlightedBox>
								</StepContent>
							</Step> : null
					}


				</Stepper>
			</Dialog>
	);
})

export default EquipmentRelocationHistory;
