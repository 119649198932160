import React, { useMemo } from "react";
import { Avatar, AvatarGroup, Badge, Box, Tooltip } from "@mui/material";
import { lighten, Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { DataGrid, GridColumns, GridRowData, GridToolbarContainer } from "@mui/x-data-grid";
import MuiDataGridStyles from "components/MuiDataGridStyles";
import dayjs from "dayjs";
import clsx from "clsx";
import { useStores } from "../../../../store";
import { observer } from "mobx-react-lite";
import FiltersPanel, { FiltersType } from "components/FiltersPanel";
import FilterAutocomplete from "components/FiltersPanel/FilterComponents/FilterAutocomplete";
import {
  ExternalOrderEntity,
  ExternalOrderRecordEntity,
  SportSubZoneEntity,
  SportZoneEntity
} from "../../../../graphql/graphQlApiHooks";
import FilterSearch from "components/FiltersPanel/FilterComponents/FilterSearch";
import FilterDateRange from "components/FiltersPanel/FilterComponents/FilterDateRange";
import Settings from "../../../../store/settings";
import Tooltiper from "components/Tooltiper";
import { useTheme } from "@mui/styles";
import BlockIcon from "@mui/icons-material/Block";
import RentDialog from "../Dialogs/Edit";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { ExternalOrderStatusEnum } from "../../ReturnedEquipments/external-order-status.enum";
import { MouseOverPopover } from "../../EquipmentRelocate/Table";
import { BootstrapTooltip } from "../../../../components/BootstrapTooltip";


const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-row": {
        "& $cell": {
          width: "100%",
          maxHeight: "70px !important",
          overflow: "hidden"
        }
      }
    }
  },
  status: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "&.created": {
      color: theme.palette.primary.main,
      background: lighten(theme.palette.primary.main, 0.85),
      borderRadius: 4,
      padding: "4px 8px"
    },
    "&.reconciliation": {
      color: "#3656f4",
      background: lighten("#3646f4", 0.85),
      borderRadius: 4,
      padding: "4px 8px"
    },
    "&.rejected": {
      color: "#e22d48",
      background: lighten("#e22d48", 0.85),
      borderRadius: 4,
      padding: "4px 8px"
    },
    "&.agreed": {
      color: "#51cc88",
      background: lighten("#51cc88", 0.85),
      borderRadius: 4,
      padding: "4px 8px"
    },
    "&.in_progress": {
      color: "#51cc88",
      background: lighten("#51cc88", 0.85),
      borderRadius: 4,
      padding: "4px 8px"
    },
    "&.ended": {
      color: "#ccced0",
      background: lighten("#ccced0", 0.85),
      borderRadius: 4,
      padding: "4px 8px"
    }
  },
  statusIcon: {
    marginRight: ".5rem"
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa"
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `rgba(${theme.palette.primary.main}, .25) 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    "&[aria-selected=\"true\"]": {
      backgroundColor: "transparent"
    },
    "&[data-focus=\"true\"]": {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  },
  cell: {}
}));

interface IStatus {
  name: string;
  value: string;
  className: string;
}

const statuses: IStatus[] = [
  {
    name: "Заявка создана",
    value: "CREATED",
    className: "created"
  },
  {
    name: "Отправлено",
    value: ExternalOrderStatusEnum.InTransportService,
    className: "reconciliation"
  },
  {
    name: "Возврщено",
    value: ExternalOrderStatusEnum.ReturnedIncomplete,
    className: "rejected"
  },
  {
    name: "Завершено",
    value: ExternalOrderStatusEnum.ReturnedComplete,
    className: "ended"
  }
];

const getStatusName = (value: any) => {
  return statuses.find((status: IStatus) => status?.value === value)?.name || "";
};
const getStatusValue = (name: any) => {
  return statuses.find((status: IStatus) => status.value === name)?.className || "";
};
const RenderStatus = (status: any) => {
  const classes = useStyles();
  status = status.status;
  let icon, statusClass;


  switch (statuses.find((s: IStatus) => s?.value === status)?.value) {
    case ExternalOrderStatusEnum.Created:
      icon = <FiberManualRecordIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = "created";
      break;
    case ExternalOrderStatusEnum.ReturnedComplete:
      icon = <ThumbUpAltIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = "agreed";
      break;
    case ExternalOrderStatusEnum.ReturnedIncomplete:
      icon = <KeyboardReturnIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = "reconciliation";
      break;
    case ExternalOrderStatusEnum.InTransportService:
      icon = <RestartAltIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = "in_progress";
      break;
    default:
      icon = <DoneAllIcon
        fontSize={"small"}
        classes={{ root: classes.statusIcon }}
      />;
      statusClass = "ended";
  }

  return (
    <React.Fragment>
      <div className={clsx(classes.status, statusClass)}>
        {icon}
        {getStatusName(status)}
      </div>
    </React.Fragment>
  );

};


const CustomToolbar = observer(() => {
  const { serviceJobsStore, dictionariesStore } = useStores();

  const filters: FiltersType[] = [
    {
      name: "sportZoneIds",
      title: "Объекты спорта",
      removable: false,
      filterComponent: (props: any) => <FilterAutocomplete
        values={serviceJobsStore.sportZones}
        selected={serviceJobsStore.filters.sportZoneIds}
        filterResult={(result: SportZoneEntity[]) => serviceJobsStore.setFilters({ sportZoneIds: result.map(val => val.id) })}
        {...props}
      />,
      chipDescription: () => {
        return (serviceJobsStore.filters.sportZoneIds !== null) ? `: ${serviceJobsStore.filters.sportZoneIds.length}/${serviceJobsStore.sportZoneIds.length}` : "";
      }
    },
    {
      name: "sportSubZoneIds",
      title: "Спортзона",
      removable: false,
      filterComponent: (props: any) => <FilterAutocomplete
        values={serviceJobsStore.sportSubZones}
        selected={serviceJobsStore.filters.sportSubZoneIds}
        filterResult={(result: SportSubZoneEntity[]) => serviceJobsStore.setFilters({ sportSubZoneIds: result.map(val => val.id) })}
        {...props}
      />,
      chipDescription: () => {
        return (serviceJobsStore.filters.sportSubZoneIds !== null) ? `: ${serviceJobsStore.filters.sportSubZoneIds.length}/${serviceJobsStore.sportSubZoneIds.length}` : `: 0/${serviceJobsStore.sportSubZoneIds.length}`;
      }
    },
    {
      name: "equipmentName",
      title: "Название оборудования",
      filterComponent: (props: any) => <FilterSearch
        value={serviceJobsStore.filters.equipmentName}
        filterResult={(result: string) => serviceJobsStore.setFilters({ equipmentName: result })}
        {...props}
      />,
      chipDescription: () => {
        return (serviceJobsStore.filters.equipmentName !== null && serviceJobsStore.filters.equipmentName !== "") ? ` содержит ${serviceJobsStore.filters.equipmentName}` : "";
      },
      clearFilter: () => {
        serviceJobsStore.setFilters({ equipmentName: null });
      }
    },
    {
      name: "equipmentInventoryNumber",
      title: "Инвентарный номер",
      filterComponent: (props: any) => <FilterSearch
        value={serviceJobsStore.filters.equipmentInventoryNumber}
        filterResult={(result: string) => serviceJobsStore.setFilters({ equipmentInventoryNumber: result })}
        {...props}
      />,
      chipDescription: () => {
        return (serviceJobsStore.filters.equipmentInventoryNumber !== null && serviceJobsStore.filters.equipmentInventoryNumber !== "") ? ` содержит ${serviceJobsStore.filters.equipmentInventoryNumber}` : "";
      },
      clearFilter: () => {
        serviceJobsStore.setFilters({ equipmentInventoryNumber: null });
      }
    },
    {
      name: "deadline",
      title: "Крайний срок выполнения",
      filterComponent: (props: any) => <FilterDateRange
        value={serviceJobsStore.filters.deadline}
        filterResult={(result) => serviceJobsStore.setFilters({ deadline: result })}
        {...props}
      />,
      chipDescription: () => {
        const deadline = serviceJobsStore.filters.deadline;
        if (deadline?.[0] !== null) {
          if (!dayjs(deadline?.[0]).isSame(deadline?.[1]) && deadline?.[1]) {
            return ` С ${dayjs(deadline?.[0]).format("ll")} по ${dayjs(deadline?.[1]).format("ll")}`;
          } else {
            return ` ${dayjs(deadline?.[0]).format("ll")}`;
          }
        } else {
          return "";
        }
      },
      clearFilter: () => {
        serviceJobsStore.setFilters({ deadline: [null, null] });
      }
    },
    {
      name: "done",
      title: "Дата выполнения",
      filterComponent: (props: any) => <FilterDateRange
        value={serviceJobsStore.filters.done}
        filterResult={(result) => serviceJobsStore.setFilters({ done: result })}
        {...props}
      />,
      chipDescription: () => {
        const done = serviceJobsStore.filters.done;
        if (done?.[0] !== null) {
          if (!dayjs(done?.[0]).isSame(done?.[1]) && done?.[1]) {
            return ` С ${dayjs(done?.[0]).format("ll")} по ${dayjs(done?.[1]).format("ll")}`;
          } else {
            return ` ${dayjs(done?.[0]).format("ll")}`;
          }
        } else {
          return "";
        }
      },
      clearFilter: () => {
        serviceJobsStore.setFilters({ done: [null, null] });
      }
    }
  ];

  function getFiltersSelected(filters: any) {
    let result = [];
    for (let [key, value] of Object.entries(serviceJobsStore.filters)) {
      const filter = filters.find((item: any) => item.name === key);
      const isNullsArray = Boolean(Array.isArray(value) && value.filter((item: any) => item === null).length === 2);
      const isEmpty = Boolean(value == null || isNullsArray);
      if (!isEmpty || filter?.removable === false)
        result.push(filter);
    }
    return result;
  }

  return (
    <GridToolbarContainer>
      <FiltersPanel
        store={serviceJobsStore}
        filters={filters}
        selected={getFiltersSelected(filters)}
      />
    </GridToolbarContainer>
  );
});

const RentTable = (rest: { orders?: ExternalOrderEntity[]; loading: boolean }) => {
  const classes = useStyles();
  const theme = useTheme();
  const tableClasses = MuiDataGridStyles();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentRow, setCurrentRow] = React.useState<ExternalOrderEntity | null>(null);
  const [rows, setRows] = React.useState<readonly GridRowData[]>([]);


  const columns: GridColumns = [
    {
      field: "records", headerName: "Оборудование",
      flex: 1,
      renderCell: (params) => {
        return (
          <AvatarGroup max={4}>
            {
              params.row.records?.map((item: ExternalOrderRecordEntity) => {
                const avatarUrl = (item.equipment?.mainPhoto) ? item.equipment?.mainPhoto.url : (item.equipment.photos?.length > 0) && item.equipment.photos?.[0].avatarUrl;
                const avatarUrlOrigin = (item.equipment?.mainPhoto) ? item.equipment?.mainPhoto.url : (item.equipment.photos?.length > 0) && item.equipment.photos?.[0].url;
                return (
                  <MouseOverPopover key={item.equipment.id}
                                    urlAvatar={(avatarUrl) ? `${Settings.server}${avatarUrlOrigin}` : undefined}>
                    <BootstrapTooltip
                      title={(item.equipment.technicalCondition === "NOT_WORKING") ? "Оборудование не исправно" : ""}>
                      <Badge
                        invisible={item.equipment.technicalCondition === "WORKING"}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right"
                        }}
                        badgeContent={<BlockIcon color={"secondary"} />}>
                        <Avatar
                          sx={{
                            width: 30,
                            height: 30,
                            bgcolor: theme.palette.background.default
                          }}
                          src={(avatarUrl) ? `${Settings.server}${avatarUrl}` : ""}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/static/mossport_logo.svg`}
                            alt="mossport_logo"
                          />
                        </Avatar>
                      </Badge>
                    </BootstrapTooltip>
                  </MouseOverPopover>
                );
              })
            }
          </AvatarGroup>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "status", headerName: "Статус",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
          >
            {
              <RenderStatus status={params.row.status} />
            }
          </Box>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "externalOrderOwner", headerName: "Заявитель",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
          >
            {
              params.row.externalOrderOwner?.fio ||
              params.row.externalOrderOwner?.login
            }
          </Box>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "giverName", headerName: "Контакты для связи",
      flex: 1,
      renderCell: (params) => {
        return (
          params.row?.giverName && params.row?.giverPhone && `${params.row.giverName} ${params.row.giverPhone}`
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "targetPointAddress", headerName: "Адрес",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          <Tooltiper text={params.row.targetPointAddress} />
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "startEventDate", headerName: "Дата начала мероприятия",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
          >
            {
              params.row.startEventDate ?
                dayjs(Number(params.row.startEventDate)).format("DD MMM YYYY HH:mm")
                :
                null
            }
          </Box>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "endEventDate", headerName: "Дата окончания мероприятия",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
          >
            {
              params.row.endEventDate ?
                dayjs(Number(params.row.endEventDate)).format("DD MMM YYYY HH:mm")
                :
                null
            }
          </Box>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    },
    {
      field: "returnDate", headerName: "Дата возврата",
      flex: 1,
      type: "date",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            lineHeight={"initial"}
          >
            {
              params.row.returnDate ?
                dayjs(Number(params.row.returnDate)).format("DD MMM YYYY HH:mm")
                :
                null
            }
          </Box>
        );
      },
      minWidth: 120,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center"
    }
  ];

  useMemo(() => {
    rest.orders && setRows(rest.orders as unknown as never[]);
  }, [rest.orders]);

  const openDialogHandler = (row: ExternalOrderEntity) => {
    setCurrentRow(row);
    setOpenDialog(true);
  };


  return (
    <>
      <Box
        sx={{ height: "100%", width: "100%" }}
        className={classes.root}
      >
        <DataGrid
          className={tableClasses.root}
          rows={rows}
          columns={columns.map(column => ({
            ...column,
            cellClassName: ({ row }) => clsx(classes.cell, getStatusValue(row.status))
          }))}
          // sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          onCellDoubleClick={({ row }) => openDialogHandler(row)}
          // components={{
          // 	Toolbar: CustomToolbar,
          // }}
          initialState={{
            sorting: {
              sortModel: [{ field: "status", sort: "asc" }]
            }
          }}
          rowHeight={60}
          checkboxSelection={false}
          columnBuffer={10}
          columnThreshold={10}
          density={"standard"}
          rowThreshold={3}
          rowBuffer={5}
          loading={rest.loading}
          // pagination
          // rowCount={rows.length}
          // paginationMode="server"
          // page={serviceJobsStore.page}
          // pageSize={serviceJobsStore.pageSize}
          // onPageChange={(page) => serviceJobsStore.setPage(page)}
          // onPageSizeChange={(pageSize) =>
          // 		serviceJobsStore.setPageSize(pageSize)
          // }
          // rowsPerPageOptions={[5, 10, 50, 100]}
        />
      </Box>
      <RentDialog
        open={openDialog}
        close={setOpenDialog}
        row={currentRow as ExternalOrderEntity}
      />
    </>
  );
};


export default RentTable;

