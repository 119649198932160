import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { InputBase } from "@mui/material";

interface Props {

}

export const NameEdit = (props: GridRenderCellParams<any>) => {
  const { id, api, field, row } = props;
  const value = row?.name || "";

  const handleChange = (event: any) => {
    api.setEditCellValue({ id, field, value: event.target.value }, event);
    api.commitRowChange(row.id);
  };
  return (
    <InputBase
      sx={{
        width: "100%",
        height: "100%"
      }}
      error={!value || value === ""}
      value={value}
      onChange={handleChange}
      placeholder="Укажите название оборудования"
    />
  );
};
