import React from 'react';
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../../../components/Logo";
import {Link} from "@mui/material";
import {useTheme} from "@mui/styles";

interface Props {

}

function Brand(props: Props) {
	const theme = useTheme();

	return (
			<Link component={RouterLink} to="/" sx={{
				textDecoration: 'none',
				alignItems: 'center',
				display: 'inline-flex',
				fontWeight: 500
			}}>
				<Logo width={36} style={{marginRight: theme.spacing(1) }} />
				Московский спорт
			</Link>
	);
}

export default Brand;