// ----------------------------------------------------------------------

import {Theme} from "@mui/material";


export default function MuiFilledInput(theme:Theme) {
  return {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          background: 'transparent',
          border: `1px solid ${theme.palette.divider}`,
          '&:hover': {
            background: 'transparent',
            borderColor: theme.palette.grey['400']
          },
          '&.Mui-focused': {
            background: 'transparent',
            borderColor: theme.palette.primary.dark
          },
          '&.Mui-error': {
            background: 'transparent',
            borderColor: theme.palette.error.dark
          },
          '&.Mui-disabled':{
            background: theme.palette.grey['100']

          }
        },
      }
    }
  };
}

