import {observer} from "mobx-react-lite";
import {GridCellParams} from "@mui/x-data-grid";
import {EquipmentRelocationRequestEntity} from "../../../../../graphql/graphQlApiHooks";
import {Badge, IconButton, Tooltip} from "@mui/material";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import React from "react";
import { BootstrapTooltip } from "../../../../../components/BootstrapTooltip";

const HistoryButton = observer((props: { params: GridCellParams; openCommentsDialog: (params: EquipmentRelocationRequestEntity) => void; }) => {
	const {params: {row}} = props;

	return (
			<>
				<BootstrapTooltip title={'История перемещений'}>
					<IconButton
							color="inherit"
							component="span"
							onClick={() => props.openCommentsDialog(row as EquipmentRelocationRequestEntity)}
					>
						<Badge
								color="primary"
								variant="dot"
								invisible={
										!row?.acceptBySportObjectComments &&
										!row?.acceptComments &&
										!row?.rejectReason
								}
						>
							<MessageOutlinedIcon
									sx={{
										opacity: .5,
										transition: 'all .6s',
										fontSize: '18px !important'
									}}
							/>
						</Badge>
					</IconButton>
				</BootstrapTooltip>

			</>
	);
});

export default HistoryButton