import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {AppBar, Box, Container, Link, Stack, TextField, Toolbar} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Logo from "components/Logo";
import {useTheme} from "@mui/styles";
import SearchIcon from '@mui/icons-material/Search';
import Brand from './Header/Brand';
import Search from "./Header/Search";
import Navigate from "./Header/Navigate";
import UserPanel from "./Header/UserPanel";
import WishPanel from "./Header/WishPanel";
import BasketPanel from "./Header/BasketPanel";

const useStyles = makeStyles((theme) => ({
	root: {
		// top: 43,
		height: 64,
		borderBottom: `1px solid ${theme.palette.divider}`,
		background: theme.palette.background.paper
	},
}));

interface ITopBarProps {
	[key: string]: any
}

const Header = ({className, ...rest}: ITopBarProps) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
			<AppBar
					className={clsx(classes.root, className)}
					elevation={0}
					color={'inherit'}
					position={'sticky'}
					{...rest}
			>
				<Container maxWidth={'xl'}>
					<Toolbar disableGutters sx={{
						display: 'flex'
					}}>
						<Stack direction={'row'} alignItems={'center'} spacing={2}>
							<Brand/>
							{/*<Search/>*/}
							{/*<Navigate/>*/}
						</Stack>
						<Box sx={{flexGrow: 1}}/>

						{/*<Stack direction={'row'} alignItems={'center'} spacing={2}>*/}
						{/*	<UserPanel/>*/}
						{/*	<WishPanel/>*/}
						{/*	<BasketPanel/>*/}
						{/*</Stack>*/}
					</Toolbar>
				</Container>
			</AppBar>
	);
};

Header.propTypes = {
	className: PropTypes.string
};

export default Header;
