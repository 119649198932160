import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Autocomplete, TextField } from "@mui/material";

interface Props {

}

export const SportObjectEdit = (props: GridRenderCellParams<any> | any) => {
  const { id, api, field, row, sportObjects } = props;
  const value = row?.sportObject;

  const handleChange = (event: any, newValue: any) => {
    api.setEditCellValue({ id, field, value: newValue }, event);
    api.setEditCellValue({ id, field: "sportSubZone", value: null }, event);
    api.setEditCellValue({ id, field: "sportZone", value: null }, event);

    api.commitRowChange(row.id);
  };
  return (
    <Autocomplete
      value={value}
      id="sportObject"
      options={sportObjects || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option: any, value: any) => option?.id === value?.id}
      onChange={handleChange}
      sx={{ width: "100%", height: "100%", ".MuiTextField-root": { height: "100%" } }}
      openOnFocus
      renderInput={(params) => <TextField
        error={!value}
        {...params}
        variant="standard"
        size={"small"}
        placeholder="Выберите объект спорта"
        InputLabelProps={{
          shrink: true
        }}
      />}
    />
  );
};
