import React, {useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField,} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from "@mui/icons-material/Close";
import {CreateSportSubZoneInput, SportSubZoneEntity} from "../../../../../graphql/graphQlApiHooks";
import {useStores} from '../../../../../store';


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& .MuiDialogContent-root': {
            padding: theme.spacing(3)
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    form: {
        '& .input-wrapper': {
            flexWrap: 'nowrap',
            display: 'flex',
            '& .MuiFormControl-root:not(:last-child)': {
                marginRight: '.5em'
            }
        }
    }
}));

function AddSportSubZone({...rest}) {
    const classes = useStyles();
    const {open, onClose} = rest
    const [name, setName] = useState('');
    const [area, setArea] = useState('');
    const [eps, setEps] = useState('');
    const [nameExistsError, setNameExistsError] = useState<string | null>(null);

    const {settingsStore} = useStores()

    function handleCreate() {
        if (name !== '') {
            const nameIsExists = settingsStore.sportSubZones.find((zone:SportSubZoneEntity)=>zone.name === name)

            if (!!nameIsExists) {
                setNameExistsError('Название занято')
                return
            }
            settingsStore.sportSubZoneCreate({
                sportObjectId: settingsStore?.sportObject?.id,
                sportZoneId: settingsStore?.sportZone?.id,
                name,
                area: parseFloat(area),
                eps: parseFloat(eps),
            } as CreateSportSubZoneInput).then(() => {
                setName('')
                setArea('')
                setEps('')
                handleClose()
            })

        }
    }

    function handleClose() {
        onClose(false)
    }


    return <>

        <Dialog
            onClose={handleClose}
            aria-labelledby="add-sportSubZone-dialog-title"
            open={open}
            className={classes.root}
        >
            <DialogTitle id="add-sportSubZone-dialog-title">
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                >
                    <span>Добавление спортзоны.</span>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <form
                    className={classes.form}
                    id='add-sportSubZone-form'
                    autoComplete="off"
                >
                    <div className="input-wrapper">
                        <Box
                            width={'100%'}
                            display={"flex"}
                            flexGrow={2}
                            marginRight={1}
                        >
                            <TextField
                                name='sportSubZoneName'
                                id="sportSubZoneName"
                                label="Название"
                                placeholder="Добавьте название"
                                variant="outlined"
                                size={'small'}
                                autoComplete='off'
                                onChange={({target: {value}}: any) => {
                                    nameExistsError && setNameExistsError(null)
                                    setName(value)
                                }}
                                value={name}
                                fullWidth={true}
                                helperText={nameExistsError}
                                error={!!nameExistsError}
                            />
                        </Box>
                        <Box
                            display={"flex"}
                            flexGrow={0}
                            marginRight={1}
                        >
                            <TextField
                                name='area'
                                id="area"
                                label="Площадь"
                                placeholder="Площадь"
                                variant="outlined"
                                size={'small'}
                                autoComplete='off'
                                onChange={({target: {value}}: any) => setArea(value)}
                                value={area}
                                type={'number'}
                            />
                        </Box>
                        <Box
                            display={"flex"}
                            flexGrow={0}
                        >
                            <TextField
                                name='eps'
                                id="eps"
                                label="Е.П.С."
                                placeholder="Е.П.С."
                                variant="outlined"
                                size={'small'}
                                autoComplete='off'
                                onChange={({target: {value}}: any) => setEps(value)}
                                value={eps}
                                type={'number'}
                            />
                        </Box>
                    </div>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    color="primary"
                    onClick={handleCreate}
                >
                    Добавить
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

AddSportSubZone.protoTypes = {}

export default AddSportSubZone;
