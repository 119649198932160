// ** MUI Theme Provider
import { deepmerge } from '@mui/utils'
import { ThemeOptions } from '@mui/material'

// ** Type Import
import { Settings } from 'context/SettingsContext'

// ** Theme Override Imports
import palette from './palette'
import spacing from './spacing'
import shadows from './shadows'
import breakpoints from './breakpoints'
import typography from "./typography";

const themeOptions = (settings: Settings): ThemeOptions => {
  // ** Vars
  const { mode } = settings

  const themeConfig = {
    palette: palette(mode),
    typography: typography(palette(mode)),
    shadows: shadows(mode),
    ...spacing,
    breakpoints: breakpoints(),
    shape: {
      borderRadius: 4
    },
    mixins: {
    }
  }

  return deepmerge(themeConfig, {})
}

export default themeOptions
