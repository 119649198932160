import React, { ReactNode, useEffect, useMemo } from "react";
import { Outlet } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";

import Settings from "store/settings";
import { observer } from "mobx-react-lite";
import { useStores } from "store";
import EquipmentRelocationRequest from "store/stores/equipmentRelocationRequest.store";

import { DataGrid, GridColumns, GridRenderCellParams, GridSortItem, GridSortModel } from "@mui/x-data-grid";
import { Avatar, Badge, Box, CardHeader, CardMedia, Chip, Menu, MenuItem, Popover, Tooltip } from "@mui/material";

import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/styles";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import CircleIcon from "@mui/icons-material/Circle";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import BlockIcon from "@mui/icons-material/Block";

import MuiDataGridStyles from "components/MuiDataGridStyles";
import Tooltiper from "components/Tooltiper";

import EquipmentDetails from "../Dialogs/EquipmentDetails";
import RequestForAdmission from "../Dialogs/RequestForAdmission";
import EquipmentRelocationHistory from "../Dialogs/EquipmentRelocationHistory";
import AcceptRelocation from "../Dialogs/AcceptRelocation";
import RejectRelocation from "../Dialogs/RejectRelocation";
import RelocateConfirmation from "../Dialogs/RelocateConfirmation";
import RelocateCompletion from "../Dialogs/RelocateCompletion";
import TableToolbar from "./TableToolbar";
import ActionButtons from "./ActionButtons";
import HistoryButton from "./HistoryButton";
import MenuItems from "./MenuItems";
import AcceptTransportRequest from "../Dialogs/AcceptTransportRequest";
import RejectTransportRequest from "../Dialogs/RejectTransportRequest";

import { EquipmentRelocationRequestEntity, SportEquipmentEntity } from "graphql/graphQlApiHooks";
import { BootstrapTooltip } from "../../../../components/BootstrapTooltip";


interface DialogsStates {
  equipmentDetails: boolean;
  equipmentRelocationHistory: boolean;
  requestForAdmission: boolean;
  acceptRelocation: boolean;
  rejectRelocation: boolean;
  relocateConfirmation: boolean;
  relocateCompletion: boolean;
  acceptTransportRequest: boolean;
  rejectTransportRequest: boolean;
}

export function MouseOverPopover(props: { children: ReactNode, urlAvatar?: string }) {
  const { children, urlAvatar } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Box
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </Box>
      {urlAvatar && <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none"
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <CardMedia
          component="img"
          height="300"
          image={urlAvatar}
          alt="green iguana"
        />
      </Popover>}
    </div>
  );
}

const useStylesRelocateEquipmentsTable = makeStyles((theme: Theme) => ({
  status: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRadius: "50px",
    fontWight: "bold",
    "& .MuiChip-icon": {
      color: "inherit"
    },
    "&.REQUEST_SUBMITTED": {
      backgroundColor: "#d2eefe",
      color: "#01b1ff"
    },
    "&.ACCEPTED_BY_ACCEPTANCE_DEPARTMENT": {
      backgroundColor: "#d2eefe",
      color: "#01b1ff"
    },
    "&.ACCEPTED_NOT_RELOCATED": {
      backgroundColor: "#def1dd",
      color: "#2ca42c"
    },
    "&.RELOCATION_STARTED": {
      backgroundColor: "#def1dd",
      color: "#2ca42c"
    },
    "&.ACCEPTED_BY_SPORT_OBJECT": {
      backgroundColor: "#fdf7e7",
      color: "#ce6801"
    },
    "&.ACCEPTED_RELOCATED": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    },
    "&.REJECTED": {
      backgroundColor: "#ffd7d7",
      color: "#f73333"
    },
    "&.REJECTED_BY_ACCEPTANCE_DEPARTMENT": {
      backgroundColor: "#ffd7d7",
      color: "#f73333"
    }
  }
}));

const RelocateEquipmentsTable = observer(({ searchByEquipmentNameValue }: { searchByEquipmentNameValue: string }) => {
  const classes = useStylesRelocateEquipmentsTable();
  const tableClasses = MuiDataGridStyles();
  const theme = useTheme();

  const [rows, setRows] = React.useState<typeof EquipmentRelocationRequest[]>([]);
  const { equipmentRelocationRequestStore, sportObjectsStore } = useStores();

  useEffect(() => {
    equipmentRelocationRequestStore.setFilters({ name: searchByEquipmentNameValue });
  }, [searchByEquipmentNameValue]);

  //Table
  useMemo(() => {
    setRows([...equipmentRelocationRequestStore.allRequests]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentRelocationRequestStore.allRequests, sportObjectsStore.currentIds]);

  const getStatusIcon = (status: string) => {
    switch (status) {
      case "REQUEST_SUBMITTED":
        return <CircleIcon
          fontSize={"small"}
          sx={{ transform: "scale(.4)" }}
        />;
      case "RELOCATION_STARTED":
        return <LocalShippingOutlinedIcon
          fontSize={"small"}
        />;
      case "ACCEPTED_BY_SPORT_OBJECT":
        return <CircleIcon
          fontSize={"small"}
          sx={{ transform: "scale(.4)" }}
        />;
      case "ACCEPTED_NOT_RELOCATED":
        return <AccessTimeOutlinedIcon fontSize={"small"} />;
      case "ACCEPTED_RELOCATED":
        return <DoneAllIcon fontSize={"small"} />;
      case "ACCEPTED_BY_ACCEPTANCE_DEPARTMENT":
        return <LocalShippingOutlinedIcon fontSize={"small"} />;
      case "REJECTED":
        return <ThumbDownAltOutlinedIcon fontSize={"small"} />;
      case "REJECTED_BY_ACCEPTANCE_DEPARTMENT":
        return <ThumbDownAltOutlinedIcon fontSize={"small"} />;
      default:
        return null;
    }
  };

  const columns: GridColumns = [
    {
      field: "equipment.name",
      headerName: "Оборудование",
      flex: 2,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const avatarUrl = (params.row.equipment?.mainPhoto) ? params.row.equipment?.mainPhoto.url : (params.row.equipment.photos?.length > 0) && params.row.equipment.photos?.[0].avatarUrl;
        const avatarUrlOrigin = (params.row.equipment?.mainPhoto) ? params.row.equipment?.mainPhoto.url : (params.row.equipment.photos?.length > 0) && params.row.equipment.photos?.[0].url;
        return (
          <>
            <CardHeader
              onClick={() => handleOpenEquipmentDetails(params.row.equipment)}
              sx={{
                p: 0,
                maxWidth: "100%",
                "& .MuiCardHeader-content": {
                  overflow: "hidden"
                },
                "& .MuiTypography-root": {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                  overflow: "hidden"
                },
                "&:hover": {
                  cursor: "pointer",
                  color: theme.palette.primary.main,
                  "& .MuiTypography-root": {
                    color: theme.palette.primary.main
                  }
                }
              }}
              avatar={
                <MouseOverPopover urlAvatar={(avatarUrl) ? `${Settings.server}${avatarUrlOrigin}` : undefined}>
                  <BootstrapTooltip
                    title={(params.row.equipment.technicalCondition === "NOT_WORKING") ? "Оборудование не исправно" : ""}>
                    <Badge
                      invisible={params.row.equipment.technicalCondition === "WORKING"}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                      }}
                      badgeContent={<BlockIcon color={"secondary"} />}>
                      <Avatar
                        sx={{
                          width: 30,
                          height: 30,
                          bgcolor: theme.palette.background.default
                        }}
                        src={(avatarUrl) ? `${Settings.server}${avatarUrl}` : ""}
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/static/mossport_logo.svg`}
                          alt="mossport_logo"
                        />
                      </Avatar>
                    </Badge>
                  </BootstrapTooltip>
                </MouseOverPopover>

              }
              title={
                <Tooltiper
                  text={params.row.equipment.name}
                  props={{
                    variant: "h5"
                  }}
                />
              }
              subheader={`Инв. № ${params.row.equipment.inventoryNumber}`}
            />
          </>
        );
      }
    },
    {
      field: "sourceSportObject.name",
      headerName: "Cпортобъект нахождения",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <Tooltiper
          text={params.row.sourceSportObject?.name}
          props={{
            variant: "h6"
          }}
        />),
      editable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true
    },
    {
      field: "targetSportObject.name",
      headerName: "Cпортобъект назначения",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (params.row?.targetSportObject?.name) ? (
        <Tooltiper
          text={params.row?.targetSportObject?.name}
          props={{
            variant: "h6"
          }} />) : "Ещё не указан",
      editable: false,
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true
    },
    {
      field: "relocationRequest.status",
      headerName: "Статус заявки",
      flex: 1.5,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Chip
            size={"small"}
            className={clsx(classes.status, params.row.status)}
            icon={getStatusIcon(params.row.status)}
            label={equipmentRelocationRequestStore.getStatusName(params.row.status)}
          />

        );

      },
      disableColumnMenu: true
    },
    {
      field: "relocationRequest.rejectReason", headerName: "История",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <HistoryButton
            params={params}
            openCommentsDialog={handleOpenEquipmentRelocationHistory}
          />
        );
      },
      sortable: false,
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: "center",
      filterable: false,
      disableColumnMenu: true
    },
    {
      field: "relocationRequest.createdAt",
      headerName: "Дата заявки",
      editable: false,
      type: "date",
      renderCell: (params: GridRenderCellParams) => {
        return dayjs(Number(params.row.createdAt)).format("DD MMM YYYY");
      },
      minWidth: 120,
      disableColumnMenu: true
    },
    {
      field: "actions",
      headerName: "Действия",
      flex: 2,
      editable: false,
      filterable: false,
      sortable: false,
      align: "center",
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        const equipmentRelocationRequest = params.row as EquipmentRelocationRequestEntity;
        return <ActionButtons
          relocateEquipment={equipmentRelocationRequest}

          onOpenRequestForAdmission={() => { // Подача заявки на запрос получания оборудования
            handleOpenRequestForAdmission(equipmentRelocationRequest);
          }}
          onOpenAcceptRelocation={() => { // Одобрение заявки на перемещение
            handleOpenAcceptRelocation(equipmentRelocationRequest);
          }}
          onOpenRejectRelocation={() => { // Отклонение заявки на перемещение
            handleOpenRejectRelocation(equipmentRelocationRequest);
          }}
          onOpenRelocateConfirmation={() => { // Подтверждение отправления оборудования
            handleOpenRelocateConfirmation(equipmentRelocationRequest);
          }}
          onOpenRelocateCompletion={() => { // Подтверждение получения оборудования
            handleOpenRelocateCompletion(equipmentRelocationRequest);
          }}
          onOpenAcceptTransportRequest={() => { // Подтверждение получения оборудования
            handleOpenAcceptTransportRequest(equipmentRelocationRequest);
          }}
          onOpenRejectTransportRequest={() => { // Подтверждение получения оборудования
            handleOpenRejectTransportRequest(equipmentRelocationRequest);
          }}
        />;
      },
      minWidth: 300,
      headerAlign: "center"
    }
  ];

  const handleSortModelChange = (newModel: GridSortModel) => {
    const sortModel = newModel[0] as GridSortItem;
    const sortOrder = sortModel?.field ? { [sortModel?.field]: sortModel?.sort?.toUpperCase() } : null;

    equipmentRelocationRequestStore.setLoading(true);
    equipmentRelocationRequestStore.setSortOrder(sortOrder);
  };

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  // Обработчик контекстного меню
  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    const rowId = String(event.currentTarget.getAttribute("data-id"));
    const row = rows.find((item: any) => item.id === rowId);
    setSelectedEquipmentRelocation(row as unknown as EquipmentRelocationRequestEntity);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };
  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };


  // Выбранное оборудование
  const [selectedEquipment, setSelectedEquipment] = React.useState<SportEquipmentEntity | null>(null);
  // Выбранная заявка
  const [selectedEquipmentRelocation, setSelectedEquipmentRelocation] = React.useState<EquipmentRelocationRequestEntity | null>(null);

  // Состояния диалогов
  const [dialogsStates, setDialogsStates] = React.useState<DialogsStates>({
    equipmentDetails: false,  // Информация по оборудованию
    equipmentRelocationHistory: false,  // История перемещений
    requestForAdmission: false, // Подача заявки на запрос получания оборудования
    acceptRelocation: false, // Одобрение заявки на перемещение
    rejectRelocation: false, // Отклонение заявки на перемещение
    relocateConfirmation: false, // Подтверждение отправления оборудования
    relocateCompletion: false, // Подтверждение получения оборудования
    acceptTransportRequest: false, // Подтверждение заявки в транспортную службу из Softra
    rejectTransportRequest: false // Отклонение заявки в транспортную службу из Softra
  });

  // Обработчик открытия диалога Информация по оборудованию
  const handleOpenEquipmentDetails = (equipment: SportEquipmentEntity) => {
    setSelectedEquipment(equipment);
    setDialogsStates({ ...dialogsStates, equipmentDetails: true });
  };
  const handleCloseEquipmentDetails = () => {
    setSelectedEquipment(null);
    setDialogsStates({ ...dialogsStates, equipmentDetails: false });
  };

  // Обработчик открытия диалога История перемещений
  const handleOpenEquipmentRelocationHistory = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, equipmentRelocationHistory: true });
  };
  const handleCloseEquipmentRelocationHistory = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, equipmentRelocationHistory: false });
  };

  // Обработчик открытия диалога подача заявки на запрос получания оборудования
  const handleOpenRequestForAdmission = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, requestForAdmission: true });
  };
  const handleCloseRequestForAdmission = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, requestForAdmission: false });
  };

  // Обработчик открытия диалога одобрение заявки на перемещение
  const handleOpenAcceptRelocation = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, acceptRelocation: true });
  };
  const handleCloseAcceptRelocation = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, acceptRelocation: false });
  };

  // Обработчик открытия диалога отклонение заявки на перемещение
  const handleOpenRejectRelocation = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, rejectRelocation: true });
  };
  const handleCloseRejectRelocation = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, rejectRelocation: false });
  };

  // Обработчик открытия диалога подтверждение отправления оборудования
  const handleOpenRelocateConfirmation = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, relocateConfirmation: true });
  };
  const handleCloseRelocateConfirmation = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, relocateConfirmation: false });
  };

  // Обработчик открытия диалога подтверждение получения оборудования
  const handleOpenRelocateCompletion = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, relocateCompletion: true });
  };
  const handleCloseRelocateCompletion = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, relocateCompletion: false });
  };
  // Обработчик открытия диалога подтверждение заявки на транспортное перемещение
  const handleOpenAcceptTransportRequest = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, acceptTransportRequest: true });
  };
  const handleCloseAcceptTransportRequest = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, acceptTransportRequest: false });
  };
  // Обработчик открытия диалога отклонения заявки на транспортное перемещение
  const handleOpenRejectTransportRequest = (equipmentRelocationRequest: EquipmentRelocationRequestEntity) => {
    setSelectedEquipmentRelocation(equipmentRelocationRequest);
    setDialogsStates({ ...dialogsStates, rejectTransportRequest: true });
  };
  const handleCloseRejectTransportRequest = () => {
    setSelectedEquipmentRelocation(null);
    setDialogsStates({ ...dialogsStates, rejectTransportRequest: false });
  };
  return (
    <>
      <div style={{ height: "calc(100% )", width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          className={tableClasses.root}
          rowHeight={60}
          checkboxSelection={false}
          columnBuffer={3}
          columnThreshold={3}
          density={"standard"}
          rowThreshold={10}
          rowBuffer={10}
          loading={equipmentRelocationRequestStore.loading}
          pagination
          rowCount={equipmentRelocationRequestStore.count}
          paginationMode="server"
          page={equipmentRelocationRequestStore.page}
          pageSize={equipmentRelocationRequestStore.pageSize}
          onPageChange={(page) => equipmentRelocationRequestStore.setPage(page)}
          onPageSizeChange={(pageSize) =>
            equipmentRelocationRequestStore.setPageSize(pageSize)
          }
          rowsPerPageOptions={[5, 10, 50, 100]}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" }
            }
          }}
          components={{
            Toolbar: TableToolbar
          }}
        />
      </div>

      <EquipmentDetails
        open={dialogsStates.equipmentDetails}
        onClose={handleCloseEquipmentDetails}
        equipment={selectedEquipment}
      />

      <EquipmentRelocationHistory
        open={dialogsStates.equipmentRelocationHistory}
        onClose={handleCloseEquipmentRelocationHistory}
        data={selectedEquipmentRelocation}
      />

      <RequestForAdmission
        open={dialogsStates.requestForAdmission}
        onClose={handleCloseRequestForAdmission}
        data={selectedEquipmentRelocation}
      />

      <AcceptRelocation
        open={dialogsStates.acceptRelocation}
        onClose={handleCloseAcceptRelocation}
        data={selectedEquipmentRelocation}
      />

      <RejectRelocation
        open={dialogsStates.rejectRelocation}
        onClose={handleCloseRejectRelocation}
        data={selectedEquipmentRelocation}
      />

      <RelocateConfirmation
        open={dialogsStates.relocateConfirmation}
        onClose={handleCloseRelocateConfirmation}
        data={selectedEquipmentRelocation}
      />

      <AcceptTransportRequest
        open={dialogsStates.acceptTransportRequest}
        onClose={handleCloseAcceptTransportRequest}
        data={selectedEquipmentRelocation}
      />

      <RejectTransportRequest
        open={dialogsStates.rejectTransportRequest}
        onClose={handleCloseRejectTransportRequest}
        data={selectedEquipmentRelocation}
      />

      <RelocateCompletion
        open={dialogsStates.relocateCompletion}
        onClose={handleCloseRelocateCompletion}
        data={selectedEquipmentRelocation}
      />

      <Menu
        open={contextMenu !== null}
        onClose={handleCloseContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleCloseContextMenu();
            }
          }
        }}
      >
        <MenuItems
          relocateEquipment={selectedEquipmentRelocation as EquipmentRelocationRequestEntity}
          onOpenRequestForAdmission={() => {
            handleCloseContextMenu();
            handleOpenRequestForAdmission(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
          onOpenRejectRelocation={() => {
            handleCloseContextMenu();
            handleOpenRejectRelocation(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
          onOpenAcceptRelocation={() => {
            handleCloseContextMenu();
            handleOpenAcceptRelocation(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
          onOpenRelocateConfirmation={() => {
            handleCloseContextMenu();
            handleOpenRelocateConfirmation(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
          onOpenRelocateCompletion={() => {
            handleCloseContextMenu();
            handleOpenRelocateCompletion(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
          onOpenAcceptTransportRequest={() => {
            handleCloseContextMenu();
            handleOpenAcceptTransportRequest(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
          onOpenRejectTransportRequest={() => {
            handleCloseContextMenu();
            handleOpenRejectTransportRequest(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
        />
        <MenuItem
          onClick={() => {
            handleCloseContextMenu();
            handleOpenEquipmentRelocationHistory(selectedEquipmentRelocation as EquipmentRelocationRequestEntity);
          }}
        >История перемещений</MenuItem>
      </Menu>

      <Outlet />
    </>
  );
});

export default RelocateEquipmentsTable;
