import React, { MouseEvent, useRef, useState } from "react";
import { IconButton, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { ImageStoreEntity } from "graphql/graphQlApiHooks";
import { TooltipIcon } from "../styled";
import Settings from "store/settings";
import Iconify from "components/Iconify";
import { BootstrapTooltip } from "../../../../../../../../components/BootstrapTooltip";

interface Props {
  isMainPhoto: boolean;
  isEditable?: boolean;
  data: ImageStoreEntity;
  onFullScreen: (e: MouseEvent) => void;
  onSetMainPhoto: (photo: ImageStoreEntity) => void;
  onDelete: (id: string) => void;
}

export const MoreButton = (props: Props) => {
  const {
    onSetMainPhoto,
    data,
    onFullScreen,
    onDelete,
    isMainPhoto,
    isEditable
  } = props;

  const anchorEl = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <BootstrapTooltip title={"Действия"}>
        <TooltipIcon
          ref={anchorEl}
          className={"more-button"}
        >
          <IconButton
            size={"small"}
            onClick={() => {
              setIsOpen(true);
            }}
            sx={{
              fontSize: `13px !important`
            }}
          >
            <Iconify fontSize={"inherit"} icon={"akar-icons:more-vertical"} />
          </IconButton>
        </TooltipIcon>
      </BootstrapTooltip>

      <Menu
        open={isOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { p: 0 }
        }}
        MenuListProps={{
          dense: true,
          disablePadding: true
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        disablePortal
      >

        <MenuItem
          sx={{ color: "text.secondary" }}
          dense
          onClick={(e) => {
            onFullScreen(e as MouseEvent);
            setIsOpen(false);
          }}
        >
          <ListItemText primary="На весь экран" primaryTypographyProps={{ variant: "body2" }} />
        </MenuItem>

        <MenuItem
          sx={{ color: "text.secondary" }}
          dense
          disabled={isMainPhoto || !isEditable}
          onClick={() => {
            onSetMainPhoto(data as ImageStoreEntity);
            setIsOpen(false);
          }}
        >
          <ListItemText
            primary={isMainPhoto ? "Основное фото" : "Сделать основным"}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          sx={{ color: "text.secondary" }}
          dense
          component={"a"}
          href={Settings.server + data.url}
        >
          <ListItemText primary="Скачать" primaryTypographyProps={{ variant: "body2" }} />
        </MenuItem>


        <MenuItem
          onClick={() => {
            onDelete(data?.id as string);
            setIsOpen(false);
          }}
          sx={{ color: "text.secondary" }}
          dense
          disabled={!isEditable}
        >
          <ListItemText primary="Удалить" primaryTypographyProps={{ variant: "body2" }} />
        </MenuItem>

      </Menu>
    </>
  );
};
