// @ts-ignore
import {MouseEvent} from 'React';
import {observer} from 'mobx-react-lite';
import {
	Box,
	IconButton,
	List,
	listClasses,
	ListSubheader,
	SvgIconProps,
	TextField, Tooltip,
	Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {
	SportCategoryDictionaryEntity,
	SportKindDictionaryEntity,
} from '../../../../../graphql/graphQlApiHooks';
import {useStores} from '../../../../../store';
import React, {ElementType, SyntheticEvent, useEffect, useState} from 'react';
import {TreeItem, treeItemClasses, TreeItemProps, TreeView} from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Tooltiper from 'components/Tooltiper';
import {styled} from '@mui/styles';
import AddSportCategoryDialog from '../Dialogs/AddSportCategoryDialog';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Menu} from '@mui/material';
import {MenuItem} from '@mui/material';
import EditSportKind from '../Dialogs/EditSportKind';
import DeleteSportKind from '../Dialogs/DeleteSportKind';
import EditSportCategoryDialog from '../Dialogs/EditSportCategoryDialog';
import DeleteSportCategory from '../Dialogs/DeleteSportCategory';
import AddCategoryName from '../Dialogs/AddCategoryName';
import {useNavigate} from "react-router-dom";
import { BootstrapTooltip } from "../../../../../components/BootstrapTooltip";

declare module 'react' {
	interface CSSProperties {
		'--tree-view-color'?: string;
		'--tree-view-bg-color'?: string;
	}
}

type StyledTreeItemProps = TreeItemProps & {
	bgColor?: string;
	color?: string;
	labelIcon?: ElementType<SvgIconProps>;
	labelInfo?: string;
	labelText: string;
	selected?: boolean;
	valueId: string;
	dictionaryType: 'category' | 'sportKind';
};

const StyledTreeItemRoot = styled(TreeItem)(({theme}) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		paddingRight: theme.spacing(1),
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		fontWeight: theme.typography.fontWeightMedium,
		'&.Mui-expanded': {
			fontWeight: theme.typography.fontWeightRegular,
		},
		'&:hover': {
			backgroundColor: theme.palette.action.hover,
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.background.default})`,
			color: 'var(--tree-view-color)',
		},
		'&.active, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.background.default})`,
			color: 'var(--tree-view-color)',
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'inherit',
			color: 'inherit',
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

const StyledList = styled(List)(({theme}) => ({
	[`&.${listClasses.root}`]: {
		color: theme.palette.text.secondary,
		maxWidth: 360,
		borderRadius: theme.shape.borderRadius,
		height: '100%',
		background: 'white',
		padding: 8,
		'& .MuiTreeView-root': {
			height: 'calc(100% - 100px)',
			overflowY: 'auto',
		},
	},
}));

const StyledTreeItem = observer((props: StyledTreeItemProps) => {
	const {
		bgColor,
		color,
		labelIcon: LabelIcon,
		labelInfo,
		labelText,
		selected,
		valueId,
		dictionaryType,
		...other
	} = props;
	const navigate = useNavigate();

	const {dictionariesStore} = useStores();
	const [anchorElMoreAction, setAnchorElMoreAction] =
			useState<null | HTMLElement>(null);

	const [editSportKind, setEditSportKind] =
			useState<SportKindDictionaryEntity | null>(null);
	const [anchorEditSportKind, setAnchorEditSportKind] =
			useState<null | HTMLElement>(null);

	const [editSportCategory, setEditSportCategory] =
			useState<SportCategoryDictionaryEntity | null>(null);
	const [editSporCategoryDialog, setEditSportCategoryDialog] =
			useState<boolean>(false);

	const [deleteSportKindDialog, setDeleteSportKindDialog] =
			useState<boolean>(false);
	const [deleteSportCategoryDialog, setDeleteSportCategoryDialog] =
			useState<boolean>(false);

	const handleClickMoreAreas = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorElMoreAction(event.currentTarget);
	};

	const handleCloseMoreAreas = () => {
		setAnchorElMoreAction(null);
	};

	const editHandle = (event: any, anchor: HTMLElement | null) => {
		const category = anchor?.getAttribute('aria-label');
		const valueId = anchor?.getAttribute('aria-describedby');

		if (category === 'sportKind') {
			const activeDictionary =
					dictionariesStore.sportKindDictionary.find(
							(item: SportKindDictionaryEntity) => item.id === valueId
					) || null;

			setEditSportKind(activeDictionary);
			setAnchorElMoreAction(null);
			setAnchorEditSportKind(event.currentTarget);
		}

		if (category === 'category') {
			const activeDictionary =
					dictionariesStore.sportCategoryDictionary.find(
							(item: SportCategoryDictionaryEntity) => item.id === valueId
					) || null;

			navigate(`editSportCategory/${valueId}`)
			setEditSportCategory(activeDictionary);
			setAnchorElMoreAction(null);
			setEditSportCategoryDialog(true);
		}
	};

	const deleteHandle = (event: any, anchor: HTMLElement | null) => {
		const category = anchor?.getAttribute('aria-label');
		const valueId = anchor?.getAttribute('aria-describedby');

		if (category === 'sportKind') {
			const activeDictionary =
					dictionariesStore.sportKindDictionary.find(
							(item: SportKindDictionaryEntity) => item.id === valueId
					) || null;
			setDeleteSportKindDialog(true);
			setEditSportKind(activeDictionary);
		}

		if (category === 'category') {
			const activeDictionary =
					dictionariesStore.sportCategoryDictionary.find(
							(item: SportCategoryDictionaryEntity) => item.id === valueId
					) || null;
			setDeleteSportCategoryDialog(true);
			setEditSportCategory(activeDictionary);
		}
	};

	const handleCloseEditSportKind = () => {
		setAnchorEditSportKind(null);
	};

	const handleCloseDeleteSportKind = () => {
		setDeleteSportKindDialog(false);
	};

	const handleConfirmDeleteSportKind = () => {
		setDeleteSportKindDialog(false);
	};

	const handleCloseEditSportCategory = () => {
		setEditSportCategoryDialog(false);
	};

	const handleCloseDeleteSportCategory = () => {
		setDeleteSportCategoryDialog(false);
	};

	const handleConfirmDeleteSportCategory = () => {
		setDeleteSportCategoryDialog(false);
	};

	return (
			<>
				<StyledTreeItemRoot
						label={
							<Box sx={{display: 'flex', alignItems: 'center', p: 0.5, pr: 0}}>
								{LabelIcon && (
										<Box component={LabelIcon} color='inherit' sx={{mr: 1}}/>
								)}
								<Tooltiper
										text={labelText}
										props={{
											variant: 'body2',
											sx: {
												fontWeight: 'inherit',
												flexGrow: 1,
												width: '100%',
											},
										}}
								/>
								<Typography variant='caption' color='inherit'>
									{labelInfo}
								</Typography>
								<Box>
									<IconButton
											aria-haspopup='true'
											aria-label={dictionaryType}
											aria-describedby={valueId}
											size='small'
											onClick={handleClickMoreAreas}
									>
										<MoreHorizIcon fontSize='inherit'/>
									</IconButton>
								</Box>
							</Box>
						}
						style={{
							'--tree-view-color': color,
							'--tree-view-bg-color': bgColor,
						}}
						ContentProps={{
							className: selected !== undefined && selected ? 'active' : '',
						}}
						{...other}
				/>
				<Menu
						id='dictionaries-more-action'
						anchorEl={anchorElMoreAction}
						keepMounted
						open={Boolean(anchorElMoreAction)}
						onClose={handleCloseMoreAreas}
						// className={classes.sportZoneMoreAction}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
				>
					<MenuItem
							onClick={(event: any) => editHandle(event, anchorElMoreAction)}
					>
						Изменить
					</MenuItem>
					<MenuItem
							onClick={(event: any) => deleteHandle(event, anchorElMoreAction)}
					>
						Удалить
					</MenuItem>
				</Menu>

				<EditSportKind
						anchorEl={anchorEditSportKind}
						onClose={handleCloseEditSportKind}
						sportKind={editSportKind}
				/>
				<DeleteSportKind
						open={deleteSportKindDialog}
						onClose={handleCloseDeleteSportKind}
						onConfirm={handleConfirmDeleteSportKind}
						sportKind={editSportKind}
				/>

				{/*<EditSportCategoryDialog*/}
				{/*	open={editSporCategoryDialog}*/}
				{/*	onClose={handleCloseEditSportCategory}*/}
				{/*	sportCategory={editSportCategory}*/}
				{/*/>*/}

				<DeleteSportCategory
						open={deleteSportCategoryDialog}
						onClose={handleCloseDeleteSportCategory}
						onConfirm={handleConfirmDeleteSportCategory}
						sportCategory={editSportCategory}
				/>
			</>
	);
});

const DictionariesMenu = observer(() => {
	const {dictionariesStore} = useStores();
	const [sportKindDictionary, setSportKindDictionary] = useState<SportKindDictionaryEntity[]>([]);
	const [sportKindDictionarySearch, setSportKindDictionarySearch] = useState<string>('');
	const [sportCategoryDictionary, setSportCategoryDictionary] = useState<SportCategoryDictionaryEntity[]>([]);
	const [addSportCategoryDialog, setAddSportCategoryDialog] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

	const [openAddSportCategory, setOpenAddSportCategory] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	useEffect(() => {
		setSportKindDictionary(
				[...dictionariesStore?.sportKindDictionary]
						.filter((sportKind: SportKindDictionaryEntity) =>
								sportKind.name
										.toLowerCase()
										.includes(sportKindDictionarySearch.toLowerCase())
						)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dictionariesStore?.sportKindDictionary, sportKindDictionarySearch]);

	useEffect(() => {
		let categories = [...dictionariesStore?.sportCategoryDictionary]
				.map((sportCategory: SportCategoryDictionaryEntity) => ({
							...sportCategory,
							sportKinds: sportCategory.sportKinds
									.filter((sportKind: SportKindDictionaryEntity) =>
											sportKind.name.toLowerCase().includes(sportKindDictionarySearch.toLowerCase())
									)
						})
				)
				.sort((a: any, b: any) => {
					if (a.name === 'Без категории') return -1
					return 1
				})

		// if (sportKindDictionarySearch !== ''){
			// categories.filter()
		// }


		setSportCategoryDictionary(categories)


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dictionariesStore?.sportCategoryDictionary, sportKindDictionarySearch]);

	const [expanded, setExpanded] = useState<string[]>([]);
	const [selected, setSelected] = useState<string[]>([]);

	const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	const handleSelect = (event: SyntheticEvent, nodeIds: string[]) => {
		setSelected(nodeIds);
	};

	function handleClickAddSportCategoryDialog() {
		setAddSportCategoryDialog(true);
	}

	const handleClickAddSportCategoryPanel = (
			event: MouseEvent<HTMLButtonElement>
	) => {
		setAnchorEl(event.currentTarget);
		setOpenAddSportCategory(true);
	};

	function handleCloseAddSportCategoryPanel() {
		setAnchorEl(null);
		setOpenAddSportCategory(false);
	}

	function handleCloseAddSportCategoryDialog() {
		setAddSportCategoryDialog(false);
	}

	const handleListItemClick = (sportKind: SportKindDictionaryEntity) => {
		setSelected([sportKind.id]);
		dictionariesStore.setActiveSportKind(sportKind.id);
	};

	useEffect(() => {
		if (dictionariesStore?.activeSportKind?.id) {
			setSelected([dictionariesStore.activeSportKind.id]);

			const activeSportCategory =
					dictionariesStore.sportCategoryDictionary?.find(
							(item: SportCategoryDictionaryEntity) =>
									item.sportKinds
											.map((sportKind: SportKindDictionaryEntity) => sportKind.id)
											.includes(dictionariesStore.activeSportKind.id)
					);

			if (activeSportCategory?.id) {
				setExpanded([activeSportCategory.id]);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sportKindDictionary]);

	return (
			<Box
					sx={{
						flexGrow: 1,
						padding: '1.25rem 0 1.25rem 1.25rem',
						height: '100%',
						'& .MuiListSubheader-root': {
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
						},
					}}
			>
				<StyledList
						aria-labelledby='dictionaries'
						subheader={
							<ListSubheader id='nested-list-subheader' disableSticky disableGutters sx={{px: 1}}>
								Категории спорта
								<BootstrapTooltip title={'Добавить новую категория спорта'}>
									<IconButton
											aria-controls='customized-menu'
											aria-haspopup='true'
											size='small'
											onClick={handleClickAddSportCategoryPanel}
									>
										<AddIcon fontSize='inherit'/>
									</IconButton>
								</BootstrapTooltip>
								<AddCategoryName
										anchorEl={anchorEl}
										open={openAddSportCategory}
										onClose={handleCloseAddSportCategoryPanel}
								/>
							</ListSubheader>
						}
				>
					<TextField
							fullWidth={true}
							label='Поиск'
							size={'small'}
							type={'search'}
							sx={{mb: 2}}
							placeholder={'Вид спорта'}

							value={sportKindDictionarySearch}
							onChange={({target: {value}}) => setSportKindDictionarySearch(value)}
					/>

					<TreeView
							aria-label='controlled'
							defaultCollapseIcon={<ExpandMoreIcon/>}
							defaultExpandIcon={<ChevronRightIcon/>}
							expanded={expanded}
							selected={selected}
							onNodeToggle={handleToggle}
							onNodeSelect={handleSelect}
							multiSelect
					>
						{sportCategoryDictionary.map(category => (
								<StyledTreeItem
										nodeId={category.id}
										key={category.id}
										labelText={category.name}
										dictionaryType={'category'}
										valueId={category.id}
										selected={selected?.[0] === category.id}
								>
									{category.sportKinds.map(sportKind => (
											<StyledTreeItem
													key={sportKind.id}
													nodeId={sportKind.id}
													labelText={sportKind.name}
													labelInfo={sportKind?.equipmentTypes?.length.toString()}
													selected={selected?.[0] === sportKind.id}
													dictionaryType={'sportKind'}
													valueId={sportKind.id}
													onClick={() => handleListItemClick(sportKind)}
											/>
									))}
								</StyledTreeItem>
						))}
					</TreeView>
				</StyledList>
				<AddSportCategoryDialog
						open={addSportCategoryDialog}
						onClose={handleCloseAddSportCategoryDialog}
				/>
			</Box>
	);
});

export default DictionariesMenu;
