import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {
	Box,
	Button, DialogContentText,
	TextField,
} from "@mui/material";
import {EquipmentRelocationRequestEntity, StartEquipmentRelocationInput,} from "../../../../graphql/graphQlApiHooks";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";
import {useStores} from "../../../../store";

interface Props {
	data?: EquipmentRelocationRequestEntity | null
	open: boolean
	onClose: () => void
}

const RelocateConfirmation = observer((props: Props) => {
	const {data, open, onClose} = props;
	const {equipmentRelocationRequestStore} = useStores();
	const [comment, setComment] = useState<string>('');

	const handleConfirm = () => {
		const input: StartEquipmentRelocationInput = {
			relocateRequestId: data?.id as string,
			comments: comment,
		}

		equipmentRelocationRequestStore.startEquipmentRelocation(input).then(() => {
			handleClose()
		}).catch((error: any) => {
			console.error(error.message)
		})

	};

	function handleClose() {
		setComment('')
		onClose()
	}

	return (

			<Dialog
					open={open}
					onClose={handleClose}
					title={'Подтверждение отправления оборудования'}
					titles={[
						<EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt}/>,
						<StepperRelocation targetSportObject={data?.targetSportObject}
						                   sourceSportObject={data?.sourceSportObject}/>
					]}
					actions={<>
						<Button
								variant={'contained'}
								onClick={handleConfirm}
								color={'primary'}
						>Подтвердить</Button>
					</>}
			>
				<DialogContentText variant={'h4'}>
					Вы подтверждаете, что оборудование было отправлено?
				</DialogContentText>
				<Box id="alert-dialog-RejectedComment" mt={2}>
					<TextField
							autoFocus
							margin="dense"
							id="RejectedComment"
							type="text"
							fullWidth
							multiline={true}
							rows={5}
							placeholder={'Укажите комментарий к отправлению'}
							value={comment}
							onChange={({target: {value}}) => setComment(value)}
							size={'small'}
					/>
				</Box>

			</Dialog>
	);
})

export default RelocateConfirmation;
