import React from "react";
import Tabs from "./Tabs";
import { SportEquipmentTab } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentTabActive";
import { useReactiveVar } from "@apollo/client";
import {
  rentSportEquipmentTabActiveVars,
  useRentSportEquipmentTabActiveVars
} from "../../../../store/rent-sport-equpments/rentSportEquipmentTabActive";


export default () => {
  const tabActive = useReactiveVar(rentSportEquipmentTabActiveVars);
  const { setTabActive } = useRentSportEquipmentTabActiveVars();

  if (!tabActive) return null;

  return (
    <>
      <Tabs
        tabActive={tabActive as SportEquipmentTab}
        actions={{
          setTabActive
        }}
      />
    </>
  );
};