import { Components, Theme } from "@mui/material";
// ----------------------------------------------------------------------
import MuiButton from "./MuiButton";
import MuiFilledInput from "./MuiFilledInput";
import MuiDataGrid from "./MuiDataGrid";
import MuiTab from "./MuiTab";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    MuiTab(theme),
    MuiDataGrid(theme),
    MuiButton(theme),
    MuiFilledInput(theme),
  ) as Components<Theme>;
}
