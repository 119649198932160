import React from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import {
  sportEquipmentListFiltersVars,
  useSportEquipmentListFiltersVars
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListFilters";

interface Props {
  title: string;
}

export default ({ title }: Props) => {
  const navigate = useNavigate();

  const filters = useReactiveVar(sportEquipmentListFiltersVars);
  const { setFilters } = useSportEquipmentListFiltersVars();

  const openAddSportEquipmentDialog = () => {
    navigate("add");
  };

  return <Header
    title={title}
    searchInputPlaceholder={"Название обрудования"}
    addButtonName={"Добавить оборудование"}

    actions={{
      searchSportEquipmentByName: (text) => setFilters({ ...filters, name: text }),
      openAddSportEquipmentDialog
    }}
  />;
}

