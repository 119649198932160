import React, { useMemo } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";

import { Box, Divider, Stack, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { SportEquipmentEntity } from "graphql/graphQlApiHooks";

const useStylesUploadForm = makeStyles((theme: Theme) => ({
    placementWrapper: {
        padding: "1em",
        backgroundColor: 'rgb(243,246,250)',
        borderRadius: "6px",
        marginBottom: "1em",
    }
}))

const Form = observer((props:{equipment:SportEquipmentEntity | null}) => {
    const {equipment} = props;
    const classesForm = useStylesUploadForm();

    const commissioningDate = useMemo(() => {
        return (!isNaN(Number(equipment?.commissioningDate))) ? dayjs(Number(equipment?.commissioningDate)).format('DD MMM YYYY, HH:MM') : dayjs(equipment?.commissioningDate).format('DD MMM YYYY, HH:MM')
    }, [equipment]);

    return (
        <>
        <Stack direction={'column'} spacing={1} className={classesForm.placementWrapper}>
            <Box>
                <Typography variant={'caption'}>Спортобъект</Typography>
                <Typography variant={'h5'}>{equipment?.sportObject?.name}</Typography>
            </Box>
            <Box>
                <Typography variant={'caption'}>Объект спорта</Typography>
                <Typography variant={'h5'}>{equipment?.sportZone?.name}</Typography>
            </Box>
            <Box>
                <Typography variant={'caption'}>Спортзона</Typography>
                <Typography variant={'h5'}>{equipment?.sportSubZone?.name}</Typography>
            </Box>
            
        </Stack>

<Stack direction={'column'} spacing={1.5} px={2}>
    <Box>
        <Typography variant={'caption'}>Дата ввода в эксплуатацию</Typography>
        <Typography variant={'h5'}>{commissioningDate}</Typography>
    </Box>

    <Box>
        <Typography variant={'caption'}>Техническое состояние</Typography>
        <Typography variant={'h5'}>{(equipment?.technicalCondition === 'WORKING') ? 'Исправно' : 'Неисправно'}</Typography>
    </Box>
    <Box>
        <Typography variant={'caption'}>Используется</Typography>
        <Typography variant={'h5'}>{equipment?.inUse ? 'Да' : 'Нет'}</Typography>
    </Box>
    <Divider/>
    <Box>
        <Typography variant={'caption'}>Ктегория спорта</Typography>
        <Typography variant={'h5'}>{equipment?.sportCategoryObject?.name || ''}</Typography>
    </Box>
    <Box>
        <Typography variant={'caption'}>Вид спорта</Typography>
        <Typography variant={'h5'}>{equipment?.sportKindObject?.name || ''}</Typography>
    </Box>
    <Box>
        <Typography variant={'caption'}>Тип оборудования</Typography>
        <Typography variant={'h5'}>{equipment?.equipmentTypeObject?.name || ''}</Typography>
    </Box>
</Stack>


        </>
    )
})

export default Form;
