import React from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Hidden, ListItem, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";

import withStyles from "@mui/styles/withStyles";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    boxShadow: theme.shadows[12],
    fontSize: 12,
    fontWeight: 400,
    backdropFilter: "saturate(180%) blur(20px)"
  }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    justifyContent: "center",
    "&:not(:first-child)": {
      "& $button": {
        marginTop: "1rem"
      }
    }

  },
  button: {
    // color: theme.palette.text.secondary,
    width: "3rem",
    height: "2.5rem",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderRadius: "0.375rem",
    background: "transparent",
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: 0,
    padding: 0,
    textTransform: "none",
    minWidth: "auto",
    maxWidth: 72,
    color: "#2b2f39",
    "&:hover": {
      color: "rgb(72, 81, 249)"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      maxWidth: "initial",
      justifyContent: "left",
      padding: "0 1rem",
      "& .s-icon, .MuiSvgIcon-root": {
        marginRight: ".5rem"
      }
    }
  },
  "$ .s-icon, .MuiSvgIcon-root": {
    textShadow: theme.shadows[24]
  },
  title: {
    marginRight: "auto",
    marginLeft: ".5rem"
  },
  active: {
    boxShadow: theme.shadows[0],
    background: "rgba(219, 229, 254, 1)",
    "&:hover": {
      background: "rgba(219, 229, 254, 0.8)"
    },
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium
    },
    "& .s-icon, .button-text, .MuiSvgIcon-root": {
      color: "rgb(72, 81, 249)",
      textShadow: "-4px 6px 10px rgba(72, 81, 249, .6)"
    }
  }
}));

interface NavItemProps {
  [key: string]: any;
}

const NavItem = ({ className, href, icon, title, clickHandler, ...rest }: NavItemProps) => {
  const classes = useStyles();
  const location = useLocation();
  let active = "";
  if (href) {
    active = href.split("/")[1] === location.pathname.split("/")[1] ? classes.active : "";
  }

  function onButtonClick() {
    if (!href) {
      clickHandler();
    }
  }

  return (
    <ListItem
      className={clsx(classes.item, className)}
      disableGutters {...rest} >

      <Hidden mdDown>
        <LightTooltip
          arrow
          title={title}
          placement='right'
        >
          <Button
            className={clsx(classes.button, active)}
            component={href && RouterLink}
            to={href}
          >
            <span className={`s-icon icon-${icon}`} />
          </Button>
        </LightTooltip>
      </Hidden>
      <Hidden mdUp>
        <Button
          className={clsx(classes.button, active)}
          component={href && RouterLink}
          to={href}
          onClick={onButtonClick}
        >

          {typeof icon == "string" ?
            (<span className={`s-icon icon-${icon}`} />)
            :
            (icon)
          }

          <span className={"button-text"}>{title}</span>
        </Button>
      </Hidden>
    </ListItem>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  title: PropTypes.string
};

export default NavItem;
