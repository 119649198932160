import React, {useEffect, useRef, useState} from 'react';
import {observer} from "mobx-react-lite";
import {
	Box, Card, CardContent, CardHeader,
	Chip, ClickAwayListener, IconButton,
	InputBase, ListItem, ListItemButton, ListItemText,
	Popper,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {styled} from '@mui/material/styles';

import CloseIcon from '@mui/icons-material/Close';
import {useTheme} from "@mui/styles";

const Root = styled('div')`
  width: 100%
`;

const Label = styled('label')`
`;

const InputWrapper = styled('div')(() => `
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & input {
    box-sizing: border-box;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    margin: 0;
    outline: 0;
  }
`,
);

const ListBox = styled('ul')(({theme}) => `
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: auto;
  max-height: 350px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgb(63 63 68 / 5%), 0 10px 20px -5px rgb(0 0 0 / 40%);
  z-index: 1;

 
`,
);

const FilterChip = observer((props: any) => {

	const theme = useTheme();
	const chipRef = useRef(null);
	const ref = useRef(null);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [hideOnLoad, setHideOnLoad] = useState(false);

	useEffect(() => {
		const filterValue = props.store.filters[props.name]
		const isNullArray = (Array.isArray(filterValue) && filterValue.filter((item: any) => item === null).length === 2)
		const condition = (filterValue && !isNullArray);
		if (condition || props?.removable === false) {
			setHideOnLoad(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.removable])

	useEffect(() => {
		if (chipRef?.current) {
			setAnchorEl(chipRef?.current)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chipRef?.current]);


	function handleClickAway() {
		props.handleCanceled()
		setAnchorEl(null)
	}


	function handleClick(e: React.MouseEvent<HTMLDivElement>) {
		hideOnLoad && setHideOnLoad(false)
		setAnchorEl(chipRef.current)
	}

	useEffect(() => {
		const clearFilterListener = (e: any) => {
			if (e.key === "Escape" && anchorEl && !hideOnLoad) {
				props?.clearFilter() && props.clearFilter()
				handleClickAway()
			}
		}
		const canceledListener = (e: any) => {
			if (e.key === "Enter" && anchorEl && !hideOnLoad) {
				handleClickAway()
			}
		}

		window.addEventListener('keydown', clearFilterListener);
		window.addEventListener('keypress', canceledListener);

		return () => {
			window.removeEventListener('keydown', clearFilterListener);
			window.removeEventListener('keypress', canceledListener);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchorEl]);

	return (
			<>
				<Chip
						label={<>{props.title}<span style={{fontWeight: 'bold'}}>{props?.chipDescription()}</span></>}

						onDelete={props.onDelete}
						clickable={true}
						ref={chipRef}
						onClick={(e: React.MouseEvent<HTMLDivElement>) => handleClick(e)}
						sx={{mx: .5, backgroundColor: theme.palette.background.default}}

				/>

				{anchorEl && !hideOnLoad &&
                    <ClickAwayListener onClickAway={handleClickAway}>

                        <Card
                            sx={{
								top: 0,
								left: anchorEl.offsetLeft + 'px',
								position: 'absolute',
								zIndex: 10,
								backgroundColor: '#f6f8fa',
	                            maxWidth: '312px',
	                            minWidth: '112px',
							}}
                            ref={ref}
                        >
                            <CardHeader
                                title={props.title}
                                sx={{
									py: 1,
									borderBottom: '1px solid #e6e6e6',
									'& .MuiCardHeader-title': {fontSize: '15px'}
								}}
                                action={<IconButton
										size={'small'}
										aria-label="close"
										onClick={handleClickAway}
								>
									<CloseIcon fontSize={'small'}/>
								</IconButton>}
                            />
                            <CardContent sx={{p: 0, '&.MuiCardContent-root:last-child': {pb: 0}}}>
								{props.filterComponent()}
                            </CardContent>
                        </Card>

                    </ClickAwayListener>
				}
			</>
	)
});

export interface FiltersType {
	name: string
	title: string
	removable?: boolean
	filterComponent: ({props}: any) => JSX.Element
	chipDescription: () => string
	clearFilter?: () => void
}

const FiltersPanel = observer((props: { store: any, filters: FiltersType[], selected: FiltersType[] }) => {
	const {store, filters, selected} = props;

	const id = 'filtersPanel';
	const [inputAnchorEl, setInputAnchorEl] = React.useState<HTMLElement | null>(null);
	const [filterValue, setFilterValue] = useState<FiltersType[]>([]);
	const [searchFilterValue, setSearchFilterValue] = useState<FiltersType[]>([]);
	const [inputValue, setInputValue] = useState('');
	const [filterSelected, setFilterSelected] = useState<FiltersType[]>([]);

	useEffect(() => {
		setFilterSelected(selected)
	}, [selected]);

	useEffect(() => {
		setFilterValue(filters as FiltersType[])
	}, [filters]);

	useEffect(() => {
		setSearchFilterValue(filters as FiltersType[])
	}, [filters]);

	const handleBlur = () => setInputAnchorEl(null);

	const handleFocus = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => setInputAnchorEl(e.currentTarget);

	const handleInputChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (!inputAnchorEl) setInputAnchorEl(e.currentTarget);

		const searchText = e.currentTarget.value
		setInputValue(searchText)
		setSearchFilterValue([...filterValue.filter(val => val.title.toLowerCase().includes(searchText.toLowerCase()))] || filterValue)
	};

	const handleOptionClick = (e: React.MouseEvent, newFilterValues: FiltersType[], filterSelectedValue: FiltersType) => {
		// setFilterValue(newFilterValues)
		setFilterSelected(Array.from(new Set([...filterSelected, filterSelectedValue].flat())))

		setInputAnchorEl(null);
	};

	const handleTagDelete = (option: FiltersType, index: number) => {
		if (option.name === 'commissioningDate') {
			store.setFilters({[option.name]: [null, null]})
		} else {
			store.setFilters({[option.name]: null})
		}

		if (option.name === 'equipmentTypeIds' && filterSelected.map(filter => filter?.name).includes('sportKindIds') ) return


		setFilterValue(Array.from(new Set([...filterValue, option].flat())))
		setFilterSelected([...filterSelected].filter((item) => item?.name !== option?.name))

	};

	return (
			<Root>
				<Box sx={{display: 'flex', alignItems: 'center', width: '100%', position: 'relative'}}>
					<Label
							htmlFor={id}
							id={`${id}-label`}
					><FilterListIcon sx={{mr: 2}}/></Label>

					<InputWrapper>
						{filterSelected.map((option: FiltersType, index: number) => (
								option && <FilterChip
										handleCanceled={() => {
											const filterValue = store.filters[option.name];
											if (option?.removable !== false) {
												const isNullArray = (Array.isArray(filterValue) && filterValue.filter((item: any) => item === null).length === 2)
												if (filterValue?.length === 0  || filterValue === null || isNullArray) handleTagDelete(option, index)
											}
										}}
										{...option}
										{...{
											key: index,
											'data-tag-index': index,
											tabIndex: -1,
											onDelete: (option?.removable !== false) ? () => handleTagDelete(option, index) : undefined
										}}
										store={store}
								/>)
						)}
						<InputBase
								sx={{ml: 1, flex: 1}}
								placeholder="Фильтры"
								inputProps={{
									id,
									value: inputValue,
									onBlur: handleBlur,
									onFocus: (e) => handleFocus(e),
									onChange: (e) => handleInputChange(e),
									autoComplete: 'off',
									autoCapitalize: 'none',
									spellCheck: 'false'
								}}
						/>
					</InputWrapper>
				</Box>

				<Popper
						id={`${id}--list-filters`}
						open={Boolean(inputAnchorEl)}
						anchorEl={inputAnchorEl}
						placement="bottom-start"
				>
					<ListBox {...{
						role: 'listBox',
						id: `${id}-listBox`,
						'aria-labelledby': `${id}-label`,
						onMouseDown: e => e.preventDefault()
					}}  >
						{filterValue
								.sort((a, b) => {
									if (a.title === 'Вид спорта') return -1;
									if (b.title === 'Вид спорта') return 1;
									if (a.title === 'Тип оборудования') return -1;
									if (b.title === 'Тип оборудования') return 1;
									return (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1
								})
								.map((option, index) => {
									if (filterSelected.filter((search) => option?.name === search?.name).length > 0) return null;
									return searchFilterValue.find((search) => option?.name === search?.name) &&
                                        <ListItem
                                            key={index}
                                            tabIndex={-1}
                                            id={`${id}-option-${index}`}
                                            data-option-index={index}
                                            disablePadding
                                            dense
                                        >
                                            <ListItemButton
                                                onClick={(e:any) => handleOptionClick(e, [...filterValue].filter((item: any) => item.name !== filterValue[index].name), filterValue[index])}
                                                dense
                                                role={'option'}
                                                component={'div'}
                                            >
                                                <ListItemText primary={option.title} primaryTypographyProps={{
													fontWeight: 'normal !important'
                                                }} />
                                            </ListItemButton>
                                        </ListItem>
								})}
					</ListBox>
				</Popper>
			</Root>
	)
})

export default FiltersPanel;


