import {
    CreateEquipmentServicePeriodDocument,
    CreateEquipmentServicePeriodInput,
    DeleteEquipmentServicePeriodDocument,
    EquipmentServiceJobEntity,
    EquipmentServicePeriodEntity,
    GetEquipmentServiceJobsDocument,
    GetEquipmentServicePeriodsDocument,
    UpdateEquipmentServicePeriodDocument,
    UpdateEquipmentServicePeriodInput,
} from '../../graphql/graphQlApiHooks';

import {makeAutoObservable, runInAction} from "mobx";
import RootStore from "../.";

import {MobxApolloQuery} from 'mobx-apollo';
import {sortByCreateAt} from '../../helpers';
import {client} from "../.";


export interface IServiceJobsStore {
    allServiceJobs: MobxApolloQuery<unknown>
    error: string
    loading: boolean
    serviceJobs: EquipmentServiceJobEntity[]
    count: number
}

const servicePeriodStore = class {
    public rootStore: RootStore;
    public servicePeriods: EquipmentServicePeriodEntity[] = [];
    public loading: boolean = true
    public error?: any
    public count: number = 0
    public equipmentId?: string

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this)
    }

    getEquipmentServicePeriods(equipmentId: string) {
        runInAction(() => {
            this.equipmentId = equipmentId
            this.servicePeriods = []
            this.loading = true
        })

        client.query({
            query: GetEquipmentServicePeriodsDocument,
            variables: {id: equipmentId},
        }).then((result: any) => {
            runInAction(() => {
                this.servicePeriods = result.data.getEquipmentServicePeriods.slice().sort(sortByCreateAt);
                this.count = this.servicePeriods.length;
                this.loading = false;
            })
        }).catch((error) => {
            runInAction(() => {
                this.loading = false
                this.count = 0
                this.error = error
            })
        })
    }

    create = (input: CreateEquipmentServicePeriodInput) => client.mutate({
        mutation: CreateEquipmentServicePeriodDocument,
        variables: {input},
        refetchQueries: [
            {query: GetEquipmentServicePeriodsDocument, variables: {id: this.equipmentId}},
            { query: GetEquipmentServiceJobsDocument, variables: { sportObjectId: this.rootStore.sportObjectsStore.current.id } }
        ]
    })
        .then(() => this.rootStore?.setSnakeMessage('Обслуживание оборудования создано.'))
        .catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

    update = (input: UpdateEquipmentServicePeriodInput) => client.mutate({
        mutation: UpdateEquipmentServicePeriodDocument,
        variables: {input},
        refetchQueries: [
            {query: GetEquipmentServicePeriodsDocument, variables: {id: input.id}},
            { query: GetEquipmentServiceJobsDocument, variables: { sportObjectId: this.rootStore.sportObjectsStore.current.id } }
        ]
    })
        .then(() => this.rootStore?.setSnakeMessage('Обслуживание оборудования обновлено.'))
        .catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

    delete = (id: string) => client.mutate({
        mutation: DeleteEquipmentServicePeriodDocument,
        variables: {id},
        refetchQueries: [
            {query: GetEquipmentServicePeriodsDocument, variables: {id: this.equipmentId}},
            { query: GetEquipmentServiceJobsDocument, variables: { sportObjectId: this.rootStore.sportObjectsStore.current.id } }
        ]
    })
        .then(() => this.rootStore?.setSnakeMessage('Обслуживание оборудования удалено.'))
        .catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

}

export default servicePeriodStore;
