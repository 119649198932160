import React, { useState } from "react";
import { IconButton, InputBase, Paper, Stack } from "@mui/material";
import Iconify from "components/Iconify";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  searchSportEquipmentByName: (searchText: string) => void;

  searchText?: string;
  placeholder?: string;
}

export default (props: Props) => {
  const { searchSportEquipmentByName, searchText, placeholder = "Название оборудования" } = props;

  const [state, setState] = useState({
    searchText: searchText || "",
    isFocused: false
  });

  const handleSubmit = (e: any) => {
    const searchText = e.target.value.trim();

    if (e.which === 13) {
      searchSportEquipmentByName(searchText);
    }

    if (e.which === 27) {
      setState((prev) => ({ ...prev, searchText: "" }));
    }
  };

  const handleChange = (e: any) => {
    setState(prev => ({ ...prev, searchText: e.target.value }));
  };

  const handleBlur = (e: any) => {
    const searchText = e.target.value.trim();
    searchSportEquipmentByName(searchText);

    setState(prev => ({ ...prev, isFocused: false }));
  };

  const handleFocus = () => {
    setState(prev => ({ ...prev, isFocused: true }));
  };

  return (
    <Paper
      elevation={0}
      sx={{
        transitionProperty: "all",
        transitionDuration: "150ms",
        p: [.2, .5],
        bgcolor: "background.paper",
        border: 1,
        borderColor: state.isFocused ? "primary.main" : "divider"
      }}
    >
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        <Iconify icon={"material-symbols:search"} fontSize="small" sx={{
          ml: 1,
          color: state.isFocused ? "text.primary" : "text.secondary"
        }} />
        <InputBase
          sx={{
            flex: 1,
            minWidth: state.isFocused ? 200 : 170,
            transitionProperty: "all",
            transitionDuration: "150ms",
            cursor: "pointer",
            fontSize: "0.875rem"
          }}
          placeholder={placeholder}
          inputProps={{ "aria-label": placeholder }}
          value={state.searchText}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleSubmit}
        />
        {(state.isFocused || state.searchText !== "") &&
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{
              visibility: state.searchText
                ? "visible"
                : "hidden"
            }}
            onClick={() => {
              setState((prev) => ({ ...prev, searchText: "" }));
              searchSportEquipmentByName("");
            }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>}
      </Stack>

    </Paper>
  );
};