import { InMemoryCache } from "@apollo/client";


export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        users: {
          merge(existing, incoming) {
            return existing;
          }
        },
      }
    }
  }
});




