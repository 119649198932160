import React, {useEffect, useRef, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ButtonUpload from "components/ButtonUpload";
import {v4 as uuidv4} from "uuid";


const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		height: 'calc(100% - 49px)',
		width: '100%',
		overflowY: 'auto',
		padding: '1rem 2rem',
		background: 'white',
		'& .document:not(:first-child)': {
			marginTop: '1rem'
		}
	},
}));


function BasicDocuments({...rest}) {
	const classes = useStyles();
	const [dragOver, setDragOver] = useState(false);
	const dragZone = useRef<any>();
	const {documents, onDocumentsChange} = rest

	useEffect(() => {
		if (dragZone && dragZone.current) {
			dragZone.current.addEventListener('dragover', (e: any) => {
				e.preventDefault();
				setDragOver(true)
			}, false)

			dragZone.current.addEventListener('drop', (e: any) => {
				e.preventDefault();
				setDragOver(false)
			}, false)
		}

	}, [dragZone])

	function setResponseData(index: number, data: any) {
		documents[index].document = data
		onDocumentsChange(documents)
	}

	function handleDeleteFile(index: number) {
		delete documents[index].document
		onDocumentsChange(documents)
	}

	return (
			<>
				<div
						className={classes.root}
						ref={dragZone}
				>
					{documents.map((item: any, index: number) => {

						return (
								<ButtonUpload
										key={index}
										id={item.id + uuidv4()}
										description={item.description}
										dragOver={dragOver}
										response={(data: any) => setResponseData(index, data)}
										document={item?.document}
										handleDeleteFile={() => handleDeleteFile(index)}
										readOnly={rest.readOnly}
								/>
						);
					})}
				</div>
			</>
	)
}

BasicDocuments.protoTypes = {}

export default BasicDocuments;
