import React, {useEffect, useState} from 'react';
import {Autocomplete as MuiAutocomplete, AutocompleteCloseReason, Box, Button, TextField, Tooltip} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Popper from "./Popper";
import {useTheme} from "@mui/material/styles";
import { GroupHeader } from 'components/Autocomplete/GroupHeader.styled';
import { GroupItems } from 'components/Autocomplete/GroupItems.styled';
import { BootstrapTooltip } from "../BootstrapTooltip";


interface Props {
  filterResult: (result: any[]) => void,
  value: any[],
  options: any[],
  groupBy?: (option: any) => any,
  onClose?: (value: string[]) => void
}

function Autocomplete(props: Props) {
  const {filterResult, options, onClose, groupBy} = props
  const theme = useTheme()


  const [value, setValue] = useState<any[]>([]);


  useEffect(() => {
    props.value && props.value !== value && setValue(options.filter(option => props.value?.includes(option.id)))

    return () => {
      setValue([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, options])

  const onSubmit = () => {
    value !== props.value && value && filterResult(value)
    onClose && onClose(value);
  };


  function selectAll() {
    setValue(options)
  }

  function unSelectAll() {
    setValue([])
  }

  return <>
    <div>
      <MuiAutocomplete
        open
        multiple
        // disablePortal
        onClose={(
          event: React.ChangeEvent<{}>,
          reason: AutocompleteCloseReason,
        ) => {
          if (reason === 'escape') {
            onClose && onClose(value);
          }
        }}
        value={value}

        onChange={(event, newValue, reason) => {
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Enter' &&
            (event as React.KeyboardEvent).altKey
          ) {
            onSubmit()
          }
          if (
            event.type === 'keydown' &&
            (event as React.KeyboardEvent).key === 'Backspace' &&
            reason === 'removeOption'
          ) {
            return;
          }
          setValue(newValue)
        }}
        disableCloseOnSelect
        PopperComponent={Popper}
        forcePopupIcon={false}
        renderTags={() => null}
        noOptionsText="Нет результатов"
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={
          options
        }
        getOptionLabel={(option) => option.name}

        {...{
            ...(groupBy && {
              groupBy
            })
          }
        }

        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}

        renderInput={(params) =>
          <>
            <TextField
              type={'search'}
              autoFocus
              size={'small'}
              sx={{
                padding: '10px',
                width: '100%',
                borderBottom: '1px solid #dfe2e5',
              }}
              ref={params.InputProps.ref}
              inputProps={{...params.inputProps}}
            />
            <Box
              sx={{
                borderBottom: `1px solid ${
                  theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
                }`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              <Box
                sx={{
                  display: "flex", justifyContent: 'space-between',
                  "& .MuiButton-root": {
                    py: .5,
                    px: 1,
                  }
                }}
              >
                <Button
                  size={'small'}
                  onClick={selectAll}
                >Выбрать все</Button>
                <Button
                  size={'small'}
                  onClick={unSelectAll}
                >Сбросить</Button>
              </Box>
            </Box>
          </>
        }
        renderOption={(props, option, {selected}) => {
          return (
            <li {...props} key={option.id}>
              <Box
                component={DoneIcon}
                sx={{width: 17, height: 17, mr: '5px', ml: '-2px'}}
                style={{
                  visibility: selected ? 'visible' : 'hidden',
                }}
              />

              <Box
                sx={{
                  flexGrow: 1,
                  '& span': {
                    color: '#586069',
                  },
                }}
              >
                {option.name}
                <br/>
                <span>{option.description}</span>
              </Box>
              <Box
                component={CloseIcon}
                sx={{opacity: 0.6, width: 18, height: 18}}
                style={{
                  visibility: selected ? 'visible' : 'hidden',
                }}
              />
            </li>
          )
        }}

      />

      <Box p={1} justifyContent={'end'} display={'flex'}>
        <BootstrapTooltip title={'Alt + Enter'}>
          <Box display={'flex'}>
            <Button
              onClick={onSubmit}
              variant={'contained'}
              size={'small'}

            >
              Применить
            </Button>
          </Box>
        </BootstrapTooltip>
      </Box>
    </div>
  </>
}

export default Autocomplete;
