import {lighten, Theme} from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';

const MuiDataGridStyles = makeStyles((theme: Theme) => ({
    "@keyframes fade": {
        "from": {
            opacity: 0,
        },
        'to': {
            opacity: 1,
        },
    },
    root: {
        border: 'none !important',
        borderRadius: 0,
        '& .MuiDataGrid-columnsContainer': {
            border: 'none',
            '& .MuiDataGrid-columnHeaderWrapper': {
                '& .MuiDataGrid-columnHeader': {
                    MsUserSelect: 'none',
                    MozUserSelect: 'none',
                    KhtmlUserSelect: 'none',
                    WebkitUserSelect: 'none',
                    '& .MuiDataGrid-columnSeparator': {
                        color: lighten(theme.palette.text.secondary, .7),
                    },
                    '& .MuiDataGrid-columnHeaderTitle': {
                        textTransform: 'uppercase',
                        fontSize: '0.7rem',
                        fontWeight: 600,
                        color: theme.palette.text.secondary,
                    },
                    '&:first-child': {
                        paddingLeft: '24px',
                        '& .MuiCheckbox-root':{
                            marginLeft: '-24px',
                            '& svg' : {
                                fontSize: '18px !important'
                            }
                        }
                    },
                    '&:last-child': {
                        paddingRight: '24px',
                        '& .MuiDataGrid-columnSeparator': {
                            display: 'none'
                        }
                    },
                    // '&:focus': {
                    //     outline: 'none',
                    // },
                    // '&:focus-within': {
                    //     outline: 'none',
                    // },
                },
            },
        },
        '& .MuiDataGrid-virtualScroller': {
            boxShadow: `0 0 0 1px ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            background: theme.palette.background.paper
        },
        '& .MuiDataGrid-main': {
            '& .MuiDataGrid-row': {
                transition: 'all .3s',
                MsUserSelect: 'none',
                MozUserSelect: 'none',
                KhtmlUserSelect: 'none',
                WebkitUserSelect: 'none',
                opacity: 0,
                animation: `$fade .1s normal forwards ease-in-out`,
                '& *':{
                    fontSize: 14,
                },
                '&:not(.Mui-selected):hover': {
                    backgroundColor: lighten(theme.palette.background.default, .2),
                },
                // '&.Mui-selected': {
                    // backgroundColor: lighten(theme.palette.background.default, .4),
                // },
                '& .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    overflow: 'hidden',
                    '&:first-child': {
                        paddingLeft: '24px',
                        '& .MuiCheckbox-root':{
                            marginLeft: '-24px',
                            '& svg' : {
                                fontSize: '18px !important'
                            }
                        }
                    },
                    '&:last-child': {
                        paddingRight: '24px'
                    },
                    '&:focus, &:focus-within': {
                        // outline: 'none',
                    },
                    '& .MuiListItem-root': {
                        padding: 0
                    }
                }
            }
        }
    },

}));

export default MuiDataGridStyles;
