import React, { FC, useEffect, useMemo, useState } from "react";
import { FormikErrors } from "formik/dist/types";
import { Alert, Box, Button, Stack } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

interface Props {
  errors: FormikErrors<any>;
}

const ErrorsStepper: FC<Props> = ({ errors }: Props) => {
  const [errorsKeys, setErrorsKeys] = useState([]);
  const [activeError, setActiveError] = useState(0);

  useMemo(() => {
    setErrorsKeys(Object.keys(errors));
    setActiveError(0);
  }, [errors]);


  const handleNext = () => {
    setActiveError((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveError((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      {
        Object.keys(errors).length > 0 && (
          <Alert severity='error'>
            <Stack
              spacing={1}
              direction={"row"}
              alignItems={"center"}
            >
              <span> {activeError + 1} / {errorsKeys.length} </span>
              <span>{errors[errorsKeys[activeError]] as string}</span>
              <Button
                size='small'
                onClick={handleBack}
                disabled={activeError === 0}
                variant={"text"}
                color={"inherit"}
                sx={{
                  py: 0.5,
                  px: 1,
                  fontSize: "1rem",
                  minWidth: "initial"
                }}
              >
                <KeyboardArrowLeft fontSize={"inherit"} />
              </Button>
              <Button
                size='small'
                onClick={handleNext}
                disabled={activeError === errorsKeys.length - 1}
                variant={"text"}
                color={"inherit"}
                sx={{
                  py: 0.5,
                  px: 1,
                  fontSize: "1rem",
                  minWidth: "initial"
                }}
              >
                <KeyboardArrowRight fontSize={"inherit"} />
              </Button>
            </Stack>
          </Alert>
        )

      }
    </Box>
  );
};

export default ErrorsStepper;