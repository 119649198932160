import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import PageDialog from "components/Dialogs/PageDialog";
import { useNavigate } from "react-router-dom";
import {
  CreateSportEquipmentInput,
  FileStoreEntity,
  GetSportEquipmentsDocument,
  ImageStoreEntity,
  SportEquipmentEntity,
  useCreateSportEquipmentMutation
} from "graphql/graphQlApiHooks";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import { PageDialogActions } from "components/Dialogs/PageDialogActions";
import { useSnackbar } from "notistack";
import dayjs, { Dayjs } from "dayjs";
import { Alert, Box, Button, Card, DialogActions, DialogContent, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import StickyBox from "react-sticky-box";
import { isEqual } from "helpers";
import { useDialog } from "providers/DialogProvider";
import { TechnicalCondition } from "graphql/technical-condition.enum";
import { SportEquipmentAboutInfo } from "../views/SportEquipmentAboutInfo";
import { SportEquipmentLocation } from "../views/SportEquipmentLocation";
import { SportEquipmentCategory } from "../views/SportEquipmentCategory";
import { SportEquipmentPhotos } from "../views/SportEquipmentPhotos";
import { SportEquipmentDocuments } from "../views/SportEquipmentDocuments";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import CommissioningDate from "../views/СommissioningDate";
import ErrorsStepper from "../views/ErrorsStepper";
import { FormikErrors } from "formik/dist/types";

interface ActionsProps {
  isSubmitting: boolean;
  handleSubmit: () => void;
  errors: FormikErrors<CreateSportEquipmentInput>;
}
const Actions = ({ isSubmitting, handleSubmit, errors }: ActionsProps) => {

  return (
    <>
      <PageDialogActions>

        <ErrorsStepper errors={errors} />

        <Box
          display={"flex"}
          flex={1}
        />

        <LoadingButton
          loading={isSubmitting}
          size='small'
          onClick={() => handleSubmit()}
          variant='contained'
        >
          Добавить
        </LoadingButton>
      </PageDialogActions>
    </>
  );
};

export interface ISportEquipmentDocuments {
  certificate: FileStoreEntity[] | null,
  commissioningCertificate: FileStoreEntity[] | null,
  guarantee: FileStoreEntity[] | null,
  manual: FileStoreEntity[] | null,
  passport: FileStoreEntity[] | null,
  otherDocuments: FileStoreEntity[],
}

const initialSportEquipment = {
  name: "",
  accountingName: "",
  inventoryNumber: "",
  technicalCondition: TechnicalCondition.WORKING,
  inUse: true,
  isEditable: true,
  commissioningDate: dayjs().format("YYYY-MM-DD")
} as SportEquipmentEntity;

const AddSportEquipment = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getInput } = useGetSportEquipmentInputVars();
  const [dialog, closeDialog] = useDialog();


  const [createSportEquipment] = useCreateSportEquipmentMutation();


  const [sportEquipment, setSportEquipment] = useState<SportEquipmentEntity>(initialSportEquipment);

  const onClose = () => {
    if (!isEqual(initialSportEquipment, sportEquipment)) {
      dialog({
        title: "Данные будут утеряны",
        children: <>
          <DialogContent
            dividers
          >
            <Alert severity={"warning"}>
              Вы уверены что хотите закрыть диалог добавления нового оборудования?
              Все введённые данные будут утерены!
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              size={"small"}
              onClick={() => {
                navigate("/equipments");
                closeDialog();
              }}
              color={"inherit"}
              variant={"contained"}
            >
              Закрыть диалог, с потерей данных
            </Button>
            <Button
              size={"small"}
              onClick={() => {
                closeDialog();
              }}
              color={"primary"}
              autoFocus
              variant={"contained"}
            >
              Продолжить доваление
            </Button>
          </DialogActions>
        </>
      });
    } else {
      navigate("/equipments");
    }
  };

  const CreateSportEquipmentSchema = useMemo(() => Yup.object().shape({
    name: Yup.string().required("Укажите название оборудования"),
    accountingName: Yup.string().required("Укажите бухгалтерское название оборудования"),
    inventoryNumber: Yup.string().required("Укажите инвентарный номер"),
    commissioningDate: Yup.string()
      .required("Укажите дату ввода в эксплуатацию")
      .nullable(),

    ...((sportEquipment?.technicalCondition === TechnicalCondition.NOT_WORKING && sportEquipment?.failureReason === "") && { failureReason: Yup.string().required("Укажите причину неисправности") }),

    sportObjectId: Yup.string().required("Выберите спортобъект").nullable()
  }), [sportEquipment?.technicalCondition, sportEquipment?.failureReason  ]);

  const formik = useFormik({
    initialValues: {
      name: "",
      accountingName: "",
      inventoryNumber: "",
      description: undefined,
      inUse: true,
      technicalCondition: TechnicalCondition.WORKING,
      failureReason: undefined,
      commissioningDate: null,

      sportCategoryId: undefined,
      sportKindId: undefined,
      equipmentTypeId: undefined,

      sportObjectId: undefined,
      sportZoneId: undefined,
      sportSubZoneId: undefined,

      specificationValues: [],

      certificateIds: [],
      commissioningCertificateIds: [],
      guaranteeIds: [],
      manualIds: [],
      passportIds: [],
      otherDocumentsIds: [],

      mainPhotoId: undefined,
      photosIds: []
    } as CreateSportEquipmentInput,
    validationSchema: CreateSportEquipmentSchema,
    onSubmit: (
      values: CreateSportEquipmentInput,
      { setSubmitting }: FormikHelpers<CreateSportEquipmentInput>
    ) => {
      createSportEquipment({
        variables: {
          input: { ...values }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GetSportEquipmentsDocument,
            fetchPolicy: "network-only",
            variables: {
              input: getInput()
            }
          }
        ]
      })
        .then((res) => {
          navigate("/equipments");
          enqueueSnackbar(
            `Обрудование ${res?.data?.createSportEquipment?.name}, успешно добавлено.`
          );
        })
        .catch(({ message }) => {
          enqueueSnackbar(
            message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
            {
              variant: "error"
            }
          );

        })
        .finally(() => setSubmitting(false));
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, setValues, handleBlur, values } = formik;

  useEffect(() => {
    if (sportEquipment) {
      setValues(prevState => ({
        ...prevState,
        ...(sportEquipment?.id !== undefined && { id: sportEquipment.id }),
        ...(sportEquipment?.name !== undefined && { name: sportEquipment.name }),
        ...(sportEquipment?.accountingName !== undefined && { accountingName: sportEquipment.accountingName }),
        ...(sportEquipment?.inventoryNumber !== undefined && { inventoryNumber: sportEquipment.inventoryNumber }),
        ...(sportEquipment?.description !== undefined && { description: sportEquipment.description }),
        ...(sportEquipment?.inUse !== undefined && { inUse: sportEquipment.inUse }),
        ...(sportEquipment?.technicalCondition !== undefined && { technicalCondition: sportEquipment.technicalCondition }),
        ...(sportEquipment?.failureReason !== undefined && { failureReason: (sportEquipment?.technicalCondition === TechnicalCondition.NOT_WORKING) ? sportEquipment.failureReason : "" }),
        ...(sportEquipment?.commissioningDate !== undefined && {
          commissioningDate: dayjs(sportEquipment.commissioningDate).format("DD.MM.YYYY") === 'Invalid Date' ? "" : dayjs(sportEquipment.commissioningDate).format("DD.MM.YYYY")
        }),
        ...(sportEquipment?.sportCategoryObject?.id && { sportCategoryId: sportEquipment.sportCategoryObject.id }),
        ...(sportEquipment?.sportKindObject?.id && { sportKindId: sportEquipment.sportKindObject.id }),
        ...(sportEquipment?.equipmentTypeObject?.id && { equipmentTypeId: sportEquipment.equipmentTypeObject.id }),
        ...(sportEquipment?.sportObject?.id && { sportObjectId: sportEquipment.sportObject.id }),
        ...(sportEquipment?.sportZone?.id && { sportZoneId: sportEquipment.sportZone.id }),
        ...(sportEquipment?.sportSubZone?.id && { sportSubZoneId: sportEquipment.sportSubZone.id }),
        ...(sportEquipment?.certificate?.length > 0 && { certificateIds: sportEquipment.certificate?.map(item => item.id) }),
        ...(sportEquipment?.commissioningCertificates?.length > 0 && { commissioningCertificateIds: sportEquipment.commissioningCertificates?.map(item => item.id) }),
        ...(sportEquipment?.guarantee?.length > 0 && { guaranteeIds: sportEquipment.guarantee?.map(item => item.id) }),
        ...(sportEquipment?.manual?.length > 0 && { manualIds: sportEquipment.manual?.map(item => item.id) }),
        ...(sportEquipment?.passport?.length > 0 && { passportIds: sportEquipment.passport?.map(item => item.id) }),
        ...(sportEquipment?.otherDocuments?.length > 0 && { otherDocumentsIds: sportEquipment.otherDocuments?.map(item => item.id) }),
        ...((sportEquipment?.mainPhoto?.id) ? { mainPhotoId: sportEquipment.mainPhoto.id } : sportEquipment?.photos?.length > 0 && { mainPhotoId: sportEquipment?.photos?.[0]?.id }),
        ...(sportEquipment?.photos && { photosIds: sportEquipment.photos?.map(item => item.id) })
      } as CreateSportEquipmentInput));


      if (sportEquipment?.photos?.length > 0) {
        const mainPhoto = sportEquipment?.photos?.find(item => item.id === sportEquipment?.mainPhoto?.id);
        if (!mainPhoto)
          setSportEquipment(prevState => ({ ...prevState, mainPhoto: sportEquipment?.photos?.[0] }));
      }


    }
  }, [sportEquipment]);

  return (
    <>
      <PageDialog
        title={sportEquipment?.name === "" ? "Добавление спортивного оборудования" : sportEquipment?.name}
        onClose={onClose}
        maxWidth={"lg"}
        ActionsComponent={Actions}
        ActionsProps={{
          isSubmitting: isSubmitting,
          handleSubmit: () => handleSubmit(),
          errors: errors
        }}
      >
        <FormikProvider value={formik}>

          <Grid
            container
            spacing={4}
            px={3}
            pb={3}
          >
            <Grid
              item
              lg={2.5}
              md={3.5}
            >
              <StickyBox
                offsetTop={11}
                offsetBottom={11}
              >
                <Stack
                  spacing={2}
                  mt={2}
                >

                  <Card
                    sx={{ p: 2 }}
                    elevation={2}
                  >
                    <CommissioningDate
                      touched={touched}
                      errors={errors}

                      handleBlur={handleBlur}
                      isEditable

                      date={dayjs(sportEquipment?.commissioningDate)}
                      setDate={(date: Dayjs | null) => {
                        setSportEquipment(prevState => ({
                            ...prevState,
                            commissioningDate: dayjs(date).format("YYYY-MM-DD")
                          })
                        );
                      }}
                    />
                  </Card>

                  <Card
                    sx={{ p: 2 }}
                    elevation={2}
                  >

                    <SportEquipmentLocation
                      vertical
                      touched={touched}

                      handleBlur={handleBlur}
                      errors={errors}
                      sportEquipment={sportEquipment}
                      setSportEquipment={setSportEquipment}
                    />
                  </Card>

                  <Card
                    sx={{ p: 2 }}
                    elevation={2}
                  >
                    <SportEquipmentCategory
                      vertical
                      touched={touched}
                      errors={errors}
                      isEditable={sportEquipment?.isEditable}
                      sportEquipment={sportEquipment}
                      setSportEquipment={setSportEquipment}
                      handleBlur={handleBlur}
                    />
                  </Card>

                </Stack>
              </StickyBox>
            </Grid>
            <Grid
              item
              lg={9.5}
              md={8.5}
            >
              <Stack
                spacing={4}
                mt={2}
              >
                <SportEquipmentAboutInfo
                  errors={errors}
                  sportEquipment={sportEquipment}
                  setSportEquipment={setSportEquipment}
                  isEditable
                  handleBlur={handleBlur}
                  touched={touched}
                />

                <Card
                  sx={{ p: 3 }}
                  elevation={2}
                >
                  <SportEquipmentPhotos
                    isEditable
                    sportEquipmentPhotos={sportEquipment?.photos || []}
                    setSportEquipmentPhotos={(photos: ImageStoreEntity[]) => setSportEquipment(prevState => {
                      return {
                        ...prevState,
                        photos: photos as ImageStoreEntity[]
                      };
                    })}
                    mainPhoto={sportEquipment?.mainPhoto}
                    setMainPhoto={(mainPhoto) => setSportEquipment(prevState => ({
                      ...prevState,
                      mainPhoto: mainPhoto as ImageStoreEntity
                    }))}
                  />
                </Card>

                <SportEquipmentDocuments
                  isEditable
                  documents={{
                    ...(sportEquipment?.certificate?.length > 0 && { certificate: sportEquipment?.certificate }),
                    ...(sportEquipment?.commissioningCertificates?.length > 0 && { commissioningCertificate: sportEquipment?.commissioningCertificates }),
                    ...(sportEquipment?.guarantee?.length > 0 && { guarantee: sportEquipment?.guarantee }),
                    ...(sportEquipment?.manual?.length > 0 && { manual: sportEquipment?.manual }),
                    ...(sportEquipment?.passport?.length > 0 && { passport: sportEquipment?.passport }),
                    ...(sportEquipment?.otherDocuments?.length > 0 && { otherDocuments: sportEquipment?.otherDocuments })
                  } as ISportEquipmentDocuments}
                  setDocuments={(documents: ISportEquipmentDocuments) => setSportEquipment(prevState => ({
                    ...prevState,
                    certificate: documents?.certificate,
                    commissioningCertificates: documents?.commissioningCertificate,
                    guarantee: documents?.guarantee,
                    passport: documents?.passport,
                    manual: documents?.manual,
                    otherDocuments: documents?.otherDocuments
                  }))}
                />
              </Stack>
            </Grid>
          </Grid>


        </FormikProvider>

      </PageDialog>
    </>
  );
};

export default AddSportEquipment;