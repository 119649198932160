import React, { useState } from "react";
import { Stack } from "@mui/material";
import { ImageStoreEntity } from "graphql/graphQlApiHooks";
import { SportEquipmentPhotosHeader as Header } from "./Header";
import { SportEquipmentPhotosToolbar as Toolbar } from "./Toolbar";
import { PhotoGrid } from "./PhotoGrid";
import Settings from "../../../../../../../store/settings";


interface Props {
  sportEquipmentPhotos: ImageStoreEntity[];
  setSportEquipmentPhotos: (value: ImageStoreEntity[]) => void;
  mainPhoto?: ImageStoreEntity;
  setMainPhoto?: (value: ImageStoreEntity) => void;
  isEditable?: boolean;
}

export const SportEquipmentPhotos = (props: Props) => {
  const {
    sportEquipmentPhotos,
    setSportEquipmentPhotos,
    mainPhoto,
    setMainPhoto,
    isEditable
  } = props;

  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [isSelectable, setIsSelectable] = useState(false);

  const onSelectedDeleteHandler = () => {
    if (selectedPhotos.includes(mainPhoto.id)) {
      setMainPhoto(null);
    }
    setSportEquipmentPhotos([...sportEquipmentPhotos].filter(item => !selectedPhotos.includes(item.id)) as ImageStoreEntity[]);
    setSelectedPhotos([]);
    setIsSelectable(false);
  };


  return (
    <Stack spacing={3}>

      <Header
        title={"Фото оборудования"}
        description={"Загрузите одно или несколько фото обрудования"}
        photos={sportEquipmentPhotos}
        setIsSelectable={setIsSelectable}
        isSelectable={isSelectable}
        isEditable={isEditable}
      />

      <Toolbar
        isSelectable={isSelectable}
        selectedPhotos={selectedPhotos}
        onSelectAll={() => setSelectedPhotos([...sportEquipmentPhotos].map(item => item.id))}
        onUnSelectAll={() => setSelectedPhotos([])}
        onDeleteSelected={onSelectedDeleteHandler}
      />

      <PhotoGrid
        isEditable={isEditable}

        id={"SportEquipmentPhotos1"}

        photos={sportEquipmentPhotos}
        setPhotos={setSportEquipmentPhotos}

        {...{ ...(mainPhoto && { mainPhoto: mainPhoto }) }}
        {...{ ...(setMainPhoto && { setMainPhoto: setMainPhoto }) }}

        isSelectable={isSelectable}
        selectedPhotos={selectedPhotos}
        setSelectedPhotos={setSelectedPhotos}
      />

    </Stack>
  );
};
