const breakpoints = () => ({
  values: {
    xs: 0,
    sm: 600,
    md: 970,
    lg: 1472,
    xl: 1536,
  }
})

export default breakpoints
