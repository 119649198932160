import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  dialogContentClasses,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled, useTheme } from "@mui/material/styles";
import {
  CreateSportEquipmentInput, GetSportEquipmentsDocument,
  ImportSportEquipmentsInput,
  useImportSportEquipmentMutation
} from "graphql/graphQlApiHooks";
import EditableTable from "./EditableTable";
import { ImportedSportEquipmentRecord } from "./types";
import { useSnackbar } from "notistack";
import { TechnicalCondition } from "graphql/technical-condition.enum";
import { declOfNum } from "helpers/declination";
import { LoadingButton } from "@mui/lab";
import { faker } from "@faker-js/faker";
import { rowValidate } from "./utils";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import { BootstrapTooltip } from "../../../../../../../../components/BootstrapTooltip";


const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  position: "sticky",
  top: 0,
  display: "flex",
  margin: 0,
  height: 48,
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  zIndex: 1
}));

const Title = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "left"
})) as typeof Typography;


interface Props {
  open: boolean;
  close: () => void;
  importedSportEquipmentRecords?: ImportedSportEquipmentRecord[];
}


export const ImportFromExcel = (props: Props) => {
  const { open, close, importedSportEquipmentRecords } = props;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [records, setRecords] = useState<ImportedSportEquipmentRecord[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<any>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { getInput } = useGetSportEquipmentInputVars();

  function handleCloseModal() {
    close();
    setRecords([]);
    setDisabled(true);
    setErrors([]);
    setSubmitting(false);
  }

  const [importSportEquipment] = useImportSportEquipmentMutation();

  const onSubmit = () => {
    setSubmitting(true);
    const getImportSportEquipmentsInput = (records: ImportedSportEquipmentRecord[]): CreateSportEquipmentInput[] => records.map((record: ImportedSportEquipmentRecord) => {
      const {
        name,
        inventoryNumber,
        description,
        technicalCondition,
        inUse,
        commissioningDate,
        equipmentType: { id: equipmentTypeId },
        sportKind: { id: sportKindId },
        sportObject: { id: sportObjectId },
        sportZone: { id: sportZoneId },
        sportSubZone: { id: sportSubZoneId },
        sportCategory: { id: sportCategoryId }
      } = record;

      return {
        ...(name && {
          name
        }),
        ...(inventoryNumber && {
          inventoryNumber
        }),
        ...(description && {
          description
        }),
        ...(commissioningDate && {
          commissioningDate
        }),
        ...(technicalCondition && {
          technicalCondition: (technicalCondition) ? TechnicalCondition.WORKING : TechnicalCondition.NOT_WORKING
        }),
        ...(inUse && {
          inUse
        }),

        ...(sportObjectId && {
          sportObjectId
        }),
        ...(sportZoneId && {
          sportZoneId
        }),
        ...(sportSubZoneId && {
          sportSubZoneId
        }),

        ...(sportCategoryId && {
          sportCategoryId
        }),
        ...(sportKindId && {
          sportKindId
        }),
        ...(equipmentTypeId && {
          equipmentTypeId
        })

      } as CreateSportEquipmentInput;
    });

    const createEquipmentInputs: CreateSportEquipmentInput[] = getImportSportEquipmentsInput(records);

    const importSportEquipmentsInput: ImportSportEquipmentsInput = {
      createEquipmentInputs: createEquipmentInputs
    };

    importSportEquipment({
      variables: {
        input: importSportEquipmentsInput
      },
      refetchQueries: [
        {
          query: GetSportEquipmentsDocument,
          fetchPolicy: "network-only",
          variables: {
            input: getInput()
          }
        }
      ]
    }).then((res) => {
      handleCloseModal();
      enqueueSnackbar(
        `Добавлено ${records.length}, ${declOfNum(records.length, ["единица", "единицы", "единиц"])} оборудования`
      );
    }).catch(({ message }) => {
      enqueueSnackbar(
        message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
        {
          variant: "error"
        }
      );
    }).finally(() => {
      setSubmitting(false);
    });

  };

  useEffect(() => {
    const errs = [...records]?.map((data: any) => rowValidate(data));
    setDisabled([...errs].filter(error => Object.keys(error).length > 1).length > 0);

  }, [records]);

  useEffect(() => {
    if (importedSportEquipmentRecords && records.length === 0) {
      const initialData: ImportedSportEquipmentRecord[] = [...importedSportEquipmentRecords]
        .map(item => ({
          ...item,
          id: faker.datatype.uuid()
        })) || [];
      setRecords(initialData);
    }
  }, [importedSportEquipmentRecords]);


  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        maxWidth={false}
        fullWidth
        sx={{
          [`& .${dialogClasses.paper}`]: {
            width: "100%",
            height: "100%",
            [theme.breakpoints.down("md")]: {
              margin: 0,
              borderRadius: 0,
              alignSelf: "end",
              maxHeight: "100%"
            },
            [`& .${dialogContentClasses.root}`]: {
              padding: 0
            }
          }
        }}
      >

        <DialogTitle>
          <Title variant={"h5"} align={"center"} component={"div"}> Загрузка оборудования из файла </Title>
          <BootstrapTooltip title={"Закрыть"}>
            <IconButton size={"small"} onClick={handleCloseModal}> <CloseIcon fontSize={"inherit"} /> </IconButton>
          </BootstrapTooltip>
        </DialogTitle>

        <DialogContent dividers>
          {records?.length > 0 && <EditableTable
            onDataChange={(data) => setRecords(data)}
            data={records}
          />}
        </DialogContent>

        <DialogActions>
          <BootstrapTooltip title={disabled ? "Сначала заполните обязательные поля" : ""}>
            <Box>
              <LoadingButton
                disabled={disabled}
                color={"primary"}
                variant="contained"
                size={"small"}
                onClick={onSubmit}
                loading={submitting}
              >
                Добавить
              </LoadingButton>
            </Box>
          </BootstrapTooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};
