import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppBar, Box, Hidden, IconButton, Toolbar} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useTheme} from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import HotelsSelect from './HotelsSelect';
import CompanyToolbar from './CompanyMenu';
import Logo from "components/Logo";
import {DepartmentEnum} from "../../../../../store/department.enum";
import {observer} from "mobx-react-lite";
import {useStores} from "../../../../../store";
import SportObjectsSelect from "./SportObjectsSelect";


const useStyles = makeStyles((theme) => ({
    logo: {
        marginRight: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 69,
        '& img': {
            transition: 'all .3s'
        },
        '&:hover img': {
            background: '#f0f4f9',
            borderRadius: '10px'
        }
    },
    avatar: {
        width: 60,
        height: 60
    },
    topBar: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: theme.spacing(0),
        borderBottom: `1px solid ${theme.palette.divider}`

    }

}));

interface ITopBarProps {
    [key: string]: any
}


const TopBar = observer(({className, onMobileNavOpen, ...rest}: ITopBarProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const {userStore} = useStores();
    const isExternalUser = userStore?.currentUser?.user?.departments?.includes(DepartmentEnum.External)

    return (
        <AppBar
            color="inherit"
            position={'sticky'}
            elevation={1}
            {...rest}
            sx={{
                boxShadow: theme.shadows[0]
            }}
        >
            <Toolbar className={classes.topBar}>
                <RouterLink
                    to="/"
                    className={classes.logo}
                >
                    <Logo width={34}/>
                </RouterLink>
                {!isExternalUser && <SportObjectsSelect/>}
                <Hidden mdDown>
                    <Box flexGrow={1}/>
                </Hidden>
                <Hidden mdDown>
                    <CompanyToolbar/>
                </Hidden>
                <Hidden mdUp>
                    <IconButton
                        onClick={onMobileNavOpen}
                        size="large"
                        sx={{
                            marginLeft: theme.spacing(2),
                            color: theme.palette.text.secondary,
                            width: 57,
                            height: 57
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
});



export default TopBar;
