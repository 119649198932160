import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEqual } from "helpers";
import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";
import StickyBox from "react-sticky-box";
import { FormikHelpers, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import PageDialog from "components/Dialogs/PageDialog";
import { PageDialogActions } from "components/Dialogs/PageDialogActions";
import {
  Alert,
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ISportEquipmentDocuments } from "../dialogs";
import { useDialog } from "providers/DialogProvider";
import { TechnicalCondition } from "graphql/technical-condition.enum";
import {
  GetSportEquipmentsDocument,
  ImageStoreEntity,
  SportEquipmentEntity,
  UpdateSportEquipmentInput,
  useGetSportEquipmentByIdQuery,
  useSetEditableMutation,
  useUpdateSportEquipmentMutation
} from "graphql/graphQlApiHooks";
import { SportEquipmentAboutInfo } from "../views/SportEquipmentAboutInfo";
import { SportEquipmentLocation } from "../views/SportEquipmentLocation";
import { SportEquipmentCategory } from "../views/SportEquipmentCategory";
import { SportEquipmentPhotos } from "../views/SportEquipmentPhotos";
import { SportEquipmentDocuments } from "../views/SportEquipmentDocuments";
import { useGetSportEquipmentInputVars } from "store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import CommissioningDate from "../views/СommissioningDate";
import Iconify from "components/Iconify";
import { useReactiveVar } from "@apollo/client";
import { userVars } from "../../../../../../store/reactiveVarsStores/auth/user";
import { omit } from "lodash";
import { FormikErrors } from "formik/dist/types";
import ErrorsStepper from "../views/ErrorsStepper";
import { useBeforeUnload } from "react-router-dom";
import { BootstrapTooltip } from "../../../../../../components/BootstrapTooltip";


interface ActionProps {
  isSubmitting: boolean;
  handleSubmit: () => void;
  errors: FormikErrors<UpdateSportEquipmentInput>;
}


const Actions = (props: ActionProps) => {
  const { isSubmitting, handleSubmit, errors } = props;


  return (
    <>
      <PageDialogActions>
        <ErrorsStepper errors={errors} />

        <Box
          display={"flex"}
          flex={1}
        />

        <LoadingButton
          loading={isSubmitting}
          size='small'
          onClick={() => handleSubmit()}
          variant='contained'
        >
          Сохранить
        </LoadingButton>
      </PageDialogActions>
    </>
  );
};

export const EditSportEquipment = () => {
  const { sportEquipmentId: id } = useParams();
  const { isForSportObject } = useReactiveVar(userVars);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getInput } = useGetSportEquipmentInputVars();
  const [dialog, closeDialog] = useDialog();

  const [updateSportEquipment] = useUpdateSportEquipmentMutation();

  const [loading, setLoading] = useState(true);

  const [sportEquipment, setSportEquipment] = useState<SportEquipmentEntity | null>(null);
  const [sportEquipmentInit, setSportEquipmentInit] = useState<SportEquipmentEntity | null>(null);

  const UpdateSportEquipmentSchema = useMemo(() => Yup.object().shape({
    name: Yup.string().required("Укажите название оборудования"),
    accountingName: Yup.string().required("Укажите бухгалтерское название оборудования"),
    inventoryNumber: Yup.string().required("Укажите инвентарный номер"),
    commissioningDate: Yup.string().required("Укажите дату ввода в эксплуатацию").nullable(),
    ...((sportEquipment?.technicalCondition === TechnicalCondition.NOT_WORKING && sportEquipment?.failureReason === "") && { failureReason: Yup.string().required("Укажите причину неисправности") }),

    sportObjectId: Yup.string().required("Выберите спортобъект").nullable()
  }), [sportEquipment?.technicalCondition, sportEquipment?.failureReason]);


  const { data, error } = useGetSportEquipmentByIdQuery({ variables: { id }, skip: !id });

  if (error?.message) {
    enqueueSnackbar(error?.message, { variant: "error" });
  }

  const formik = useFormik({
    initialValues: undefined,
    validationSchema: UpdateSportEquipmentSchema,
    onSubmit: (
      values: UpdateSportEquipmentInput | undefined,
      { setSubmitting }: FormikHelpers<UpdateSportEquipmentInput>
    ) => {

      if (!isEqual(omit(sportEquipmentInit, ["isEditable"]), omit(sportEquipment, ["isEditable"]))) {
        updateSportEquipment({
          variables: {
            input: { ...values }
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GetSportEquipmentsDocument,
              fetchPolicy: "network-only",
              variables: {
                input: getInput()
              }
            }
          ]
        })
          .then((res) => {
            navigate("/equipments");
            enqueueSnackbar(
              `Обрудование ${res?.data?.updateSportEquipment?.name}, успешно изменено.`
            );
          })
          .catch(({ message }) => {
            enqueueSnackbar(
              message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
              {
                variant: "error"
              }
            );
          })
          .finally(() => setSubmitting(false));
      } else {
        dialog({
          title: "Закрыть диалог?",
          children: <>
            <DialogContent
              dividers
            >
              <Alert severity={"info"}>
                Данные не были изменены. Хотите закрыть редактирование?
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button
                size={"small"}
                onClick={() => {
                  navigate("/equipments");
                  closeDialog();
                }}
                color={"inherit"}
                variant={"contained"}
              >
                Закрыть диалог
              </Button>
              <Button
                size={"small"}
                onClick={() => {
                  closeDialog();
                }}
                color={"primary"}
                autoFocus
                variant={"contained"}
              >
                Продолжить редактирование
              </Button>
            </DialogActions>
          </>
        });
        setSubmitting(false);
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, setValues, handleBlur } = formik;

  const onClose = () => {
    if (!isEqual(omit(sportEquipmentInit, ["isEditable"]), omit(sportEquipment, ["isEditable"]))) {
      dialog({
        title: "Данные будут утеряны",
        children: <>
          <DialogContent
            dividers
          >
            <Alert severity={"warning"}>
              Вы уверены что хотите закрыть диалог редактирования оборудования?
              Изменённые данные будут утерены!
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              size={"small"}
              onClick={() => {
                navigate("/equipments");
                closeDialog();
              }}
              color={"inherit"}
              variant={"contained"}
            >
              Закрыть диалог, с потерей данных
            </Button>
            <Button
              size={"small"}
              onClick={() => {
                closeDialog();
              }}
              color={"primary"}
              autoFocus
              variant={"contained"}
            >
              Продолжить редактирование
            </Button>
          </DialogActions>
        </>
      });
    } else {
      navigate("/equipments");
    }
  };

  // получаем данные оборудования по id и устанавливаем их в стейт (sportEquipment)
  useEffect(() => {
    if (data?.getSportEquipmentById) {
      let getSportEquipmentById = data?.getSportEquipmentById as SportEquipmentEntity;
      // получаем дату введения в эксплуатацию
      const commissioningDate = getSportEquipmentById?.commissioningDate;

      // getSportEquipmentById = omit(getSportEquipmentById, ["isEditable"]);

      // если нет главной фотографии, но есть фотографии, то берём первую фотографию
      if (!getSportEquipmentById?.mainPhoto && getSportEquipmentById?.photos?.length > 0)
        getSportEquipmentById = { ...getSportEquipmentById, mainPhoto: getSportEquipmentById?.photos?.[0] };

      // если  дата введения в эксплуатацию в числовом формате, то преобразуем её в строку
      if (!isNaN(Number(commissioningDate)))
        getSportEquipmentById = {
          ...getSportEquipmentById,
          commissioningDate: dayjs(Number(commissioningDate)).format("YYYY-MM-DD")
        };

      // если это первая згрузка, то устанавливаем значения в стейты для сравнения с изменёнными данными
      if (getSportEquipmentById && !sportEquipment) {
        setSportEquipment(getSportEquipmentById);
        setSportEquipmentInit(getSportEquipmentById);
      }
    }
  }, [data?.getSportEquipmentById]);

  // устанавливаем данные оборудования из стейта в форму редактирования оборудования (formik) при загрузке страницы
  useEffect(() => {
    // если есть данные оборудования, то устанавливаем их в форму редактирования оборудования (formik)
    if (sportEquipment) {
      setValues(prevState => ({
        ...prevState,
        ...(sportEquipment?.id && { id: sportEquipment.id }),
        ...(sportEquipment?.name !== undefined && { name: sportEquipment.name }),

        // если sportEquipment?.accountingName null или undefined, то устанавливаем его в форму как пустую строку инчае как значение
        ...(sportEquipment?.accountingName !== undefined && {
          accountingName: sportEquipment?.accountingName === null ? "" : sportEquipment?.accountingName
        }),

        ...(sportEquipment?.inventoryNumber !== undefined && { inventoryNumber: sportEquipment.inventoryNumber }),
        ...(sportEquipment?.description !== undefined && { description: sportEquipment.description }),
        ...(sportEquipment?.inUse !== undefined && { inUse: sportEquipment.inUse }),
        ...(sportEquipment?.availableForRent !== undefined && { availableForRent: sportEquipment.availableForRent }),
        ...(sportEquipment?.technicalCondition !== undefined && { technicalCondition: sportEquipment.technicalCondition }),
        ...(sportEquipment?.failureReason !== undefined && { failureReason: (sportEquipment?.technicalCondition === TechnicalCondition.NOT_WORKING) ? sportEquipment.failureReason : "" }),

        ...(sportEquipment?.commissioningDate !== undefined && {
          commissioningDate: dayjs(sportEquipment.commissioningDate).format("DD.MM.YYYY") === "Invalid Date" ? "" : dayjs(sportEquipment.commissioningDate).format("DD.MM.YYYY")
        }),

        ...({ sportCategoryId: sportEquipment?.sportCategoryObject?.id ? sportEquipment.sportCategoryObject.id : null }),
        ...({ sportKindId: sportEquipment?.sportKindObject?.id ? sportEquipment.sportKindObject.id : null }),
        ...({ equipmentTypeId: sportEquipment?.equipmentTypeObject?.id ? sportEquipment.equipmentTypeObject.id : null }),

        ...({ sportObjectId: sportEquipment?.sportObject?.id }),
        ...({ sportZoneId: sportEquipment?.sportZone?.id ? sportEquipment.sportZone.id : null }),
        ...({ sportSubZoneId: sportEquipment?.sportSubZone?.id ? sportEquipment.sportSubZone.id : null }),


        ...(sportEquipment?.certificate?.length > 0 && { certificateIds: sportEquipment.certificate?.map(item => item.id) }),
        ...(sportEquipment?.commissioningCertificates?.length > 0 && { commissioningCertificateIds: sportEquipment.commissioningCertificates?.map(item => item.id) }),
        ...(sportEquipment?.guarantee?.length > 0 && { guaranteeIds: sportEquipment.guarantee?.map(item => item.id) }),
        ...(sportEquipment?.manual?.length > 0 && { manualIds: sportEquipment.manual?.map(item => item.id) }),
        ...(sportEquipment?.passport?.length > 0 && { passportIds: sportEquipment.passport?.map(item => item.id) }),
        ...(sportEquipment?.otherDocuments?.length > 0 && { otherDocumentsIds: sportEquipment.otherDocuments?.map(item => item.id) }),
        ...((sportEquipment?.mainPhoto?.id) ? { mainPhotoId: sportEquipment.mainPhoto.id } : sportEquipment?.photos?.length > 0 && { mainPhotoId: sportEquipment?.photos?.[0]?.id }),
        ...(sportEquipment?.photos && { photosIds: sportEquipment.photos?.map(item => item.id) })
      } as UpdateSportEquipmentInput));

      if (sportEquipment?.photos?.length > 0) {
        const mainPhoto = sportEquipment?.photos?.find(item => item.id === sportEquipment?.mainPhoto?.id);
        if (!mainPhoto)
          setSportEquipment(prevState => ({ ...prevState, mainPhoto: sportEquipment?.photos?.[0] }));
      }

      // устанавливаем флаг загрузки
      if (loading) setLoading(false);
    }
  }, [sportEquipment]);


  // хук для изменения доступности редактирования карточки оборудования
  const [setEditable] = useSetEditableMutation();

  // обработчик изменения доступности редактирования карточки оборудования
  const handleEquipmentEditable = () => {
    // отправляем запрос на изменение доступности редактирования карточки оборудования
    setEditable({
      variables: {
        input: {
          sportEquipmentId: id,
          isEditable: !sportEquipment?.isEditable // меняем флаг доступности редактирования
        }
      },
      // обновляем кэш запроса GetSportEquipmentsDocument
      refetchQueries: [
        {
          query: GetSportEquipmentsDocument,
          fetchPolicy: "network-only",
          variables: {
            input: getInput()
          }
        }
      ]
    }).then(() => {
      // меняем флаг доступности редактирования
      setSportEquipment(prev => ({
        ...prev,
        isEditable: !sportEquipment?.isEditable
      }));
      // выводим сообщение в snackbar
      enqueueSnackbar(
        sportEquipment?.isEditable ? `Карточка оборудования больше не доступна для редактирования.` : "Карточка оборудования вновь доступна для редактирования."
      );
    }).catch(({ message }) => {
      // выводим сообщение об ошибке в snackbar
      enqueueSnackbar(
        message.replace("Unexpected error value: ", "").replace(/\"/g, ""),
        {
          variant: "error"
        }
      );
    });
  };


  useBeforeUnload(
    React.useCallback((event) => {
      if (!isEqual(omit(sportEquipmentInit, ["isEditable"]), omit(sportEquipment, ["isEditable"]))) {
        event.preventDefault();
        event.returnValue = "Вы уверены, что хотите покинуть страницу? Все несохраненные данные будут потеряны.";
      }
    }, [sportEquipment, sportEquipmentInit])
  );

  return (
    <>
      <PageDialog
        title={sportEquipment?.name === "" ? "" : sportEquipment?.name}
        onClose={onClose}
        maxWidth={"lg"}
        ActionsComponent={Actions}
        ActionsProps={{
          isSubmitting: isSubmitting,
          handleSubmit: () => handleSubmit(),
          errors: errors
        }}
        additionalButtonComponents={[
          <Box key={1}>
            {!isForSportObject && !loading &&

              <BootstrapTooltip title={sportEquipment?.isEditable ? "Запретить редактирование" : "Разрешить редактирование"}>
                <IconButton
                  size={"small"}
                  onClick={handleEquipmentEditable}
                >
                  <Iconify
                    icon={sportEquipment?.isEditable ? "material-symbols:lock-open-outline" : "material-symbols:lock"}
                    fontSize={"inherit"}
                  />
                </IconButton>
              </BootstrapTooltip>

            }
          </Box>,
          // <Box key={2}>
          //   {!loading &&
          //     <Tooltip title={"История изменений"}>
          //       <IconButton
          //         size={"small"}
          //         onClick={() => {
          //         }}
          //       >
          //         <Iconify
          //           icon={"material-symbols:history-rounded"}
          //           fontSize={"inherit"}
          //         />
          //       </IconButton>
          //     </Tooltip>
          //   }
          // </Box>
        ]}
      >


        {loading ? <LinearProgress /> :

          <FormikProvider value={formik}>

            <Grid
              container
              spacing={4}
              px={3}
              pb={3}
            >

              <Grid
                item
                lg={2.5}
                md={3.5}
              >
                <StickyBox
                  offsetTop={11}
                  offsetBottom={11}
                >
                  <Stack
                    spacing={2}
                    mt={2}
                  >

                    <Card
                      sx={{ p: 2 }}
                      elevation={2}
                    >
                      <CommissioningDate
                        touched={touched}
                        errors={errors}
                        isEditable={sportEquipment?.isEditable}
                        handleBlur={handleBlur}

                        date={dayjs(sportEquipment?.commissioningDate)}
                        setDate={(date: Dayjs | null) => {
                          setSportEquipment(prevState => ({
                              ...prevState,
                              commissioningDate: dayjs(date).format("YYYY-MM-DD")
                            })
                          );
                        }}
                      />
                    </Card>

                    <Card
                      sx={{ p: 2 }}
                      elevation={2}
                    >
                      <SportEquipmentLocation
                        vertical
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        sportEquipment={sportEquipment}
                        setSportEquipment={setSportEquipment}
                      />
                    </Card>

                    <Card
                      sx={{ p: 2 }}
                      elevation={2}
                    >
                      <SportEquipmentCategory
                        vertical
                        touched={touched}
                        handleBlur={handleBlur}
                        errors={errors}
                        isEditable={sportEquipment?.isEditable}
                        sportEquipment={sportEquipment}
                        setSportEquipment={setSportEquipment}
                      />
                    </Card>
                  </Stack>
                </StickyBox>
              </Grid>

              <Grid
                item
                lg={12 - 2.5}
                md={12 - 3.5}
                sx={{
                  transition: "all .3s ease-in-out"
                }}
              >
                <Stack
                  spacing={4}
                  mt={2}
                >
                  <SportEquipmentAboutInfo
                    errors={errors}
                    sportEquipment={sportEquipment}
                    setSportEquipment={setSportEquipment}
                    isEditable={sportEquipment?.isEditable}
                    handleBlur={handleBlur}
                    touched={touched}
                  />

                  <Card
                    sx={{ p: 3 }}
                    elevation={2}
                  >
                    <SportEquipmentPhotos
                      isEditable={sportEquipment?.isEditable}
                      sportEquipmentPhotos={sportEquipment?.photos || []}
                      setSportEquipmentPhotos={(photos) => {
                        setSportEquipment(prevState => ({
                          ...prevState,
                          photos: photos as ImageStoreEntity[]
                        }));
                      }}
                      mainPhoto={sportEquipment?.mainPhoto}
                      setMainPhoto={(mainPhoto) => {
                        setSportEquipment(prevState => ({
                          ...prevState,
                          mainPhoto: mainPhoto as ImageStoreEntity
                        }));
                      }}
                    />
                  </Card>

                  <SportEquipmentDocuments
                    isEditable={sportEquipment?.isEditable}
                    documents={{
                      ...(sportEquipment?.certificate?.length > 0 && { certificate: sportEquipment?.certificate }),
                      ...(sportEquipment?.commissioningCertificates?.length > 0 && { commissioningCertificate: sportEquipment?.commissioningCertificates }),
                      ...(sportEquipment?.guarantee?.length > 0 && { guarantee: sportEquipment?.guarantee }),
                      ...(sportEquipment?.manual?.length > 0 && { manual: sportEquipment?.manual }),
                      ...(sportEquipment?.passport?.length > 0 && { passport: sportEquipment?.passport }),
                      ...(sportEquipment?.otherDocuments?.length > 0 && { otherDocuments: sportEquipment?.otherDocuments })
                    } as ISportEquipmentDocuments}
                    setDocuments={(documents: ISportEquipmentDocuments) => {
                      setSportEquipment(prevState => ({
                        ...prevState,
                        certificate: documents?.certificate,
                        commissioningCertificates: documents?.commissioningCertificate,
                        guarantee: documents?.guarantee,
                        passport: documents?.passport,
                        manual: documents?.manual,
                        otherDocuments: documents?.otherDocuments
                      }));
                    }}
                  />
                </Stack>
              </Grid>

            </Grid>


          </FormikProvider>
        }


      </PageDialog>


    </>
  );
};
