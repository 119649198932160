import React from "react";
import { SportEquipmentEntity } from "graphql/graphQlApiHooks";
import { IconButton, Menu, Tooltip } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MoreMenuItems } from "./MoreMenuItems";
import { BootstrapTooltip } from "../../../../../../components/BootstrapTooltip";

interface Props {
  sportEquipment: SportEquipmentEntity;
}

export const ActionButtons = ({ sportEquipment }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);


  const handleClickMoreAreas = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreAreas = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <BootstrapTooltip title={"Действия"}>
        <IconButton
          size={"medium"}
          onClick={handleClickMoreAreas}
          aria-expanded={openMenu ? "true" : undefined}
          id="more-button"
          aria-controls="more-menu"
          aria-haspopup="true"
        >
          <MoreVertIcon fontSize="small" sx={{ fontSize: "18px !important" }} />
        </IconButton>
      </BootstrapTooltip>

      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMoreAreas}
        elevation={22}

        sx={{
          maxWidth: 300,
          "& .MuiListItemText-root .MuiTypography-root": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100%",
            display: "flow-root",
          }
        }}

        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}

        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <MoreMenuItems
          onClose={handleCloseMoreAreas}
          sportEquipment={sportEquipment}
        />
      </Menu>
    </>
  );
};
