import React, { useEffect, useState } from "react";
// graphql
import {
  EquipmentServicePeriodEntity,
  SportEquipmentEntity,
  useCreateEquipmentServicePeriodMutation,
  useDeleteEquipmentServicePeriodMutation,
  useGetEquipmentServicePeriodsQuery,
  useGetSportEquipmentByIdQuery,
  useGetSportEquipmentsLazyQuery,
  useUpdateEquipmentServicePeriodMutation
} from "graphql/graphQlApiHooks";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
// components
import PageDialog from "components/Dialogs/PageDialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import Actions from "./Actions";
import AddServicePeriodButton from "./AddServicePeriodButton";
// mui
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { InputBaseProps } from "@mui/material/InputBase/InputBase";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  InputBase,
  LinearProgress,
  Radio,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
// hooks
import { useSnackbar } from "notistack";
import { useDialog } from "../../../../../../providers/DialogProvider";
// utils
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import ruLocale from "date-fns/locale/ru";
import { BootstrapTooltip } from "../../../../../../components/BootstrapTooltip";


// интерфейс для вида работ
const EquipmentServicePeriod = () => {
  const navigate = useNavigate();
  const [dialog, closeDialog] = useDialog();
  const { enqueueSnackbar } = useSnackbar();
  const { sportEquipmentId: id } = useParams();

  // данные по оборудованию
  const { data, error: equipmentError } = useGetSportEquipmentByIdQuery({
    skip: !id,
    variables: { id: id },
    fetchPolicy: "network-only"
  });

  // при ошибке загрузки данных по оборудованию выводим сообщение
  if (equipmentError) {
    enqueueSnackbar("Ошибка загрузки данных об оборудовании", { variant: "error" });
  }

  // данные по видам работ
  const { data: servicePeriodData, error } = useGetEquipmentServicePeriodsQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: "network-only"
  });

  // при ошибке загрузки данных по видам работ выводим сообщение
  if (error) {
    enqueueSnackbar("Ошибка загрузки данных по видам работ", { variant: "error" });
  }

  // данные по видам работ
  const [servicePeriods, setServicePeriods] = useState<EquipmentServicePeriodEntity[]>([]);
  // исходные данные по видам работ
  const [initialRows, setInitialRows] = useState<readonly EquipmentServicePeriodEntity[] | null>(null);
  // флаг загрузки данных
  const [loading, setLoading] = useState(true);

  // при загрузке данных по видам работ заполняем таблицу
  useEffect(() => {
    if (servicePeriodData?.getEquipmentServicePeriods) {
      if (!initialRows) {
        setServicePeriods(servicePeriodData?.getEquipmentServicePeriods as EquipmentServicePeriodEntity[]);
        setInitialRows(servicePeriodData?.getEquipmentServicePeriods as EquipmentServicePeriodEntity[]);
        setLoading(false);
      }
    }
  }, [servicePeriodData]);


  // мутация для добавления вида работ
  const [createEquipmentServicePeriod] = useCreateEquipmentServicePeriodMutation();
  // мутация для удаления вида работ
  const [deleteEquipmentServicePeriod] = useDeleteEquipmentServicePeriodMutation();
  // мутация для обновления вида работ
  const [updateEquipmentServicePeriod] = useUpdateEquipmentServicePeriodMutation();

  // запрос для обновления списка оборудования
  const [getSportEquipments] = useGetSportEquipmentsLazyQuery();
  // переменные для фильтрации списка оборудования
  const { getInput } = useGetSportEquipmentInputVars();

  // обработчик сохранения видов работ
  const saveDataHandleClick = () => {
    // определить какие строки были доьавлены
    const addedRows = servicePeriods.filter((servicePeriod) => {
      return !initialRows?.find((initialRow) => initialRow.id === servicePeriod.id);
    });

    // определить какие строки были удалены
    const deletedRows = initialRows?.filter((initialRow) => {
      return !servicePeriods.find((servicePeriod) => initialRow.id === servicePeriod.id);
    });

    // определить какие строки были изменены
    const updatedRows = servicePeriods.filter((servicePeriod) => {
      const initialRow = initialRows?.find((initialRow) => initialRow.id === servicePeriod.id);
      if (initialRow) {
        return initialRow.name !== servicePeriod.name || initialRow.periodType !== servicePeriod.periodType || initialRow.nextDate !== servicePeriod.nextDate;
      }
      return false;
    });

    // общий массив промисов
    const promises: Promise<any>[] = [];

    // если есть новые строки
    if (addedRows.length > 0) {
      // добавить в массив промисов добавление
      promises.push(...addedRows.map((servicePeriod) => {
        const { name, periodType, nextDate } = servicePeriod;
        return createEquipmentServicePeriod({
          variables: {
            input: {
              sportEquipmentId: id,
              name,
              periodType,
              nextDate
            }
          }
        }).catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error"
          });
        });
      }));

    }

    // если есть измененные строки
    if (updatedRows.length > 0) {
      // добавить в массив промисов обновление
      promises.push(...updatedRows.map((servicePeriod) => {
        const { id: servicePeriodId, name, periodType, nextDate } = servicePeriod;
        return updateEquipmentServicePeriod({
          variables: {
            input: {
              id: servicePeriodId,
              name,
              periodType,
              sportEquipmentId: id
            }
          }
        }).catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error"
          });
        });
      }));
    }

    // если есть удаленные строки
    if (deletedRows.length > 0) {
      // добавить в массив промисов удаление
      promises.push(...deletedRows.map((servicePeriod) => {
        const { id: servicePeriodId } = servicePeriod;
        return deleteEquipmentServicePeriod({
          variables: {
            id: servicePeriodId
          }
        }).catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error"
          });
        });
      }));
    }

    // выполнение промисов
    Promise.all(promises).then(() => {

      // запрос на обновление списка оборудования
      getSportEquipments({
        variables: {
          input: getInput()
        },
        fetchPolicy: "network-only",
        onCompleted: () => {
          enqueueSnackbar("Данные успешно сохранены!");

          // закрыть диалог
          onClose();
        }
      });
    });
  };

  // сброс изменений
  const cancelDataHandleClick = () => {
    setServicePeriods(initialRows as EquipmentServicePeriodEntity[]);
  };

  // закрытие диалога c проверкой на изменения
  const close = () => {
    if (JSON.stringify(initialRows) !== JSON.stringify(servicePeriods)) {
      dialog({
        title: "Данные будут утеряны",
        children: <>
          <DialogContent
            dividers
          >
            <Alert severity={"warning"}>
              Вы уверены что хотите закрыть диалог без сохранения изменений?
              Изменённые данные будут утерены!
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button
              size={"small"}
              onClick={() => {
                navigate("/equipments");
                closeDialog();
              }}
              color={"inherit"}
              variant={"text"}
            >
              Закрыть диалог, с потерей данных
            </Button>
            <Button
              size={"small"}
              onClick={() => {
                closeDialog();
              }}
              color={"inherit"}
              variant={"text"}
            >
              Продолжить редактирование
            </Button>
            <Button
              size={"small"}
              onClick={() => {
                saveDataHandleClick();
                closeDialog();
              }}
              color={"primary"}
              autoFocus
              variant={"contained"}
            >
              Сохранить и закрыть
            </Button>
          </DialogActions>
        </>
      });
    } else {
      onClose();
    }
  };

  // закрытие диалога
  const onClose = () => {
    navigate("/equipments");
  };

  return (
    <PageDialog
      title={"График технического обслуживания"}
      onClose={close}
      maxWidth={"lg"}
      ActionsProps={{
        cancelDataHandleClick,
        initialData: servicePeriodData?.getEquipmentServicePeriods as EquipmentServicePeriodEntity[],
        servicePeriods,
        saveDataHandleClick
      }}
      ActionsComponent={
        Actions
      }
    >
      {loading ? <LinearProgress /> :
        <Stack>
          {data?.getSportEquipmentById && (
            <Box
              sx={{
                borderBottom: `1px solid`,
                borderColor: "divider",
                minHeight: 90
              }}
            >
              <EquipmentInfo
                equipment={data?.getSportEquipmentById as SportEquipmentEntity}
              />
            </Box>
          )}
          <Box>

            <TableHeader/>

            <Box position={"relative"}>
              {servicePeriods.map(item => (
                <Stack
                  key={item.id}
                  direction={"row"}
                  spacing={2}
                  sx={{
                    px: 30,
                    py: 1,
                    borderBottom: `1px solid`,
                    borderColor: "divider",
                    position: "relative"
                  }}
                >
                  <Typography
                    component={"div"}
                    variant={"subtitle1"}
                    pl={1}
                    sx={{ width: "100%" }}
                  >
                    <InputBase
                      sx={{
                        fontWeight: "inherit",
                        height: "100%"
                      }}
                      fullWidth
                      value={item.name}
                      onChange={({ currentTarget: { value } }) => {
                        setServicePeriods(prevState => {
                          const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                          const arr = [...prevState];
                          arr[rowIndex] = { ...arr[rowIndex], name: value };
                          return arr;
                        });
                      }}
                    />
                  </Typography>
                  <Box
                    pl={1}
                    sx={{ width: "100%" }}
                  >
                    <Radio
                      size={"small"}
                      checked={item.periodType === "WEEK"}
                      onChange={({ target: { value } }) => {
                        (item.periodType !== "WEEK") && setServicePeriods(prevState => {
                          const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                          const arr = [...prevState];
                          arr[rowIndex] = { ...arr[rowIndex], periodType: "WEEK" };
                          return arr;
                        });
                      }}
                    />
                  </Box>
                  <Box
                    pl={1}
                    sx={{ width: "100%" }}
                  >
                    <Radio
                      size={"small"}
                      checked={item.periodType === "MONTH"}
                      onChange={({ target: { value } }) => {
                        (item.periodType !== "MONTH") && setServicePeriods(prevState => {
                          const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                          const arr = [...prevState];
                          arr[rowIndex] = { ...arr[rowIndex], periodType: "MONTH" };
                          return arr;
                        });
                      }}
                    />
                  </Box>
                  <Box
                    pl={1}
                    sx={{ width: "100%" }}
                  >
                    <Radio
                      size={"small"}
                      checked={item.periodType === "QUARTER"}
                      onChange={({ target: { value } }) => {
                        (item.periodType !== "QUARTER") && setServicePeriods(prevState => {
                          const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                          const arr = [...prevState];
                          arr[rowIndex] = { ...arr[rowIndex], periodType: "QUARTER" };
                          return arr;
                        });
                      }}
                    />
                  </Box>
                  <Box
                    pl={1}
                    sx={{ width: "100%" }}
                  >
                    <Radio
                      size={"small"}
                      checked={item.periodType === "YEAR"}
                      onChange={({ target: { value } }) => {
                        (item.periodType !== "YEAR") && setServicePeriods(prevState => {
                          const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                          const arr = [...prevState];
                          arr[rowIndex] = { ...arr[rowIndex], periodType: "YEAR" };
                          return arr;
                        });
                      }}
                    />
                  </Box>
                  <Box
                    pl={1}
                    sx={{ width: "100%" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      locale={ruLocale}
                      adapterLocale={"ru"}
                    >
                      <DatePicker
                        inputFormat='dd.MM.yyyy'
                        clearable
                        views={["day", "month", "year"]}
                        value={!isNaN(Number(item.nextDate)) ? dayjs(Number(item.nextDate)).format("MM.DD.YYYY") : item.nextDate}
                        onChange={(newValue) => {
                          setServicePeriods(prevState => {
                            const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                            const arr = [...prevState];
                            arr[rowIndex] = {
                              ...arr[rowIndex],
                              nextDate: dayjs(Number(newValue)).format("MM.DD.YYYY")
                            };
                            return arr;
                          });
                        }}
                        renderInput={({ inputRef, inputProps, InputProps: { endAdornment } }) => (
                          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                            <InputBase
                              ref={inputRef}
                              {...inputProps as InputBaseProps}
                              endAdornment={endAdornment}
                              sx={{
                                height: "100%"
                              }}
                            />
                          </Box>
                        )}

                        okText={"Выбрать"}
                        cancelText={"Отмена"}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      position: "absolute",
                      right: "5%",
                      top: 0
                    }}
                  >
                    <BootstrapTooltip
                      title={"Удалить"}
                    >
                      <IconButton
                        size={"small"}
                        onClick={() => {
                          setServicePeriods(prevState => {
                            const rowIndex = prevState.findIndex(servicePeriod => servicePeriod.id === item.id);
                            const arr = [...prevState];
                            arr.splice(rowIndex, 1);
                            return arr;
                          });
                        }}
                      >
                        <DeleteIcon fontSize={"small"} />
                      </IconButton>
                    </BootstrapTooltip>
                  </Box>
                </Stack>
              ))}
              <Box
                mt={2}
                px={2}
                color={"text.secondary"}
                position={"absolute"}
                width={"100%"}
              >
                <AddServicePeriodButton
                  onAddServicePeriod={(newServicePeriod) => {
                    setServicePeriods(prevState => {
                      const arr = [...prevState];
                      arr.push(newServicePeriod);
                      return arr;
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Stack>
      }
    </PageDialog>
  );
};

//  Типы периода обслуживания
export enum PeriodTypeEnum {
  WEEK = "WEEK",
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}


const TableHeader = () => (<>
  <Stack
    direction={"row"}
    spacing={2}
    sx={{
      px: 30,
      py: 2,
      borderBottom: `1px solid`,
      borderColor: "divider"
    }}
  >
    {
      ["Название вида работ", "1 раз в неделю", "1 раз в месяц", "1 раз в квартал", "1 раз в год", "Дата обслуживания"].map((title, index) => (
        <Typography
          key={index}
          variant={"subtitle2"}
          pl={1}
          sx={{ width: "100%" }}
        >
          {title}
        </Typography>
      ))
    }
    <Box />
  </Stack>
</>);

export default EquipmentServicePeriod;
