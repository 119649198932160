import React from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import CloseIcon from "@mui/icons-material/Close";


const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function InfoDialog({...rest}) {
    const classes = useStyles();
    const {open, close, title, message} = rest

    function handleClose(confirm:boolean) {
        close(confirm)
    }

    function handleConfirm() {
        handleClose(true)
    }

    return <>
        <Dialog
            onClose={()=>handleClose(false)}
            aria-labelledby="info-dialog-title"
            open={open}
        >
            <DialogTitle id="info-dialog-title">
                <Box
                    display={'flex'}
                    alignItems={'flex-end'}
                >
                    <MessageOutlinedIcon
                        color={'primary'}
                        style={{marginRight: '.3em'}}
                    />
                    <span>{title}</span>
                </Box>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={()=>handleClose(false)}
                    size="large"
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom>{message}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleConfirm}
                    color="secondary"
                    size={'small'}

                >
                    Продолжить без сохранения
                </Button>
                <Button
                    autoFocus
                    onClick={handleConfirm}
                    color="primary"
                    size={'small'}
                    variant={"contained"}

                >
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}

InfoDialog.protoTypes = {}

export default InfoDialog;
