import React from 'react';
import {Box, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import EquipmentRequestTable from "./Table";
import Page from "components/Page";
import {useTheme} from "@mui/styles";
import {useStores} from "../../../store";
import {observer} from "mobx-react-lite";


const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flexGrow: 1,
	},
	page: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		position: 'relative',
	},
	pageContent: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		height: '100%',
		width: '100%',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
		}
	},
	pageTitle: {
		padding: theme.spacing(3, 4),
		display: 'flex',
		alignItems: 'center',
		height: 90,
		'& .toolbar': {
			marginLeft: '2rem',
			display: 'flex',
			listStyle: 'none',
			width: '100%',
			'&__item:not(:last-child)': {
				marginRight: '1em',
			}
		}
	},
	mainSection: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	mainSectionContainer: {
		width: 'auto',
		height: '100%',
		position: 'relative',
		overflowX: 'auto',
		padding: theme.spacing(0, 3),
	}
}));

const EquipmentRequestEntity = observer(() => {
	const classes = useStyles();
	const theme = useTheme();
	const {equipmentRequestStore} = useStores();

	return (
			<Page title={'Заявки на оборудование'}>

				<header className={classes.pageTitle}>
					<Typography variant="h1">
						Заявки на оборудование
						<Box
								component={'small'}
								sx={{
									ml: 1,
									fontSize: '.7em',
									color: theme.palette.text.secondary,
									opacity: .5
								}}
						>{equipmentRequestStore?.count}</Box>
					</Typography>
				</header>

				<Box className={classes.pageContent}>
					<main className={classes.mainSection}>
						<div className={classes.mainSectionContainer}>
							<EquipmentRequestTable/>
						</div>
					</main>
				</Box>
			</Page>
	)
});

export default EquipmentRequestEntity;
