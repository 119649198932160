import {
    SportZoneEntity,
    GetSportZonesByObjectIdDocument,
    CreateSportZoneDocument,
    CreateSportZoneInput,
    UpdateSportZoneInput,
    UpdateSportZoneDocument,
    DeleteSportZoneDocument,
} from './../../graphql/graphQlApiHooks';
import {makeAutoObservable} from "mobx";
import RootStore from "../.";

import { sortByCreateAt } from '../../helpers';
import {client} from "../.";



const sportZoneStore = class {
    public rootStore?: RootStore;
    public current?: SportZoneEntity;
    public sportZones: SportZoneEntity[] = [];
    public loading: boolean = true
    public error?: any
    public count: number = 0

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this)
    }

    fetchSportZones(sportObjectId:string) {
        this.current = undefined
        this.sportZones = []
        this.loading = true

        client.query({
            query: GetSportZonesByObjectIdDocument,
            variables: { sportObjectId },
        }).then((result:any)=>{
            this.sportZones = result.data.getSportZonesByObjectId.slice().sort(sortByCreateAt)
            this.loading = false
            this.count = this.sportZones.length
        }).catch((error:any)=>{
            this.loading = false
            this.error = error
        });
    }

    setCurrent = (id: string) => {
        this.current = this.sportZones.find(sportZone => sportZone.id === id) as SportZoneEntity
    };

    update = (input: UpdateSportZoneInput) => client.mutate({
            mutation: UpdateSportZoneDocument,
            variables: { input },
            refetchQueries: [{ query: GetSportZonesByObjectIdDocument, variables: { sportObjectId: input.sportObjectId } }]
        })
        .then(() => this.rootStore?.setSnakeMessage('Объект спорта успешно обновлен.'))
        .catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

    create = (input: CreateSportZoneInput) => client.mutate({
            mutation: CreateSportZoneDocument,
            variables: { input },
            refetchQueries: [{ query: GetSportZonesByObjectIdDocument, variables: { sportObjectId: input.sportObjectId } }]
        })
        .then(() => this.rootStore?.setSnakeMessage('Объект спорта успешно создан.'))
        .catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));


    delete = (id: string) => client.mutate({
            mutation: DeleteSportZoneDocument,
            variables: { id },
            refetchQueries: [{ query: GetSportZonesByObjectIdDocument, variables: { sportObjectId: this.rootStore?.sportObjectsStore.current.id } }]
        })
        .then(() => {
            this.rootStore?.setSnakeMessage('Объект спорта успешно удален.');
            if (this.current?.id === id) this.current = undefined
        })
        .catch((error: any) => this.rootStore?.setSnakeMessage(error.message, 'error'));

}

export default sportZoneStore;
