import React from 'react';
import {observer} from "mobx-react-lite";
import {Box} from "@mui/material";
import useStyles from '../styles'
import StructureMenu from "./Sidebar";
import SportSubZones from "./Main";
import {SettingsContext} from "../index";


interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel = (props: TabPanelProps) => {
	const {children, value, index, ...other} = props;

	return (
			<Box
					sx={{height: '100%', position: 'relative', width: '100%', left: 0}}
					role="tabpanel"
					hidden={value !== index}
					id={`simple-tabpanel-${index}`}
					aria-labelledby={`simple-tab-${index}`}
					{...other}
			>
				{value === index && (
						children
				)}
			</Box>
	);
};

const Structure = observer(() => {
	const classes = useStyles();
	const {tabValue} = React.useContext(SettingsContext)

	return (
			<>
				<TabPanel
						value={tabValue}
						index={0}
				>
					<Box className={classes.pageContent}>

						<section className={classes.leftSection}>
							<aside className={classes.aside}>
								<StructureMenu/>
							</aside>
						</section>

						<Box className={classes.pageContent}>
							<main className={classes.mainSection}>
								<div className={classes.mainSectionContainer}>
									<SportSubZones/>
								</div>
							</main>
						</Box>

					</Box>
				</TabPanel>
			</>
	)
})

export default Structure;
