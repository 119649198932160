import {observer} from "mobx-react-lite";
import {ActionButtonsProps} from "../ActionButtons";
import {useStores} from "../../../../../store";
import {MenuItem} from "@mui/material";
import {SportObjectEntity} from "../../../../../graphql/graphQlApiHooks";
import React from "react";

const MenuItems = observer((props: ActionButtonsProps) => {
	const {
		relocateEquipment,
		onOpenRequestForAdmission,
		onOpenRejectRelocation,
		onOpenAcceptRelocation,
		onOpenRelocateConfirmation,
		onOpenRelocateCompletion,
	} = props;
	const {status} = relocateEquipment;

	const {sportObjectsStore, userStore} = useStores();
	const userSportObjects = userStore?.currentUser?.user?.sportObjects
	const isSourceSportObjectUser = (userSportObjects && userSportObjects.length === 1 && userSportObjects[0] === relocateEquipment.sourceSportObject.id)
	const actions = () => {
		switch (status) {
			case 'REQUEST_SUBMITTED': { // Запрос подан
				if (!isSourceSportObjectUser) {
					return (
							<MenuItem
									onClick={onOpenRequestForAdmission}
							>Запросить оборудование</MenuItem>
					)
				}
				break;
			}
			case 'ACCEPTED_BY_SPORT_OBJECT': { // Запрос принял спорт. объект
				if (!userStore?.currentUser?.user?.isForSportObject) {
					return <>
						<MenuItem
								onClick={onOpenAcceptRelocation}
						>Одобрить</MenuItem>
						<MenuItem
								onClick={onOpenRejectRelocation}
						>Отклонить</MenuItem>
					</>
				}
				break;
			}
			case 'ACCEPTED_NOT_RELOCATED': { // Запрос одобрен, но оборудование не отправлено
				if (relocateEquipment && relocateEquipment?.relocationResponsibleUser) {
					if (relocateEquipment?.relocationResponsibleUser?.login === userStore?.currentUser?.user?.login) {
						return <MenuItem
								onClick={onOpenRelocateConfirmation}
						>Подтвердить отправление</MenuItem>
					}
				}
				break;
			}
			case 'RELOCATION_STARTED': { // Запрос одобрен, но оборудование не перемещено
				if (relocateEquipment && relocateEquipment?.targetSportObject) {
					if (sportObjectsStore?.sportObjects.filter((s: SportObjectEntity) => s.id === relocateEquipment.targetSportObject?.id).length > 0) {
						return <MenuItem
								onClick={onOpenRelocateCompletion}
						>Подтвердить приём</MenuItem>
					}
				}
				break;
			}
		}

		return null
	};

	return actions()
});

export default MenuItems