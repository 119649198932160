import React from 'react';
import {observer} from "mobx-react-lite";
import {
	Box,
	ListItem,
	ListItemText,
	Step,
	StepLabel,
	Stepper,
	Typography
} from "@mui/material";
import {SportObjectEntity} from "../../../graphql/graphQlApiHooks";
import {HighlightedBox} from "../../HighlightedBox";
import Tooltiper from "../../Tooltiper";


interface Props {
	sourceSportObject?: SportObjectEntity | null
	targetSportObject?: SportObjectEntity | null
}

const StepperRelocation = observer((props: Props) => {
	const {sourceSportObject, targetSportObject} = props

	return (
			<Box display={'flex'} flexDirection={'column'} width={'100%'}>
				<ListItem>
					<ListItemText
							secondary={'Движение обрудования'}
					/>
				</ListItem>

				<HighlightedBox
						sx={{
							mt: -1,
							mb: 2,
							p: 2,
							borderRadius: 2,
						}}
				>
					<Stepper sx={{
						'& .MuiStepConnector-root':{
							pr: '6%'
						}
					}}>
						<Step active={Boolean(sourceSportObject?.name)} sx={{
							width: '40%',
						}}>
							<StepLabel >
								<Typography variant={'body2'} sx={{fontSize: '11px'}}>
									Спортобъект нахождения
								</Typography>

									<Tooltiper text={sourceSportObject?.name} props={{
										variant: 'h5',
									}}/>
							</StepLabel>
						</Step>

						<Step active={Boolean(targetSportObject?.name)} sx={{
							width: '40%',
							left: '-5%',
							position: 'relative'
						}}>
							<StepLabel>
								<Typography variant={'body2'} sx={{fontSize: '11px'}}>
									Спортобъект назначения
								</Typography>
									<Tooltiper text={targetSportObject?.name} props={{
										variant: 'h5',
									}}/>
							</StepLabel>
						</Step>
					</Stepper>
				</HighlightedBox>
			</Box>
	);
})

export default StepperRelocation;
