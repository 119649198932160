import { Box, Stack, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { BootstrapTooltip } from "../../../../../../../components/BootstrapTooltip";


interface Props {
  labelText: string,
  helperText?: string,
  required?: boolean
}

export const TextFieldLabel = (props: Props) => {
  const {
    labelText,
    helperText,
    required = false
  } = props;

  return (
    <Stack direction={"row"} alignItems={"center"} display={"inline-flex"} spacing={1}>
      <span>{labelText} {required ? "(обязательное поле)" : ""}</span>
      {helperText && <BootstrapTooltip title={helperText}>
        <Box display={"flex"}>
          <HelpOutlineIcon fontSize={"small"} />
        </Box>
      </BootstrapTooltip>}
    </Stack>
  );
};