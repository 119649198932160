import React, { useState } from "react";
import ExcelToolbar from "./views/ExcelToolbar";
import { useExportSportEquipmentsInExcelLazyQuery } from "graphql/graphQlApiHooks";
import UploaderService from "services/UplodaService";
import { ImportedSportEquipmentRecord } from "./ImportFromExel/types";
import { ImportFromExcel } from "./ImportFromExel";
import Settings from "store/settings";
import {
  sportEquipmentTabActiveVars,
  SportEquipmentTabIdsEnum
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentTabActive";
import { useReactiveVar } from "@apollo/client";
import { sportEquipmentListFiltersVars } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListFilters";
import {
  sportEquipmentListPaginationVars
} from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListPagination";
import { sportEquipmentListSortOrderVar } from "store/reactiveVarsStores/spor-equipmnts/sportEquipmentListSortOrder";
import {
  useGetSportEquipmentInputVars
} from "../../../../../../../store/reactiveVarsStores/spor-equipmnts/getSportEquipmentsInput";
import { useSnackbar } from "notistack";


export default () => {
  const [importLoading, setImportLoading] = useState(false);
  const [importedSportEquipmentRecords, setImportedSportEquipmentRecords] = useState<ImportedSportEquipmentRecord[] | null>(null);


  const tabActive = useReactiveVar(sportEquipmentTabActiveVars);
  const filters = useReactiveVar(sportEquipmentListFiltersVars);
  const pagination = useReactiveVar(sportEquipmentListPaginationVars);
  const sortOrderModel = useReactiveVar(sportEquipmentListSortOrderVar);


  const importSportEquipmentListFromExcel = (event: any) => {
    setImportLoading(true);

    UploaderService.uploadImportSportEquipmentFile(event)
      .then((sportEquipmentRecord) => {
        setImportedSportEquipmentRecords(sportEquipmentRecord);
      })
      .finally(() => {
        setImportLoading(false);
      });

  };

  const [exportSportEquipmentsInExcel, { loading: exportLoading }] = useExportSportEquipmentsInExcelLazyQuery();
  const {getInput} = useGetSportEquipmentInputVars()
  const { enqueueSnackbar } = useSnackbar();
  const exportSportEquipmentListInExcel = () => {
    exportSportEquipmentsInExcel({
      variables: {
        input: getInput()
      }
    }).then((res) => {
      try {
        const data = res.data.exportSportEquipmentsInExcel;
        const url = data.url;
        window.location.replace(`${Settings.server}${url}`);
      } catch (e) {
        enqueueSnackbar("Ошибка при экспорте", { variant: "error" });
      }
    }).catch((err) => {
      enqueueSnackbar(err?.message, { variant: "error" });
    });
  };


  return (
    <>
      <ExcelToolbar
        importLoading={importLoading}
        exportLoading={exportLoading}
        actions={{
          importSportEquipmentListFromExcel,
          exportSportEquipmentListInExcel
        }}
      />

      <ImportFromExcel
        open={!!importedSportEquipmentRecords}
        close={() => {
          setImportedSportEquipmentRecords(null);
        }}
        importedSportEquipmentRecords={importedSportEquipmentRecords}
      />

    </>
  );
};