import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Autocomplete, FormControl, Stack, TextField, Typography } from "@mui/material";
import {
  SportEquipmentEntity,
  SportObjectEntity,
  SportSubZoneEntity,
  SportZoneEntity,
  useGetAllSportObjectsQuery
} from "graphql/graphQlApiHooks";
import { FormikErrors, FormikTouched } from "formik/dist/types";
import { GroupHeader, GroupItems } from "./SportEquipmentCategory";

interface Props {
  vertical?: boolean;
  touched?: FormikTouched<any>;
  errors?: FormikErrors<any>;
  sportEquipment: SportEquipmentEntity;
  setSportEquipment: (values: React.SetStateAction<SportEquipmentEntity>) => void;

  handleBlur?: (event: React.FocusEvent<any>) => void;
}

export const SportEquipmentLocation = (props: Props) => {
  const {
    vertical,
    errors,
    touched,
    sportEquipment,
    setSportEquipment,
    handleBlur
  } = props;


  const isEditable = sportEquipment?.isEditable === null || sportEquipment?.isEditable === undefined ? true : sportEquipment?.isEditable;

  const [sportObject, setSportObject] = useState<SportObjectEntity | null>(null);
  const [sportZone, setSportZone] = useState<SportZoneEntity | null>(null);
  const [sportSubZone, setSportSubZone] = useState<SportSubZoneEntity | null>(null);

  const { data: getSportObjects } = useGetAllSportObjectsQuery();


  const sportObjects: SportObjectEntity[] = useMemo(() => {
    const sportObjects = getSportObjects?.getAllSportObjects as SportObjectEntity[];
    if (sportObjects) {
      return sportObjects;
    }
    return [];

  }, [
    getSportObjects?.getAllSportObjects
  ]);


  const sportZones: SportZoneEntity[] = useMemo(() => {
    const sportObjects = getSportObjects?.getAllSportObjects as SportObjectEntity[];

    if (sportObject?.sportZones) {
      return sportObject.sportZones;
    }

    if (sportObjects) {
      return [...sportObjects].map(item => item.sportZones).flat();
    }

    return [];
  }, [
    getSportObjects?.getAllSportObjects,
    sportObject
  ]);

  const sportSubZones: SportSubZoneEntity[] = useMemo(() => {
    const sportObjects = getSportObjects?.getAllSportObjects as SportObjectEntity[];
    const allSportSubZones = getSportObjects?.getAllSportObjects?.map(item => item?.sportSubZones)?.flat() || [] as SportSubZoneEntity[];

    if (sportZone?.sportSubZones) {
      return allSportSubZones.filter(item => [...sportZone.sportSubZones].map(ssz => ssz.id).includes(item.id)) as SportSubZoneEntity[];
    }

    if (sportObject?.sportSubZones) {
      return allSportSubZones.filter(item => [...sportObject.sportSubZones].map(ssz => ssz.id).includes(item.id)) as SportSubZoneEntity[];
    }

    if (sportObjects) {
      return [...sportObjects].map(item => item.sportSubZones).flat() as SportSubZoneEntity[];
    }

    return [];
  }, [
    getSportObjects?.getAllSportObjects,
    sportObject,
    sportZone
  ]);

  function onChangeSportObjectHandler(value: SportObjectEntity | null) {
    const allSportZones = getSportObjects?.getAllSportObjects?.map(item => item?.sportZones)?.flat() || [];
    const sportZones = allSportZones?.filter(item => item.sportObject?.id === value?.id) || [];
    const sportZone = sportZones?.length === 1 ? sportZones[0] as SportZoneEntity : null;

    const allSportSubZones = getSportObjects?.getAllSportObjects?.map(item => item?.sportSubZones)?.flat() || [];
    const sportSubZones = allSportSubZones?.filter(item => item.sportZone?.id === sportZone?.id) || [];
    const sportSubZone = sportSubZones?.length === 1 ? sportSubZones[0] as SportSubZoneEntity : null;

    setSportObject(value);
    setSportZone(sportZone);
    setSportSubZone(sportSubZone);

    setSportEquipment(prevState => ({
      ...prevState,
      sportObject: value ? value : null,
      sportZone: sportZone ? sportZone : null,
      sportSubZone: sportSubZone ? sportSubZone : null
    }));
  }

  function onChangeSportZoneHandler(value: SportZoneEntity | null) {
    const allSportSubZones = getSportObjects?.getAllSportObjects?.map(item => item?.sportSubZones)?.flat() || [];
    const allSportZones = getSportObjects?.getAllSportObjects?.map(item => item?.sportZones)?.flat() || [];
    const sportZone = allSportZones?.find(item => item?.id === value?.id) as SportZoneEntity;
    const sportObject = sportObjects.find(item => item?.id === value?.sportObject?.id) || null;
    const sportSubZones = allSportSubZones?.filter(item => [...value?.sportSubZones]?.map(sz => sz.id).includes(item?.id));
    const sportSubZone = sportSubZones?.length === 1 ? sportSubZones[0] as SportSubZoneEntity : null;

    setSportObject(sportObject);
    setSportZone(sportZone);
    setSportSubZone(sportSubZone);


    setSportEquipment(prevState => ({
      ...prevState,
      sportObject: sportObject ? sportObject : null,
      sportZone: sportZone ? sportZone : null,
      sportSubZone: sportSubZone ? sportSubZone : null
    }));
  }

  function onChangeSportSubZoneHandler(value: SportSubZoneEntity | null) {
    const allSportZones = getSportObjects?.getAllSportObjects?.map(item => item?.sportZones)?.flat() || [];
    const sportZone = allSportZones?.find(item => item?.id === value?.sportZone?.id) as SportZoneEntity || null;
    const sportObject = getSportObjects?.getAllSportObjects?.find(item => item?.id === sportZone?.sportObject?.id) as SportObjectEntity || null;

    setSportObject(sportObject);
    setSportZone(sportZone);
    setSportSubZone(value);


    setSportEquipment(prevState => ({
      ...prevState,
      sportObject: sportObject ? sportObject : null,
      sportZone: sportZone ? sportZone : null,
      sportSubZone: value ? value : null
    }));
  }

  useEffect(() => {
    if (sportEquipment?.sportObject?.id && !sportObject && sportObjects.length > 0) {
      setSportObject(sportObjects.find(item => item.id === sportEquipment.sportObject.id));
    }
    if (sportEquipment?.sportZone?.id && !sportZone && sportZones.length > 0) {
      setSportZone(sportZones.find(item => item.id === sportEquipment.sportZone.id));
    }
    if (sportEquipment?.sportSubZone?.id && !sportSubZone && sportSubZones.length > 0) {
      setSportSubZone(sportSubZones.find(item => item.id === sportEquipment.sportSubZone.id));
    }
  }, [
    sportEquipment?.sportObject?.id,
    sportEquipment?.sportZone?.id,
    sportEquipment?.sportSubZone?.id,
    sportObject,
    sportObjects,
    sportZone,
    sportZones,
    sportSubZone,
    sportSubZones
  ]);


  return (
    <>
      <Stack spacing={3}>
        <Typography variant={"h5"}>
          Текущее расположение оборудования
        </Typography>
        <Stack
          direction={vertical ? "column" : "row"}
          spacing={vertical ? 2 : 1}
        >
          <FormControl
            size={"small"}
            fullWidth
          >
            <Autocomplete
              value={sportObject}
              id='sportObject'
              disabled={sportObjects.length === 0}
              readOnly={!isEditable}

              options={
                sportObjects
                  .slice()
                  .sort((a, b) => -b?.name.localeCompare(a?.name))
              }
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: SportObjectEntity) => onChangeSportObjectHandler(value)}
              {...{
                ...(!isEditable && {
                  popupIcon: null
                })
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  autoComplete='off'
                  label='Спортобъект'
                  size={"small"}
                  placeholder='Выберите спортобъект'
                  variant={"filled"}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}

                  name={"sportObjectId"}

                  onBlur={handleBlur}

                  {
                    ...{
                      ...(touched?.sportObjectId && errors?.sportObjectId && { error: true }),
                      ...(touched?.sportObjectId && errors?.sportObjectId && { helperText: errors?.sportObjectId as ReactNode })
                    }
                  }

                />
              )}
            />
          </FormControl>

          <FormControl
            size={"small"}
            fullWidth
          >
            <Autocomplete
              value={sportZone}
              id='sportZone'
              disabled={sportZones.length === 0}
              readOnly={!isEditable}
              {...{
                ...(!isEditable && {
                  popupIcon: null
                })
              }}
              options={
                sportZones
                  .slice()
                  .sort((a, b) => -b?.name.localeCompare(a?.name))
                  .sort((a, b) => -b?.sportObject?.name.localeCompare(a?.sportObject?.name))
              }
              groupBy={(option) => option?.sportObject?.name}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: any) => onChangeSportZoneHandler(value)}

              renderInput={params => (
                <TextField
                  {...params}
                  autoComplete='off'
                  label='Объект спорта'
                  size={"small"}
                  placeholder='Выберите объект спорта'
                  variant={"filled"}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}

                  name={"sportZoneId"}

                  onBlur={handleBlur}
                  {
                    ...{
                      ...(touched?.sportZoneId && errors?.sportZoneId && { error: true }),
                      ...(touched?.sportZoneId && errors?.sportZoneId && { helperText: errors?.sportZoneId as ReactNode })
                    }
                  }

                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </FormControl>
          <FormControl
            size={"small"}
            fullWidth
          >
            <Autocomplete
              value={sportSubZone}
              id='sportSubZone'
              disabled={sportSubZones.length === 0}
              readOnly={!isEditable}
              {...{
                ...(!isEditable && {
                  popupIcon: null
                })
              }}
              options={
                sportSubZones
                  .slice()
                  .sort((a, b) => -b?.name.localeCompare(a?.name))
                  .sort((a, b) => -b?.sportZone?.name.localeCompare(a?.sportZone?.name))
              }
              groupBy={(option) => option?.sportZone?.name}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              onChange={(e, value: any) => onChangeSportSubZoneHandler(value)}

              renderInput={params => (
                <TextField
                  {...params}
                  autoComplete='off'
                  label='Спортзона'
                  size={"small"}
                  placeholder='Выберите спортзону'
                  variant={"filled"}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}

                  name={"sportSubZoneId"}

                  onBlur={handleBlur}
                  {
                    ...{
                      ...(touched?.sportSubZoneId && errors?.sportSubZoneId && { error: true }),
                      ...(touched?.sportSubZoneId && errors?.sportSubZoneId && { helperText: errors?.sportSubZoneId as ReactNode })
                    }
                  }
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </FormControl>
        </Stack>
      </Stack>
    </>
  );
};
