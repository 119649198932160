import makeStyles from "@mui/styles/makeStyles";
import { lighten, Theme } from "@mui/material/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiDataGrid-main": {
      "& .MuiDataGrid-row": {
        "& $cell": {
          width: "100%",
          maxHeight: "70px !important",
          overflow: "hidden"
        }
      }
    }
  },
  status: {
    display: "inline-flex",
    maxWidth: "100%",
    margin: "0 auto",
    overflow: "hidden",
    alignItems: "center",
    height: 'auto',
    padding: theme.spacing(.7, 1.5),
    fontSize: '12px',
    lineHeight: '12px',
    borderRadius: '50px',
    fontWeight: 'bold',
    '& span': {
      width: 'inherit',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '&.created': {
      color: theme.palette.text.secondary,
      background: lighten(theme.palette.text.secondary, 0.8),
    },
    [`&.created $statusIcon`]: {
      fontSize: '12px',
    },
    "&.inTransportService": {
      color: theme.palette.primary.main,
      background: lighten(theme.palette.primary.main, 0.8),
    },
    "&.returnedIncomplete": {
      color:  theme.palette.error.main,
      background: lighten( theme.palette.error.main, 0.8),
    },
    "&.returnedComplete": {
      color: theme.palette.success.main,
      background: lighten(theme.palette.success.main, 0.8),
    },
  },
  statusIcon: {
    marginRight: ".5rem",
    lineHeight: '12px',
    fontSize: '14px',
  },
  popper: {
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0 3px 12px rgba(27,31,35,.15)",
    borderRadius: 3,
    width: 300,
    zIndex: 1,
    fontSize: 13,
    color: "#586069",
    backgroundColor: "#f6f8fa"
  },
  header: {
    borderBottom: "1px solid #e1e4e8",
    padding: "8px 10px",
    fontWeight: 600
  },
  inputBase: {
    padding: 10,
    width: "100%",
    borderBottom: "1px solid #dfe2e5",
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      padding: 8,
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      border: "1px solid #ced4da",
      fontSize: 14,
      "&:focus": {
        boxShadow: `rgba(${theme.palette.primary.main}, .25) 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: "#586069",
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    "&[aria-selected=\"true\"]": {
      backgroundColor: "transparent"
    },
    "&[data-focus=\"true\"]": {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  iconSelected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  color: {
    width: 14,
    height: 14,
    flexShrink: 0,
    borderRadius: 3,
    marginRight: 8,
    marginTop: 2
  },
  text: {
    flexGrow: 1
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  },
  cell: {}
}));