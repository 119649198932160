import {EquipmentRelocationRequestEntity} from "../../../../../graphql/graphQlApiHooks";
import {Box, Button} from "@mui/material";
import React from "react";
import {DepartmentEnum} from "../../../../../store/stores/department.enum";
import {
	EquipmentRelocationRequestStatusEnum
} from "../../../../../store/stores/equipment-relocation-request-status.enum";

export interface ActionButtonsProps {
	relocateEquipment: EquipmentRelocationRequestEntity,
	onOpenRequestForAdmission: () => void,
	onOpenAcceptRelocation: () => void,
	onOpenRejectRelocation: () => void,
	onOpenRelocateConfirmation: () => void,
	onOpenRelocateCompletion: () => void,
	onOpenAcceptTransportRequest: () => void,
	onOpenRejectTransportRequest: () => void,
}

const ActionButtons = (props: ActionButtonsProps) => {
	const {
		relocateEquipment,
		onOpenRequestForAdmission,
		onOpenAcceptRelocation,
		onOpenRejectRelocation,
		onOpenRelocateConfirmation,
		onOpenRelocateCompletion,
		onOpenAcceptTransportRequest,
		onOpenRejectTransportRequest,
	} = props;

	const {status} = relocateEquipment;

	const actions = () => {
		const userDepartments = JSON.parse(localStorage.getItem('user_departments')) || []
		const userSportObjectIds = JSON.parse(localStorage.getItem('sportObjectIds')) || []
		const isSourceSportObjectUser =
				userSportObjectIds?.includes(relocateEquipment.sourceSportObject.id) &&
				!userDepartments.includes(DepartmentEnum.PlanningAndOrganizationPaidServices) &&
				!userDepartments.includes(DepartmentEnum.Admins)

		switch (status) {
			case EquipmentRelocationRequestStatusEnum.RequestSubmitted: { // Запрос подан
				if (!isSourceSportObjectUser) {
					return (
							<Button
									size={'small'}
									onClick={onOpenRequestForAdmission}
									variant={'contained'}
									color={'info'}
							>
								Запросить оборудование
							</Button>
					)
				}
				break;
			}
			case EquipmentRelocationRequestStatusEnum.AcceptedBySportObject: { // Запрос принял спорт. объект

				if (userDepartments?.includes(DepartmentEnum.PlanningAndOrganizationPaidServices)) {
					return <>
						<Button
								size={'small'}
								color={'secondary'}
								onClick={onOpenRejectRelocation} // onOpenAcceptRelocation
						>
							Отклонить
						</Button>
						<Button
								size={'small'}
								variant={'contained'}
								onClick={onOpenAcceptRelocation} // onOpenRejectRelocation
						>
							Одобрить
						</Button>
					</>
				}

				break;
			}
			case EquipmentRelocationRequestStatusEnum.AcceptedByCommercialDepartment: { // Запрос одобрен коммерческим отделом

				if (userDepartments?.includes(DepartmentEnum.EquipmentAcceptanceAndDiagnostics)) {
					return <>
						<Button
								size={'small'}
								color={'secondary'}
								onClick={onOpenRejectTransportRequest} // onOpenAcceptRelocation
						>
							Отклонить
						</Button>
						<Button
								size={'small'}
								variant={'contained'}
								onClick={onOpenAcceptTransportRequest} // onOpenRejectRelocation
						>
							Одобрить
						</Button>
					</>
				}

				break;
			}
			case EquipmentRelocationRequestStatusEnum.AcceptedByAcceptanceDepartment: { // Запрос одобрен, но оборудование не отправлено

				if (userSportObjectIds?.includes(relocateEquipment?.sourceSportObject.id)) {
					return (
							<Button
									size={'small'}
									variant={'contained'}
									onClick={onOpenRelocateConfirmation} // onOpenRelocateConfirmation
							>
								Подтвердить отправление
							</Button>
					)
				}

				break;
			}
			case EquipmentRelocationRequestStatusEnum.RelocationStarted: { // Запрос одобрен, но оборудование не перемещено

				if (userSportObjectIds?.includes(relocateEquipment?.targetSportObject.id)) {
					return (
							<Button
									size={'small'}
									variant={'contained'}
									onClick={onOpenRelocateCompletion} //onOpenRelocateCompletion
							>
								Подтвердить приём
							</Button>
					)
				}

				break;
			}
			case EquipmentRelocationRequestStatusEnum.Relocated:
			case EquipmentRelocationRequestStatusEnum.RejectedByCommercialDepartment:
			case EquipmentRelocationRequestStatusEnum.RejectedByAcceptanceDepartment:
				return null

			default: {
				return null
			}
		}
	};

	return (
			<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						"& .MuiButton-root": {
							px: 1,
							py: .5,
							mr: 1,
							fontSize: '12px',
							"&:last-child": {
								mr: 0
							}
						}
					}}
			>
				{actions()}
			</Box>
	);
};

export default ActionButtons