import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {
	Avatar,
	Box,
	Button, CardHeader, Checkbox, Chip,
	TextField,
} from "@mui/material";
import {useStores} from "../../../../store";
import {
	AcceptRequestInput,
	EquipmentRelocationRequestEntity,
} from "../../../../graphql/graphQlApiHooks";
import {Autocomplete, LoadingButton} from "@mui/lab";
import Dialog from "components/Dialogs/Dialog";
import EquipmentInfo from "components/Dialogs/Titles/EquipmentInfo";
import StepperRelocation from "components/Dialogs/Titles/StepperRelocation";
import Settings from "../../../../store/settings";
import {escapeRegExp} from "../../Settings/SportObjects/Main";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
//@ts-ignore
const tr = new CyrillicToTranslit()


interface Props {
	data?: EquipmentRelocationRequestEntity | null
	open: boolean
	onClose: () => void
	rejectButton?: any
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

interface Errors {
	notifiableUsers: boolean;
	responsibleUser: boolean;
}

const AcceptRelocation = observer((props: Props) => {
	const {
		data,
		open,
		onClose,
		rejectButton
	} = props;

	const {userStore,equipmentRelocationRequestStore} = useStores();
	const {users, getCurrentDepartmentName} = userStore;

	const [notifiableUsers, setNotifiableUsers] = useState<any>([]);
	const [responsibleUser, setResponsibleUser] = useState<any>(null);
	const [comment, setComment] = useState<string>('');
	const [errorsHandler, setErrorsHandler] = useState<Errors>({
		notifiableUsers: false,
		responsibleUser: false
	});
	const [submitting, setSubmitting] = useState(false);

	const [sportObjectsGroups, setSportObjectsGroups] = useState<any>([]);
	const loading = open && sportObjectsGroups.length === 0;


	const onChangeNotifiableUsers = (value: any) => {
		setErrorsHandler({...errorsHandler, notifiableUsers: false})
		setNotifiableUsers(value)
	}
	const onChangeResponsibleUser = (value: any) => {
		setErrorsHandler({...errorsHandler, responsibleUser: false})
		setResponsibleUser(value)
	}
	const onChangeComment = (value: string) => {
		setComment(value)
	}
	const handleConfirm = () => {
		setSubmitting(true)
		const input:AcceptRequestInput = {
			equipmentRelocationRequestId: data?.id as string,
			targetSportObjectId: data?.targetSportObject?.id as string,
			notifiableUserIds: notifiableUsers.map((user:any) => user.id),
			comments: (comment !== '') ? comment : null,
		}

		equipmentRelocationRequestStore.accept(input).then(() => {
			handleClose()
		}).catch((error: any) => {
			console.error(error.message)
		}).finally(()=>{
			setSubmitting(false)
		})
	}

	const handleClose = () => {
		setSportObjectsGroups([]);
		setNotifiableUsers([]);
		setResponsibleUser(null)
		setComment('')
		setErrorsHandler({
			notifiableUsers: false,
			responsibleUser: false
		})
		onClose();
	};

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}
		let groupsOptions: any = []
		let otherUsers: any = []

		if (sportObjectsGroups.length === 0 && users && users?.length > 0 && data?.sourceSportObject && open) {
			users
					.map((user: any) => {
						if (process.env.NODE_ENV === 'production' && Settings.testUsers.includes(user.login)) return undefined
						if (!user?.fio && !Settings.testUsers.includes(user.login)) {
							let surname = tr.reverse(user.login);
							surname = surname.charAt(0).toUpperCase() + surname.slice(1);
							const firstName = surname.slice(-2).charAt(0).toUpperCase(),
									lastName = surname.slice(-1).toUpperCase(),
									fio = `${surname.slice(0, -2)} ${firstName}.${lastName}.`;

							return {...user, fio: fio}
						}
						return {...user}
					}).filter((user: any) => (user))
					.forEach((user: any) => {
						if (user.sportObjects?.filter((item: any) => data?.targetSportObject?.id === item.id).length > 0) {
							groupsOptions = [...groupsOptions,
								{...user, group: 'Спортобъект назначения', order: '2'}
							]
						} else if (user.sportObjects?.filter((item: any) => data?.sourceSportObject?.id === item.id).length > 0) {
							groupsOptions = [...groupsOptions,
								{...user, group: 'Спортобъект нахождения', order: '1'}
							]
						} else if (!user.isForSportObject) {
							groupsOptions = [...groupsOptions,
								{...user, group: 'Группа администраторов', order: '3'}
							]
						} else {
							otherUsers = [...otherUsers, user]
						}
					})

			const departments = Array.from(new Set(otherUsers.map((user: any) => user.sportObjects).flat().map((department: any) => department?.name).filter((department: any) => (department))))

			let otherUsersGroups = departments.map((department) => {
				return otherUsers.filter((user: any) => user?.sportObjects?.find((sportObject: any) => sportObject.name === department)).map((user: any) => ({
					...user,
					group: department,
					order: department
				}))
			}).flat()

			const withoutSportObjectsUsers = otherUsers.filter((user: any) => (!user?.sportObjects)).map((user: any) => ({
				...user,
				group: 'Без категории',
				order: 'z'
			}))

			if (active) {
				setSportObjectsGroups(groupsOptions.concat(otherUsersGroups).concat(withoutSportObjectsUsers))
			}
		}

		return () => {
			active = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, sportObjectsGroups,
		data?.sourceSportObject,
		open]);

	const filterOptions = (options: any, state: any) => {
		const {inputValue} = state;
		if (!inputValue) {
			return options;
		}
		const searchRegex = new RegExp(escapeRegExp(inputValue), 'i');

		return options.filter((row: any) => {
			return Object.keys(row).some((field) => {
				return row[field] && searchRegex.test(JSON.stringify(row[field]).toString());
			});
		})
	}

	return (

			<Dialog
					open={open}
					onClose={handleClose}
					title={'Выберите спортобъект назначения'}
					titles={[
						<EquipmentInfo equipment={data?.equipment} relocationCreatedAt={data?.createdAt}/>,
						<StepperRelocation targetSportObject={data?.targetSportObject}
						                   sourceSportObject={data?.sourceSportObject}/>
					]}
					actions={<>
						{rejectButton}
						<LoadingButton
								loading={submitting}
								variant={'contained'}
								onClick={handleConfirm}
						>Одобрить перемещение</LoadingButton>
					</>}
			>
				<Box sx={{mb: 2}}>
					<Autocomplete
							multiple
							size="small"
							limitTags={2}
							id="notifiableUsersSelect"
							value={notifiableUsers}
							options={sportObjectsGroups.sort((a: any, b: any) => -b.order.localeCompare(a.order))}
							groupBy={(option) => option.group}
							disableCloseOnSelect
							getOptionLabel={(option: any) => (option?.fio) ? option.fio : option.login}
							isOptionEqualToValue={(option, value) => option?.id === value?.id}
							filterOptions={filterOptions}
							renderOption={(props, option, {selected}) => (
									<li  {...props} key={`${option.group}_${option.id}`}>
										<Checkbox
												icon={icon}
												checkedIcon={checkedIcon}
												style={{marginRight: 8}}
												checked={selected}
										/>
										<CardHeader
												sx={{
													p: 0,
													width: '100%'
												}}
												avatar={
													<Avatar sx={{
														bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
														color: 'white !important',
														fontSize: '8px !important',
														width: '20px',
														height: '20px'
													}}>{option.login.substring(0, 2).toUpperCase()}</Avatar>
												}
												title={(option?.fio) ? option.fio : option.login}
												subheader={
													(option.isForSportObject) ? `${(option?.sportObjects) ? option?.sportObjects?.map((item: any) => item?.name).join(', ') : ''}` : (
															`${(option?.departments) ? option?.departments?.map((item: any) => getCurrentDepartmentName(item)).join(', ') : ''}`
													)
												}
												action={
													<Box
															sx={{
																fontSize: '12px',
																marginLeft: 'auto',
																opacity: '.8'
															}}
													>
														{option.login}
													</Box>

												}/>
									</li>
							)}
							style={{width: 500}}
							renderInput={(params) => (
									<TextField
											{...params}
											label="Уведомить сотрудников"
											placeholder="Список сотрудников"
											error={errorsHandler.notifiableUsers}
											helperText={(errorsHandler.notifiableUsers) ? 'Выберите сотрудников' : undefined}
									/>
							)}
							renderTags={(users, getTagProps) => {
								return users.map((option, index) => (
										<Chip {...getTagProps({index})} key={`${option?.group}_${option?.id}_${index}`} label={(option?.fio) ? option.fio : option.login}
										      size={'small'}
										      avatar={<Avatar sx={{
											      bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',
											      color: 'white !important',
											      fontSize: '8px !important',
										      }}>{option.login.substring(0, 2).toUpperCase()}</Avatar>}
										/>
								));
							}}
							onChange={(event, value) => {
								onChangeNotifiableUsers(value)
							}}
					/>

					{/*<Autocomplete*/}
					{/*		sx={{mt: 3}}*/}
					{/*		size="small"*/}
					{/*		id="checkboxes-tags-demo"*/}
					{/*		value={responsibleUser}*/}
					{/*		options={sportObjectsGroups.sort((a: any, b: any) => -b.order.localeCompare(a.order))}*/}
					{/*		groupBy={(option) => option.group}*/}
					{/*		getOptionLabel={(option: any) => (option?.fio) ? option.fio : option.login}*/}
					{/*		filterOptions={filterOptions}*/}
					{/*		renderOption={(props, option) => (*/}
					{/*				<li  {...props} key={`${option.group}_${option.id}`}>*/}
					{/*					<CardHeader*/}
					{/*							sx={{*/}
					{/*								p: 0,*/}
					{/*								width: '100%'*/}
					{/*							}}*/}
					{/*							avatar={*/}
					{/*								<Avatar sx={{*/}
					{/*									bgcolor: (!option.isForSportObject) ? 'secondary.main' : 'primary.main',*/}
					{/*									color: 'white !important',*/}
					{/*									fontSize: '8px !important',*/}
					{/*									width: '20px',*/}
					{/*									height: '20px'*/}
					{/*								}}>{option.login.substring(0, 2).toUpperCase()}</Avatar>*/}
					{/*							}*/}
					{/*							title={(option?.fio) ? option.fio : option.login}*/}
					{/*							subheader={*/}
					{/*								(option.isForSportObject) ? `${(option?.sportObjects) ? option?.sportObjects?.map((item: any) => item?.name).join(', ') : ''}` : (*/}
					{/*										`${(option?.departments) ? option?.departments?.map((item: any) => getCurrentDepartmentName(item)).join(', ') : ''}`*/}
					{/*								)*/}
					{/*							}*/}
					{/*							action={*/}
					{/*								<Box*/}
					{/*										sx={{*/}
					{/*											fontSize: '12px',*/}
					{/*											marginLeft: 'auto',*/}
					{/*											opacity: '.8'*/}
					{/*										}}*/}
					{/*								>*/}
					{/*									{option.login}*/}
					{/*								</Box>*/}

					{/*							}*/}
					{/*					/>*/}
					{/*				</li>*/}
					{/*		)}*/}
					{/*		style={{width: 500}}*/}
					{/*		renderInput={(params) => (*/}
					{/*				<TextField*/}
					{/*						{...params}*/}
					{/*						label="Ответсвенный за перемещение*"*/}
					{/*						placeholder="Список сотрудников"*/}
					{/*						error={errorsHandler.responsibleUser}*/}
					{/*						helperText={(errorsHandler.responsibleUser) ? 'Выберите ответсвенного за перемещение' : undefined}*/}
					{/*				/>)*/}
					{/*		}*/}
					{/*		onChange={(event, user) => onChangeResponsibleUser(user)}*/}
					{/*/>*/}

				</Box>
				<TextField
						size="small"
						margin="dense"
						id="AcceptRelocationComment"
						type="text"
						fullWidth
						multiline={true}
						rows={5}
						placeholder={'Комментарий к перемещению'}
						value={comment}
						onChange={({target: {value}}) => onChangeComment(value)}
				/>

			</Dialog>
	);
})

export default AcceptRelocation;
